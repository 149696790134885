import { store } from '@/store/store'
import * as Sentry from '@sentry/browser';

export default {
  log: function (type, message, extraInfo) {
    if (process.env.NODE_ENV !== 'production') {
      console.log(`${type}:`, message, extraInfo)
    }
  },

  error: function (error) {
    store.dispatch(`applicationLog/log`, error)
    if (process.env.NODE_ENV !== 'production') {
      console.error(`Error:`, JSON.stringify(error))
    }
    if (process.env.NODE_ENV !== 'development') {
      Sentry.withScope((scope) => {
        scope.setExtra("extraInfo", JSON.stringify(error));
        Sentry.captureMessage('Error: ' + error);
      });
    }
  },

  warn: function (message, extraInfo='') {
    if (process.env.NODE_ENV !== 'production') {
      console.warn(`Warn:`, message, extraInfo)
    }
    if (process.env.NODE_ENV !== 'development') {
      Sentry.captureMessage('Warn: ' + message);
    }
  },

  info: function (message, extraInfo='') {
    if (process.env.NODE_ENV === 'development') {
      console.info(`Info:`, message, extraInfo)
    }
  },

  debug: function (message, extraInfo='') {
    if (process.env.NODE_ENV !== 'production') {
      console.debug(`Debug:`, message, extraInfo)
    }
  }
}
