export const ANTI_SPAM_STATUS_NONE = 0;
export const ANTI_SPAM_STATUS_DOMAIN = 1;
export const ANTI_SPAM_STATUS_ALIAS = 2;
export const ANTI_SPAM_DESTINATION_DEFAULT_PORT = 25;
export const ANTI_SPAM_DESTINATION_DEFAULT_DNS_ACTION = "none";
export const DOMAIN_STATUS_CANCELED = 8;
export const DOMAIN_DNS_DEFAULT_TTL = 3600;
export const DOMAIN_REDIRECT_TYPE_NONE = 0;
export const DOMAIN_REDIRECT_TYPE_301_REDIRECT = 1;
export const DOMAIN_REDIRECT_TYPE_FRAME_REDIRECT = 2;

export const MAIL_TYPE_SHARED_MAIL = 1;
export const MAIL_TYPE_EXCHANGE_ONLINE = 2;
export const MAIL_TYPE_CUSTOM = 3; // Unused
export const MAIL_TYPE_NONE = 4;
export const MAIL_TYPE_DOMAIN_ALIAS = 5;
export const MAIL_TYPE_SHARED_MAIL_PREPARED = 6;
export const MAIL_TYPE_SHAREDMAIL_DELETE_PENDING = 7;

export const PASSWORD_STRENGTH_MIN = 3; // Minimum password strength 0-4
export const PASSWORD_LENGTH_MIN = 10; // Minimum password length
export const VIRTUAL_MACHINES_DEFAULT_INSTALLER_ID =
  "CBCAF04A-2D34-4765-B03C-F32E52A4B94D";

export const SPLA_LICENSES_MAX_MONTHS = 24;
export const AUTO_DNS_HISTORY_MAX_MONTHS = 24;

export const USER_CONTEXT_ID_SUB_USER = 40;

export const EXPORT_EXCLUDE_FIELDS = ["meta"];
export const DEFAULT_LANGUAGE = "nl-NL";

export const SENTRY_MAX_SIZE = 50000;

export const CHECKLIST_ITEM = 0;
export const CHECKLIST_CATEGORY = 1;

export const EQUIPMENT_TYPE_SERVER = 1;
export const EQUIPMENT_TYPE_NETWORK_SWITCH = 2;
export const EQUIPMENT_TYPE_POWER_SWITCH = 3;
export const EQUIPMENT_TYPE_VIRTUAL_MACHINE = 4;

export const GRAPH_GREEN = "#54c67e";
export const GRAPH_RED = "#fd6d5b";
export const GRAPH_GREY = "#bbbbbb";

export const REPORTS_TARGET_AVAILABILITY = 99.9;

export const CATEGORY_VIRTUAL_MACHINE = "VM";
export const CATEGORY_SERVER = "SRV";
export const CATEGORY_NETWORK_SWITCH = "NWS";
export const CATEGORY_DOMAIN = "DOM";
export const CATEGORY_NETWORK_CONFIG_ADVANCED = "ACFG";
export const CATEGORY_NETWORK_CONFIG_SIMPLE = "SCFG";

export const TYPE_SUBNETLIST_PRIVATE = "private";
export const TYPE_SUBNETLIST_PUBLIC = "public";
export const TYPE_SUBNETLIST_IPV6 = "ipv6";

export const SAVE_BUTTON_DEBOUNCE = 3000; // Number of ms save button will be disabled
