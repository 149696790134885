var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.sideNav
    ? _c(
        "div",
        [
          !_vm.nodes
            ? _c(
                "v-list-tile",
                {
                  attrs: {
                    dark: "",
                    "active-class":
                      "white--text font-weight-bold selectedMenuItem",
                    router: "",
                    to: _vm.url
                  },
                  on: {
                    click: function($event) {
                      return _vm.setBreadCrumb(_vm.breadCrumb)
                    }
                  }
                },
                [
                  _c(
                    "v-list-tile-content",
                    [_c("v-list-tile-title", [_vm._v(_vm._s(_vm.label))])],
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _vm.nodes && !_vm.firstTime ? _c("v-divider") : _vm._e(),
          _vm._l(_vm.nodes, function(node, index) {
            return _c("menu-dividers", {
              key: index,
              attrs: {
                nodes: node.nodes,
                label: node.label,
                url: node.routeTo,
                breadCrumb: node.breadCrumb,
                firstTime: false,
                sideNav: _vm.sideNav
              }
            })
          })
        ],
        2
      )
    : _c(
        "div",
        [
          !_vm.nodes
            ? _c(
                "v-list-tile",
                {
                  attrs: { router: "", to: _vm.url },
                  on: {
                    click: function($event) {
                      return _vm.setBreadCrumb(_vm.breadCrumb)
                    }
                  }
                },
                [_c("v-list-tile-title", [_vm._v(_vm._s(_vm.label))])],
                1
              )
            : _vm._e(),
          _vm.nodes && !_vm.firstTime ? _c("v-divider") : _vm._e(),
          _vm._l(_vm.nodes, function(node, index) {
            return _c("menu-dividers", {
              key: index,
              attrs: {
                nodes: node.nodes,
                label: node.label,
                url: node.routeTo,
                breadCrumb: node.breadCrumb,
                firstTime: false,
                sideNav: _vm.sideNav
              }
            })
          })
        ],
        2
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }