var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-app",
    [
      _vm.stateIsAuthenticated && _vm.stateAllowedEndpointListLoaded
        ? _c("the-header")
        : _vm._e(),
      _c(
        "v-content",
        [
          _c("loading", {
            attrs: {
              active: _vm.stateGetShowLoading,
              "can-cancel": false,
              loader: "dots",
              color: this.$vuetify.theme.secondary,
              height: Number(100),
              width: Number(100),
              opacity: Number(0.6),
              "is-full-page": ""
            },
            on: {
              "update:active": function($event) {
                _vm.stateGetShowLoading = $event
              }
            }
          }),
          _c("base-application-log-dialog"),
          _c("base-message-dialog", { ref: "userMessageDialog" }),
          !_vm.stateIsAuthenticated
            ? [_c("router-view", { key: _vm.getRouterViewKey })]
            : _vm.stateAllowedEndpointListLoaded
            ? [
                _c(
                  "v-layout",
                  [
                    _c(
                      "v-flex",
                      [
                        _c(
                          "v-card",
                          { staticClass: "ma-4", attrs: { flat: "" } },
                          [
                            _c("v-breadcrumbs", {
                              attrs: {
                                items: _vm.stateGetBreadCrumbs,
                                divider: ">"
                              }
                            })
                          ],
                          1
                        ),
                        _c("router-view", { key: _vm.getRouterViewKey })
                      ],
                      1
                    )
                  ],
                  1
                )
              ]
            : _vm._e()
        ],
        2
      ),
      _vm.stateIsAuthenticated ? _c("the-nav-bar") : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }