<template>
  <v-text-field
    :value="value"
    @input="$emit('input', $event)"
    :append-icon="showPassword ? 'visibility' : 'visibility_off'"
    :rules="[validationRules.required, validationRules.passwordStrength]"
    :type="showPassword ? 'text' : 'password'"
    :label="label"
    :hint="$t('BASE.GLOBAL.PASSWORD_HINT', { field: minPasswordLength })"
    counter
    loading
    validate-on-blur
    @click:append="showPassword = !showPassword"
  >
    <template v-slot:progress>
      <v-progress-linear
        :value="passwordStrength"
        :color="passwordStrengthColor"
        height="7"
      ></v-progress-linear>
    </template>                  
  </v-text-field>
</template>

<script>
import * as Constants from '@/lib/constants'
import validationRules from '@/mixins/ValidationRules.js';
var zxcvbn = require('zxcvbn')

export default {
  mixins: [ validationRules ],

  props: {
    value: {
      type: String,
      default: null
    },
    label: {
      type: String,
      default: null
    },
  },

  data: function () {
    return {
      showPassword: false,
    }
  },

  computed : {
    minPasswordLength() {
      return Constants.PASSWORD_LENGTH_MIN
    },
    
    passwordStrength () {
      if (this.value) {
        let pwStrength = zxcvbn(this.value)
        let pwMinLengthScore = this.value.length < Constants.PASSWORD_LENGTH_MIN ? (80/Constants.PASSWORD_LENGTH_MIN)*this.value.length : 100;
        return Math.min(100, (pwStrength.score * (100/Constants.PASSWORD_STRENGTH_MIN)), pwMinLengthScore)
      }
      return 0
    },

    passwordStrengthColor () {
      return ['error', 'warning', 'success'][Math.floor(this.passwordStrength / 40)]
    },
  }
}
</script>
