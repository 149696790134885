import { Enum, Type } from 'hello-type' // Check datatypes from API stream

export const idAsString = new Type({
  id: String
})

/** 
 * Definition of Allowed Endpoints type for the expected API properties which will be checked on API call
 * @constant
*/
export const allowedEndpoints = new Type({
  endpoint: String,
  method: String
})

export const apiToken = new Type({
  apiToken: String
})

export const roleList = new Type({
  id: String,
  name: String,
  userContext_id: Enum('10', '20', '30', '40'),
  meta: {
    canEdit: Boolean,
    canDelete: Boolean
  }  
})

export const role = new Type({
  id: String,
  name: String,
  userContext: {
    id: String,
    name: String
  }
})

export const roleScopeList = new Type({
  id: String,
  name: String
})

export const scopeList = new Type({
  categories: Array(
    {
      id: String,
      name: String,
      scopes: Array(
        {
          id: String,
          name: String
        }
      )
    }
  )
})

export const apiKeyList = new Type({
  id: Number,
  name: String,
  prefix: String,
  dateCreated: String,
  userName: String
})

export const apiKey = new Type({
  id: Number,
  name: String,
  dateCreated: String,
  dateLastModified: String,
  prefix: String,
  scopes: Array(
    {
      id: String,
      name: String
    }
  ),
  user: {
    id: String,
    name: String
  }
})

export const apiKeyString = new Type({
  apiKey: String
})
