import { RepositoryFactory } from '@/api/RepositoryFactory'
const EquipmentRepository = RepositoryFactory.get('equipment')

export default {
  namespaced: true,

  state: function () {
    return {
      backupMethodList: undefined,
      installerList: undefined,
      osVersionList: undefined,
      rackList: undefined,
      serviceLevelList: undefined,
      statusList: undefined,
      switchList: undefined,
      equipmentNamePoolList: undefined,
      physicalNetworkedEquipmentList: undefined
    }
  },

  getters: {
    /**
     * Get list of backup methods
     * @returns {array}
     */ 
    getBackupMethodList: state => {
      return state.backupMethodList || []
    },

    /**
     * Get list of installers
     * @returns {array}
     */ 
    getInstallerList: state => {
      return state.installerList || []
    },

    /**
     * Get list of OS versions
     * @returns {array}
     */ 
    getOsVersionList: state => {
      return state.osVersionList || []
    },

    /**
     * Get list of racks
     * @returns {array}
     */ 
    getRackList: state => {
      return state.rackList || []
    },

    /**
     * Get list of service levels
     * @returns {array}
     */ 
    getServiceLevelList: state => {
      return state.serviceLevelList || []
    },

    /**
     * Get list of statuses
     * @returns {array}
     */ 
    getStatusList: state => {
      return state.statusList || []
    },

    /**
     * Get list of switches
     * @returns {array}
     */ 
    getSwitchList: state => {
      return state.switchList || []
    },

    /**
     * Get list of Equipment name pools
     * @returns {array}
     */ 
    getEquipmentNamePoolList: state => {
      return state.equipmentNamePoolList || []
    },

    /**
     * Get list of physical networked equipment
     * @returns {array}
     */ 
    getPhysicalNetworkedEquipmentList: state => {
      return state.physicalNetworkedEquipmentList || []
    },

  },

  mutations: {
    /**
     * Mutation: Set list of backup methods
     * @param {Object} state
     * @param {Array} payload
     */ 
    setBackupMethodList (state, payload) {
      state.backupMethodList = payload
    },

    /**
     * Mutation: Set list of installers
     * @param {Object} state
     * @param {Array} payload
     */ 
    setInstallerList (state, payload) {
      state.installerList = payload
    },

    /**
     * Mutation: Set list of OS versions
     * @param {Object} state
     * @param {Array} payload
     */ 
    setOsVersionList (state, payload) {
      state.osVersionList = payload
    },

    /**
     * Mutation: Set list of racks
     * @param {Object} state
     * @param {Array} payload
     */ 
    setRackList (state, payload) {
      state.rackList = payload
    },

    /**
     * Mutation: Set list of service levels
     * @param {Object} state
     * @param {Array} payload
     */ 
    setServiceLevelList (state, payload) {
      state.serviceLevelList = payload
    },

    /**
     * Mutation: Set list of statuses
     * @param {Object} state
     * @param {Array} payload
     */ 
    setStatusList (state, payload) {
      state.statusList = payload
    },

    /**
     * Mutation: Set list of switches
     * @param {Object} state
     * @param {Array} payload
     */ 
    setSwitchList (state, payload) {
      state.switchList = payload
    },
    
    /**
     * Mutation: Set list of equipment name pools
     * @param {Object} state
     * @param {Array} payload
     */ 
    setEquipmentNamePoolList (state, payload) {
      state.equipmentNamePoolList = payload
    },

    /**
     * Mutation: Set list of physical networked equipment
     * @param {Object} state
     * @param {Array} payload
     */ 
    setPhysicalNetworkedEquipmentList (state, payload) {
      state.physicalNetworkedEquipmentList = Array(
        { id: null, name: '-None-'},
        ...payload
      )
    },

    clearCache (state) {
      state.backupMethodList = undefined
      state.installerList = undefined
      state.osVersionList = undefined
      state.rackList = undefined
      state.serviceLevelList = undefined
      state.statusList = undefined
      state.switchList = undefined
      state.equipmentNamePoolList = undefined
      state.physicalNetworkedEquipmentList = undefined
    }
},

  actions: {
    clearCache ({ commit }) {
      commit('clearCache')
    },

    /**
     * Action: Set list of backup methods
     * @param {Object} context
     * @param {Array} payload
     */ 
    setBackupMethodList (context, payload) {
      context.commit(`setBackupMethodList`, payload)
    },

    /**
     * Action: Set list of installers
     * @param {Object} context
     * @param {Array} payload
     */ 
    setInstallerList (context, payload) {
      context.commit(`setInstallerList`, payload)
    },

    /**
     * Action: Set list of OS versions
     * @param {Object} context
     * @param {Array} payload
     */ 
    setOsVersionList (context, payload) {
      context.commit(`setOsVersionList`, payload)
    },

    /**
     * Action: Set list of racks
     * @param {Object} context
     * @param {Array} payload
     */ 
    setRackList (context, payload) {
      context.commit(`setRackList`, payload)
    },

    /**
     * Action: Set list of service levels
     * @param {Object} context
     * @param {Array} payload
     */ 
    setServiceLevelList (context, payload) {
      context.commit(`setServiceLevelList`, payload)
    },

    /**
     * Action: Set list of statuses
     * @param {Object} context
     * @param {Array} payload
     */ 
    setStatusList (context, payload) {
      context.commit(`setStatusList`, payload)
    },

    /**
     * Action: Set list of switches
     * @param {Object} context
     * @param {Array} payload
     */ 
    setSwitchList (context, payload) {
      context.commit(`setSwitchList`, payload)
    },

    /**
     * Action: Set list of equipment name pools
     * @param {Object} context
     * @param {Array} payload
     */ 
    setEquipmentNamePoolList (context, payload) {
      context.commit(`setEquipmentNamePoolList`, payload)
    },

    /**
     * Action: Set list of physical networked equipment
     * @param {Object} context
     * @param {Array} payload
     */ 
    setPhysicalNetworkedEquipmentList (context, payload) {
      context.commit(`setPhysicalNetworkedEquipmentList`, payload)
    },


    /**
     * Action: Load DNSsec statuses via API and store them, but only if items have not been stored yet.
     */ 
    async loadBackupMethodList (context, payload = {}) {
      if (context.rootState.equipment.backupMethodList !== undefined && !payload.force) {
        // The items are already stored
        return
      }
      try {
        const data = await EquipmentRepository.getBackupMethodList()
        context.dispatch(`setBackupMethodList`, data)
      }
      catch(e) {
        context.dispatch(`applicationLog/log`, `Failed loading items: ${e}`, {root:true})
      }
    },

    /**
     * Action: Load installer list
     */ 
    async loadInstallerList (context, payload = {}) {
      if (context.rootState.equipment.installerList !== undefined && !payload.force) {
        // The items are already stored
        return
      }
      try {
        const data = await EquipmentRepository.getInstallerList()
        context.dispatch(`setInstallerList`, data)
      }
      catch(e) {
        context.dispatch(`applicationLog/log`, `Failed loading items: ${e}`, {root:true})
      }
    },
    
    /**
     * Action: Load OS version list
     */ 
    async loadOsVersionList (context, payload = {}) {
      if (context.rootState.equipment.osVersionList !== undefined && !payload.force) {
        // The items are already stored
        return
      }
      try {
        const data = await EquipmentRepository.getOsVersionList()
        context.dispatch(`setOsVersionList`, data)
      }
      catch(e) {
        context.dispatch(`applicationLog/log`, `Failed loading items: ${e}`, {root:true})
      }
    },
    
    /**
     * Action: Load rack list
     */ 
    async loadRackList (context, payload = {}) {
      if (context.rootState.equipment.rackList !== undefined && !payload.force) {
        // The items are already stored
        return
      }
      try {
        const data = await EquipmentRepository.getRackList('short')
        context.dispatch(`setRackList`, data)
      }
      catch(e) {
        context.dispatch(`applicationLog/log`, `Failed loading items: ${e}`, {root:true})
      }
    },
    
    /**
     * Action: Load service level list
     */ 
    async loadServiceLevelList (context, payload = {}) {
      if (context.rootState.equipment.serviceLevelList !== undefined && !payload.force) {
        // The items are already stored
        return
      }
      try {
        const data = await EquipmentRepository.getServiceLevelList('short')
        context.dispatch(`setServiceLevelList`, data)
      }
      catch(e) {
        context.dispatch(`applicationLog/log`, `Failed loading items: ${e}`, {root:true})
      }
    },
    
    /**
     * Action: Load status list
     */ 
    async loadStatusList (context, payload = {}) {
      if (context.rootState.equipment.statusList !== undefined && !payload.force) {
        // The items are already stored
        return
      }
      try {
        const data = await EquipmentRepository.getStatusList('short')
        context.dispatch(`setStatusList`, data)
      }
      catch(e) {
        context.dispatch(`applicationLog/log`, `Failed loading items: ${e}`, {root:true})
      }
    },
    
    /**
     * Action: Load switch list
     */ 
    async loadSwitchList (context, payload = {}) {
      if (context.rootState.equipment.switchList !== undefined && !payload.force) {
        // The items are already stored
        return
      }
      try {
        const data = await EquipmentRepository.getNetworkSwitchList('short')
        context.dispatch(`setSwitchList`, data)
      }
      catch(e) {
        context.dispatch(`applicationLog/log`, `Failed loading items: ${e}`, {root:true})
      }
    },
    
    /**
     * Action: Load equipment name pool list
     */ 
    async loadEquipmentNamePoolList (context, payload = {}) {
      if (context.rootState.equipment.equipmentNamePoolList !== undefined && !payload.force) {
        // The items are already stored
        return
      }
      try {
        const data = await EquipmentRepository.getEquipmentNamePoolList()
        context.dispatch(`setEquipmentNamePoolList`, data)
      }
      catch(e) {
        context.dispatch(`applicationLog/log`, `Failed loading items: ${e}`, {root:true})
      }
    },

    /**
     * Action: Load via API and store them, but only if items have not been stored yet.
     */ 
    async loadPhysicalNetworkedEquipmentList (context, payload = {}) {
      if (context.rootState.equipment.physicalNetworkedEquipmentList !== undefined && !payload.force) {
        // The items are already stored
        return
      }
      try {
        const data = await EquipmentRepository.getPhysicalNetworkedEquipmentList('short')
        context.dispatch(`setPhysicalNetworkedEquipmentList`, data)
      }
      catch(e) {
        context.dispatch(`applicationLog/log`, `Failed loading items: ${e}`, {root:true})
      }
    },

  }
}
