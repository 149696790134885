import Vue from 'vue';
import VueI18n from 'vue-i18n' // Internationalization
import * as Constants from '@/lib/constants.js'

Vue.use(VueI18n);

const i18n = new VueI18n({
  locale: localStorage.getItem('language') ? localStorage.getItem('language') : Constants.DEFAULT_LANGUAGE,
  messages: {
    'nl-NL': require('@/locales/nl.json'),
    'en-US': require('@/locales/en.json'),
  }
})

export default i18n