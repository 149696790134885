import { Enum, Type, IfExists } from 'hello-type' // Check datatypes from API stream

export const idAsString = new Type({
  id: String
})

/** 
 * Definition of Sla type for the expected API properties which will be checked on API call
 * @constant
*/
export const contactList = new Type({
  id: String,
  name: String,
  dateCreated: IfExists(String),
  dateLastModified: IfExists(String),
  isArchive: IfExists(Boolean),
  email: IfExists(String),
  daytimePhone: IfExists(String),
  emergencyPhone: IfExists(Enum(String, null)),
  priority: IfExists(Number),
  remarks: IfExists(Enum(String, null)),
  customer_id: IfExists(String),
  customer_name: IfExists(String),
})

export const contact = new Type({
  id: String,
  name: String,
  dateCreated: String,
  dateLastModified: String,
  isArchive: Boolean,
  email: String,
  daytimePhone: String,
  emergencyPhone: Enum(String, null),
  priority: Number,
  remarks: Enum(String, null),
  customer: {
    id: String,
    name: String
  }
})

export const slaList = new Type({
  id: String,
  name: String,
  dateCreated: IfExists(String),
  dateLastModified: IfExists(String),
  isArchive: IfExists(Boolean),
  alias: IfExists(String),
  memo: IfExists(Enum(String, null)),
  equipmentType_id: IfExists(Number),
  equipmentType_name: IfExists(String),
  serviceLevel_id: IfExists(String),
  serviceLevel_name: IfExists(String),
  backupMethod_id: IfExists(String),
  backupMethod_name: IfExists(String),
  status_id: IfExists(Number),
  status_name: IfExists(String),
  osVersion_id: IfExists(String),
  osVersion_name: IfExists(String),
  customer_id: IfExists(String),
  customer_name: IfExists(String),
})

export const uptimeReportList = new Type({
    availabilityCurrentMonth: Enum(null, Number),
    hasReports: Boolean,
    isArchive: Enum(0, 1),
    name: String,
    id: String,
    availabilityValues: [
      {
        availability: Enum(null, Number),
        label: String
      }
    ]
})

export const uptimeReportEquipment = new Type({
  criticalServices: [
    IfExists(
      {
        availabilityCurrentMonth: Enum(null, Number),
        name: String,
        availabilityValues: [
          {
            availability: Enum(null, Number),
            label: Enum('01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12')
          }
        ]
      }
    )
  ],
  operationalServices: [
    IfExists(
      {
        availabilityCurrentMonth: Enum(null, Number),
        name: String,
        availabilityValues: [
          {
            availability: Enum(null, Number),
            label: Enum('01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12')
          }
        ]
      }
    )
  ]
})
