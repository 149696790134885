import { ExportToCsv } from 'export-to-csv'
import * as Constants from '@/lib/constants'

export default {
  methods: {
  /*
    Option            Default                 Description
    fieldSeparator    ,                       Defines the field separator character
    filename          'generated'             Sets the name of the downloaded file. ".csv" will be appended to the value provided.
    quoteStrings      "                       If provided, will use this characters to "escape" fields, otherwise will use double quotes as deafult
    decimalSeparator  .                       Defines the decimal separator character (default is .). If set to "locale", it uses the language sensitive representation of the number.
    showLabels        false                   If true, the first row will be the headers option or object keys if useKeysAsHeaders is present
    showTitle         false                   Includes the title as the first line in the generated file
    title             'My Generated Report'   This string will be used as the report title
    useBom            true                    If true, adds a BOM character at the start of the CSV to improve file compatibility
    useTextFile       false                   If true, returns a .txt file instead of .csv
    useKeysAsHeaders  false                   If true, this will use the keys of the first object in the collection as the column headers
    headers           []                      Expects an array of strings, which if supplied, will be used as the column headers
  */
    async exportToCsv (data, options = { }) {
      // Clone data object before modifying it
      let dataToExport = JSON.parse(JSON.stringify(data))
      // Delete specific fields from the export
      Constants.EXPORT_EXCLUDE_FIELDS.forEach(function(field) {
        dataToExport.forEach(function(v){ delete v[field] });
      })
      options.fieldSeparator = options.fieldSeparator ? options.fieldSeparator : ';'
      const csvExporter = new ExportToCsv(options)
      csvExporter.generateCsv(dataToExport)
    }
  }
}
