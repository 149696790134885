var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.stateGetShowDialog
    ? _c(
        "v-layout",
        { attrs: { row: "", "justify-center": "" } },
        [
          _c(
            "v-dialog",
            { attrs: { value: true, persistent: "", "max-width": "400" } },
            [
              _c(
                "v-card",
                [
                  _c("div", {
                    domProps: { innerHTML: _vm._s(_vm.getHtmlMessages) }
                  }),
                  _c(
                    "v-card-actions",
                    [
                      _c("v-spacer"),
                      _c(
                        "v-btn",
                        {
                          attrs: { color: "green darken-1", flat: "" },
                          on: {
                            click: function($event) {
                              return _vm.stateResetMessageList()
                            }
                          }
                        },
                        [_vm._v(_vm._s(_vm.$t("BUTTON.OK")))]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }