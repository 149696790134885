<template>
  <v-layout v-if="stateGetShowDialog" row justify-center>
    <v-dialog :value="true" persistent max-width="400">
      <v-card>
          <div v-html="getHtmlMessages"></div>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" flat @click="stateResetMessageList()">{{ $t('BUTTON.OK') }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-layout>
</template>

<script>
import AppError from '@/lib/customExceptions/AppError.js'
import { mapGetters, mapActions } from 'vuex'

export default {
  data: function () {
    return {
    }
  },
  computed: {
    ...mapGetters({
      'stateGetMessageList': `applicationLog/getMessageList`,
      'stateGetShowDialog': `applicationLog/getShowDialog`,
    }),

    getHtmlMessages() {
      let resultHtml = ''
      this.stateGetMessageList.forEach(item => {
        if (item instanceof AppError) {
          resultHtml += `<div class="pa-3">`
          resultHtml += `<div class="headline py-3">${item.title}</div>`
          if (item.validationErrors) {
            resultHtml += '<ul class="pa-3">'
            for (var key in item.validationErrors) {
              resultHtml += `<li>${key}:</li>`
              resultHtml += '<ul>'
              item.validationErrors[key].forEach(element => resultHtml += `<li>${element.message}</li>`)
              resultHtml += '</ul>'
            }
            resultHtml += '</ul>'
          }
          resultHtml += `</div>`
        }
        else if (item instanceof Error) {
          resultHtml += '<div class="pa-3">'
          resultHtml += `<div class="headline py-3">${item.name}</div>`
          resultHtml += `<div>${item.message}</div>`
          resultHtml += '</div>'
        }
        else {
          resultHtml += `<div class="pa-3">`
          resultHtml += `<div class="headline py-3">${this.$t('BASE.USER_MESSAGE_TITLE')}</div>`
          resultHtml += `<div>${item}</div>`
          resultHtml += `</div>`
        }
      })
      return resultHtml
    }
  },

  methods: {
    ...mapActions({
      'stateResetMessageList': `applicationLog/resetMessageList`
    })
  }
}
</script>
