<template>
  <v-container fluid>
    <div
      v-html="nodeDump"
    >
    </div>
  </v-container>
</template>

<script>
import nodedump from 'nodedump'

export default {
  props: {
    dumpObject: Object
  },

  /**
   * Methods
   */
  computed: {
    nodeDump: function() {
      return nodedump.dump(this.dumpObject)
    }
  },
}
</script>