var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-menu",
    {
      attrs: {
        "close-on-content-click": false,
        "nudge-right": 40,
        lazy: "",
        transition: "scale-transition",
        "offset-y": "",
        "full-width": "",
        "min-width": "290px"
      },
      scopedSlots: _vm._u([
        {
          key: "activator",
          fn: function(ref) {
            var on = ref.on
            return [
              _c(
                "v-text-field",
                _vm._g(
                  {
                    attrs: {
                      value: _vm.computedDateFormatted,
                      label: _vm.label,
                      "prepend-icon": "event"
                    }
                  },
                  on
                )
              )
            ]
          }
        }
      ]),
      model: {
        value: _vm.showDatePicker,
        callback: function($$v) {
          _vm.showDatePicker = $$v
        },
        expression: "showDatePicker"
      }
    },
    [
      _c("v-date-picker", {
        ref: "datePicker",
        attrs: { value: _vm.computedValue },
        on: {
          input: function($event) {
            return _vm.updateDate()
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }