import Repository from '../Repository'
//import Logger from '@/lib/Logger.js'
import * as ApiTypes from '../../api/api-types';
import Helpers from '@/lib/Helpers'
import * as DataTypes from './CustomersRepositoryDataTypes'
import { store } from '@/store/store'


const moduleName = 'CustomersRepository'

export default {
  async getCustomerList (listFormat='default', listType='active') {
    return await Repository.get(
      `${ApiTypes.GET_CUSTOMERS}?listType=${listType}&listFormat=${listFormat}`,
      DataTypes.customerList,
      `${moduleName}.getCustomer()`
    )
  },

  /**
   * Post a new customer
   * 
   * @alias module: CustomersRepository
   * @param {Object} payload
   * @returns {Object} 
   */
  async postCustomer (payload) {
    let result = await Repository.post(
      ApiTypes.POST_CUSTOMERS,
      payload,
      DataTypes.idAsString,
      `${moduleName}.postCustomer()`
    )
    store.dispatch('customers/loadCustomerList', { force: true })
    return result
  },

  /**
   * Get a customer
   * @alias module: CustomersRepository
   * @param {String} id
   * @returns {Object} Object
   */
  async getCustomer (id) {
    return await Repository.get(
      Helpers.substituteApiVars(ApiTypes.GET_CUSTOMERS_ID, { id: id }),
      DataTypes.customer,
      `${moduleName}.getCustomer()`
    )
  },

  /**
   * Update a customer
   * 
   * @alias module: CustomersRepository
   * @param {Object} payload
   */
  async putCustomer (payload) {
    let result = await Repository.put(
      Helpers.substituteApiVars(ApiTypes.PUT_CUSTOMERS_ID, { id: payload.id }),
      payload,
      null,
      `${moduleName}.putCustomer()`
    )
    store.dispatch('customers/loadCustomerList', { force: true })
    return result
  },

  /**
   * Delete a customer
   * 
   * @alias module: CustomersRepository
   * @param {String} id
   */
  async deleteCustomer (id) {
    let result = await Repository.delete(
      Helpers.substituteApiVars(ApiTypes.DELETE_CUSTOMERS_ID, { id: id }),
      `${moduleName}.deleteCustomer()`
    )
    store.dispatch('customers/loadCustomerList', { force: true })
    return result
  },

  async getRelationList () {
    return await Repository.get(
      ApiTypes.GET_CUSTOMERS_RELATIONS,
      DataTypes.relationList,
      `${moduleName}.getRelationList()`
    )
  },

  async getRelation (customerId) {
    return await Repository.get(
      Helpers.substituteApiVars(ApiTypes.GET_CUSTOMERS_ID_RELATIONS, { customerId: customerId }),
      DataTypes.relation,
      `${moduleName}.getRelation()`
    )
  },

  async getInvoiceList (customerId, invoiceType='all') {
    return await Repository.get(
      Helpers.substituteApiVars(`${ApiTypes.GET_CUSTOMERS_ID_INVOICES}?invoiceType=${invoiceType}`, { customerId: customerId }),
      DataTypes.invoiceList,
      `${moduleName}.getInvoiceList()`
    )
  },

  async getInvoice (customerId, invoiceId) {
    return await Repository.getBlob(
      Helpers.substituteApiVars(ApiTypes.GET_CUSTOMERS_ID_INVOICES_ID, { customerId: customerId, id: invoiceId }),
      null,
      `${moduleName}.getInvoice(${customerId}, ${invoiceId})`
    )
  },

  async getOrder (customerId, orderId) {
    return await Repository.get(
      Helpers.substituteApiVars(ApiTypes.GET_CUSTOMERS_ID_ORDERS_ID, { customerId: customerId, id: orderId }),
      DataTypes.order,
      `${moduleName}.getOrder()`
    )
  },

  async getSubscriptionList (customerId) {
    return await Repository.get(
      Helpers.substituteApiVars(ApiTypes.GET_CUSTOMERS_ID_SUBSCRIPTIONS, { customerId: customerId }),
      DataTypes.subscriptionList,
      `${moduleName}.getSubscriptionList()`
    )
  },

  async getStatusList () {
    return await Repository.get(
      ApiTypes.GET_CUSTOMERS_STATUSES,
      DataTypes.statusList,
      `${moduleName}.getStatusList()`
    )
  },

}
