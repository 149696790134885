<template>
    <v-card class="pa-4">
      <v-layout row wrap justify-start align-baseline>
        <v-flex xs12 lg4 class="text-xs-left">
          <!--
            Button for adding a new item
            On Click an event will be emitted to the parent
          //-->
          <template v-if="enableAddButton && addButtonList">
            <v-menu bottom offset-y style="display: inline;">
              <template v-slot:activator="{ on }">
                <v-btn
                  color="newButton" 
                  class="white--text"
                  v-on="on"
                >
                  <v-icon>add_circle_outline</v-icon>
                  {{ $t("BUTTON.NEW") }}
                </v-btn>
              </template>
              <v-list>
                <v-list-tile v-for="(item, i) in addButtonList" :key="i" @click="$emit('add-clicked', item.id)">
                  <v-list-tile-title>{{ item.name }}</v-list-tile-title>
                </v-list-tile>
              </v-list>
            </v-menu>
          </template>
          <template v-else-if="enableAddButton">
            <v-btn
              color="newButton" 
              class="white--text"
              @click.stop="$emit('add-clicked')"
            >
              <v-icon>add_circle_outline</v-icon>
              {{ $t("BUTTON.NEW") }}
            </v-btn>
          </template>
          <slot name="extra-buttons">
          </slot>

          <!--
            Export Button for exporting the displayed table
            content to a file
          //-->
          <v-btn 
            v-if="exportFilenamePrefix"
            color="exportButton"
            class="white--text"
            :disabled="!$refs.refName || !$refs.refName.filteredItems || !$refs.refName.filteredItems.length > 0"
            @click="exportData()"
          >
            <v-icon>mdi-file-excel</v-icon>
            {{ $t("BUTTON.TO_EXCEL") }}
          </v-btn>
        </v-flex>
        
        <!--
          Search field for searching through all displayed data
          based on regular expressions with a input-debounce
          This debounce makes sure the search will only be performed
          if the user has stopped typing for a few hundred milliseconds
        //-->
        <v-flex 
          v-if="enableSearch"
          xs12 lg4 class="px-3">
          <v-text-field
            v-model="searchInput"
            append-icon="search"
            :label="$t('BASE.SEARCH')"
            single-line
            hide-details
            flat
            @keyup.native="debounceInput"
          ></v-text-field>
        </v-flex>
        <!--
          Filter for searching data of specific companies
          Multiple companies kan be selected
          This search also supports regular expressions
        //-->
        <v-flex xs12 lg2 class="px-3">
        <slot name="extra-right">
        </slot>

        <v-autocomplete 
          v-if="multiSearchField && companyList.length > 1"
          v-model="multiSearchItems"
          :items="companyList"
          item-text="customer"
          item-value="customer"
          :label="multiSearchLabel"
          hide-no-data
          chips
          deletable-chips
          small-chips
          multiple
          dense
          flat
          :filter="autocompleteSearch"
        ></v-autocomplete>
        </v-flex>

        <!--
          A switch to select "All" data or only data
          which is not archived.
        //-->
        <v-flex class="text-xs-right px-3" xs12 lg2>
          <v-switch 
            v-if="archiveSwitchField"
            :label="archiveSwitchLabel"
            v-model="archiveSwitch">
          </v-switch>
        </v-flex>
      </v-layout>
      <v-layout row wrap justify-space-between align-baseline>
        <v-flex xs12 class="py-3">
          <!-- Just some space //-->
        </v-flex>
      </v-layout>
      <v-layout row wrap justify-space-between align-baseline>
        <!--
          The data-table containing the list of results
        //-->
        <v-flex xs12>
          <v-data-table
            :headers="headers"
            :items="filteredMultiSearchItems"
            :pagination.sync="paginationSync"
            :search="search"
            :custom-filter="dataTableSearch"
            :rows-per-page-items="[10,25,100,250]"
            must-sort
            ref="refName"
            @update:pagination="updatePagination($event)"
          >

            <!-- Pass on all named slots -->
            <slot v-for="slot in Object.keys($slots)" :name="slot" :slot="slot">
            </slot>
            <!-- Pass on all scoped slots -->
            <template v-for="slot in Object.keys($scopedSlots)" :slot="slot" slot-scope="scope">
              <slot :name="slot" v-bind="scope"/>
            </template>

          </v-data-table>              
        </v-flex>
    </v-layout>
  </v-card>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import exportToCsvMixin from '@/mixins/ExportCSV.js';
import _ from 'lodash'

export default {
  // Props
  props: {
    multiSearchField: {
      type: String,
      default: null
    },
    multiSearchLabel: {
      type: String,
      default: ''
    },
    archiveSwitchField: {
      type: String,
      default: null
    },
    archiveSwitchLabel: {
      type: String,
      default: ''
    },
    inverseArchiveSwitch: {
      type: Boolean,
      default: false
    },
    enableAddButton: {
      type: Boolean,
      default: false
    },
    addButtonList: {
      type: Array,
      default: null
    },
    enableSearch: {
      type: Boolean,
      default: true
    },
    headers: {
      type: Array,
      required: true
    },
    items: {
      type: Array,
      required: true
    },
    exportFilenamePrefix: {
      type: String
    },
    sortBy: {
      type: String,
      required: true
    },
    descending: {
      type: Boolean,
      default: false,
    }
  },

  // Mixins
  mixins: [ exportToCsvMixin ],

  // data
  data: function () {
    return {
//      searchInput: '', // Used with debounce to fill search variable
      search: '',
      paginationSync: {
        descending: this.descending,
        sortBy: this.sortBy,
        rowsPerPage: parseInt(localStorage.getItem('rowsPerPage') ? localStorage.getItem('rowsPerPage') : 10)
      }
    }
  },

  // Computed properties
  computed: {
    ...mapGetters( {
      'stateGetMultiSearchItems': 'getMultiSearchItems',
      'stateGetArchiveSwitch': 'getArchiveSwitch',
      'stateGetSearchField': 'getSearchField',
    }),

    multiSearchItems: {
      get: function() {
        let result = this.stateGetMultiSearchItems
        return result
      },

      set: function (value) {
        this.stateSetMultiSearchItems(value)
      }
    },

    archiveSwitch: {
      get: function() {
        return this.stateGetArchiveSwitch
      },

      set: function (value) {
        this.stateSetArchiveSwitch(value)
      }
    },

    searchInput: {
      get: function() {
        return this.stateGetSearchField[this.$route.name]
      },

      set: function (value) {
        this.stateSetSearchField(value)
      }
    },

    // companyList()
    // Extract the company names from the DomainList
    // Used for the company-search field
    companyList: function () {
      let tmpList = this.items.map((item) => {
        return item.customer_name
      })
      // dedupe with Set and spread operator
      return [...new Set(_.sortBy(tmpList, [customer_name => customer_name.toLowerCase()]))];
    },

    // itemsMeetSwitchAll()
    // used by: filteredCompaniesItems()
    // return an array of domains based on the Active/All switch
    itemsMeetSwitchAll: function () {
      var result = this.items
      if (!this.archiveSwitch && this.archiveSwitchField) {
        // Get all Active items (items where isArchive === 0)
        if (this.inverseArchiveSwitch) {
          result = result.filter(row => row[this.archiveSwitchField] === true )
        }
        else {
          result = result.filter(row => row[this.archiveSwitchField] === false)
        }
      }
      return result
    },

    // filteredMultiSearchItems()
    // used by: v-data-table (domain list)
    // return all domains that meet the search criteria for (multiple) companies
    filteredMultiSearchItems: function () {
      var result = this.itemsMeetSwitchAll
      if (!this.multiSearchField) {
        return result
      }
      var multiSearchItemsLowerCase = this.multiSearchItems.map(item => item.toLowerCase())

      for (let i = 0; i < this._.get(this.multiSearchItems, 'length'); i++) {
        result = result.filter(row => multiSearchItemsLowerCase.includes(row[this.multiSearchField].toString().toLowerCase()))
      }
      return result
    },

    computedRegExp () {
      return RegExp(this.search, 'i')
    }
  },

  // Methods
  methods: {
    ...mapActions({
      'stateSetMultiSearchItems': `setMultiSearchItems`,
      'stateSetArchiveSwitch': `setArchiveSwitch`,
      'stateSetSearchField': `setSearchField`,
    }),

    // autocompleteSearch()
    // used by: v-auto-complete (Filter company or sub-user)
    // Special search filter for v-auto-complete to support
    // searches by regular expressions
    autocompleteSearch(item, queryText, itemText) {
      try {
        // If RegExp is valid than do a RegExp search
        let regExp = RegExp(queryText, 'i')
        return regExp.test(itemText)
      }
      catch(e) {
        // If RegExp is invalid than do a normal search
        return itemText.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) > -1
      }
    },

    addButtonClicked () {
      this.$emit('add-clicked')
    },

    // debounceInput()
    // used by: v-text-field (Search)
    // Create a delay for keyboard input to prevent filtering instantly
    // by every keyboard key
    debounceInput: _.debounce(function () {
        this.search = this.searchInput
    }, 200),

    // exportData()
    // used by: v-btn (exportToExcel)
    // A local export of current domain list, where all search/selection criteria
    // are respected
    exportData () {
      let date = new Date()

      let tmpRowsPerPage = this.paginationSync.rowsPerPage
      this.paginationSync.rowsPerPage = -1
      this.exportToCsv(
        this.$refs.refName.filteredItems, 
        { 
          filename: this.exportFilenamePrefix + '_' + date.getFullYear() + ('0' + (date.getMonth()+1)).slice(-2) + ('0' + date.getDate()).slice(-2),
          useKeysAsHeaders: true,
          showLabels: true
        }
      )
      this.paginationSync.rowsPerPage = tmpRowsPerPage
    },

    // dataTableSearch()
    // used by: v-data-table (domain list)
    // Special search filter to support searches by regular expressions
    dataTableSearch(items, search, filter, headers) {
      if (search.trim() === '') return items

      try {
        // If RegExp is valid than do a RegExp search
        const props = headers.map(h => h.value)
        
        
        // Sometimes there are additional columns which are not part of
        // the actual data. Their value is '', so only check headers (prop) 
        // which have a value. Otherwise the result will be "undefined",
        // which will return a positive result when searching for characters
        // in the word "undefined", ie searchterm "def"
        var result = items.filter(item => props.some(prop => prop ? this.computedRegExp.test(item[prop]) : false))
        return result
      }
      catch(e) {
        // If RegExp is invalid than do a normal search
        const props = headers.map(h => h.value)
        return items.filter(item => props.some(prop => filter(item[prop], search)))
      }
    },

    updatePagination($event) {
      localStorage.setItem('rowsPerPage', $event.rowsPerPage)
    }
  },

  mounted() {
    this.search = this.searchInput
  }
}
</script>

