export const LOGIN = '/Login'
export const FORGOT_PASSWORD = '/Forgot-Password'
export const RESET_PASSWORD = '/Reset-Password'
export const ACTIVATE_USER = '/Activate-User'

export const HOME_PAGE = '/Main/Dashboard'

export const DOMAINS_INDEX = '/Domains'
export const DOMAINS_DETAILS = '/Domains/Domains/Overview/Details'
export const DOMAINS_DNS = '/Domains/Dns'
export const DOMAINS_EMAIL = '/Domains/Email'
export const DOMAINS_ANTI_SPAM = '/Domains/AntiSpam'
export const DOMAINS_REDIRECT = '/Domains/Redirect'

export const DNS_TEMPLATES_INDEX = '/DnsTemplates'
export const DNS_TEMPLATES_DETAILS = '/DnsTemplates/Overview/Details'
export const DNS_TEMPLATES_RECORDS = '/DnsTemplates/Overview/Records'

export const AUTO_DNS_ACCOUNTS_INDEX = '/AutoDnsAccounts'
export const AUTO_DNS_ACTIONS_INDEX = '/AutoDnsActions'
export const AUTO_DNS_HISTORY_ITEMS_INDEX = '/AutoDnsHistoryItems'

export const CHECKLISTS_TEMPLATES_INDEX = '/ChecklistTemplates'
export const CHECKLISTS_TEMPLATES_DETAILS = '/Checklists/Templates/Overview/Details'
export const CHECKLISTS_TEMPLATES_ITEMS = '/Checklists/Templates/Overview/Items'

export const NETWORK_SWITCHES_INDEX = '/Switches'
export const NETWORK_SWITCHES_DETAILS = '/Switches/Details'
export const NETWORK_SWITCHES_NETWORK = '/Switches/Network'
export const NETWORK_SWITCHES_LICENSES = '/Switches/Licenses'

export const MISC_EQUIPMENT_INDEX = '/MiscEquipment'
export const MISC_EQUIPMENT_DETAILS = '/MiscEquipment/Details'

export const SERVERS_INDEX = '/Servers'
export const SERVERS_DETAILS = '/Servers/Details'
export const SERVERS_NETWORK = '/Servers/Network'
export const SERVERS_LICENSES = '/Servers/Licenses'
export const SERVERS_SLA = '/Servers/Sla'
export const SERVERS_CHECKLISTS = '/Servers/Checklists'

export const FINANCE_INDEX = '/Finance'
export const SUBSCRIPTIONS_INDEX = '/Finance/Subscriptions'
export const INVOICES_HISTORY_INDEX = '/Finance/Archive'
export const INVOICES_OPEN_INDEX = '/Finance/Open'

export const VIRTUAL_MACHINES_INDEX = '/VirtualMachines'
export const VIRTUAL_MACHINES_DETAILS = '/VirtualMachines/Details'
export const VIRTUAL_MACHINES_NETWORK = '/VirtualMachines/Network'
export const VIRTUAL_MACHINES_LICENSES = '/VirtualMachines/Licenses'
export const VIRTUAL_MACHINES_SLA = '/VirtualMachines/Sla'
export const VIRTUAL_MACHINES_CHECKLISTS = '/VirtualMachines/Checklists'

export const RACKS_INDEX = '/Racks'
export const RACKS_LAYOUT_INDEX = '/Racks/Layout'
export const PUBLIC_IP_V4_NUMBERS_INDEX = '/PublicIpv4Numbers'
export const IP_POOLS_INDEX = '/IpPools'
export const OS_VERSIONS_INDEX = '/OsVersions'
export const EQUIPMENT_NAMES_INDEX = '/EquipmentNames'
export const SERVICE_LEVELS_INDEX = '/equipment/sla-descriptions'

export const NETWORK_CONFIGS_INDEX = '/NetworkConfigs'
export const NETWORK_CONFIGS_DETAILS = '/NetworkConfigs/Overview/Details'
export const NETWORK_CONFIGS_SUBNETS = '/NetworkConfigs/Overview/Subnets'

export const SLA_INDEX = '/Sla'
export const SLA_MACHINES_INDEX = '/Sla/Machines'
export const SLA_CONTACT_INDEX = '/Sla/Contacts'

export const USER_MANAGEMENT_CUSTOMERS_INDEX = '/Customers'
export const USER_MANAGEMENT_USERS_INDEX = '/Users/Users'
export const USER_MANAGEMENT_USERS_USERS = '/Users/Users'
export const USER_MANAGEMENT_USERS_APIKEYS = '/Users/Api-Keys'
export const USER_MANAGEMENT_ACCOUNT_SETTINGS_INDEX = '/AccountSettings'
export const USER_MANAGEMENT_ENABLE_TWO_FACTOR_AUTHENTICATION_INDEX = '/Enable-Two-Factor-Authentication'
export const USER_MANAGEMENT_ROLES_INDEX = '/Roles'
export const USER_MANAGEMENT_ERROR_LOG_ENTRIES_INDEX = '/ErrorLogEntries'
export const USER_MANAGEMENT_ERROR_LOG_ENTRIES_DETAILS = '/ErrorLogEntries/Details'
export const USER_MANAGEMENT_ORM_LOG_ENTRIES_INDEX = '/AuditLogEntries'
export const USER_MANAGEMENT_ORM_LOG_ENTRIES_DETAILS = '/AuditLogEntries/Details'
export const USER_MANAGEMENT_RABBIT_TRANSACTIONS_INDEX = '/RabbitTransactions'
export const USER_MANAGEMENT_RABBIT_TRANSACTIONS_DETAILS = '/RabbitTransactions/Details'

// Administration
export const ADMINISTRATION_CHANGED_EQUIPMENT_RESOURCES_INDEX = '/ChangedEquipmentResources'
export const ADMINISTRATION_LICENSES_INDEX = '/Licenses'
export const ADMINISTRATION_LICENSE_CATEGORIES_INDEX = '/LicenseCategories'
export const ADMINISTRATION_SPLA_LICENSES_INDEX = '/Licenses/SplaLicenses'
export const ADMINISTRATION_LICENSES_PER_MACHINE_INDEX = '/Licenses/usagePerMachine'
export const ADMINISTRATION_NON_REGISTERED_DOMAINS_INDEX = '/DomainReports/getNonRegisteredPortalDomains'
export const ADMINISTRATION_NON_PORTAL_REGISTERED_DOMAINS_INDEX = '/DomainReports/getNonPortalRegisteredDomains'
export const ADMINISTRATION_NON_PORTAL_ACCOUNTING_DOMAINS_INDEX = '/DomainReports/getNonPortalAccountingDomains'
export const ADMINISTRATION_NON_ACCOUNTING_PORTAL_DOMAINS_INDEX = '/DomainReports/getNonAccountingPortalDomains'
export const ADMINISTRATION_MAILBOX_REPORT_INDEX = '/SharedMailBoxes/SharedMailBoxReport'
export const ADMINISTRATION_IMPORTED_DOMAINS_INDEX = '/ImportedDomains'

// Reports
export const REPORTS_UPTIME_INDEX = '/Reports/Uptime'
export const REPORTS_UPTIME_DETAILS = '/Reports/UptimeDetails'

export const DOCUMENTS_CATEGORIES_INDEX = '/Documents/Categories'
export const DOCUMENTS_MANAGE = '/Documents/Manage'

export const SANDBOX_TEST_COMPONENT = '/Sandbox/TestComponent'
