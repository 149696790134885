<template>
  <div v-if="sideNav">
    <v-list-tile
      dark
      active-class="white--text font-weight-bold selectedMenuItem" 
      v-if="!nodes"
      router
      :to="url"
      @click="setBreadCrumb(breadCrumb)"
    >
      <v-list-tile-content>
        <v-list-tile-title>{{ label }}</v-list-tile-title>
      </v-list-tile-content>
      <!--
      <v-list-tile-action>
        <v-icon
          @click.prevent="$emit('add-to-favorites-clicked', url)"
          color="grey lighten-1"
        >
          star_border
        </v-icon>
      </v-list-tile-action>
      //-->
    </v-list-tile>
    <v-divider v-if="nodes && !firstTime"></v-divider>
    <menu-dividers
      v-for="(node, index) in nodes" :key="index"
      :nodes="node.nodes"
      :label="node.label"
      :url="node.routeTo"
      :breadCrumb="node.breadCrumb"
      :firstTime="false"
      :sideNav="sideNav"
    >
    </menu-dividers>
  </div>
  <div v-else>
    <v-list-tile
      v-if="!nodes"
      router
      :to="url"
      @click="setBreadCrumb(breadCrumb)"
    >
      <v-list-tile-title>{{ label }}</v-list-tile-title>
    </v-list-tile>
    <v-divider v-if="nodes && !firstTime"></v-divider>

    <menu-dividers
      v-for="(node, index) in nodes" :key="index"
      :nodes="node.nodes"
      :label="node.label"
      :url="node.routeTo"
      :breadCrumb="node.breadCrumb"
      :firstTime="false"
      :sideNav="sideNav"
    >
    </menu-dividers>
  </div>
</template>

<script>
  import { mapActions } from 'vuex'

  export default { 
    props: [ 'label', 'url', 'nodes', 'breadCrumb', 'firstTime', 'sideNav' ],
    name: 'menu-dividers',
    methods: {
      ...mapActions([
        'setBreadCrumb'
      ])
    }
  }
</script>
