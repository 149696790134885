var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-form",
    [
      _c(
        "v-container",
        [
          _c(
            "v-layout",
            { attrs: { wrap: "" } },
            [
              _c(
                "v-flex",
                { attrs: { xs12: "" } },
                [
                  _c(
                    "v-card",
                    [
                      _c(
                        "v-toolbar",
                        {
                          class: { impersonated: _vm.isImpersonated },
                          attrs: { dense: "", flat: "", float: "" }
                        },
                        [
                          _c(
                            "v-toolbar-title",
                            {
                              staticClass: "body-2",
                              class: {
                                impersonated: _vm.isImpersonated,
                                "grey--text": !_vm.isImpersonated
                              }
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.isImpersonated ? "[Impersonated]" : ""
                                  ) +
                                  " " +
                                  _vm._s(_vm.$t("BASE.GLOBAL.WELCOME")) +
                                  " "
                              )
                            ]
                          )
                        ],
                        1
                      ),
                      _c(
                        "v-card",
                        { staticClass: "pa-2", attrs: { flat: "" } },
                        [
                          _c(
                            "v-list",
                            { attrs: { "two-line": "" } },
                            [
                              _c(
                                "v-list-tile",
                                [
                                  _c(
                                    "v-list-tile-avatar",
                                    [
                                      _c(
                                        "v-icon",
                                        {
                                          staticClass:
                                            "grey lighten-1 white--text",
                                          attrs: { large: "" }
                                        },
                                        [_vm._v(" mdi-account ")]
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-list-tile-content",
                                    [
                                      _c("v-list-tile-title", [
                                        _vm._v(_vm._s(_vm.userName))
                                      ]),
                                      _c("v-list-tile-sub-title", [
                                        _vm._v(_vm._s(_vm.customerName))
                                      ])
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "v-layout",
                            { attrs: { wrap: "" } },
                            [
                              _c(
                                "v-flex",
                                { attrs: { xs12: "" } },
                                [
                                  _c(
                                    "v-btn",
                                    {
                                      attrs: { color: "warning", small: "" },
                                      on: {
                                        click: function($event) {
                                          return _vm.$emit(
                                            "logout-button-clicked"
                                          )
                                        }
                                      }
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(_vm.$t("BUTTON.LOGOUT")) +
                                          " "
                                      )
                                    ]
                                  ),
                                  _c(
                                    "v-btn",
                                    {
                                      attrs: { color: "warning", small: "" },
                                      on: {
                                        click: function($event) {
                                          return _vm.$emit(
                                            "change-password-button-clicked"
                                          )
                                        }
                                      }
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.$t("BUTTON.CHANGE_PASSWORD")
                                          ) +
                                          " "
                                      )
                                    ]
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _vm.isImpersonated
                            ? _c(
                                "v-layout",
                                { attrs: { wrap: "" } },
                                [
                                  _c(
                                    "v-flex",
                                    { attrs: { xs12: "" } },
                                    [
                                      _c(
                                        "v-btn",
                                        {
                                          attrs: { color: "error", small: "" },
                                          on: {
                                            click: function($event) {
                                              return _vm.$emit(
                                                "return-to-user-button-clicked"
                                              )
                                            }
                                          }
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.$t("BUTTON.RETURN_TO_USER")
                                              ) +
                                              " "
                                          )
                                        ]
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            : _vm._e()
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }