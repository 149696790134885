import Repository from '../Repository'
//import Logger from '@/lib/Logger.js'
import * as ApiTypes from '../../api/api-types';
import Helpers from '@/lib/Helpers'
import * as DataTypes from './UsersRepositoryDataTypes'


const moduleName = 'UsersRepository'

export default {
  async getUserList () {
    return await Repository.get(
      `${ApiTypes.GET_USERS}`,
      DataTypes.userList,
      `${moduleName}.getUserList()`
    )
  },

  /**
   * Post a new user
   * 
   * @alias module: UsersRepository
   * @param {Object} payload
   * @returns {Object} 
   */
  async postUser (payload) {
    return await Repository.post(
      ApiTypes.POST_USERS,
      payload,
      DataTypes.idAsString,
      `${moduleName}.postUser()`
    )
  },

  /**
   * Get a user
   * @alias module: UsersRepository
   * @param {String} id
   * @returns {Object} Object
   */
  async getUser (id) {
    return await Repository.get(
      Helpers.substituteApiVars(ApiTypes.GET_USERS_ID, { id: id }),
      DataTypes.user,
      `${moduleName}.getUser()`
    )
  },

  /**
   * Update a user
   * 
   * @alias module: UsersRepository
   * @param {Object} payload
   */
  async putUser (payload) {
    return await Repository.put(
      Helpers.substituteApiVars(ApiTypes.PUT_USERS_ID, { id: payload.id }),
      payload,
      null,
      `${moduleName}.putUser()`
    )
  },

  /**
   * Delete a user
   * 
   * @alias module: UsersRepository
   * @param {String} id
   */
  async deleteUser (id) {
    return await Repository.delete(
      Helpers.substituteApiVars(ApiTypes.DELETE_USERS_ID, { id: id }),
      `${moduleName}.deleteUser()`
    )
  },

  async getUserContextRoleList () {
    return await Repository.get(
      `${ApiTypes.GET_USERS_CONTEXT_ROLES}`,
      DataTypes.userContextRoleList,
      `${moduleName}.getUserContextRoleList()`
    )
  },

  /**
   * Update a user's own password
   * 
   * @alias module: UsersRepository
   * @param {Object} payload
   */
  async putUserOwnPassword (payload) {
    return await Repository.put(
      ApiTypes.PUT_USERS_OWN_PASSWORD,
      payload,
      null,
      `${moduleName}.putUserOwnPassword()`
    )
  },

  /**
   * Update a user's password
   * 
   * @alias module: UsersRepository
   * @param {Object} payload
   */
  async putUserPassword (payload) {
    return await Repository.put(
      Helpers.substituteApiVars(ApiTypes.PUT_USERS_ID_PASSWORD, { id: payload.id }),
      payload,
      DataTypes.user,
      `${moduleName}.putUserPassword()`
    )
  },

  /**
   * Create Password Reset Request
   * 
   * @alias module: UsersRepository
   * @param {Object} payload
   * @returns {Object} 
   */
  async createPasswordResetRequest (payload) {
    return await Repository.post(
      ApiTypes.POST_USERS_PASSWORD_RESET_REQUEST,
      payload,
      DataTypes.passwordResetRequest,
      `${moduleName}.resetPasswordFromToken()`
    )
  },

  /**
   * Reset password from token
   * 
   * @alias module: UsersRepository
   * @param {Object} payload
   * @returns {Object} 
   */
  async resetPasswordFromToken (payload) {
    return await Repository.post(
      ApiTypes.POST_USERS_PASSWORD_RESET,
      payload,
      DataTypes.user,
      `${moduleName}.resetPasswordFromToken()`
    )
  },

  /**
   * Create 2FA key
   * 
   * @alias module: UsersRepository
   * @returns {Object} 
   */
  async create2FAKey () {
    return await Repository.post(
      ApiTypes.POST_USERS_2FA_KEY,
      null,
      DataTypes.user2FAKey,
      `${moduleName}.create2FAKey()`
    )
  },

  /**
   * Enable 2FA
   * 
   * @alias module: UsersRepository
   * @param {Object} payload
   * @returns {Object} 
   */
  async enable2FA (payload) {
    return await Repository.post(
      ApiTypes.POST_USERS_2FA,
      payload,
      DataTypes.idAsString,
      `${moduleName}.enable2FA()`
    )
  },

  /**
   * Get 2FA Recovery codes
   * 
   * @alias module: UsersRepository
   * @returns {Array}
   */
  async get2FARecoveryCodeList (userToken) {
    return await Repository.get(
      `${ApiTypes.GET_USERS_2FA_RECOVERY_CODES}?userToken=${userToken}`,
      DataTypes.user2FARecoveryCodeList,
      `${moduleName}.get2FARecoveryCodeList()`
    )
  },

  /**
   * Generate new recovery code list
   * 
   * @alias module: UsersRepository
   * @param {string} userToken
   */
  async put2FARecoveryCodeList (userToken) {
    return await Repository.put(
      `${ApiTypes.PUT_USERS_2FA_RECOVERY_CODES}?userToken=${userToken}`,
      null,
      DataTypes.user2FARecoveryCodeList,
      `${moduleName}.put2FARecoveryCodeList()`
    )
  },

  /**
   * Deactivate 2FA
   * 
   * @alias module: UsersRepository
   */
  async disable2FA(payload = { id: null, userToken: null }) {
    let apiCall = payload.id == null 
      ? `${ApiTypes.DELETE_USERS_2FA}?userToken=${payload.userToken}`
      : Helpers.substituteApiVars(ApiTypes.DELETE_USERS_ID_2FA, { id: payload.id })
    
    return await Repository.delete(
      apiCall,
      `${moduleName}.disable2FA()`
    )
  },

}
