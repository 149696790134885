import { Enum, Type, IfExists } from 'hello-type' // Check datatypes from API stream


export const idAsNumber = new Type({
  id: Number
})

export const idAsString = new Type({
  id: String
})

/** 
 * Definition of Domains type for the expected API properties which will be checked on API call
 * @constant
*/
export const domainList = new Type(
  {
    // short Version
    id: String,
    name: String,
    isArchive: IfExists(Boolean),
    dnsEnabled: IfExists(Boolean),
    antiSpamCode: IfExists(Number),
    redirectCode: IfExists(Enum(0,1,2)),
    remarks: IfExists(Enum(null, String)),
    dnsSecStatus: IfExists(Number),
    customer_name: IfExists(String),
    status_id: IfExists(Number),
    status_name: IfExists(String),
    mailType_id: IfExists(Number),
    mailType_name: IfExists(String),
    registrar_name: IfExists(String),
  }
)

/** 
 * Definition of Domain type for the expected API properties which will be checked on API call
 * @constant
*/
export const domain = new Type({
  id: String,
  name: String,
  dateCreated: String,
  dateLastModified: String,
  isArchive: Boolean,
  customer: {
    id: String,
    name: String
  },
  dnsEnabled: Boolean,
  mailType: {
    id: Number,
    name: String
  },
  registrar: {
    id: Number,
    name: String
  },
  antiSpamCode: Number,
  dnsSecStatus: Number,
  redirectCode: Number,
  status: {
    id: Number,
    name: String
  },
  remarks: String,
})

/** 
* Definition of Mail type for the expected API properties which will be checked on API call
* @constant
*/
export const mailType = new Type({
  name: String,
  id: Number
})

/** 
* Definition of DNSsec status type for the expected API properties which will be checked on API call
* @constant
*/
export const dnsSecStatus = new Type({
  name: String,
  id: Number
})

/** 
* Definition of Domain status type for the expected API properties which will be checked on API call
* @constant
*/
export const domainStatus = new Type({
  name: String,
  id: Number
})

/** 
* Definition of Registrar type for the expected API properties which will be checked on API call
* @constant
*/
export const registrar = new Type({
  name: String,
  id: Number,
})

/** 
 * Definition of Imported Domains type for the expected API properties which will be checked on API call
 * @constant
*/
export const importedDomainList = new Type({
  id: Number,
  name: String,
  registrar_name: String
})

export const importedDomain = new Type({
  id: Number,
  name: String,
  registrar: {
    id: Number,
    name: String
  }
})

export const nonAccountingPortalDomainList = new Type({
  domainName: String,
  domainStatus: String,
  registrar: String
})

export const nonPortalAccountingDomainList = new Type({
  domainName: String,
  relatie: String
})

export const nonPortalRegisteredDomainList = new Type({
  domainName: String,
  id: Number,
  registrar: String
})

export const nonRegisteredPortalDomainList = new Type({
  domainName: String,
  domainStatus: String,
  id: Number,
  registrar: String
})

export const customerDomainList = new Type({
  id: String,
  name: String
})

export const redirect = new Type({
  id: Enum(Number, null),
  toUrl: String,
  type: Enum(0, 1, 2),
  proto: Enum('', 'http', 'https'),
  frameTitle: String,
  fromDomain: String,
  canModifyDns: Boolean,
  hasDnsRecordsForRedirect: Boolean,
  incorrectDnsRecordsForRedirect: Array(
    IfExists({
      id: Number,
      name: String,
      content: String,
      type: Enum('A', 'AAAA', 'CNAME')
    })
  ),
})