export default {
  namespaced: true,

  state: function () {
    return {
      message: null 
    }
  },

  getters: {
    getUserMessage: state => {
      return state.message
    },

    getShowDialog: state => {
      return state.message ? true : false
    }
  },

  mutations: {
    setUserMessage (state, payload) {
      state.message = payload
    },
    
    resetUserMessage (state) {
      state.message = null
    }
  },

  actions: {
    setUserMessage (context, payload) {
      if (payload !== null) {
        context.commit('setUserMessage', payload)
      }
    },

    resetUserMessage (context) {
      context.commit('resetUserMessage')
    }
  }
}
