import Repository from '../Repository'
import * as ApiTypes from '@/api/api-types'
import * as RoutingTypes from '@/router/routing-types'
import i18n from '@/lib/i18n';

import * as DataTypes from './MenuRepositoryDataTypes'

const moduleName = 'MenuRepository'

export default {
  async getMainMenu () {
    return [
      {
        label: 'HOME',
        routeTo: RoutingTypes.HOME_PAGE,
        icon: 'home'
      },
      {
        label: 'DOMAINS',
        routeTo: RoutingTypes.DOMAINS_INDEX,
        icon: 'public',
        apiEndpoint: {
          endpoint: ApiTypes.GET_DOMAINS,
          method: 'GET'
        }
      },
      {
        label: 'VIRTUAL_MACHINES',
        routeTo: RoutingTypes.VIRTUAL_MACHINES_INDEX,
        icon: 'mdi-virtual-reality',
        apiEndpoint: {
          endpoint: ApiTypes.GET_EQUIPMENT_VIRTUAL_MACHINES,
          method: 'GET'
        }
      },
      {
        label: 'SERVERS',
        routeTo: RoutingTypes.SERVERS_INDEX,
        icon: 'mdi-server',
        apiEndpoint: {
          endpoint: ApiTypes.GET_EQUIPMENT_SERVERS,
          method: 'GET',
        }
      },
      {
        label: 'FINANCE',
        routeTo: RoutingTypes.FINANCE_INDEX,
        icon: 'monetization_on',
        apiEndpoint: {
          endpoint: ApiTypes.GET_CUSTOMERS_ID_INVOICES_ID,
          method: 'GET',
        }
      },
      {
        label: 'REPORTS',
        routeTo: RoutingTypes.REPORTS_UPTIME_INDEX,
        icon: 'mdi-finance',
        apiEndpoint: {
          endpoint: ApiTypes.GET_SLA_UPTIME_REPORTS_CUSTOMERS_ID,
          method: 'GET',
        }
      },
      {
        label: 'SLA',
        routeTo: RoutingTypes.SLA_INDEX,
        icon: 'verified_user',
        apiEndpoint: {
          endpoint: ApiTypes.GET_SLA_CONTACTS,
          method: 'GET',
        }
      },
      {
        label: 'DOCUMENTS',
        routeTo: RoutingTypes.DOCUMENTS_MANAGE,
        icon: 'mdi-file-document',
        apiEndpoint: {
          endpoint: ApiTypes.GET_CUSTOMERS_ID_DOCUMENTS,
          method: 'GET',
        }
      },
      {
        label: 'USER_MANAGEMENT',
        routeTo: RoutingTypes.USER_MANAGEMENT_USERS_INDEX,
        icon: 'supervisor_account',
        apiEndpoint: {
          endpoint: ApiTypes.POST_USERS,
          method: 'POST',
        }
      },
    ]
  },

  async getManagementMenu () {
    return {
      routeTo: RoutingTypes.HOME_PAGE,
      label: i18n.t('MENU.MANAGEMENT.HOME'),
      nodes: [
        {
          label: i18n.t('MENU.MANAGEMENT.DOMAINS'),
          nodes: [
            {
              routeTo: RoutingTypes.DOMAINS_INDEX,
              label: i18n.t('MENU.MANAGEMENT.DOMAINS'),
              apiEndpoint: {
                endpoint: ApiTypes.GET_DOMAINS,
                method: 'GET',
              }
            },
            {
              label: i18n.t('MENU.MANAGEMENT.DOMAINS.DNS_TEMPLATES'),
              nodes: [
                {
                  routeTo: RoutingTypes.DNS_TEMPLATES_INDEX,
                  label: i18n.t('MENU.MANAGEMENT.DOMAINS.DNS_TEMPLATES'),
                  apiEndpoint: {
                    endpoint: ApiTypes.POST_DNS_TEMPLATES,
                    method: 'POST',
                  }
                }
              ]
            },
            {
              label: i18n.t('MENU.MANAGEMENT.DOMAINS.AUTO_DNS'),
              nodes: [
                {
                  routeTo: RoutingTypes.AUTO_DNS_ACCOUNTS_INDEX,
                  label: i18n.t('MENU.MANAGEMENT.DOMAINS.AUTO_DNS'),
                  apiEndpoint: {
                    endpoint: ApiTypes.POST_AUTO_DNS_ACCOUNTS,
                    method: 'POST',
                  }
                },
                {
                  routeTo: RoutingTypes.AUTO_DNS_ACTIONS_INDEX,
                  label: i18n.t('MENU.MANAGEMENT.DOMAINS.AUTO_DNS_ACTIONS'),
                  apiEndpoint: {
                    endpoint: ApiTypes.POST_AUTO_DNS_ACTIONS,
                    method: 'POST',
                  }
                },
                {
                  routeTo: RoutingTypes.AUTO_DNS_HISTORY_ITEMS_INDEX,
                  label: i18n.t('MENU.MANAGEMENT.DOMAINS.AUTO_DNS_HISTORY'),
                  apiEndpoint: {
                    endpoint: ApiTypes.GET_AUTO_DNS_HISTORY_ITEMS,
                    method: 'GET',
                  }
                }
              ]
            }
          ]
        },
        {
          label: i18n.t('MENU.MANAGEMENT.EQUIPMENT'),
          nodes: [
            {
              routeTo: RoutingTypes.VIRTUAL_MACHINES_INDEX,
              label: i18n.t('MENU.MANAGEMENT.EQUIPMENT.VIRTUAL_MACHINES'),
              apiEndpoint: {
                endpoint: ApiTypes.GET_EQUIPMENT_VIRTUAL_MACHINES,
                method: 'GET',
              }
            },
            {
              routeTo: RoutingTypes.SERVERS_INDEX,
              label: i18n.t('MENU.MANAGEMENT.EQUIPMENT.SERVERS'),
              apiEndpoint: {
                endpoint: ApiTypes.GET_EQUIPMENT_SERVERS,
                method: 'GET',
              }
            },
            {
              routeTo: RoutingTypes.NETWORK_SWITCHES_INDEX,
              label: i18n.t('MENU.MANAGEMENT.EQUIPMENT.NETWORK_SWITCHES'),
              apiEndpoint: {
                endpoint: ApiTypes.POST_EQUIPMENT_SWITCHES,
                method: 'POST',
              }
            },
            {
              routeTo: RoutingTypes.MISC_EQUIPMENT_INDEX,
              label: i18n.t('MENU.MANAGEMENT.EQUIPMENT.MISC'),
              apiEndpoint: {
                endpoint: ApiTypes.POST_EQUIPMENT_MISC,
                method: 'POST',
              }
            },
            {
              routeTo: RoutingTypes.RACKS_INDEX,
              label: i18n.t('MENU.MANAGEMENT.EQUIPMENT.SERVER_RACKS'),
              apiEndpoint: {
                endpoint: ApiTypes.POST_EQUIPMENT_RACKS,
                method: 'POST',
              }
            },
            {
              label: i18n.t('MENU.MANAGEMENT.EQUIPMENT.INFO'),
              nodes: [
                {
                  routeTo: RoutingTypes.SERVICE_LEVELS_INDEX,
                  label: i18n.t('MENU.MANAGEMENT.EQUIPMENT.SERVICE_LEVEL'),
                  apiEndpoint: {
                    endpoint: ApiTypes.POST_EQUIPMENT_SERVICE_LEVELS,
                    method: 'POST',
                  }
                },
                {
                  routeTo: RoutingTypes.OS_VERSIONS_INDEX,
                  label: i18n.t('MENU.MANAGEMENT.EQUIPMENT.OS_VERSIONS'),
                  apiEndpoint: {
                    endpoint: ApiTypes.POST_EQUIPMENT_OS_VERSIONS,
                    method: 'POST',
                  }
                },
                {
                  routeTo: RoutingTypes.EQUIPMENT_NAMES_INDEX,
                  label: i18n.t('MENU.MANAGEMENT.EQUIPMENT.SERVER_NAMES'),
                  apiEndpoint: {
                    endpoint: ApiTypes.POST_EQUIPMENT_NAMES,
                    method: 'POST',
                  }
                }
              ]
            },
            {
              routeTo: RoutingTypes.CHECKLISTS_TEMPLATES_INDEX,
              label: i18n.t('MENU.MANAGEMENT.EQUIPMENT.CHECKLIST_TEMPLATES'),
              apiEndpoint: {
                endpoint: ApiTypes.POST_CHECKLISTS_TEMPLATES,
                method: 'POST',
              }
            }
          ]
        },
        {
          label: i18n.t('MENU.MANAGEMENT.NETWORK'),
          nodes: [
            {
              routeTo: RoutingTypes.NETWORK_CONFIGS_INDEX,
              label: i18n.t('MENU.MANAGEMENT.NETWORK.NETWORK_CONFIG'),
              apiEndpoint: {
                endpoint: ApiTypes.POST_NETWORK_SIMPLE_CONFIGS,
                method: 'POST',
              }
            },
            {
              routeTo: RoutingTypes.PUBLIC_IP_V4_NUMBERS_INDEX,
              label: i18n.t('MENU.MANAGEMENT.NETWORK.PUBLIC_IPV4_NUMBERS'),
              apiEndpoint: {
                endpoint: ApiTypes.POST_NETWORK_PUBLIC_IPV4,
                method: 'POST',
              }
            },
            {
              routeTo: RoutingTypes.IP_POOLS_INDEX,
              label: i18n.t('MENU.MANAGEMENT.NETWORK.IP_POOLS'),
              apiEndpoint: {
                endpoint: ApiTypes.POST_NETWORK_IP_POOLS,
                method: 'POST',
              }
            },
          ]
        },
        {
          label: i18n.t('MENU.MANAGEMENT.ADMIN'),
          nodes: [
            {
              routeTo: RoutingTypes.ADMINISTRATION_CHANGED_EQUIPMENT_RESOURCES_INDEX,
              label: i18n.t('MENU.MANAGEMENT.ADMIN.CHANGED_EQUIPMENT_RESOURCES'),
              apiEndpoint: {
                endpoint: ApiTypes.GET_EQUIPMENT_RESOURCES,
                method: 'GET',
              }
            },
            {
              routeTo: RoutingTypes.ADMINISTRATION_NON_REGISTERED_DOMAINS_INDEX,
              label: i18n.t('MENU.MANAGEMENT.ADMIN.DOMAINS_NOT_REGISTERED'),
              apiEndpoint: {
                endpoint: ApiTypes.GET_NON_REGISTERED_PORTAL_DOMAINS,
                method: 'GET',
              }
            },
            {
              routeTo: RoutingTypes.ADMINISTRATION_NON_PORTAL_REGISTERED_DOMAINS_INDEX,
              label: i18n.t('MENU.MANAGEMENT.ADMIN.DOMAINS_REGISTERED'),
              apiEndpoint: {
                endpoint: ApiTypes.GET_NON_PORTAL_REGISTERED_DOMAINS,
                method: 'GET',
              }
            },
            {
              routeTo: RoutingTypes.ADMINISTRATION_NON_PORTAL_ACCOUNTING_DOMAINS_INDEX,
              label: i18n.t('MENU.MANAGEMENT.ADMIN.DOMAINS_SS_NOT_IN_MYSITE4U'),
              apiEndpoint: {
                endpoint: ApiTypes.GET_NON_PORTAL_ACCOUNTING_DOMAINS,
                method: 'GET',
              }
            },
            {
              routeTo: RoutingTypes.ADMINISTRATION_NON_ACCOUNTING_PORTAL_DOMAINS_INDEX,
              label: i18n.t('MENU.MANAGEMENT.ADMIN.DOMAINS_MYSITE4U_NOT_IN_SS'),
              apiEndpoint: {
                endpoint: ApiTypes.GET_NON_ACCOUNTING_PORTAL_DOMAINS,
                method: 'GET',
              }
            },
            {
              routeTo: RoutingTypes.ADMINISTRATION_IMPORTED_DOMAINS_INDEX,
              label: i18n.t('MENU.MANAGEMENT.ADMIN.DOMAINS_IMPORTED_LIST'),
              apiEndpoint: {
                endpoint: ApiTypes.POST_IMPORTED_DOMAINS,
                method: 'POST',
              }
            },
            {
              label: i18n.t('MENU.MANAGEMENT.ADMIN.LICENSES'),
              nodes: [
                {
                  routeTo: RoutingTypes.ADMINISTRATION_LICENSES_INDEX,
                  label: i18n.t('MENU.MANAGEMENT.ADMIN.LICENSES'),
                  apiEndpoint: {
                    endpoint: ApiTypes.POST_LICENSES,
                    method: 'POST',
                  }
                },
                {
                  routeTo: RoutingTypes.ADMINISTRATION_LICENSE_CATEGORIES_INDEX,
                  label: i18n.t('MENU.MANAGEMENT.ADMIN.LICENSES_CATEGORIES'),
                  apiEndpoint: {
                    endpoint: ApiTypes.POST_LICENSE_CATEGORIES,
                    method: 'POST',
                  }
                },
                {
                  routeTo: RoutingTypes.ADMINISTRATION_SPLA_LICENSES_INDEX,
                  label: i18n.t('MENU.MANAGEMENT.ADMIN.SPLA_LICENSES'),
                  apiEndpoint: {
                    endpoint: ApiTypes.GET_LICENSE_REPORTS_SPLA,
                    method: 'GET',
                  }
                },
                {
                  routeTo: RoutingTypes.ADMINISTRATION_LICENSES_PER_MACHINE_INDEX,
                  label: i18n.t('MENU.MANAGEMENT.ADMIN.LICENSES_PER_MACHINE'),
                  apiEndpoint: {
                    endpoint: ApiTypes.GET_LICENSE_REPORTS_PER_MACHINE,
                    method: 'GET',
                  }
                }
              ]
            },
            {
              label: i18n.t('MENU.MANAGEMENT.ADMIN.MAILBOX_REPORTING'),
              nodes: [
                {
                  routeTo: RoutingTypes.ADMINISTRATION_MAILBOX_REPORT_INDEX,
                  label: i18n.t('MENU.MANAGEMENT.ADMIN.MAILBOXES'),
                  apiEndpoint: {
                    endpoint: ApiTypes.GET_MAILBOX_REPORT,
                    method: 'GET',
                  }
                },
              ]
            },
            {
              label: i18n.t('MENU.MANAGEMENT.DOCUMENT_STORE'),
              nodes: [
                {
                  routeTo: RoutingTypes.DOCUMENTS_CATEGORIES_INDEX,
                  label: i18n.t('MENU.MANAGEMENT.DOCUMENT_STORE.CATEGORIES'),
                  apiEndpoint: {
                    endpoint: ApiTypes.POST_DOCUMENTS_CATEGORIES,
                    method: 'POST',
                  }
                },
                {
                  routeTo: RoutingTypes.DOCUMENTS_MANAGE,
                  label: i18n.t('MENU.MANAGEMENT.DOCUMENT_STORE.MANAGE'),
                  apiEndpoint: {
                    endpoint: ApiTypes.POST_CUSTOMERS_ID_DOCUMENTS,
                    method: 'POST',
                  }
                }
              ]
            }
          ]
        },
        {
          label: i18n.t('MENU.MANAGEMENT.ADMIN.USERS_MANAGEMENT'),
          nodes: [
            {
              routeTo: RoutingTypes.USER_MANAGEMENT_USERS_INDEX,
              label: i18n.t('MENU.MANAGEMENT.ADMIN.USERS'),
              apiEndpoint: {
                endpoint: ApiTypes.POST_USERS,
                method: 'POST',
              }
            },
            {
              routeTo: RoutingTypes.USER_MANAGEMENT_ROLES_INDEX,
              label: i18n.t('MENU.MANAGEMENT.ADMIN.ROLES'),
              apiEndpoint: {
                endpoint: ApiTypes.POST_AUTH_ROLES,
                method: 'POST',
              }
            },
            {
              routeTo: RoutingTypes.USER_MANAGEMENT_CUSTOMERS_INDEX,
              label: i18n.t('MENU.MANAGEMENT.ADMIN.CUSTOMERS'),
              apiEndpoint: {
                endpoint: ApiTypes.POST_CUSTOMERS,
                method: 'POST',
              }
            },
            {
              label: i18n.t('MENU.MANAGEMENT.ADMIN.LOG_FILES'),
              nodes: [
                {
                  routeTo: RoutingTypes.USER_MANAGEMENT_ERROR_LOG_ENTRIES_INDEX,
                  label: i18n.t('MENU.MANAGEMENT.ADMIN.ERROR_LOG'),
                  apiEndpoint: {
                    endpoint: ApiTypes.GET_LOGGED_ERRORS,
                    method: 'GET',
                  }
                },
                {
                  routeTo: RoutingTypes.USER_MANAGEMENT_ORM_LOG_ENTRIES_INDEX,
                  label: i18n.t('MENU.MANAGEMENT.ADMIN.AUDIT_LOG'),
                  apiEndpoint: {
                    endpoint: ApiTypes.GET_LOGGED_AUDIT_ENTRIES,
                    method: 'GET',
                  }
                },
                {
                  routeTo: RoutingTypes.USER_MANAGEMENT_RABBIT_TRANSACTIONS_INDEX,
                  label: i18n.t('MENU.MANAGEMENT.ADMIN.RABBIT_TRANSACTIONS'),
                  apiEndpoint: {
                    endpoint: ApiTypes.GET_RABBIT_TRANSACTIONS,
                    method: 'GET',
                  }
                }
              ]
            }
          ]
        }
      ],
    }
  },

  async getUserMenu() {
    return {
      nodes: [
        {
          routeTo: RoutingTypes.USER_MANAGEMENT_ACCOUNT_SETTINGS_INDEX,
          label: i18n.t('MENU.USER_ACCOUNT.SETTINGS'),
        },
        {
          routeTo: RoutingTypes.LOGIN,
          label: i18n.t('BUTTON.LOGOUT'),
        },
      ],
    }
  },

  /**
   * Get search item list for global search box
   *
   * @alias module: MenuRepository
   * @returns {Array} Array of objects
   */
  async getSearchItemList () {
    return await Repository.get(
      ApiTypes.GET_SEARCH_ITEMS,
      DataTypes.searchItemList,
      `${moduleName}.getSearchItemList()`
    )
  },
  
}
