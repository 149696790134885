import Repository from '../Repository'
//import Logger from '@/lib/Logger.js'
import Helpers from '@/lib/Helpers.js'
import * as DataTypes from './AuthRepositoryDataTypes'
import * as ApiTypes from '@/api/api-types'
import Logger from '@/lib/Logger.js'

const moduleName = 'AuthRepository'

/**
 * A module for handling API calls for anti-spam
 * @module AuthRepository
 */
export default {
  /**
   * Get Allowed Api Endpoints
   * @alias module: AuthRepository
   * @returns {Array} Array of allowed endpoints
   */
  async getAllowedEndpoints () {
    return await Repository.get(
      ApiTypes.GET_AUTH_ENDPOINTS,
      DataTypes.allowedEndpoints,
      `${moduleName}.getAllowedEndpoints()`
    )
  },

  /**
   * Create a new ApiToken (bearer token) based on user credentials
   * 
   * @alias module: AuthRepository
   * @param {Object} payload
   * @returns {Object} ApiToken
   */
  async createApiToken (payload) {
    return await Repository.post(
      ApiTypes.POST_AUTH_APITOKENS,
      payload,
      DataTypes.apiToken,
      `${moduleName}.createApiToken()`
    )
  },

 /**
   * Create a impersonated ApiToken
   * 
   * @alias module: AuthRepository
   * @param {String} id
   * @returns {Object} ApiToken
   */
  async createImpersonatedApiToken (id) {
    return await Repository.post(
      Helpers.substituteApiVars(ApiTypes.POST_AUTH_API_IMPERSONATED_TOKEN_ID, { id: id }),
      null,
      DataTypes.apiToken,
      `${moduleName}.createImpersonatedApiToken()`
    )
  },

  /**
   * Create parent user token
   * 
   * @alias module: AuthRepository
   * @returns {Object} ApiToken
   */
  async createParentUserApiToken () {
    return await Repository.post(
      ApiTypes.POST_AUTH_API_PARENT_USER_TOKEN,
      null,
      DataTypes.apiToken,
      `${moduleName}.createParentUserApiToken()`
    )
  },
  
  /**
   * Get Role list
   * @alias module: AuthRepository
   * @returns {Array} Array of objects
   */
  async getRoleList () {
    return await Repository.get(
      ApiTypes.GET_AUTH_ROLES,
      DataTypes.roleList,
      `${moduleName}.getRoleList()`
    )
  },

  /**
   * Create a new Role
   * 
   * @alias module: AuthRepository
   * @param {Object} payload
   * @returns {String} id
   */
  async postRole (payload) {
    return await Repository.post(
      ApiTypes.POST_AUTH_ROLES,
      payload,
      DataTypes.idAsString,
      `${moduleName}.postRole()`
    )
  },

  /**
   * Update Role
   * 
   * @alias module: AuthRepository
   * @param {Object} payload
   */
  async putRole (id, payload) {
    return await Repository.put(
      Helpers.substituteApiVars(ApiTypes.PUT_AUTH_ROLES_ID, { id: id }),
      payload,
      null,
      `${moduleName}.putRole()`
    )
  },
  
  /**
   * Get Role
   * @alias module: AuthRepository
   * @param {String} id
   * @returns {Object} Role
   */
  async getRole (id) {
    Logger.info(`${moduleName}.getRole(id): called with id ${id}`)
    return await Repository.get(
      Helpers.substituteApiVars(ApiTypes.GET_AUTH_ROLES_ID, { id: id }),
      DataTypes.role,
      `${moduleName}.getRole()`
    )
  },

  /**
   * Delete Role
   * @alias module: AuthRepository
   * @param {String} id
   */
  async deleteRole (id) {
    Logger.info(`${moduleName}.deleteRole(id): called with id ${id}`)
    return await Repository.delete(
      Helpers.substituteApiVars(ApiTypes.DELETE_AUTH_ROLES_ID, { id: id }),
      null,
      `${moduleName}.deleteRole()`
    )
  },

  /**
   * Get Role scopes list
   * @alias module: AuthRepository
   * @param {String} id
   * @returns {Array} Array of objects
   */
  async getRoleScopeList (id) {
    return await Repository.get(
      Helpers.substituteApiVars(ApiTypes.GET_AUTH_ROLES_ID_SCOPES, { id: id }),
      DataTypes.roleScopeList,
      `${moduleName}.getRoleScopeList()`
    )
  },

  /**
   * Update Role scopes
   * 
   * @alias module: AuthRepository
   * @param {String} id,
   * @param {Object} payload
   */
  async updateRoleScopes (id, payload) {
    return await Repository.put(
      Helpers.substituteApiVars(ApiTypes.PUT_AUTH_ROLES_ID_SCOPES, { id: id }),
      payload,
      null,
      `${moduleName}.updateRoleScopes()`
    )
  },

  /**
   * Get Scope list
   * @alias module: AuthRepository
   * @returns {Array} Array of objects
   */
  async getScopeList () {
    return await Repository.get(
      ApiTypes.GET_AUTH_SCOPES,
      DataTypes.scopeList,
      `${moduleName}.getScopeList()`
    )
  },

  /**
   * Get ApiKey list
   * @alias module: AuthRepository
   * @returns {Array} Array of objects
   */
  async getApiKeyList () {
    return await Repository.get(
      ApiTypes.GET_AUTH_APIKEYS,
      DataTypes.apiKeyList,
      `${moduleName}.getApiKeyList()`
    )
  },

  /**
   * Create a new ApiKey
   * 
   * @alias module: AuthRepository
   * @param {Object} payload
   * @returns {String} ApiKey
   */
  async postApiKey(payload, validationField = null) {
    let urlParams = validationField ? `?validateOnly=true&validateFields=${validationField}` : ''
    return await Repository.post(
      `${ApiTypes.POST_AUTH_APIKEYS}${urlParams}`,
      payload,
      DataTypes.apiKeyString,
      `${moduleName}.postApiKey()`
    )
  },

  /**
   * Update ApiKey
   * 
   * @alias module: AuthRepository
   * @param {Object} payload
   */
  async putApiKey(payload, validationField = null) {
    let urlParams = validationField ? `?validateOnly=true&validateFields=${validationField}` : ''
    return await Repository.put(
      Helpers.substituteApiVars(`${ApiTypes.PUT_AUTH_APIKEYS_ID}${urlParams}`, { id: payload.id }),
      payload,
      null,
      `${moduleName}.putApiKey()`
    )
  },
  
  /**
   * Get ApiKey
   * @alias module: AuthRepository
   * @param {String} id
   * @returns {Object} ApiKey
   */
  async getApiKey (id) {
    Logger.info(`${moduleName}.getApiKey(id): called with id ${id}`)
    return await Repository.get(
      Helpers.substituteApiVars(ApiTypes.GET_AUTH_APIKEYS_ID, { id: id }),
      DataTypes.apiKey,
      `${moduleName}.getApiKey()`
    )
  },

  /**
   * Delete ApiKey
   * @alias module: AuthRepository
   * @param {String} id
   */
  async deleteApiKey (id) {
    Logger.info(`${moduleName}.deleteApiKey(id): called with id ${id}`)
    return await Repository.delete(
      Helpers.substituteApiVars(ApiTypes.DELETE_AUTH_APIKEYS_ID, { id: id }),
      null,
      `${moduleName}.deleteApiKey()`
    )
  },

  /**
   * Get AllApiKey list
   * @alias module: AuthRepository
   * @returns {Array} Array of objects
   */
  async getAllApiKeyList () {
      return await Repository.get(
        ApiTypes.GET_AUTH_APIKEYS_ALL,
        DataTypes.apiKeyList,
        `${moduleName}.getAllApiKeyList()`
      )
    },
  
}
