<template>
  <v-card>
    <v-layout row wrap>
      <slot v-for="item in items">
        <v-flex xs5 class="Grid-cell-name">
          <div class="text-xs-left font-weight-bold pa-2">{{ item.name }}</div>
        </v-flex>
        <v-flex xs7 class="Grid-cell-value">
          <div 
            v-if="item.renderHtml"
            class="text-xs-left pa-2"
            v-html="item.value"
          >
          </div>
          <div 
            v-else
            class="text-xs-left pa-2"
          >
          {{ item.value }}
          </div>
        </v-flex>
      </slot>
    </v-layout>
  </v-card>
</template>

<script>
  export default {
    props: {
      items: Array
    },
    data () {
      return {
      }
    }
  }
</script>

<style scoped>
.Grid-cell-name:nth-child(4n+1) {
  background: var(--v-evenOddColor-base);
}
.Grid-cell-value:nth-child(4n+2) {
  background: var(--v-evenOddColor-base);
}
</style>