<template>
  <v-layout row justify-center>
    <v-dialog v-model="show" persistent max-width="500px">
      <v-card>
        <v-card-title class="headline">
          <div v-html="title"></div>  
        </v-card-title>

        <v-card-text>
          <template v-if="htmlSafe">
            {{ message }}
          </template>
          <template v-else>
            <div v-html="message"></div>
          </template>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" flat @click.native="agree()">{{ $t('BUTTON.OK') }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-layout>
</template>

<script>
export default {
  // This component generates the following emit events:
  // cancel-button-clicked
  // ok-button-clicked

  props: {
    htmlSafe: {
      type: Boolean,
      default: false
    }
  },

  data: function () {
    return {
      show: false,
      resolve: null,
      reject: null,
      message: null,
      title: null
    }
  },

  methods: {
    close() {
      this.show = false
    },

    open(title, message) {
      this.show = true
      this.title = title
      this.message = message

      return new Promise((resolve, reject) => {
        this.resolve = resolve
        this.reject = reject
      })
    },

    agree() {
      this.resolve(true)
      this.close()
    },

    cancel() {
      this.resolve(false)
      this.close()
    }
  }
}
</script>
