import Repository from '../Repository'
import Logger from '@/lib/Logger.js'
import Helpers from '@/lib/Helpers.js'
import * as DataTypes from './DnsTemplatesRepositoryDataTypes'
import * as ApiTypes from '@/api/api-types'

const moduleName = 'DnsTemplatesRepository'

/**
 * A module for handling API calls for domain templates
 * @module DnsTemplatesRepository
 */
export default {

  /**
   * Get DNS records of a specified domain id
   *
   * @returns {Array} Array of array of name/value pairs
   */
  async getDnsTemplateList () {
    Logger.info(`${moduleName}.getList(): called`)
    return await Repository.get(
      ApiTypes.GET_DNS_TEMPLATES,
      DataTypes.dnsTemplateList,
      `${moduleName}.getDnsTemplateList()`
    )
  },

  /**
   * Post a new DNS template
   * 
   * @alias module: DnsTemplatesRepository
   * @param {Object} payload
   * @returns {Object}
   */
  async postDnsTemplate (payload) {
    return await Repository.post(
      ApiTypes.POST_DNS_TEMPLATES,
      payload,
      DataTypes.idAsString,
      `${moduleName}.postDnsTemplate()`
    )
  },

  /**
   * Get a specific DNS template
   *
   * @alias module: DnsTemplatesRepository
   * @param {String} templateId
   * @returns {Object}
   */
  async getDnsTemplate (templateId) {
    Logger.info(`${moduleName}.getDnsTemplate(templateId): called with templateId ${templateId}`)
    return await Repository.get(
      Helpers.substituteApiVars(ApiTypes.GET_DNS_TEMPLATES_ID, { id: templateId }),
      DataTypes.dnsTemplate,
      `${moduleName}.getDnsTemplate()`
    )
  },

  /**
   * Update a DNS template
   * 
   * @alias module: DnsTemplatesRepository
   * @param {Object} payload
   * @returns {Object}
   */
  async putDnsTemplate (dnsTemplateId, payload) {
    return await Repository.put(
      Helpers.substituteApiVars(ApiTypes.PUT_DNS_TEMPLATES_ID, { id: dnsTemplateId }),
      payload,
      null,
      `${moduleName}.putDnsTemplate()`
    )
  },

  /**
   * Delete a DNS template
   * 
   * @alias module: DnsTemplatesRepository
   * @param {String} templateId
   */
  async deleteDnsTemplate (templateId) {
    return await Repository.delete(
      Helpers.substituteApiVars(ApiTypes.DELETE_DNS_TEMPLATES_ID, { id: templateId }),
      `${moduleName}.deleteDnsTemplate()`
    )
  },

  /**
   * Duplicate a DNS template
   * 
   * @alias module: DnsTemplatesRepository
   * @param {String} templateId
   * @returns {Object}
   */
  async duplicateDnsTemplate (templateId) {
    return await Repository.post(
      Helpers.substituteApiVars(ApiTypes.POST_DNS_TEMPLATES_ID_DUPLICATE, { id: templateId }),
      {},
      DataTypes.idAsString,
      `${moduleName}.duplicateDnsTemplate()`
    )
  },

  /**
   * Get records of a specified template
   *
   * @param {String} templateId
   * @returns {Array}
   */
  async getRecordList (templateId) {
    Logger.info(`${moduleName}.getRecordList(): called`)
    return await Repository.get(
      Helpers.substituteApiVars(ApiTypes.GET_DNS_TEMPLATES_ID_RECORDS, { dnsTemplateId: templateId }),
      DataTypes.dnsTemplateRecordList,
      `${moduleName}.getRecordList()`
    )
  },

  /**
   * Post a new DNS template record
   * 
   * @alias module: DnsTemplatesRepository
   * @param {String} templateId
   * @param {Object} payload
   * @returns {Object}
   */
  async postRecord (templateId, payload) {
    return await Repository.post(
      Helpers.substituteApiVars(ApiTypes.POST_DNS_TEMPLATES_ID_RECORDS, { dnsTemplateId: templateId }),
      payload,
      DataTypes.idAsString,
      `${moduleName}.postRecord()`
    )
  },

  /**
   * Get a specific DNS template record
   *
   * @alias module: DnsTemplatesRepository
   * @param {String} templateId
   * @param {String} recordId
   * @returns {Object}
   */
  async getRecord (templateId, recordId) {
    Logger.info(`${moduleName}.getRecord(templateId, recordId): called with templateId ${templateId} and recordId ${recordId}`)
    return await Repository.get(
      Helpers.substituteApiVars(ApiTypes.GET_DNS_TEMPLATES_ID_RECORDS_ID, { dnsTemplateId: templateId, id: recordId }),
      DataTypes.dnsTemplateRecord,
      `${moduleName}.getRecord()`
    )
  },

  /**
   * Update a DNS template record
   * 
   * @alias module: DnsTemplatesRepository
   * @param {String} templateId
   * @param {Object} payload
   * @returns {Object}
   */
  async putRecord (templateId, payload) {
    return await Repository.put(
      Helpers.substituteApiVars(ApiTypes.PUT_DNS_TEMPLATES_ID_RECORDS_ID, { dnsTemplateId: templateId, id: payload.id }),
      payload,
      null,
      `${moduleName}.putRecord()`
    )
  },

  /**
   * Delete a DNS template record
   * 
   * @alias module: DnsTemplatesRepository
   * @param {String} templateId
   * @param {String} recordId
   */
  async deleteRecord (templateId, recordId) {
    return await Repository.delete(
      Helpers.substituteApiVars(ApiTypes.DELETE_DNS_TEMPLATES_ID_RECORDS_ID, { dnsTemplateId: templateId, id: recordId }),
      `${moduleName}.deleteRecord()`
    )
  },

}