import '@mdi/font/css/materialdesignicons.css' // Ensure you are using css-loader
import Vue from 'vue'
import './plugins/vuetify'
import App from './App.vue'
import router from './router'
import { store } from './store/store'
import Vuetify from 'vuetify'
//import VueLodash from 'vue-lodash'
import _ from 'lodash'
Object.defineProperty(Vue.prototype, '_', { value: _ })

import 'vuetify/dist/vuetify.min.css' // Ensure you are using css-loader
// import '~vuetify/src/stylus/main' // Ensure you are using stylus-loader
import i18n from '@/lib/i18n';
import * as Sentry from '@sentry/browser';
import * as Integrations from '@sentry/integrations';

Sentry.init({
  dsn: 'https://fb7d6175b3064fc5aec10977bf640981@sentry.io/1811254',
  integrations: [new Integrations.Vue({Vue, logErrors: true, attachProps: false})],
  environment: process.env.NODE_ENV
});

// TODO: Cleanup code

// Load functionality to make BaseComponents available automatically
import upperFirst from 'lodash/upperFirst'
import camelCase from 'lodash/camelCase'

const requireComponent = require.context(
  // The relative path of the components folder
  '@/components/Base',
  // Whether or not to look in subfolders
  false,
  // The regular expression used to match base component filenames
  /Base[A-Z]\w+\.(vue|js)$/
)

requireComponent.keys().forEach(fileName => {
  // Get component config
  const componentConfig = requireComponent(fileName)

  // Get PascalCase name of component
  const componentName = upperFirst(
    camelCase(
      // Strip the leading `./` and extension from the filename
      fileName.replace(/^\.\/(.*)\.\w+$/, '$1')
    )
  )

  // Register component globally
  Vue.component(
    componentName,
    // Look for the component options on `.default`, which will
    // exist if the component was exported with `export default`,
    // otherwise fall back to module's root.
    componentConfig.default || componentConfig
  )
})

Vue.config.productionTip = false

//Vue.use(VueLodash)
Vue.use(Vuetify, {
  iconfont: 'mdi',
  theme: {
    primary: '#424242',
    secondary: '#29B6F6',
    newButton: '#29B6F6',
    deleteButton: '#E53935',
    editButton: '#29B6F6',
    exportButton: '#01579B',
    evenOddColor: '#F5F5F5',
    headerBar: '#dfdfdf',
    disabled: '#bbbbbb',
    info: '#E1F5FE',
    ok: '#00C853',
    selectedMenuItem: '#626262',
    
  /*
    secondary: '#555555',
    accent: '#66BB6A',
    error: '#E53935',
    warning: '#FFB74D',
    info: '#4DB6AC'
  */
  },
  options: {
    customProperties: true
  }
})


// Import global to use constants and functionality
// and assign it to the Vue instance
import Logger from '@/lib/Logger.js'
Object.defineProperty(Vue.prototype, '$Logger', { value: Logger });

import Helpers from '@/lib/Helpers.js'
Object.defineProperty(Vue.prototype, '$Helpers', { value: Helpers })

import jwt_decode from 'jwt-decode';

// Check if requested route needs authentication
// and check if the user is authenticated when needed
router.beforeEach((to, from, next) => {
  store.dispatch('setBreadCrumbDetail', null)
  store.dispatch('menu/setBreadCrumbPath', to.meta.breadCrumbMainPath)
  // Check if we switch to different component
  // In that case we need to reset the MultiSearchItems (company search) in the Store
  let fromParts = from.path.split('/')
  let toParts = to.path.split('/')
  if (fromParts.length < 2 || toParts.length < 2 || fromParts[1] != toParts[1]) {
    store.dispatch('setMultiSearchItems', [])
    store.dispatch('setArchiveSwitch', false)
    store.dispatch('setSearchField', null)
    store.dispatch('setSelectedDropDownItem', null)
  }
  if(to.matched.some(record => record.meta.requiresAuth)) {
    let token = store.getters['security/getToken']
    let decodedToken = token ? jwt_decode(token) : null
    let currentDate = new Date().getTime()

    if (/*to.name == 'users' ||*/ !token || !decodedToken || decodedToken.exp < Math.floor(currentDate / 1000) ||
      (decodedToken.mfareq && !decodedToken.mfa && to.name !== 'enableTwoFactorAuthentication')) {
      if (store.getters['security/isAuthenticated']) {
        // If the user was already logged in, then display e nice message
        store.dispatch('userMessages/setUserMessage', i18n.t('BASE.LOGIN.EXPIRED'))
      }
      next({
        name: 'login'
      })
    }  
    else {
      next()
    }
  }
  else {
    next()
  }
})


// Define global filters
Vue.filter('uppercase', function (value) {
  if (!value) return ''
  return value.toUpperCase()
})

Vue.config.errorHandler = function (err) {
  Logger.error(err)
}

new Vue({
  store,
  router,
  i18n,
  render: h => h(App)
}).$mount('#app')

