export default class AppError extends Error {
  constructor(payload) {
    super(payload.title); // (1)
    this.name = 'appError'
    this.type = payload.type
    this.title = payload.title
    this.status = payload.status
    this.detail = payload.detail
    this.status = payload.status
    this.instance = payload.instance
    if (payload.validationErrors) {
      this.validationErrors = payload.validationErrors
    }
    if (payload.subDomains) {
      this.subDomains = payload.subDomains
    }
    if (payload.apiToken) {
      this.apiToken = payload.apiToken
    }
  }
}