import { Type, Enum } from 'hello-type' // Check datatypes from API stream

export const idAsString = new Type({
  id: String
})

/** 
* Definition of documents for the expected API properties which will be checked on API call
* @constant
*/
export const documentList = new Type({
  category_id: String,
  category_name: String,
  documents: Enum(Array(), Array(
    {
      id: String,
      title: String,
      dateCreated: String,
      dateLastModified: String,
      isPublic: Boolean,
      customer_id: Enum(null, String),
      type_code: Enum('link', 'pdf'),
    }
  )),
})

export const document = new Type({
  customer: Enum(null, {
    id: String,
    name: String
  }),
  fileName: String,
  documentCategory: {
    id: String,
    name: String
  },
  order: Number,
  dateCreated: String,
  dateLastModified: String,
  documentType: Enum('pdf'),
  id: String,
  isArchive: Boolean,
  title: String
})

export const link = new Type({
  url: String,
  documentCategory: {
    id: String,
    name: String
  },
  order: Number,
  dateCreated: String,
  dateLastModified: String,
  documentType: Enum('link'),
  id: String,
  isArchive: Boolean,
  title: String
})

export const categoryList = new Type({
    id: String,
    name: String,
    order: Number
})

export const category = new Type({
  id: String,
  name: String,
  order: Number
})

export const typeList = new Type({
    id: String,
    code: String,
    name: String
})
