var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { attrs: { bg: "", "fill-height": "", "grid-list-md": "" } },
    [
      _c(
        "v-dialog",
        {
          attrs: { persistent: "", "max-width": "500px" },
          model: {
            value: _vm.show,
            callback: function($$v) {
              _vm.show = $$v
            },
            expression: "show"
          }
        },
        [
          _c(
            "v-layout",
            { attrs: { row: "", "justify-center": "", "align-center": "" } },
            [
              _c(
                "v-flex",
                { attrs: { xs12: "" } },
                [
                  _c(
                    "v-card",
                    [
                      _c(
                        "v-form",
                        {
                          ref: "form",
                          on: {
                            submit: function($event) {
                              $event.preventDefault()
                            }
                          }
                        },
                        [
                          _c("v-card-title", [
                            _c("span", { staticClass: "headline" }, [
                              _c("div", {
                                domProps: { innerHTML: _vm._s(_vm.dialogTitle) }
                              })
                            ])
                          ]),
                          _c("base-api-error-messages-dialog", {
                            attrs: { apiErrorMessages: _vm.apiErrorMessages }
                          }),
                          _c(
                            "v-card-text",
                            [
                              !_vm.useRecoveryCode
                                ? [
                                    _c(
                                      "v-container",
                                      { attrs: { "grid-list-md": "" } },
                                      [
                                        _c(
                                          "v-layout",
                                          { attrs: { wrap: "" } },
                                          [
                                            _c(
                                              "v-flex",
                                              {
                                                staticClass: "pa-2",
                                                attrs: { xs2: "" }
                                              },
                                              [
                                                _c(
                                                  "v-icon",
                                                  { attrs: { large: "" } },
                                                  [_vm._v("mdi-cellphone-key")]
                                                )
                                              ],
                                              1
                                            ),
                                            _c(
                                              "v-flex",
                                              { attrs: { xs8: "" } },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.$t(
                                                        "BASE.GLOBAL.USERTOKEN_MESSAGE"
                                                      )
                                                    ) +
                                                    " "
                                                )
                                              ]
                                            )
                                          ],
                                          1
                                        ),
                                        _c(
                                          "v-layout",
                                          { attrs: { wrap: "" } },
                                          [
                                            _c(
                                              "v-flex",
                                              {
                                                staticClass: "pa-2",
                                                attrs: { xs8: "" }
                                              },
                                              [
                                                _c("v-text-field", {
                                                  ref: "userToken",
                                                  attrs: {
                                                    mask: "### ###",
                                                    type: "text",
                                                    label: _vm.$t(
                                                      "BASE.GLOBAL.USERTOKEN"
                                                    ),
                                                    rules: [
                                                      _vm.validationRules
                                                        .required
                                                    ],
                                                    "validate-on-blur": ""
                                                  },
                                                  on: {
                                                    keyup: function($event) {
                                                      if (
                                                        !$event.type.indexOf(
                                                          "key"
                                                        ) &&
                                                        _vm._k(
                                                          $event.keyCode,
                                                          "enter",
                                                          13,
                                                          $event.key,
                                                          "Enter"
                                                        )
                                                      ) {
                                                        return null
                                                      }
                                                      return _vm.submit()
                                                    }
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.formData.userToken,
                                                    callback: function($$v) {
                                                      _vm.$set(
                                                        _vm.formData,
                                                        "userToken",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "formData.userToken"
                                                  }
                                                })
                                              ],
                                              1
                                            )
                                          ],
                                          1
                                        ),
                                        _c(
                                          "v-layout",
                                          { attrs: { wrap: "" } },
                                          [
                                            _c(
                                              "v-flex",
                                              {
                                                staticClass: "pa-2",
                                                attrs: { xs1: "" }
                                              },
                                              [
                                                _c("v-checkbox", {
                                                  staticClass: "ma-0 pa-0",
                                                  model: {
                                                    value:
                                                      _vm.formData
                                                        .saveTrustedDevice,
                                                    callback: function($$v) {
                                                      _vm.$set(
                                                        _vm.formData,
                                                        "saveTrustedDevice",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "formData.saveTrustedDevice"
                                                  }
                                                })
                                              ],
                                              1
                                            ),
                                            _c(
                                              "v-flex",
                                              {
                                                staticClass: "pa-2",
                                                attrs: { xs11: "" }
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.$t(
                                                        "BASE.GLOBAL.SAVE_TRUSTED_DEVICE"
                                                      )
                                                    ) +
                                                    " "
                                                )
                                              ]
                                            )
                                          ],
                                          1
                                        ),
                                        _c(
                                          "v-layout",
                                          { attrs: { wrap: "" } },
                                          [
                                            _c(
                                              "v-flex",
                                              { attrs: { xs8: "" } },
                                              [
                                                _c("span", [
                                                  _c(
                                                    "a",
                                                    {
                                                      staticStyle: {
                                                        "font-size": "12px",
                                                        color: "#aaa"
                                                      },
                                                      attrs: { href: "#" },
                                                      on: {
                                                        click: function(
                                                          $event
                                                        ) {
                                                          _vm.useRecoveryCode = true
                                                        }
                                                      }
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.$t(
                                                            "BUTTON.USE_RECOVERY_CODE"
                                                          )
                                                        )
                                                      )
                                                    ]
                                                  )
                                                ])
                                              ]
                                            )
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    )
                                  ]
                                : [
                                    _c(
                                      "v-container",
                                      { attrs: { "grid-list-md": "" } },
                                      [
                                        _c(
                                          "v-layout",
                                          { attrs: { wrap: "" } },
                                          [
                                            _c(
                                              "v-flex",
                                              {
                                                staticClass: "pa-2",
                                                attrs: { xs2: "" }
                                              },
                                              [
                                                _c(
                                                  "v-icon",
                                                  { attrs: { large: "" } },
                                                  [_vm._v("mdi-restore")]
                                                )
                                              ],
                                              1
                                            ),
                                            _c(
                                              "v-flex",
                                              { attrs: { xs8: "" } },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.$t(
                                                        "BASE.GLOBAL.RECOVERY_CODE_MESSAGE"
                                                      )
                                                    ) +
                                                    " "
                                                )
                                              ]
                                            )
                                          ],
                                          1
                                        ),
                                        _c(
                                          "v-layout",
                                          { attrs: { wrap: "" } },
                                          [
                                            _c(
                                              "v-flex",
                                              {
                                                staticClass: "pa-2",
                                                attrs: { xs8: "" }
                                              },
                                              [
                                                _c("v-text-field", {
                                                  attrs: {
                                                    mask: "## ### ###",
                                                    type: "text",
                                                    label: _vm.$t(
                                                      "BASE.GLOBAL.RECOVERY_CODE"
                                                    ),
                                                    rules: [
                                                      _vm.validationRules
                                                        .required
                                                    ],
                                                    "validate-on-blur": ""
                                                  },
                                                  on: {
                                                    keyup: function($event) {
                                                      if (
                                                        !$event.type.indexOf(
                                                          "key"
                                                        ) &&
                                                        _vm._k(
                                                          $event.keyCode,
                                                          "enter",
                                                          13,
                                                          $event.key,
                                                          "Enter"
                                                        )
                                                      ) {
                                                        return null
                                                      }
                                                      return _vm.submit()
                                                    }
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.formData.userToken,
                                                    callback: function($$v) {
                                                      _vm.$set(
                                                        _vm.formData,
                                                        "userToken",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "formData.userToken"
                                                  }
                                                })
                                              ],
                                              1
                                            )
                                          ],
                                          1
                                        ),
                                        _c(
                                          "v-layout",
                                          { attrs: { wrap: "" } },
                                          [
                                            _c(
                                              "v-flex",
                                              { attrs: { xs8: "" } },
                                              [
                                                _c("span", [
                                                  _c(
                                                    "a",
                                                    {
                                                      staticStyle: {
                                                        "font-size": "12px",
                                                        color: "#aaa"
                                                      },
                                                      attrs: { href: "#" },
                                                      on: {
                                                        click: function(
                                                          $event
                                                        ) {
                                                          _vm.useRecoveryCode = false
                                                        }
                                                      }
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.$t(
                                                            "BUTTON.USE_AUTHENTICATOR_APP"
                                                          )
                                                        )
                                                      )
                                                    ]
                                                  )
                                                ])
                                              ]
                                            )
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    )
                                  ]
                            ],
                            2
                          ),
                          _c(
                            "v-card-actions",
                            [
                              _c("v-spacer"),
                              _c(
                                "v-btn",
                                {
                                  attrs: { color: "blue darken-1", flat: "" },
                                  nativeOn: {
                                    click: function($event) {
                                      return _vm.cancel()
                                    }
                                  }
                                },
                                [_vm._v(_vm._s(_vm.$t("BUTTON.CANCEL")))]
                              ),
                              _c(
                                "v-btn",
                                {
                                  attrs: { color: "green darken-1", flat: "" },
                                  on: {
                                    click: function($event) {
                                      return _vm.submit()
                                    }
                                  }
                                },
                                [_vm._v(_vm._s(_vm.$t("BUTTON.SUBMIT")))]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }