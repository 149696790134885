import Repository from '../Repository'
import Logger from '@/lib/Logger.js'
import Helpers from '@/lib/Helpers.js'
import * as DataTypes from './LoggingRepositoryDataTypes'
import * as ApiTypes from '@/api/api-types'

const moduleName = 'LoggingRepository'

/**
 * A module for handling API calls for Logging
 * @module LoggingRepository
 */
export default {

  /**
   * List Audit entries
   *
   * @alias module: LoggingRepository
   * @returns {Array} Array of objects
   */
  async getAuditEntryList () {
    Logger.info(`${moduleName}.getAuditEntryList(): called`)
    return await Repository.get(
      ApiTypes.GET_LOGGED_AUDIT_ENTRIES,
      DataTypes.auditEntryList,
      `${moduleName}.getAuditEntryList()`
    )
  },

  /**
   * Show audit entry
   *
   * @alias module: LoggingRepository
   * @param {String} auditEntryId
   * @returns {Object}
   */
  async getAuditEntry (auditEntryId) {
    Logger.info(`${moduleName}.getAuditEntry(auditEntryId): called with auditEntryId ${auditEntryId}`)
    return await Repository.get(
      Helpers.substituteApiVars(ApiTypes.GET_LOGGED_AUDIT_ENTRIES_ID, { id: auditEntryId }),
      null,
      `${moduleName}.getAuditEntry()`
    )
  },

  /**
   * List Error Log Entries
   *
   * @alias module: LoggingRepository
   * @returns {Array} Array of objects
   */
  async getLoggedErrorList () {
    Logger.info(`${moduleName}.getLoggedErrorList(): called`)
    return await Repository.get(
      ApiTypes.GET_LOGGED_ERRORS,
      DataTypes.loggedErrorList,
      `${moduleName}.getLoggedErrorList()`
    )
  },

  /**
   * Show error
   *
   * @alias module: LoggingRepository
   * @param {String} loggedErrorId
   * @returns {Object}
   */
  async getLoggedError (loggedErrorId) {
    Logger.info(`${moduleName}.getLoggedError(loggedErrorId): called with loggedErrorId ${loggedErrorId}`)
    return await Repository.get(
      Helpers.substituteApiVars(ApiTypes.GET_LOGGED_ERRORS_ID, { id: loggedErrorId }),
      null,
      `${moduleName}.getLoggedError()`
    )
  },

  /**
   * List Rabbit transactions
   *
   * @alias module: LoggingRepository
   * @returns {Array} Array of objects
   */
  async getRabbitTransactionList () {
    Logger.info(`${moduleName}.getRabbitTransactionList(): called`)
    return await Repository.get(
      ApiTypes.GET_RABBIT_TRANSACTIONS,
      DataTypes.rabbitTransactionList,
      `${moduleName}.getRabbitTransactionList()`
    )
  },

  /**
   * Show Rabbit transaction
   *
   * @alias module: LoggingRepository
   * @param {String} id
   * @returns {Object}
   */
  async getRabbitTransaction (id) {
    Logger.info(`${moduleName}.getRabbitTransaction(id): called with id ${id}`)
    return await Repository.get(
      Helpers.substituteApiVars(ApiTypes.GET_RABBIT_TRANSACTIONS_ID, { id: id }),
      null,
      `${moduleName}.getRabbitTransaction()`
    )
  },

}