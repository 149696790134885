import Repository from '../Repository'
import Logger from '@/lib/Logger.js'
import Helpers from '@/lib/Helpers.js'
import * as DataTypes from './SharedMailRepositoryDataTypes'
import * as DomainsDataTypes from './DomainsRepositoryDataTypes'
import * as ApiTypes from '@/api/api-types'

const moduleName = 'SharedMailRepository'

/**
 * A module for handling API calls for shared mail
 * @module SharedMailRepository
 */
export default {
  /**
   * Get list of mail aliases
   * @alias module: SharedMailRepository
   * @param {String} domainName
   * @returns {Array} Array of array of name/value pairs for mail alias properties
   */
  async getMailAliases (domainName) {
    return await Repository.get(
      Helpers.substituteApiVars(ApiTypes.GET_DOMAINS_ID_MAIL_ALIASES, { domainName: domainName }),
      DataTypes.mailAliasList,
      `${moduleName}.getMailAliases()`
    )
  },

  /**
   * Get a single mail alias
   * @alias module: SharedMailRepository
   * @param {String} domainName
   * @param {Number} aliasId
   * @returns {Array} array of name/value pairs for mail alias properties
   */
  async getMailAlias (domainName, aliasId) {
    return await Repository.get(
      Helpers.substituteApiVars(ApiTypes.GET_DOMAINS_ID_MAIL_ALIASES_ID, { domainName: domainName, id: aliasId }),
      DataTypes.mailAlias,
      `${moduleName}.getMailAlias()`
    )
  },

  /**
   * Post a new Mail Alias
   * 
   * @alias module: SharedMailRepository
   * @param {Array} payload Array of name/value pairs for mail Alias
   * @param {String} validationField If set, do a remote validation on the given field
   */
  async postMailAlias (domainName, payload, validationField = null) {
    let urlParams = validationField ? `?validateOnly=true&validateFields=${validationField}` : ''
    return await Repository.post(
      Helpers.substituteApiVars(`${ApiTypes.POST_DOMAINS_ID_MAIL_ALIASES}${urlParams}`, { domainName: domainName }),
      payload,
      DataTypes.idAsNumber,
      `${moduleName}.postMailAlias()`
    )
  },

  /**
   * Update a new Mail Alias
   * 
   * @alias module: SharedMailRepository
   * @param {Array} payload Array of name/value pairs for mail Alias
   * @param {String} validationField If set, do a remote validation on the given field
   */
  async putMailAlias (domainName, payload, validationField = null) {
    let urlParams = validationField ? `?validateOnly=true&validateFields=${validationField}` : ''
    return await Repository.put(
      Helpers.substituteApiVars(`${ApiTypes.PUT_DOMAINS_ID_MAIL_ALIASES_ID}${urlParams}`, { domainName: domainName, id: payload.id }),
      payload,
      null,
      `${moduleName}.putMailAlias()`
    )
  },

  /**
   * Delete a Mail Alias
   * 
   * @alias module: SharedMailRepository
   * @param {String} domainName
   * @param {Array} aliasId 
   */
  async deleteMailAlias (domainName, aliasId) {
    return await Repository.delete(
      Helpers.substituteApiVars(ApiTypes.DELETE_DOMAINS_ID_MAIL_ALIASES_ID, { domainName: domainName, id: aliasId }),
      `${moduleName}.deleteMailAlias()`
    )
  },

  /**
   * Get list of mail boxes
   * @alias module: SharedMailRepository
   * @param {String} domainName
   * @returns {Array} Array of array of name/value pairs for mailbox properties
   */
  async getMailBoxes (domainName) {
    return await Repository.get(
      Helpers.substituteApiVars(ApiTypes.GET_DOMAINS_ID_MAIL_BOXES, { domainName: domainName }),
      DataTypes.mailBoxList,
      `${moduleName}.getMailBoxes()`
    )
  },

  /**
   * Get a single mailbox
   * @alias module: SharedMailRepository
   * @param {String} domainName
   * @param {Number} mailBoxId
   * @returns {Array} array of name/value pairs for mailbox properties
   */
  async getMailBox (domainName, mailBoxId) {
    return await Repository.get(
      Helpers.substituteApiVars(ApiTypes.GET_DOMAINS_ID_MAIL_BOXES_ID, { domainName: domainName, id: mailBoxId }),
      DataTypes.mailBox,
      `${moduleName}.getMailBox()`
    )
  },

  /**
   * Post a new MailBox
   * 
   * @alias module: SharedMailRepository
   * @param {Array} payload Array of name/value pairs for mailbox
   * @param {String} validationField If set, do a remote validation on the given field
   */
  async postMailBox (domainName, payload, validationField = null) {
    let urlParams = validationField ? `?validateOnly=true&validateFields=${validationField}` : ''
    return await Repository.post(
      Helpers.substituteApiVars(`${ApiTypes.POST_DOMAINS_ID_MAIL_BOXES}${urlParams}`, { domainName: domainName }),
      payload,
      DataTypes.idAsNumber,
      `${moduleName}.postMailBox()`
    )
  },

  /**
   * Update a Mailbox
   * 
   * @alias module: SharedMailRepository
   * @param {Array} payload Array of name/value pairs for mailbox
   * @param {String} validationField If set, do a remote validation on the given field
   */
  async putMailBox (domainName, payload, validationField = null) {
    let urlParams = validationField ? `?validateOnly=true&validateFields=${validationField}` : ''
    return await Repository.put(
      Helpers.substituteApiVars(`${ApiTypes.PUT_DOMAINS_ID_MAIL_BOXES_ID}${urlParams}`, { domainName: domainName, id: payload.id }),
      payload,
      null,
      `${moduleName}.putMailBox()`
    )
  },

  /**
   * Delete a Mailbox
   * 
   * @alias module: SharedMailRepository
   * @param {String} domainName
   * @param {Array} mailBoxId
   */
  async deleteMailBox (domainName, mailBoxId) {
    return await Repository.delete(
      Helpers.substituteApiVars(ApiTypes.DELETE_DOMAINS_ID_MAIL_BOXES_ID, { domainName: domainName, id: mailBoxId }),
      `${moduleName}.deleteMailBox()`
    )
  },

  /**
   * Get Mail settings for a specified domain
   * @alias module: SharedMailRepository
   * @param {String} domainName
   * @returns {Array} Array of array of name/value pairs for mail settings properties
   */
  async getMailSettings (domainName) {
    Logger.info(`${moduleName}.getMailSettings() with domainName ${domainName}`)
    return await Repository.get(
      Helpers.substituteApiVars(ApiTypes.GET_DOMAINS_ID_MAIL_SETTINGS, { domainName: domainName }),
      DataTypes.mailSettings,
      `${moduleName}.getMailSettings()`
    )
  },

  /**
   * Update Mail Settings
   * 
   * @alias module: SharedMailRepository
   * @param {Array} payload Array of name/value pairs for mail Settings
   */
  async putMailSettings (domainName, payload) {
    return await Repository.put(
      Helpers.substituteApiVars(ApiTypes.PUT_DOMAINS_ID_MAIL_SETTINGS, { domainName: domainName, id: payload.id }),
      payload,
      null,
      `${moduleName}.putMailSettings()`
    )
  },

  /**
   * Get Mail Catchall settings for a specified domain
   * @alias module: SharedMailRepository
   * @param {String} domainName
   * @returns {Array} Array of array of name/value pairs for catch all settings properties
   */
  async getMailCatchAll (domainName) {
    return await Repository.get(
      Helpers.substituteApiVars(ApiTypes.GET_DOMAINS_ID_MAIL_CATCHALL, { domainName: domainName }),
      DataTypes.mailCatchAll,
      `${moduleName}.getMailCatchAll()`
    )
  },

  /**
   * Post CatchAll
   * 
   * @alias module: SharedMailRepository
   * @param {Array} payload Array of name/value pairs for CatchAll
   */
  async postMailCatchAll (domainName, payload) {
    return await Repository.post(
      Helpers.substituteApiVars(ApiTypes.POST_DOMAINS_ID_MAIL_CATCHALL, { domainName: domainName }),
      payload,
      DataTypes.idAsNumber,
      `${moduleName}.postMailCatchAll()`
    )
  },

  /**
   * Update CatchAll
   * 
   * @alias module: SharedMailRepository
   * @param {Array} payload Array of name/value pairs for CatchAll
   */
  async putMailCatchAll (domainName, payload) {
    return await Repository.put(
      Helpers.substituteApiVars(ApiTypes.PUT_DOMAINS_ID_MAIL_CATCHALL, { domainName: domainName }),
      payload,
      null,
      `${moduleName}.putMailCatchAll()`
    )
  },

  /**
   * Delete CatchAll
   * 
   * @alias module: SharedMailRepository
   * @param {String} domainName
   */
  async deleteMailCatchAll (domainName) {
    return await Repository.delete(
      Helpers.substituteApiVars(ApiTypes.DELETE_DOMAINS_ID_MAIL_CATCHALL, { domainName: domainName }),
      `${moduleName}.deleteMailCatchAll()`
    )
  },

  /**
   * Post Prepared Shared Mail
   * 
   * @alias module: SharedMailRepository
   * @param {String} domainName 
   */
  async postPreparedSharedMail (domainName) {
    return await Repository.post(
      Helpers.substituteApiVars(ApiTypes.POST_DOMAINS_ID_PREPARED_SHARED_MAIL, { domainName: domainName }),
      null,
      DomainsDataTypes.idAsString,
      `${moduleName}.postPreparedSharedMail()`
    )
  },

  /**
   * Post Shared Mail
   * Enable Shared Mail
   * 
   * @alias module: SharedMailRepository
   * @param {String} domainName
   */
  async postSharedMail (domainName, payload) {
    return await Repository.post(
      Helpers.substituteApiVars(ApiTypes.POST_DOMAINS_ID_SHARED_MAIL, { domainName: domainName }),
      payload,
      DomainsDataTypes.idAsString,
      `${moduleName}.postSharedMail()`
    )
  },

  /**
   * Delete Shared Mail
   * Disable shared mail
   * 
   * @alias module: SharedMailRepository
   * @param {String} domainName
   */
  async deleteSharedMail (domainName) {
    return await Repository.delete(
      Helpers.substituteApiVars(ApiTypes.DELETE_DOMAINS_ID_SHARED_MAIL, { domainName: domainName }),
      `${moduleName}.deleteSharedMail()`
    )
  },

  /**
   * Post Exchange
   * Enable Exchange
   * 
   * @alias module: SharedMailRepository
   * @param {String} domainName
   */
  async postExchange (domainName) {
    return await Repository.post(
      Helpers.substituteApiVars(ApiTypes.POST_DOMAINS_ID_EXCHANGE, { domainName: domainName }),
      null,
      DomainsDataTypes.idAsString,
      `${moduleName}.postExchange()`
    )
  },

  /**
   * Delete Exchange
   * Disable Exchange
   * 
   * @alias module: SharedMailRepository
   * @param {String} domainName
   */
  async deleteExchange (domainName) {
    return await Repository.delete(
      Helpers.substituteApiVars(ApiTypes.DELETE_DOMAINS_ID_EXCHANGE, { domainName: domainName }),
      `${moduleName}.deleteExchange()`
    )
  },

  /**
   * Get list of mailbox reports
   * @alias module: SharedMailRepository
   * @returns {Array} Array of objects
   */
  async getMailboxReportList () {
    return await Repository.get(
      ApiTypes.GET_MAILBOX_REPORT,
      DataTypes.mailBoxReportList,
      `${moduleName}.getMailBoxReportList()`
    )
  },

}
