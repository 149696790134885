import Repository from '../Repository'
//import Logger from '@/lib/Logger.js'
import Helpers from '@/lib/Helpers.js'
import * as DataTypes from './DomainsRepositoryDataTypes'
import * as ApiTypes from '@/api/api-types'

const moduleName = 'DomainsRepository'

/**
 * A module for handling API calls for domains
 * @module DomainsRepository
 */
export default {
  /**
   * Get list of domains
   * @alias module: DomainsRepository
   * @returns {Array} Array of array of name/value pairs for domain properties
   */
  async get () {
    return await Repository.get(
      `${ApiTypes.GET_DOMAINS}?ListType=all`,
      DataTypes.domainList,
      `${moduleName}.get()`
    )
  },

  /**
   * Update a new domain
   * 
   * @alias module: DomainsRepository
   * @param {Array} payload Array of name/value pairs for domain properties
   */
  async put (domainName, payload) {
    return await Repository.put(
      Helpers.substituteApiVars(ApiTypes.PUT_DOMAINS_ID, { domainName: domainName }), 
      payload,
      null,
      `${moduleName}.put()`
    )
  },

  /**
   * Post a new domain
   * 
   * @alias module: DomainsRepository
   * @param {Array} payload Array of name/value pairs for domain properties
   */
  async post (payload) {
    return await Repository.post(
      ApiTypes.POST_DOMAINS, 
      payload,
      DataTypes.idAsString,
      `${moduleName}.post()`
    )
  },

  /**
   * Delete a new domain
   * 
   * @alias module: DomainsRepository
   * @param {String} domainName Id of the domain to delete
   */
  async delete (domainName) {
    return await Repository.delete(
      Helpers.substituteApiVars(ApiTypes.DELETE_DOMAINS_ID, { domainName: domainName }),
      `${moduleName}.delete()`
    )
  },

  /**
   * Get properties for a specified domain id
   *
   * @param {Number} domainName
   * @returns {Array} Array of name/value pairs for domain properties
   */
  async getDomain (domainName) {
    return await Repository.get(
      Helpers.substituteApiVars(ApiTypes.GET_DOMAINS_ID, { domainName: domainName }),
      DataTypes.domain,
      `${moduleName}.getDomain()`
    )
  },

  /**
   * Update DNSsec status
   * 
   * @alias module: DomainsRepository
   * @param {Object} payload Object containing the DNSsec status id
   */
  async updateDnsSecStatus (domainName, payload) {
    return await Repository.put(
      Helpers.substituteApiVars(ApiTypes.PUT_DOMAINS_ID_DNS_SEC_STATUS, { domainName: domainName }), 
      payload,
      null,
      `${moduleName}.updateDnsSecStatus()`
    )
  },

  /**
   * Enable DNSsec
   * 
   * @alias module: DomainsRepository
   * @param {String} domainName
   */
  async enableDnsSec (domainName, enableSubdomains = false) {
    var response = await Repository.post(
      Helpers.substituteApiVars(`${ApiTypes.POST_DOMAINS_DOMAIN_NAME_DNSSEC}?enableSubdomains=${enableSubdomains}`, { domainName: domainName }),
      null,
      DataTypes.idAsString,
      `${moduleName}.enableDnsSec()`
    )
    return response
  },

  /**
   * Enable DNS
   * 
   * @alias module: DomainsRepository
   * @param {String} domainName
   * @param {Object} value
   */
  async enableDns (domainName, value) {
    var response = await Repository.post(
      Helpers.substituteApiVars(ApiTypes.POST_DNS_DOMAIN_NAME, { domainName: domainName }),
      value,
      DataTypes.idAsNumber,
      `${moduleName}.enableDns()`
    )
    return response
  },

  /**
   * Disable DNS
   * 
   * @alias module: DomainsRepository
   * @param {String} domainName
   */
  async disableDns (domainName) {
    var response = await Repository.delete(
      Helpers.substituteApiVars(ApiTypes.DELETE_DNS_DOMAIN_NAME, { domainName: domainName }),
      null,
      null,
      `${moduleName}.disableDns()`
    )
    return response
  },

  /**
   * Get list of Mail DataTypes
   *
   * @returns {Array} Array of id and name for Mail DataTypes
   */
  async getMailTypes () {
    return await Repository.get(
      ApiTypes.GET_DOMAINS_MAIL_TYPES,
      DataTypes.mailType,
      `${moduleName}.getMailTypes()`
    )
  },

  /**
   * Get list of DNSsec statuses
   *
   * @returns {Array} Array of id and name for DNSsec statuses
   */
  async getDnsSecStatuses () {
    return await Repository.get(
      ApiTypes.GET_DOMAINS_DNS_SEC_STATUSES,
      DataTypes.dnsSecStatus,
      `${moduleName}.getDnsSecStatuses()`
    )
  },

  /**
   * Get list of Domain statuses
   *
   * @returns {Array} Array of id and name for Domain statuses
   */
  async getDomainStatuses () {
    return await Repository.get(
      ApiTypes.GET_DOMAINS_STATUSES,
      DataTypes.domainStatus,
      `${moduleName}.getDomainStatuses()`
    )
  },

  /**
   * Get list of Registrars
   *
   * @returns {Array} Array of id and name for Registrars
   */
  async getRegistrars () {
    return await Repository.get(
      ApiTypes.GET_DOMAINS_REGISTRARS,
      DataTypes.registrar,
      `${moduleName}.getRegistrars()`
    )
  },
  
  /**
   * Get list of imported domains
   * @alias module: DomainsRepository
   * @returns {Array} Array of objects
   */
  async getImportedDomainList () {
    return await Repository.get(
      `${ApiTypes.GET_IMPORTED_DOMAINS}`,
      DataTypes.importedDomainList,
      `${moduleName}.getImportedDomainList()`
    )
  },

  /**
   * Update an imported domain
   * 
   * @alias module: DomainsRepository
   * @param {Array} payload Object of name/value pairs for domain properties
   */
  async putImportedDomain (payload) {
    return await Repository.put(
      Helpers.substituteApiVars(ApiTypes.PUT_IMPORTED_DOMAINS_ID, { id: payload.id }), 
      payload,
      null,
      `${moduleName}.putImportedDomain()`
    )
  },

  /**
   * Post a new imported domain
   * 
   * @alias module: DomainsRepository
   * @param {Array} payload Object of name/value pairs for domain properties
   */
  async postImportedDomain (payload) {
    return await Repository.post(
      ApiTypes.POST_IMPORTED_DOMAINS, 
      payload,
      DataTypes.idAsNumber,
      `${moduleName}.postImportedDomain()`
    )
  },

  /**
   * Delete an imported domain
   * 
   * @alias module: DomainsRepository
   * @param {String} id Id of the imported domain to delete
   */
  async deleteImportedDomain (id) {
    return await Repository.delete(
      Helpers.substituteApiVars(ApiTypes.DELETE_IMPORTED_DOMAINS_ID, { id: id }),
      `${moduleName}.deleteImportedDomain()`
    )
  },

  /**
   * Get properties for a specified imported domain id
   *
   * @param {Number} id
   * @returns {Array} Array of objects
   */
  async getImportedDomain (id) {
    return await Repository.get(
      Helpers.substituteApiVars(ApiTypes.GET_IMPORTED_DOMAINS_ID, { id: id }),
      DataTypes.importedDomain,
      `${moduleName}.getImportedDomain()`
    )
  },

  /**
   * Get list of non accounting portal domains
   * @alias module: DomainsRepository
   * @returns {Array} Array of objects
   */
  async getNonAccountingPortalDomainList () {
    return await Repository.get(
      `${ApiTypes.GET_NON_ACCOUNTING_PORTAL_DOMAINS}`,
      DataTypes.nonAccountingPortalDomainList,
      `${moduleName}.getNonAccountingPortalDomainList()`
    )
  },

  /**
   * Get list of non portal accounting domains
   * @alias module: DomainsRepository
   * @returns {Array} Array of objects
   */
  async getNonPortalAccountingDomainList () {
    return await Repository.get(
      `${ApiTypes.GET_NON_PORTAL_ACCOUNTING_DOMAINS}`,
      DataTypes.nonPortalAccountingDomainList,
      `${moduleName}.getNonPortalAccountingDomainList()`
    )
  },

  /**
   * Get list of non portal registered domains
   * @alias module: DomainsRepository
   * @returns {Array} Array of objects
   */
  async getNonPortalRegisteredDomainList () {
    return await Repository.get(
      `${ApiTypes.GET_NON_PORTAL_REGISTERED_DOMAINS}`,
      DataTypes.nonPortalRegisteredDomainList,
      `${moduleName}.getNonPortalRegisteredDomainList()`
    )
  },

  /**
   * Get list of non registered portal domains
   * @alias module: DomainsRepository
   * @returns {Array} Array of objects
   */
  async getNonRegisteredPortalDomainList () {
    return await Repository.get(
      `${ApiTypes.GET_NON_REGISTERED_PORTAL_DOMAINS}`,
      DataTypes.nonRegisteredPortalDomainList,
      `${moduleName}.getNonRegisteredPortalDomainList()`
    )
  },

  /**
   * Get list of domains owned by a specified customerId
   * @alias module: DomainsRepository
   * @param {String} customerId
   * @returns {Array} Array of objects
   */
  async getCustomerDomainList (customerId) {
    return await Repository.get(
      Helpers.substituteApiVars(ApiTypes.GET_CUSTOMERS_ID_DOMAINS, { customerId: customerId }),
      DataTypes.customerDomainList,
      `${moduleName}.getCustomerDomainList()`
    )
  },

  /**
   * Get properties for a domain redirect
   *
   * @param {Number} id
   * @returns {Array} Array of objects
   */
  async getRedirect (domainName) {
    return await Repository.get(
      Helpers.substituteApiVars(ApiTypes.GET_DOMAINS_ID_REDIRECT, { domainName: domainName }),
      DataTypes.redirect,
      `${moduleName}.getRedirect()`
    )
  },

  /**
   * Update a redirect for a domain
   * 
   * @alias module: DomainsRepository
   * @param {String} domainName
   * @param {Array} payload Object of name/value pairs for domain properties
   */
  async putRedirect (domainName, payload) {
    return await Repository.put(
      Helpers.substituteApiVars(ApiTypes.PUT_DOMAINS_ID_REDIRECT, { domainName: domainName }), 
      payload,
      null,
      `${moduleName}.putRedirect()`
    )
  },

  /**
   * Post a new redirect for a domain
   * 
   * @alias module: DomainsRepository
   * @param {String} domainName
   * @param {Array} payload Object of name/value pairs for domain properties
   */
  async postRedirect (domainName, payload) {
    return await Repository.post(
      Helpers.substituteApiVars(ApiTypes.POST_DOMAINS_ID_REDIRECT, { domainName: domainName }), 
      payload,
      DataTypes.idAsNumber,
      `${moduleName}.postRedirect()`
    )
  },

  /**
   * Delete a redirect of a domain
   * 
   * @alias module: DomainsRepository
   * @param {String} id Id of the domain to delete the redirect for
   */
  async deleteRedirect (domainName) {
    return await Repository.delete(
      Helpers.substituteApiVars(ApiTypes.DELETE_DOMAINS_ID_REDIRECT, { domainName: domainName }),
      `${moduleName}.deleteRedirect()`
    )
  },
  
}
