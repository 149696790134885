import Logger from "@/lib/Logger.js";
import AppError from "@/lib/customExceptions/AppError.js";

const moduleName = "Helpers";

export default {
  /**
   * Assert the data from the API
   *
   * @param {Object} dataType
   * @param {Array} data
   */
  assertApiData(methodName, dataType, response) {
    //Logger.info(`${moduleName}.assertApiData(): calling method is ${methodName}`)
    //Logger.info(dataType)
    // If an error occurred, there is no data to check
    // If dataType is null, than the returned data can be anything (ie AuditEntry logs)
    if (response.error || dataType === null) {
      return;
    }
    if (!dataType) {
      throw Error(
        `${moduleName}.assertApiData(): dataType undefined, calling method is ${methodName}`
      );
    }
    try {
      if (response.data && Array.isArray(response.data)) {
        // If array, only check the first record
        // return if array is empty (no results from api)
        if (response.data.length === 0) {
          return;
        }
        dataType.assert(response.data[0]);
      } else {
        // If the object is empty, it is valid
        if (
          Object.keys(response.data).length === 0 &&
          response.data.constructor === Object
        ) {
          return;
        }
        dataType.assert(response.data);
      }
    } catch (e) {
      throw Error(`${methodName}: ${e}`, response);
    }
  },

  /**
   * Check if the given object has a meta.canDelete and return its value
   *
   * @param {Object} object
   * @returns Boolean
   */
  canDelete(object) {
    return object && object.meta ? object.meta.canDelete : true;
  },

  /**
   * Check if the given object has a meta.canEdit and return its value
   *
   * @param {Object} object
   * @returns Boolean
   */
  canEdit(object) {
    return object && object.meta ? object.meta.canEdit : true;
  },

  /**
   * Check if the given object has a meta.canChangePassword and return its value
   *
   * @param {Object} object
   * @returns Boolean
   */
  canChangePassword(object) {
    return object && object.meta ? object.meta.canChangePassword : true;
  },

  /**
   * Check if the given object has a meta.canImpersonate and return its value
   *
   * @param {Object} object
   * @returns Boolean
   */
  canImpersonate(object) {
    return object && object.meta ? object.meta.canImpersonate : true;
  },

  /**
   * Substitute parameters in a resource for real data
   * Resource: /domains/:domainName/dns-records/:id
   * Parameters: { domainName: 1, id: 2 }
   * Result: /domains/1/dns-records/2
   *
   * @param {*} resource
   * @param {*} parameters
   * @returns String
   */
  substituteApiVars(resource, parameters) {
    var regex = /:([a-z]*)/gi,
      result;
    var resultResource = resource;

    while ((result = regex.exec(resource))) {
      // Example result for resource /domains/:id and parameters { id: 1 }
      // result[0]: :id
      // result[1]: 1
      if (!result[0] || !result[1]) {
        throw `${moduleName}.substituteApiVars(): Unexpected empty result for found variable in resource ${resource}`;
      }
      if (!parameters[result[1]]) {
        throw `${moduleName}.substituteApiVars(): No parameter found for variable ${result[0]} in resource ${resource}, parameter value ${result[1]}`;
      }
      resultResource = resultResource.replace(result[0], parameters[result[1]]);
    }
    Logger.debug(resultResource);
    return resultResource;
  },

  /**
   * Handle API Error Messages
   *
   * @param {Object} error
   * @returns error
   */
  handleApiErrors(error) {
    if (!(error instanceof AppError) || error.type === "about:blank") {
      // This is not a normal application error, so rethrow the error
      throw error;
    }

    return error;
  },

  /**
   * Handle Routing Error Messages
   *
   * @param {Object} error
   * @returns error
   */
  handleRoutingErrors(error) {
    if (error.name !== "NavigationDuplicated") {
      // If someone tries to route to the same place, ignore it, otherwise re-throw error
      throw error;
    }
  },

  /**
   * Handle Remote Validation Errors
   *
   * @param {Object} error
   * @returns Array
   */
  assertRemoteValidationErrors(error) {
    if (
      !(error instanceof AppError) ||
      error.type !== "api-errors/validation-error"
    ) {
      // This is not a normal remote valudation error, so rethrow the error
      throw error;
    }

    return error;
  },
};
