import { Enum, Type, IfExists } from 'hello-type' // Check datatypes from API stream

export const idAsString = new Type({
  id: String
})

/** 
* Definition of DNS templates for the expected API properties which will be checked on API call
* @constant
*/
export const dnsTemplateList = new Type({
  id: String,
  name: String,
  customer_name: IfExists(String)
})

export const dnsTemplate = new Type({
  id: String,
  name: String,
  dateCreated: String,
  dateLastModified: String,
  isArchive: Boolean,
  customer: {
    id: String,
    name: String
  },
})

export const dnsTemplateRecordList = new Type({
  meta: {
    canDelete: Boolean,
    canEdit: Boolean
  },
  id: String,
  name: String,
  type: Enum('A', 'AAAA', 'CAA', 'CNAME', 'DS', 'MX', 'NS', 'PTR', 'SOA', 'TLSA', 'TXT'),
  content: String,
  ttl: Number,
  prio: Number
})

export const dnsTemplateRecord = new Type({
  id: String,
  name: String,
  dateCreated: String,
  dateLastModified: String,
  isArchive: Boolean,
  customer: {
    id: String,
    name: String
  },
  content: String,
  ttl: Number,
  prio: Enum(Number, null),
  dnsTemplate: {
    id: String,
    name: String
  },
  type: Enum('A', 'AAAA', 'CAA', 'CNAME', 'MX', 'PTR', 'TLSA', 'TXT', 'DS', 'NS', 'SOA'),
})
