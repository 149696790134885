import Repository from '../Repository'
//import Logger from '@/lib/Logger.js'
import Helpers from '@/lib/Helpers.js'
import * as DataTypes from './AntiSpamRepositoryDataTypes'
import * as ApiTypes from '@/api/api-types'

const moduleName = 'AntiSpamRepository'

/**
 * A module for handling API calls for anti-spam
 * @module AntiSpamRepository
 */
export default {
  /**
   * Get Anti-Spam settings
   * @alias module: AntiSpamRepository
   * @param {String} domainName
   * @returns {Object} Anti-spam settings
   */
  async getAntiSpamSettings (domainName) {
    return await Repository.get(
      Helpers.substituteApiVars(ApiTypes.GET_DOMAINS_ID_ANTI_SPAM_SETTINGS, { domainName: domainName }),
      DataTypes.antiSpamSettings,
      `${moduleName}.getAntiSpamSettings()`
    )
  },

  /**
   * Enable Antispam for domain
   * 
   * @alias module: AntiSpamRepository
   * @param {Array} payload
   * @returns {Object} Anti-spam settings
   */
  async enableAntiSpamDomain (domainName, payload) {
    return await Repository.post(
      Helpers.substituteApiVars(ApiTypes.POST_DOMAINS_ID_ANTI_SPAM_DOMAIN, { domainName: domainName }),
      payload,
      DataTypes.idAsString,
      `${moduleName}.enableAntiSpamDomain()`
    )
  },

  /**
   * Update Antispam destination
   * 
   * @alias module: AntiSpamRepository
   * @param {Array} payload
   * @returns {Object} Anti-spam settings
   */
  async updateAntiSpamDestination (domainName, payload) {
    return await Repository.put(
      Helpers.substituteApiVars(ApiTypes.PUT_DOMAINS_ID_ANTI_SPAM_DOMAIN, { domainName: domainName, id: payload.id }),
      payload,
      null,
      `${moduleName}.updateAntiSpamDestination()`
    )
  },

  /**
   * Delete Anti Spam
   * 
   * @alias module: AntiSpamRepository
   * @param {String} domainName
   */
  async deleteAntiSpam (domainName) {
    return await Repository.delete(
      Helpers.substituteApiVars(ApiTypes.DELETE_DOMAINS_ID_ANTI_SPAM_DOMAIN, { domainName: domainName }),
      `${moduleName}.deleteAntiSpam()`
    )
  },

  /**
   * Enable Antispam alias
   * 
   * @alias module: AntiSpamRepository
   * @param {Array} payload
   * @returns {Object} Anti-spam settings
   */
  async enableAntiSpamAlias (domainName, payload) {
    return await Repository.post(
      Helpers.substituteApiVars(ApiTypes.POST_DOMAINS_ID_ANTI_SPAM_ALIAS, { domainName: domainName }),
      payload,
      DataTypes.idAsString,
      `${moduleName}.enableAntiSpamAlias()`
    )
  },

  /**
   * Delete Anti Spam Alias
   * 
   * @alias module: AntiSpamRepository
   * @param {String} domainName
   */
  async deleteAntiSpamAlias (domainName) {
    return await Repository.delete(
      Helpers.substituteApiVars(ApiTypes.DELETE_DOMAINS_ID_ANTI_SPAM_ALIAS, { domainName: domainName }),
      `${moduleName}.deleteAntiSpamAlias()`
    )
  },

  /**
   * Get list of potential antispam primary domains for alias
   * @alias module: AntiSpamRepository
   * @param {String} domainName
   * @returns {Array} List of domains
   */
  async getAliasTargetDomainList (domainName) {
    return await Repository.get(
      Helpers.substituteApiVars(ApiTypes.GET_DOMAINS_ID_ANTI_SPAM_ALIAS_TARGETS, { domainName: domainName }),
      DataTypes.antiSpamAliasTargets,
      `${moduleName}.getAntiSpamAliasTargets()`
    )
  },

}
