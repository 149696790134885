<template>
  <v-app>
    <the-header v-if="stateIsAuthenticated && stateAllowedEndpointListLoaded"></the-header>
      <v-content>
      <!--
          Application-wide overlay for showing a "Loading-overlay"
          This will show-up on every axios call
      //-->
      <loading :active.sync="stateGetShowLoading" 
        :can-cancel="false"
        loader="dots"
        :color="this.$vuetify.theme.secondary"
        :height="Number(100)"
        :width="Number(100)"
        :opacity="Number(0.6)"
        is-full-page>
      </loading>

      <!-- 
          Application-wide dialog for warning and
          error messages
      //-->
      <base-application-log-dialog>
      </base-application-log-dialog>

      <!--
          Application-wide dialog for user messages which result from
          user errors (ie input validation) returned by the API
      //-->
      <base-message-dialog ref="userMessageDialog">
      </base-message-dialog>

      <template v-if="!stateIsAuthenticated">
          <router-view :key="getRouterViewKey"/>
      </template>
      <template v-else-if="stateAllowedEndpointListLoaded">
        <v-layout>
          <v-flex>
            <v-card 
              flat
              class="ma-4"
            >
              <v-breadcrumbs 
                :items="stateGetBreadCrumbs" divider=">">
              </v-breadcrumbs>
            </v-card>
            <!-- 
              add route name and params to the router-view key
              to make sure the route will also work if only the id in 
              the URL changes
            //-->
            <router-view :key="getRouterViewKey"/>
          </v-flex>
        </v-layout>
      </template>
    </v-content>
    <the-nav-bar v-if="stateIsAuthenticated"></the-nav-bar>
  </v-app>
</template>

<script>
import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'
import TheHeader from '@/components/Default/TheHeader.vue'
import TheNavBar from '@/components/Default/TheNavBar.vue'

import { mapActions, mapGetters } from 'vuex'

export default {

  components: {
    Loading,
    TheHeader,
    TheNavBar,
  },

  data () {
    return {
      apiErrorMessages: null,
    }
  },

  computed: {
    ...mapGetters({
      'stateGetBreadCrumbs': 'getBreadCrumbs',
      'stateGetCurrentBreadCrumb': 'menu/getCurrentBreadCrumb',
      'stateGetShowLoading': `loadingOverlay/getShowLoading`,
      'stateIsAuthenticated': 'security/isAuthenticated',
      'stateAllowedEndpointListLoaded': 'security/allowedEndpointListLoaded',
    }),

    showNavigation () {
      return this.stateIsAuthenticated;
    },

    // Generate a unique router key to make sure that a route with a new variable will always reload
    getRouterViewKey() {
      return this.$route.name + (Object.keys(this.$route.params).map(param => this.$route.params[param]).join('.'))
    }
  },

  methods: {
    ...mapActions({
      'stateResetPasswordRequest': 'security/resetPasswordRequest',
      'stateSetToken': 'security/setToken',
      'stateSetImpersonated': 'security/setImpersonated',
    }),

  },

  mounted () {
    if (sessionStorage.getItem('token')) {
      if (sessionStorage.getItem('isImpersonated') && sessionStorage.getItem('isImpersonated') == true) {
        this.stateSetImpersonated(sessionStorage.getItem('token'))
      }
      else {
        this.stateSetToken(sessionStorage.getItem('token'))
      }
    }
  },


  name: 'App'
}
</script>

<style>
.v-text-field--solo-inverted.v-input--is-focused > .v-input__control>.v-input__slot input {
  caret-color: auto;
}

.v-input__slot input {
  caret-color: auto;
}
</style>
