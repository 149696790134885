<template>
    <v-card class="pa-4">
      <v-layout row wrap justify-start align-baseline>
        <v-flex xs12 sm7 md4 class="text-xs-left">
        <!--
          Button for adding a new item
          On Click an event will be emitted to the parent
        //-->
        <v-btn
          v-if="enableAddButton"
          color="newButton" 
          class="white--text"
          @click.stop="$emit('add-clicked')"
        >
          <v-icon>add_circle_outline</v-icon>
          {{ $t("BUTTON.NEW") }}
        </v-btn>

        <!--
          Export Button for exporting the displayed table
          content to a file
        //-->
        <v-btn 
          v-if="exportFilenamePrefix"
          color="exportButton"
          class="white--text"
          @click="exportData()"
        >
          <v-icon>mdi-file-excel</v-icon>
          {{ $t("BUTTON.TO_EXCEL") }}
        </v-btn>
        </v-flex>

        <!--
          Search field for searching through all displayed data
          based on regular expressions with a input-debounce
          This debounce makes sure the search will only be performed
          if the user has stopped typing for a few hundred milliseconds
        //-->
        <v-flex xs12 sm3 md3 class="px-3">
          <v-text-field
            v-model="searchInput"
            append-icon="search"
            :label="$t('BASE.SEARCH')"
            single-line
            hide-details
            flat
            @keyup.native="debounceInput"
          ></v-text-field>
        </v-flex>

        <!--
          Filter for searching data of a specific list
          This search also supports regular expressions
        //-->
        <v-flex xs12 sm3 md3 class="px-3">
        <v-autocomplete 
          v-if="dropdownList"
          v-model="computedDropdownSelectedItem"
          :items="dropdownList"
          item-text="name"
          item-value="id"
          :label="dropdownLabel"
          hide-no-data
          dense
          flat
          :filter="autocompleteSearch"
        ></v-autocomplete>
        </v-flex>

        <!--
          A switch to select "All" data or only data
          which is not archived.
        //-->
        <v-flex class="text-xs-right" xs6 md2>
          <v-switch 
            v-if="archiveSwitchField"
            :label="archiveSwitchLabel"
            v-model="switchShowAll">
          </v-switch>
        </v-flex>
      </v-layout>
      <v-layout row wrap justify-space-between align-baseline>
        <v-flex xs12 class="py-3">
          <!-- Just some space //-->
        </v-flex>
      </v-layout>
      <v-layout row wrap justify-space-between align-baseline>
        <!--
          The data-table containing the list of results
        //-->
        <v-flex xs12>
          <v-data-table
            :headers="headers"
            :items="items"
            :pagination.sync="paginationSync"
            :search="search"
            :custom-filter="dataTableSearch"
            :rows-per-page-items="[10,25,100,250]"
            must-sort
            ref="refName"
          >

            <!-- Pass on all named slots -->
            <slot v-for="slot in Object.keys($slots)" :name="slot" :slot="slot">
            </slot>
            <!-- Pass on all scoped slots -->
            <template v-for="slot in Object.keys($scopedSlots)" :slot="slot" slot-scope="scope"><slot :name="slot" v-bind="scope"/>
            </template>

          </v-data-table>              
        </v-flex>
    </v-layout>
  </v-card>
</template>

<script>
import exportToCsvMixin from '@/mixins/ExportCSV.js';
import _ from 'lodash'
import { mapGetters, mapActions } from 'vuex'

export default {
  // Props
  props: {
    dropdownList: {
      type: Array,
      default: null
    },
    dropdownLabel: {
      type: String,
      default: ''
    },
    dropdownSelectedItem: null,
    archiveSwitchField: {
      type: String,
      default: null
    },
    archiveSwitchLabel: {
      type: String,
      default: ''
    },
    enableAddButton: {
      type: Boolean,
      default: false
    },
    headers: {
      type: Array,
      required: true
    },
    items: {
      type: Array,
      required: true
    },
    exportFilenamePrefix: {
      type: String
    },
    sortBy: {
      type: String,
      required: true
    },
    descending: {
      type: Boolean,
      default: false,
    }
  },

  // Mixins
  mixins: [ exportToCsvMixin ],

  // data
  data: function () {
    return {
      searchInput: '', // Used with debounce to fill search variable
      search: '',
      switchShowAll: false,

      paginationSync: {
        descending: this.descending,
        rowsPerPage: 10, // -1 for All
        sortBy: this.sortBy
      }
    }
  },

  // Computed properties
  computed: {
    ...mapGetters( {
      'stateGetSelectedDropDownItem': 'getSelectedDropDownItem',
    }),

    // itemsMeetSwitchAll()
    // used by: filteredCompaniesItems()
    // return an array of domains based on the Active/All switch
    itemsMeetSwitchAll: function () {
      var result = this.items
      if (!this.switchShowAll && this.archiveSwitchField) {
        // Get all Active items (items where isArchive === 0)
        result = result.filter(row => row[this.archiveSwitchField] === false)
      }
      return result
    },

    computedDropdownSelectedItem: {
      get () {
        return this.stateGetSelectedDropDownItem ? this.stateGetSelectedDropDownItem : this.dropdownSelectedItem
      },

      set (value) {
        this.stateSetSelectedDropDownItem(value)
        this.$emit('dropdown-change', value)
      }
    }
  },

  // Methods
  methods: {
    ...mapActions({
      'stateSetSelectedDropDownItem': `setSelectedDropDownItem`,
    }),
    
    // autocompleteSearch()
    // used by: v-auto-complete (Filter company or sub-user)
    // Special search filter for v-auto-complete to support
    // searches by regular expressions
    autocompleteSearch(item, queryText, itemText) {
      try {
        // If RegExp is valid than do a RegExp search
        let regexp = RegExp(queryText, 'gi')
        return regexp.test(itemText)
      }
      catch(e) {
        // If RegExp is invalid than do a normal search
        return itemText.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) > -1
      }
    },

    addButtonClicked () {
      this.$emit('add-clicked')
    },

    // debounceInput()
    // used by: v-text-field (Search)
    // Create a delay for keyboard input to prevent filtering instantly
    // by every keyboard key
    debounceInput: _.debounce(function () {
        this.search = this.searchInput
    }, 200),

    // exportData()
    // used by: v-btn (exportToExcel)
    // A local export of current domain list, where all search/selection criteria
    // are respected
    exportData () {
      let tmpRowsPerPage = this.paginationSync.rowsPerPage
      let date = new Date()

      this.paginationSync.rowsPerPage = -1
      this.exportToCsv(
        this.$refs.refName.filteredItems, 
        { 
          filename: this.exportFilenamePrefix + '_' + date.getFullYear() + ('0' + (date.getMonth()+1)).slice(-2) + ('0' + date.getDate()).slice(-2),
          useKeysAsHeaders: true,
          showLabels: true
        })
      this.paginationSync.rowsPerPage = tmpRowsPerPage
    },

    // dataTableSearch()
    // used by: v-data-table (domain list)
    // Special search filter to support searches by regular expressions
    dataTableSearch(items, search, filter, headers) {
      if (search.trim() === '') return items

      try {
        // If RegExp is valid than do a RegExp search
        var regexp = RegExp(search, 'gi')
        const props = headers.map(h => h.value)
        
        
        // Sometimes there are additional columns which are not part of
        // the actual data. Their value is '', so only check headers (prop) 
        // which have a value. Otherwise the result will be "undefined",
        // which will return a positive result when searching for characters
        // in the word "undefined", ie searchterm "def"
        var result = items.filter(item => props.some(prop => prop ? regexp.test(item[prop]) : false))
        return result
      }
      catch(e) {
        // If RegExp is invalid than do a normal search
        const props = headers.map(h => h.value)
        return items.filter(item => props.some(prop => filter(item[prop], search)))
      }
    },

  }
}
</script>