var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-layout",
    { attrs: { row: "", "justify-center": "" } },
    [
      _c(
        "v-dialog",
        {
          attrs: { persistent: "", "max-width": "500px" },
          model: {
            value: _vm.show,
            callback: function($$v) {
              _vm.show = $$v
            },
            expression: "show"
          }
        },
        [
          _c(
            "v-card",
            [
              _c("v-card-title", { staticClass: "headline" }, [
                _c("div", { domProps: { innerHTML: _vm._s(_vm.title) } })
              ]),
              _c(
                "v-card-text",
                [
                  _vm.htmlSafe
                    ? [_vm._v(" " + _vm._s(_vm.message) + " ")]
                    : [
                        _c("div", {
                          domProps: { innerHTML: _vm._s(_vm.message) }
                        })
                      ]
                ],
                2
              ),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "green darken-1", flat: "" },
                      nativeOn: {
                        click: function($event) {
                          return _vm.agree()
                        }
                      }
                    },
                    [_vm._v(_vm._s(_vm.$t("BUTTON.OK")))]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }