var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("v-container", { attrs: { fluid: "" } }, [
    _c("div", { domProps: { innerHTML: _vm._s(_vm.nodeDump) } })
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }