import Repository from '../Repository'
import Logger from '@/lib/Logger.js'
import Helpers from '@/lib/Helpers.js'
import * as DataTypes from './LicensesRepositoryDataTypes'
import * as ApiTypes from '@/api/api-types'
import { store } from '@/store/store'

const moduleName = 'LicensesRepository'

/**
 * A module for handling API calls for Licenses
 * @module LicensesRepository
 */
export default {

  /**
   * List license-assignment for equipment
   *
   * @alias module: LicensesRepository
   * @param {String} equipmentId
   * @returns {Array} Array of objects
   */
  async getLicenseAssignmentList (equipmentId) {
    Logger.info(`${moduleName}.getLicenseAssignmentList(): called`)
    return await Repository.get(
      Helpers.substituteApiVars(ApiTypes.GET_EQUIPMENT_ID_LICENSE_ASSIGNMENTS, { equipmentId: equipmentId }),
      DataTypes.licenseAssignmentList,
      `${moduleName}.getLicenseAssignmentList()`
    )
  },

  /**
   * Create license-assignment for machine
   * 
   * @alias module: LicensesRepository
   * @param {String} equipmentId
   * @param {Object} payload
   * @returns {Object}
   */
  async postLicenseAssignment (equipmentId, payload) {
    return await Repository.post(
      Helpers.substituteApiVars(ApiTypes.POST_EQUIPMENT_ID_LICENSE_ASSIGNMENTS, { equipmentId: equipmentId }),
      payload,
      DataTypes.idAsNumber,
      `${moduleName}.postLicenseAssignment()`
    )
  },

  /**
   * Show license-assignment
   *
   * @alias module: LicensesRepository
   * @param {String} equipmentId
   * @param {Number} licenseAssignmentId
   * @returns {Object}
   */
  async getLicenseAssignment (equipmentId, licenseAssignmentId) {
    Logger.info(`${moduleName}.getLicenseAssignment(equipmentId, licenseAssignmentId): called with equipmentId ${equipmentId} and licenseAssignmentId ${licenseAssignmentId}`)
    return await Repository.get(
      Helpers.substituteApiVars(ApiTypes.GET_EQUIPMENT_ID_LICENSE_ASSIGNMENTS_ID, { equipmentId: equipmentId, id: licenseAssignmentId }),
      DataTypes.licenseAssignment,
      `${moduleName}.getLicenseAssignment()`
    )
  },

  /**
   * Update license-assignment
   * 
   * @alias module: LicensesRepository
   * @param {equipmentId} String
   * @param {Object} payload
   * @returns {Object}
   */
  async putLicenseAssignment (equipmentId, payload) {
    return await Repository.put(
      Helpers.substituteApiVars(ApiTypes.PUT_EQUIPMENT_ID_LICENSE_ASSIGNMENTS_ID, { equipmentId: equipmentId, id: payload.id }),
      payload,
      null,
      `${moduleName}.putLicenseAssignment()`
    )
  },

  /**
   * Delete License assignment
   * 
   * @alias module: LicensesRepository
   * @param {String} equipmentId
   * @param {Number} licenseAssignmentId
   */
  async deleteLicenseAssignment (equipmentId, licenseAssignmentId) {
    return await Repository.delete(
      Helpers.substituteApiVars(ApiTypes.DELETE_EQUIPMENT_ID_LICENSE_ASSIGNMENTS_ID, { equipmentId: equipmentId, id: licenseAssignmentId }),
      `${moduleName}.deleteLicenseAssignment()`
    )
  },

  /**
   * List Licenses
   *
   * @returns {Array}
   */
  async getLicenseList () {
    Logger.info(`${moduleName}.getLicenseList(): called`)
    return await Repository.get(
      `${ApiTypes.GET_LICENSES}?listType=all`,
      DataTypes.licenseList,
      `${moduleName}.getLicenseList()`
    )
  },

  /**
   * Create license
   * 
   * @alias module: LicensesRepository
   * @param {Object} payload
   * @returns {Object}
   */
  async postLicense (payload) {
    return await Repository.post(
      ApiTypes.POST_LICENSES,
      payload,
      DataTypes.idAsNumber,
      `${moduleName}.postLicense()`
    )
  },

  /**
   * Show license
   *
   * @alias module: LicensesRepository
   * @param {Number} licenseId
   * @returns {Object}
   */
  async getLicense (licenseId) {
    Logger.info(`${moduleName}.getLicense(licenseId): called with licenseId ${licenseId}`)
    return await Repository.get(
      Helpers.substituteApiVars(ApiTypes.GET_LICENSES_ID, { id: licenseId }),
      DataTypes.license,
      `${moduleName}.getLicense()`
    )
  },

  /**
   * Update License
   * 
   * @alias module: LicensesRepository
   * @param {Object} payload
   * @returns {Object}
   */
  async putLicense (payload) {
    return await Repository.put(
      Helpers.substituteApiVars(ApiTypes.PUT_LICENSES_ID, { id: payload.id }),
      payload,
      null,
      `${moduleName}.putLicense()`
    )
  },

  /**
   * Delete License
   * 
   * @alias module: LicensesRepository
   * @param {Number} licenseId
   */
  async deleteLicense (licenseId) {
    return await Repository.delete(
      Helpers.substituteApiVars(ApiTypes.DELETE_LICENSES_ID, { id: licenseId }),
      `${moduleName}.deleteLicense()`
    )
  },

  /**
   * List licenses for category
   *
   * @param {Number} licenseCategoryId
   * @returns {Array}
   */
  async getCategoryLicenseList (licenseCategoryId) {
    Logger.info(`${moduleName}.getCategoryLicenseList(): called`)
    return await Repository.get(
      Helpers.substituteApiVars(ApiTypes.GET_LICENSE_CATEGORIES_ID_LICENSES, { licenseCategoryId: licenseCategoryId }),
      DataTypes.categoryLicenseList,
      `${moduleName}.getCategoryLicenseList()`
    )
  },

  /**
   * List License categories
   *
   * @returns {Array}
   */
  async getCategoryList () {
    Logger.info(`${moduleName}.getCategoryList(): called`)
    return await Repository.get(
      `${ApiTypes.GET_LICENSE_CATEGORIES}?listType=all`,
      DataTypes.licenseCategoryList,
      `${moduleName}.getCategoryList()`
    )
  },

  /**
   * Create license category
   * 
   * @alias module: LicensesRepository
   * @param {Object} payload
   * @returns {Object}
   */
  async postCategory (payload) {
    let result = await Repository.post(
      ApiTypes.POST_LICENSE_CATEGORIES,
      payload,
      DataTypes.idAsNumber,
      `${moduleName}.postCategory()`
    )
    store.dispatch('licenses/loadCategoryList', { force: true })
    return result
  },

  /**
   * Show license category
   *
   * @alias module: LicensesRepository
   * @param {Number} licenseCategoryId
   * @returns {Object}
   */
  async getCategory (licenseCategoryId) {
    Logger.info(`${moduleName}.getLicenseCategory(licenseCategoryId): called with licenseCategoryId ${licenseCategoryId}`)
    return await Repository.get(
      Helpers.substituteApiVars(ApiTypes.GET_LICENSE_CATEGORIES_ID, { id: licenseCategoryId }),
      DataTypes.licenseCategory,
      `${moduleName}.getCategory()`
    )
  },

  /**
   * Update LicenseCategory
   * 
   * @alias module: LicensesRepository
   * @param {Object} payload
   * @returns {Object}
   */
  async putCategory (payload) {
    let result = await Repository.put(
      Helpers.substituteApiVars(ApiTypes.PUT_LICENSE_CATEGORIES_ID, { id: payload.id }),
      payload,
      null,
      `${moduleName}.putCategory()`
    )
    store.dispatch('licenses/loadCategoryList', { force: true })
    return result
  },

  /**
   * Delete LicenseCategory
   * 
   * @alias module: LicensesRepository
   * @param {Number} licenseCategoryId
   */
  async deleteCategory (licenseCategoryId) {
    let result = await Repository.delete(
      Helpers.substituteApiVars(ApiTypes.DELETE_LICENSE_CATEGORIES, { id: licenseCategoryId }),
      `${moduleName}.deleteCategory()`
    )
    store.dispatch('licenses/loadCategoryList', { force: true })
    return result
  },

  /**
   * List License reports spla
   *
   * @returns {Array}
   */
  async getLicenseReportsSplaList (year, month) {
    Logger.info(`${moduleName}.getLicenseReportsSplaList(): called`)
    return await Repository.get(
      `${ApiTypes.GET_LICENSE_REPORTS_SPLA}?year=${year}&month=${month}`,
      DataTypes.licenseReportsSplaList,
      `${moduleName}.getLicenseReportsSplaList()`
    )
  },

  /**
   * List Licenses per Machine
   * @param {Number}  year
   * @param {Number}  month
   * @returns {Array}
   */
  async getLicenseReportsPerMachineList (year, month) {
    Logger.info(`${moduleName}.getLicenseReportsPerMachineList(): called`)
    return await Repository.get(
      `${ApiTypes.GET_LICENSE_REPORTS_PER_MACHINE}?year=${year}&month=${month}`,
      DataTypes.licenseReportsPerMachineList,
      `${moduleName}.getLicenseReportsPerMachineList()`
    )
  },
}