<template>
  <v-content>
    <!-- Confirm dialog for record deletion //-->
    <base-confirm-dialog
      ref="confirmAppUpdate"
    >
    </base-confirm-dialog>

    <!--
      mobile-break-point="0" is nodig om te zorgen dat de sideNav verborgen blijft bij resizen van window.
    //-->
    <v-navigation-drawer
      dark
      app
      mobile-break-point="0"
      :mini-variant="!stateGetMenuExpanded"
    >
      <v-layout align-left justify-space-between column fill-height>
        <v-list dark>
          <v-list-tile 
            v-if="stateGetApiVersionChanged"
            @click="appUpdate()"
          >
            <v-list-tile-action>
              <v-icon color="warning" class="pr-1">mdi-alert</v-icon>
            </v-list-tile-action>
            <v-list-tile-content>
              <v-btn color="warning" class="ma-0">{{ $t('BUTTON.RELOAD_APP') }}</v-btn>
            </v-list-tile-content>
          </v-list-tile>
          
          <v-list-tile
            @click="stateSwitchMenuExpanded" 
            active-class="white--text font-weight-bold selectedMenuItem"
          >
            <v-list-tile-action>
              <v-icon class="pr-1">{{ stateGetMenuExpanded ? 'mdi-arrow-left-bold' : 'mdi-arrow-right-bold' }}</v-icon>
            </v-list-tile-action>
            <v-list-tile-content>
              Collapse
            </v-list-tile-content>
          </v-list-tile>

          <v-list-tile
            v-for="(menuItem, index) in stateGetMainMenuItems"
            :key="index"
            router
            :to="menuItem.routeTo"
            active-class="white--text font-weight-bold selectedMenuItem"
          >
            <v-list-tile-action>
              <v-icon class="pr-1">{{ menuItem.icon }}</v-icon>
            </v-list-tile-action>
            <v-list-tile-content>
              {{ $t(`MENU.MAIN.${menuItem.label}`) }}
            </v-list-tile-content>
          </v-list-tile>
        </v-list>

        <v-list dark
          v-if="!managementMenuEmpty()"
        >
          <v-list-group 
            active-class="white--text font-weight-bold"
            no-action
            prepend-icon="settings"
            @click="collapseAll()"
          >
            <v-list-tile 
              active-class="white--text font-weight-bold"
              slot="activator"
            >
              <v-list-tile-content>
                {{ $t(`MENU.MANAGEMENT`) }}
              </v-list-tile-content>
            </v-list-tile>
            <v-list-group
              sub-group
              v-for="(menuItem, index) in stateGetMenuItems"
              active-class="white--text font-weight-bold"
              :key="menuItem.label"
              v-model="listGroupValue[index]"
              no-action
              @click="collapseOthers(index)"
            >
              <v-list-tile 
                active-class="white--text font-weight-bold selectedMenuItem"
                slot="activator"
              >
                <v-list-tile-content>
                  {{ menuItem.label }}
                </v-list-tile-content>
              </v-list-tile>
              <base-menu-dividers 
                :label="menuItem.label" 
                :url="menuItem.routeTo" 
                :nodes="menuItem.nodes"
                :firstTime="true"
                :sideNav="true">
              </base-menu-dividers>
            </v-list-group>
          </v-list-group>
        </v-list>

      </v-layout>
    </v-navigation-drawer>
  </v-content>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import BaseMenuDividers from '@/components/Base/BaseMenuDividers'
import * as RoutingTypes from '@/router/routing-types.js'

export default {
  components: {
    BaseMenuDividers
  },

  data () {
    return {
      listGroupValue: [],
      routingTypes: RoutingTypes
    }
  },

  // Computed
  computed: {
    ...mapGetters({
      'stateGetMenuItems': `menu/getMenu`,
      'stateGetMainMenuItems': `menu/getMainMenu`,
      'stateGetShowSideNav': `getShowSideNavigation`,
      'stateGetMenuExpanded': `getMenuExpanded`,
      'stateGetApiVersionChanged': 'getApiVersionChanged'
    }),

    showSideNav: {
      // We need to create a getter and setter for showSideNav
      // because v-navigation-drawer needs a v-model
      // Using :value="showSideNav" @click="stateSwitchSideNav" will
      // not work if user clicked outside the Navigationbar to dismiss the menu
      get () {
        return this.stateGetShowSideNav
      },
      set (value) {
        this.stateSetShowSideNav(value)
      }
    },
  },

  watch: {
    stateGetApiVersionChanged (val) {
      if (val) {
        this.appUpdate()
      }
    }
  },

  // Methods
  methods: {
    ...mapActions({
      'stateSwitchSideNav': `switchSideNavigation`,
      'stateSwitchMenuExpanded': `switchMenuExpanded`,
      'stateSetShowSideNav': `setShowSideNavigation`,
      'stateLoadMenuItems': `menu/loadMenu`,
      'stateLoadAllowedEndpointList': 'security/loadAllowedEndpointList',
    }),

    async appUpdate() {
      if (await this.$refs.confirmAppUpdate.open(
        this.$t('BASE.NEW_VERSION.TITLE'),
        this.$t('BASE.NEW_VERSION.MESSAGE')
      )) {
        // OK clicked, so update app
        window.location.reload()
      }
    },

    collapseAll() {
      for (let i=0; i < this.listGroupValue.length; i++) {
        this.$set(this.listGroupValue, i, false)
      }
      if (!this.stateGetMenuExpanded) {
        this.stateSwitchMenuExpanded()
      }
    },

    collapseOthers(index) {
      for (let i=0; i < this.listGroupValue.length; i++) {
        if (i !== index) {
          this.$set(this.listGroupValue, i, false)
        }
      }
    },

    managementMenuEmpty: function() {
      return this.stateGetMenuItems.length == 0
    }
  },

  async mounted() {
    await this.stateLoadAllowedEndpointList()
    await this.stateLoadMenuItems()
  }

}
</script>
