import Vue from 'vue'
import Vuex from 'vuex'
import router from '@/router'

import applicationLog from '@/store/modules/application-log'
import customers from '@/store/modules/customers'
import domains from '@/store/modules/domains'
import equipment from '@/store/modules/equipment'
import network from '@/store/modules/network'
import loadingOverlay from '@/store/modules/loading-overlay'
import menu from '@/store/modules/menu'
import security from '@/store/modules/security'
import userMessages from '@/store/modules/user-messages'
import users from '@/store/modules/users'
import licenses from '@/store/modules/licenses'
import documents from '@/store/modules/documents'

Vue.use(Vuex)

export const store = new Vuex.Store({
  strict: process.env.NODE_ENV === 'development',

  modules: {
    applicationLog,
    customers,
    domains,
    equipment,
    network,
    loadingOverlay,
    menu,
    security,
    userMessages,
    users,
    licenses,
    documents
  },

  // State
  state: {
    showSideNavigation: false,
    menuExpanded: true,
    breadCrumb: [],
    breadCrumbDetail: null,
    cachedMultiSearchItems: [],
    cachedArchiveSwitch: false,
    cachedSearchField: {},
    cachedSelectedDropDownItem: null,
    apiVersionChanged: false,
    apiVersion: null
  },

  // Getters
  getters: {
    getApiVersion: state => {
      return state.apiVersion
    },

    getApiVersionChanged: state => {
      return state.apiVersionChanged
    },

    getShowSideNavigation: state => {
      return state.showSideNavigation
    },
    
    getMenuExpanded: state => {
      return state.menuExpanded
    },

    getBreadCrumbs: (state, getters) => {
      let returnValue = JSON.parse(JSON.stringify(getters['menu/getCurrentBreadCrumb']))
      if (state.breadCrumbDetail) {
        returnValue.push(state.breadCrumbDetail)
      }
      return returnValue
    },

    getMultiSearchItems: state => {
      return state.cachedMultiSearchItems
    },

    getArchiveSwitch: state => {
      return state.cachedArchiveSwitch
    },

    getSearchField: state => {
      return state.cachedSearchField
    },

    getSelectedDropDownItem: state => {
      return state.cachedSelectedDropDownItem
    }
  },

  // Mutations
  mutations: {
    setMultiSearchItems: (state, payload) => {
      state.cachedMultiSearchItems = payload
    },

    setArchiveSwitch: (state, payload) => {
      state.cachedArchiveSwitch = payload
    },

    setSearchField: (state, payload) => {
      if (payload === null) {
        state.cachedSearchField = {}
      }
      else {
        let tmpSearchField = JSON.parse(JSON.stringify(state.cachedSearchField))
        tmpSearchField[router.currentRoute.name] = payload
        state.cachedSearchField = tmpSearchField
      }
    },

    setSelectedDropDownItem: (state, payload) => {
      state.cachedSelectedDropDownItem = payload
    },

    switchSideNavigation: state => {
      state.showSideNavigation = !state.showSideNavigation
    },

    switchMenuExpanded: state => {
      state.menuExpanded = !state.menuExpanded
    },

    setShowSideNavigation: (state, payload) => {
      state.showSideNavigation = payload
    },

    setBreadCrumb: (state, payload) => {
      state.breadCrumb = payload
    },

    setBreadCrumbDetail: (state, payload) => {
      if (payload) {
        state.breadCrumbDetail = {
          text: payload,
          disabled: true
        }
      }
      else {
        state.breadCrumbDetail = null
      }
    },

    setApiVersionChanged: (state, payload) => {
      state.apiVersionChanged = payload == true
    },

    setApiVersion: (state, payload) => {
      state.apiVersion = payload
    },

    clearCache: () => {
    }
  },

  // Actions
  actions: {
    checkVersionChange: (context, payload) => {
      if (payload.headers && payload.headers['x-api-version']) {
        if (context.getters['getApiVersion'] !== payload.headers['x-api-version']) {
          if (context.getters['getApiVersion'] !== null) {
            context.commit('setApiVersionChanged', true)
          }
          context.commit('setApiVersion', payload.headers['x-api-version'])
        }
      }
    },

    setMultiSearchItems: ({ commit }, payload) => {
      commit('setMultiSearchItems', payload)
    },

    setArchiveSwitch: ({ commit }, payload) => {
      commit('setArchiveSwitch', payload)
    },

    setSearchField: ({ commit }, payload) => {
      commit('setSearchField', payload)
    },

    setSelectedDropDownItem: ({ commit }, payload) => {
      commit('setSelectedDropDownItem', payload)
    },

    switchSideNavigation: ({ commit }) => {
      commit('switchSideNavigation')
    },

    switchMenuExpanded: ({ commit }) => {
      commit('switchMenuExpanded')
    },

    setShowSideNavigation: ({ commit }, payload) => {
      commit('setShowSideNavigation', payload)
    },

    setBreadCrumb: ({ commit }, value) => {
      commit('setBreadCrumb', value)
    },

    setBreadCrumbDetail: ({ commit }, value) => {
      commit('setBreadCrumbDetail', value)
    },

    async clearCache ({ commit, dispatch }) {
      commit('clearCache')
      dispatch('customers/clearCache')
      dispatch('domains/clearCache')
      dispatch('equipment/clearCache')
      dispatch('licenses/clearCache')
      dispatch('network/clearCache')
      dispatch('users/clearCache')
      dispatch('documents/clearCache')
      // Security/clearCache moet expliciet worden aangeroepen bij wisselen van gebruiker
      // of wachtwoord wijzigen en niet bij alle clear caches worden aangeroepen
    }
  }
})
