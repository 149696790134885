import jwt_decode from 'jwt-decode';
import { RepositoryFactory } from '@/api/RepositoryFactory'
const CustomersRepository = RepositoryFactory.get('customers')
const AuthRepository = RepositoryFactory.get('auth')
const UsersRepository = RepositoryFactory.get('users')

export default {
  namespaced: true,

  state: function () {
    return {
      token: '',
      userName: '',
      customerName: '',
      authenticated: false,
      impersonated: false,
      allowedEndpointList: []
    }
  },

  getters: {
    customerId: (state, getters) => {
      if (!getters['getToken']) {
        return null
      }
      let token = jwt_decode(getters['getToken'])
      return token.cid
    },
    
    getCustomerName: (state) => {
      return state.customerName
    },

    getUserId: (state, getters) => {
      if (!getters['getToken']) {
        return null
      }
      let token = jwt_decode(getters['getToken'])
      return token.sub
    },

    getUserName: (state, getters) => {
      if (!getters['getToken']) {
        return null
      }
      let token = jwt_decode(getters['getToken'])
      return token.nam
    },

    getAllowedEndpointList: (state) => {
      return state.allowedEndpointList
    },

    hasTwoFactorEnabled: (state, getters) => {
      if (!getters['getToken']) {
        return null
      }
      let token = jwt_decode(getters['getToken'])
      return token.mfa === true
    },

    twoFactorRequired: (state, getters) => {
      if (!getters['getToken']) {
        return null
      }
      let token = jwt_decode(getters['getToken'])
      return token.mfareq === true
    },

    isAuthorized: (state, getters) => (params) => {
      if (params.method && params.endpoint) {
        var result = state.allowedEndpointList.filter(obj => {
          return obj.method === params.method && obj.endpoint === `${params.endpoint}`
        })
        return result.length > 0
      }
      else if (params.scope) {
        let token = jwt_decode(getters['getToken'])
        let scopes = token.scope ? token.scope.split(' ') : []
        
        return (scopes.indexOf(params.scope) != -1)
      }

      return false
    },

    isAuthenticated: (state) => {
      if (state.authenticated) {
        return true
      }

      let token = sessionStorage.getItem('token')
      if (token !== null) {
        let decodedToken = jwt_decode(token)
        if (!decodedToken.mfareq || decodedToken.mfa) {
          // Only return true if it is a full token, not a token only available for enabling 2FA
          return true
        }
      }

      return false
    },

    allowedEndpointListLoaded: (state) => {
      return (state.allowedEndpointList.length)
    },

    isImpersonated: (state, getters) => {
      if (!getters['getToken']) {
        return false
      }
      let token = jwt_decode(getters['getToken'])
      return token.imp === true
    },

    getToken: (state) => {
      // TODO: token via cookie
      return state.token ? state.token : sessionStorage.getItem('token')
    }
  },

  mutations: {
    async logout(state) {
      state.authenticated = false
      state.token = null
      sessionStorage.removeItem('token')
    },

    setCustomerName (state, payload) {
      state.customerName = payload
    },

    setAllowedEndpointList (state, payload) {
      state.allowedEndpointList = payload
    },

    async setToken (state, payload) {
      sessionStorage.setItem('token', payload)
      sessionStorage.setItem('isImpersonated', false)
      state.token = payload
      state.impersonated = false
      
      let token = jwt_decode(payload)

      // If the token contains an mfareq (TwoFactorRequired) then this.authenticated must not be set
      if (!token.mfareq || token.mfa) {
        state.authenticated = true
      }
    },

    setImpersonated (state, payload) {
      sessionStorage.setItem('token', payload)
      sessionStorage.setItem('isImpersonated', true)
      state.token = payload
      state.authenticated = true
      state.impersonated = true
    },

    clearCache (state) {
      state.allowedEndpointList = []
    }
  },

  actions: {
    clearCache ({ commit }) {
      commit('clearCache')
    },

    async loadUserInfo (context) {
      try {
        let response = await CustomersRepository.getCustomer(context.getters['customerId'])
        context.commit('setCustomerName', response.name)
      }
      catch(e) {
        context.dispatch(`applicationLog/log`, `Failed loading userInfo: ${e}`, {root:true})
      }
    },

    setAllowedEndpointList (context, payload) {
      context.commit(`setAllowedEndpointList`, payload)
    },

    setToken (context, payload) {
      context.commit(`setToken`, payload)
    },
    
    setImpersonated (context, payload) {
      context.commit(`setImpersonated`, payload)
    },

    /**
     * Action: Load Allowed Endpoints
     */ 
    async loadAllowedEndpointList (context) {
      try {
        const data = await AuthRepository.getAllowedEndpoints()
        if (Array.isArray(data)) {
          context.dispatch(`setAllowedEndpointList`, data)
        }
      }
      catch(e) {
        context.dispatch(`applicationLog/log`, `Failed loading items: ${e}`, {root:true})
      }
    },

    /**
     * Action: Login
     */ 
    async login (context, payload) {
      const response = await AuthRepository.createApiToken(payload)
      if (response.data.apiToken) {
        await context.commit(`setToken`, response.data.apiToken)
        await context.dispatch('loadAllowedEndpointList')
      }
      return response
    },

    /**
     * Action: Reset Password Request
     */ 
    async resetPasswordRequest (context, payload) {
      try {
        const response = await UsersRepository.createPasswordResetRequest(payload)
        return response
      }
      catch(e) {
        context.dispatch(`applicationLog/log`, `Failed resetPasswordRequest: ${e}`, {root:true})
      }
    },

    /**
     * Action: Impersonate
     */ 
    async impersonate (context, payload) {
      try {
        const response = await AuthRepository.createImpersonatedApiToken(payload)
        if (!response.error && response.data.apiToken) {
          context.commit(`setImpersonated`, response.data.apiToken)
          context.dispatch(`loadUserInfo`) // Load Customer name
        }
        return response
      }
      catch(e) {
        context.dispatch(`applicationLog/log`, `Failed loading items: ${e}`, {root:true})
      }
    },

    /**
     * Action: Return to user (from impersonation)
     */ 
    async returnToUser (context) {
      try {
        const response = await AuthRepository.createParentUserApiToken()
        if (!response.error && response.data.apiToken) {
          context.commit(`setToken`, response.data.apiToken)
          context.dispatch(`loadUserInfo`) // Load Customer name
        }
        return response
      }
      catch(e) {
        context.dispatch(`applicationLog/log`, `Failed loading items: ${e}`, {root:true})
      }
    },

    logout(context) {
      context.commit(`logout`)
    }
  }

}