<template>
  <v-menu
    v-model="showDatePicker"
    :close-on-content-click="false"
    :nudge-right="40"
    lazy
    transition="scale-transition"
    offset-y
    full-width
    min-width="290px"
  >
    <template v-slot:activator="{ on }">
      <v-text-field
        :value="computedDateFormatted"
        :label="label"
        prepend-icon="event"
        v-on="on"
      ></v-text-field>
    </template>
    <v-date-picker ref="datePicker" :value="computedValue" @input="updateDate()"></v-date-picker>
  </v-menu>
</template>

<script>

import datesMixin from '@/mixins/Dates.js'
export default {
  mixins: [ datesMixin ],

  props: {
    value: {
      type: String,
      default: null
    },
    label: {
      type: String,
      default: ''
    }
  },

  data: function () {
    return {
      showDatePicker: false,
      dateFormatted: null,
    }
  },

  computed: {
    computedValue: function() {
      return this.parseDate(this.value ? this.value : new Date(), 'YYYY-MM-DD', 'YYYY-MM-DD')
    },

    computedDateFormatted: function () {
      return this.parseDate(this.value, 'YYYY-MM-DD', 'dddd, MMMM Do YYYY')
    }
  },

  methods: {
    updateDate: function() {
      this.showDatePicker = false
      this.$emit('input', this.$refs.datePicker.inputDate)
    }
  }
}
</script>
