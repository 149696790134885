import Vue from 'vue'
import Router from 'vue-router'
import * as RoutingTypes from '@/router/routing-types.js'

// Lazy Loading needed routing components
const HomePage = () => import('@/views/HomePage')
const Login = () => import('@/views/Login')
const ForgotPassword = () => import('@/views/ForgotPassword')
const ResetPassword = () => import('@/views/ResetPassword')
const PageNotFound = () => import('@/views/PageNotFound')

const Domains = () => import('@/views/Domains/Domains')
const DomainsOverview = () => import('@/views/Domains/Domains/Overview')
const DomainsOverviewDns = () => import('@/views/Domains/Domains/OverviewDns')
const DomainsOverviewEmail = () => import('@/views/Domains/Domains/OverviewEmail')
const DomainsOverviewAntiSpam = () => import('@/views/Domains/Domains/OverviewAntiSpam')
const DomainOverviewDetails = () => import('@/views/Domains/Domains/OverviewDetails')
const DomainsOverviewRedirect = () => import('@/views/Domains/Domains/OverviewRedirect')

const DnsTemplates = () => import('@/views/Domains/DnsTemplates')
const DnsTemplatesOverview = () => import('@/views/Domains/DnsTemplates/Overview')
const DnsTemplatesOverviewDetails = () => import('@/views/Domains/DnsTemplates/OverviewDetails')
const DnsTemplatesOverviewRecords = () => import('@/views/Domains/DnsTemplates/OverviewRecords')

const AutoDnsAccounts = () => import('@/views/Domains/AutoDnsAccounts')
const AutoDnsActions = () => import('@/views/Domains/AutoDnsActions')
const AutoDnsHistory = () => import('@/views/Domains/AutoDnsHistory')

const NetworkSwitches = () => import('@/views/Equipment/NetworkSwitches')
const NetworkSwitchOverview = () => import('@/views/Equipment/NetworkSwitches/Overview')
const NetworkSwitchOverviewDetails = () => import('@/views/Equipment/NetworkSwitches/OverviewDetails')
const NetworkSwitchOverviewNetwork = () => import('@/views/Equipment/NetworkSwitches/OverviewNetwork')
const NetworkSwitchOverviewLicenses = () => import('@/views/Equipment/NetworkSwitches/OverviewLicenses')

const MiscEquipment = () => import('@/views/Equipment/Misc')
const MiscEquipmentOverview = () => import('@/views/Equipment/Misc/Overview')
const MiscEquipmentOverviewDetails = () => import('@/views/Equipment/Misc/OverviewDetails')

const NetworkConfigs = () => import('@/views/Equipment/NetworkConfigs')
const NetworkConfigsOverview = () => import('@/views/Equipment/NetworkConfigs/Overview')
const NetworkConfigsOverviewDetails = () => import('@/views/Equipment/NetworkConfigs/OverviewDetails')
const NetworkConfigsOverviewSubnets = () => import('@/views/Equipment/NetworkConfigs/OverviewSubnets')

const Servers = () => import('@/views/Equipment/Servers')
const ServerOverview = () => import('@/views/Equipment/Servers/Overview')
const ServerOverviewDetails = () => import('@/views/Equipment/Servers/OverviewDetails')
const ServerOverviewNetwork = () => import('@/views/Equipment/Servers/OverviewNetwork')
const ServerOverviewLicenses = () => import('@/views/Equipment/Servers/OverviewLicenses')
const ServerOverviewSla = () => import('@/views/Equipment/Servers/OverviewSla')
const ServerOverviewChecklists = () => import('@/views/Equipment/Servers/OverviewChecklists')

const FinanceOverview = () => import('@/views/Finance/Overview')
const Subscriptions = () => import('@/views/Finance/Subscriptions')
const Invoices = () => import('@/views/Finance/Invoices')
const InvoicesOpen = () => import('@/views/Finance/InvoicesOpen')

const VirtualMachines = () => import('@/views/Equipment/VirtualMachines')
const VirtualMachineOverview = () => import('@/views/Equipment/VirtualMachines/Overview')
const VirtualMachineOverviewDetails = () => import('@/views/Equipment/VirtualMachines/OverviewDetails')
const VirtualMachineOverviewNetwork = () => import('@/views/Equipment/VirtualMachines/OverviewNetwork')
const VirtualMachineOverviewLicenses = () => import('@/views/Equipment/VirtualMachines/OverviewLicenses')
const VirtualMachineOverviewSla = () => import('@/views/Equipment/VirtualMachines/OverviewSla')
const VirtualMachineOverviewChecklists = () => import('@/views/Equipment/VirtualMachines/OverviewChecklists')

const Racks = () => import('@/views/Equipment/Racks')
const RackLayout = () => import('@/views/Equipment/Racks/RackLayout')
const PublicIpv4Numbers = () => import('@/views/Equipment/PublicIpv4Numbers')
const IpPools = () => import('@/views/Equipment/IpPools')
const OsVersions = () => import('@/views/Equipment/OsVersions')
const EquipmentNames = () => import('@/views/Equipment/EquipmentNames')
const ServiceLevels = () => import('@/views/Equipment/ServiceLevels')
const Checklist = () => import('@/views/Equipment/Checklists/Checklist')

const ChecklistTemplates = () => import('@/views/Equipment/ChecklistTemplates')
const ChecklistTemplatesOverview = () => import('@/views/Equipment/ChecklistTemplates/Overview')
const ChecklistTemplatesOverviewDetails = () => import('@/views/Equipment/ChecklistTemplates/OverviewDetails')
const ChecklistTemplatesOverviewItems = () => import('@/views/Equipment/ChecklistTemplates/OverviewItems')

const Customers = () => import('@/views/UserManagement/Customers')
const UsersOverview = () => import('@/views/UserManagement/Users/Overview')
const Users = () => import('@/views/UserManagement/Users/Users')
const ApiKeys = () => import('@/views/UserManagement/Users/ApiKeys')
const AccountSettings = () => import('@/views/UserManagement/AccountSettings')
const EnableTwoFactorAuthentication = () => import('@/views/UserManagement/EnableTwoFactorAuthentication')
const Roles = () => import('@/views/UserManagement/Roles')
const LoggedErrors = () => import('@/views/UserManagement/LoggedErrors')
const LoggedErrorsDetails = () => import('@/views/UserManagement/LoggedErrors/Details')
const LoggedAuditEntries = () => import('@/views/UserManagement/LoggedAuditEntries')
const LoggedAuditEntriesDetails = () => import('@/views/UserManagement/LoggedAuditEntries/Details')
const RabbitTransactions = () => import('@/views/UserManagement/RabbitTransactions')
const RabbitTransactionsDetails = () => import('@/views/UserManagement/RabbitTransactions/Details')

const ChangedEquipmentResources = () => import('@/views/Administration/ChangedEquipmentResources')
const Licenses = () => import('@/views/Administration/Licenses')
const LicenseCategories = () => import('@/views/Administration/LicenseCategories')
const SplaLicenses = () => import('@/views/Administration/SplaLicenses')
const LicensesPerMachine = () => import('@/views/Administration/LicensesPerMachine')
const NonRegisteredDomains = () => import('@/views/Administration/NonRegisteredDomains')
const NonPortalRegisteredDomains = () => import('@/views/Administration/NonPortalRegisteredDomains')
const NonPortalAccountingDomains = () => import('@/views/Administration/NonPortalAccountingDomains')
const NonAccountingPortalDomains = () => import('@/views/Administration/NonAccountingPortalDomains')
const MailboxReport = () => import('@/views/Administration/MailboxReport')
const ImportedDomains = () => import('@/views/Administration/ImportedDomains')

const Uptime = () => import('@/views/Reports/Uptime')
const UptimeDetails = () => import('@/views/Reports/UptimeDetails')

const SlaOverview = () => import('@/views/Sla/Overview')
const SlaMachines = () => import('@/views/Sla/Machines')
const SlaContact = () => import('@/views/Sla/Contact')

const DocumentCategories = () => import('@/views/Documents/DocumentCategories')
const DocumentManage = () => import('@/views/Documents/Manage')

const SandboxTestComponent = () => import('@/views/Sandbox/TestComponent')

Vue.use(Router)

export default new Router({
  mode: 'history', // Disable # in urls
  
  routes: [
    {
      path: '*',
      component: PageNotFound
    },
    
    {
      path: '/', redirect: RoutingTypes.HOME_PAGE
    },
  
    {
      path: RoutingTypes.LOGIN, 
      name: 'login',
      component: Login,
      meta: {
        breadCrumbMainPath: RoutingTypes.LOGIN,
        requiresAuth: false,
      }
    },
    {
      path: RoutingTypes.FORGOT_PASSWORD, 
      name: 'forgot-password',
      component: ForgotPassword,
      meta: {
        breadCrumbMainPath: RoutingTypes.FORGOT_PASSWORD,
        requiresAuth: false,
      }
    },
    {
      path: `${RoutingTypes.RESET_PASSWORD}/:resetToken`, 
      name: 'reset-password',
      component: ResetPassword,
      meta: {
        breadCrumbMainPath: RoutingTypes.RESET_PASSWORD,
        requiresAuth: false,
      }
    },
    {
      path: RoutingTypes.HOME_PAGE, 
      name: 'home',
      component: HomePage,
      meta: {
        breadCrumbMainPath: RoutingTypes.HOME_PAGE,
        requiresAuth: true,
      }
    },

    /**
     * Domains
     */
    {
      path: RoutingTypes.DOMAINS_INDEX,
      name: 'domains',
      component: Domains,
      meta: {
        breadCrumbMainPath: RoutingTypes.DOMAINS_INDEX,
        requiresAuth: true,
      }
    },
    {
      path: `${RoutingTypes.DOMAINS_INDEX}/:domainName`,
      name: 'domains-overview',
      component: DomainsOverview,
      meta: {
        breadCrumbMainPath: RoutingTypes.DOMAINS_INDEX,
        requiresAuth: true,
      },

      children: [
        {
          path: `${RoutingTypes.DOMAINS_DETAILS}/:domainName`,
          name: 'domain-overview-details',
          components: {
            // domainOverviewTabs is used for the named router-view
            domainOverviewTabs: DomainOverviewDetails
          },
          meta: {
            breadCrumbMainPath: RoutingTypes.DOMAINS_INDEX,
            requiresAuth: true,
          },
        },
        {
          path: `${RoutingTypes.DOMAINS_DNS}/:domainName`,
          name: 'dns',
          components: {
            // domainOverviewTabs is used for the named router-view
            domainOverviewTabs: DomainsOverviewDns
          },
          meta: {
            breadCrumbMainPath: RoutingTypes.DOMAINS_INDEX,
            requiresAuth: true,
          },
        },
        {
          path: `${RoutingTypes.DOMAINS_EMAIL}/:domainName`,
          name: 'email',
          components: {
            // domainOverviewTabs is used for the named router-view
            domainOverviewTabs: DomainsOverviewEmail
          },
          meta: {
            breadCrumbMainPath: RoutingTypes.DOMAINS_INDEX,
            requiresAuth: true,
          },
        },
        {
          path: `${RoutingTypes.DOMAINS_ANTI_SPAM}/:domainName`,
          name: 'anti-spam',
          components: {
            // domainOverviewTabs is used for the named router-view
            domainOverviewTabs: DomainsOverviewAntiSpam
          },
          meta: {
            breadCrumbMainPath: RoutingTypes.DOMAINS_INDEX,
            requiresAuth: true,
          },
        },
        {
          path: `${RoutingTypes.DOMAINS_REDIRECT}/:domainName`,
          name: 'redirect',
          components: {
            // domainOverviewTabs is used for the named router-view
            domainOverviewTabs: DomainsOverviewRedirect
          },
          meta: {
            breadCrumbMainPath: RoutingTypes.DOMAINS_INDEX,
            requiresAuth: true,
          },
        }
      ]
    },
    {
      path: RoutingTypes.DNS_TEMPLATES_INDEX,
      name: 'dns-templates',
      component: DnsTemplates,
      meta: {
        breadCrumbMainPath: RoutingTypes.DNS_TEMPLATES_INDEX,
        requiresAuth: true,
      }
    },
    {
      path: `${RoutingTypes.DNS_TEMPLATES_INDEX}/:id`,
      name: 'dns-template-overview',
      component: DnsTemplatesOverview,
      meta: {
        breadCrumbMainPath: RoutingTypes.DNS_TEMPLATES_INDEX,
        requiresAuth: true,
      },
      children: [
        {
          path: `${RoutingTypes.DNS_TEMPLATES_DETAILS}/:id`,
          name: 'dns-template-overview-details',
          components: {
            // dnsTemplateOverviewTabs is used for the named router-view
            dnsTemplateOverviewTabs: DnsTemplatesOverviewDetails
          },
          meta: {
            breadCrumbMainPath: RoutingTypes.DNS_TEMPLATES_INDEX,
            requiresAuth: true,
          }
            },
        {
          path: `${RoutingTypes.DNS_TEMPLATES_RECORDS}/:id`,
          name: 'dns-template-records',
          components: {
            // dnsTemplateOverviewTabs is used for the named router-view
            dnsTemplateOverviewTabs: DnsTemplatesOverviewRecords
          },
          meta: {
            breadCrumbMainPath: RoutingTypes.DNS_TEMPLATES_INDEX,
            requiresAuth: true,
          }
            },
      ]
    },

    /**
     * AutoDNS Accounts
     */
    {
      path: RoutingTypes.AUTO_DNS_ACCOUNTS_INDEX,
      name: 'autodns-accounts',
      component: AutoDnsAccounts,
      meta: {
        breadCrumbMainPath: RoutingTypes.AUTO_DNS_ACCOUNTS_INDEX,
        requiresAuth: true,
      }
    },

    /**
     * AutoDNS Actions
     */
    {
      path: RoutingTypes.AUTO_DNS_ACTIONS_INDEX,
      name: 'autodns-actions',
      component: AutoDnsActions,
      meta: {
        breadCrumbMainPath: RoutingTypes.AUTO_DNS_ACTIONS_INDEX,
        requiresAuth: true,
      }
    },

    /**
     * AutoDNS History
     */
    {
      path: RoutingTypes.AUTO_DNS_HISTORY_ITEMS_INDEX,
      name: 'autodns-history',
      component: AutoDnsHistory,
      meta: {
        breadCrumbMainPath: RoutingTypes.AUTO_DNS_HISTORY_ITEMS_INDEX,
        requiresAuth: true,
      }
    },

    /**
     * Equipment / VirtualMachines
     */

    {
      path: RoutingTypes.VIRTUAL_MACHINES_INDEX,
      name: 'virtual-machines',
      component: VirtualMachines,
      meta: {
        breadCrumbMainPath: RoutingTypes.VIRTUAL_MACHINES_INDEX,
        requiresAuth: true,
      }
    },
    {
      path: `${RoutingTypes.VIRTUAL_MACHINES_INDEX}/:id`,
      name: 'virtual-machines-overview',
      component: VirtualMachineOverview,
      meta: {
        breadCrumbMainPath: RoutingTypes.VIRTUAL_MACHINES_INDEX,
        requiresAuth: true,
      },
      children: [
        {
          path: `${RoutingTypes.VIRTUAL_MACHINES_DETAILS}/:id`,
          name: 'virtual-machine-details',
          components: {
            // virtualMachinesOverviewTabs is used for the named router-view
            virtualMachinesOverviewTabs: VirtualMachineOverviewDetails
          },
          meta: {
            breadCrumbMainPath: RoutingTypes.VIRTUAL_MACHINES_INDEX,
            requiresAuth: true,
          }
        },
        {
          path: `${RoutingTypes.VIRTUAL_MACHINES_NETWORK}/:id`,
          name: 'virtual-machine-network',
          components: {
            // virtualMachinesOverviewTabs is used for the named router-view
            virtualMachinesOverviewTabs: VirtualMachineOverviewNetwork
          },
          meta: {
            breadCrumbMainPath: RoutingTypes.VIRTUAL_MACHINES_INDEX,
            requiresAuth: true,
          }
        },
        {
          path: `${RoutingTypes.VIRTUAL_MACHINES_LICENSES}/:id`,
          name: 'virtual-machine-licenses',
          components: {
            // virtualMachinesOverviewTabs is used for the named router-view
            virtualMachinesOverviewTabs: VirtualMachineOverviewLicenses
          },
          meta: {
            breadCrumbMainPath: RoutingTypes.VIRTUAL_MACHINES_INDEX,
            requiresAuth: true,
          }
        },
        {
          path: `${RoutingTypes.VIRTUAL_MACHINES_SLA}/:id`,
          name: 'virtual-machine-sla',
          components: {
            // virtualMachinesOverviewTabs is used for the named router-view
            virtualMachinesOverviewTabs: VirtualMachineOverviewSla
          },
          meta: {
            breadCrumbMainPath: RoutingTypes.VIRTUAL_MACHINES_INDEX,
            requiresAuth: true,
          }
        },
        {
          path: `${RoutingTypes.VIRTUAL_MACHINES_CHECKLISTS}/:id`,
          name: 'virtual-machine-checklists-overview',
          components: {
            // virtualMachinesOverviewTabs is used for the named router-view
            virtualMachinesOverviewTabs: VirtualMachineOverviewChecklists
          },
          meta: {
            breadCrumbMainPath: RoutingTypes.VIRTUAL_MACHINES_INDEX,
            requiresAuth: true,
          },
          children: [
              {
                path: `${RoutingTypes.VIRTUAL_MACHINES_CHECKLISTS}/:id/:checklistId`, 
                name: 'virtual-machine-checklists',
                components: {
                  checklistsOverviewTabs: Checklist
                },
                meta: {
                  breadCrumbMainPath: RoutingTypes.VIRTUAL_MACHINES_INDEX,
                  requiresAuth: true,
                }
              },
          ]
        },
      ]
    },

    /**
     * Equipment / Servers
     */

    {
      path: RoutingTypes.SERVERS_INDEX,
      name: 'servers',
      component: Servers,
      meta: {
        breadCrumbMainPath: RoutingTypes.SERVERS_INDEX,
        requiresAuth: true,
      }
    },
    {
      path: `${RoutingTypes.SERVERS_INDEX}/:id`,
      name: 'servers-overview',
      component: ServerOverview,
      meta: {
        breadCrumbMainPath: RoutingTypes.SERVERS_INDEX,
        requiresAuth: true,
      },
      children: [
        {
          path: `${RoutingTypes.SERVERS_DETAILS}/:id`,
          name: 'server-details',
          components: {
            // serversOverviewTabs is used for the named router-view
            serversOverviewTabs: ServerOverviewDetails
          },
          meta: {
            breadCrumbMainPath: RoutingTypes.SERVERS_INDEX,
            requiresAuth: true,
          }
        },
        {
          path: `${RoutingTypes.SERVERS_NETWORK}/:id`,
          name: 'server-network',
          components: {
            // serversOverviewTabs is used for the named router-view
            serversOverviewTabs: ServerOverviewNetwork
          },
          meta: {
            breadCrumbMainPath: RoutingTypes.SERVERS_INDEX,
            requiresAuth: true,
          }
        },
        {
          path: `${RoutingTypes.SERVERS_LICENSES}/:id`,
          name: 'server-licenses',
          components: {
            // serversOverviewTabs is used for the named router-view
            serversOverviewTabs: ServerOverviewLicenses
          },
          meta: {
            breadCrumbMainPath: RoutingTypes.SERVERS_INDEX,
            requiresAuth: true,
          }
        },
        {
          path: `${RoutingTypes.SERVERS_SLA}/:id`,
          name: 'server-sla',
          components: {
            // serversOverviewTabs is used for the named router-view
            serversOverviewTabs: ServerOverviewSla
          },
          meta: {
            breadCrumbMainPath: RoutingTypes.SERVERS_INDEX,
            requiresAuth: true,
          }
        },
        {
          path: `${RoutingTypes.SERVERS_CHECKLISTS}/:id`,
          name: 'server-checklists-overview',
          components: {
            // serversOverviewTabs is used for the named router-view
            serversOverviewTabs: ServerOverviewChecklists
          },
          meta: {
            breadCrumbMainPath: RoutingTypes.SERVERS_INDEX,
            requiresAuth: true,
          },
          children: [
              {
                path: `${RoutingTypes.SERVERS_CHECKLISTS}/:id/:checklistId`, 
                name: 'server-checklists',
                components: {
                  checklistsOverviewTabs: Checklist
                },
                meta: {
                  breadCrumbMainPath: RoutingTypes.SERVERS_INDEX,
                  requiresAuth: true,
                }
              },
          ]
        },
      ]
    },

    /**
     * Equipment / Network Switches
     */

    {
      path: RoutingTypes.NETWORK_SWITCHES_INDEX,
      name: 'networkSwitches',
      component: NetworkSwitches,
      meta: {
        breadCrumbMainPath: RoutingTypes.NETWORK_SWITCHES_INDEX,
        requiresAuth: true,
      }
    },
    {
      path: `${RoutingTypes.NETWORK_SWITCHES_INDEX}/:id`,
      name: 'network-switches-overview',
      component: NetworkSwitchOverview,
      meta: {
        breadCrumbMainPath: RoutingTypes.NETWORK_SWITCHES_INDEX,
        requiresAuth: true,
      },
      children: [
        {
          path: `${RoutingTypes.NETWORK_SWITCHES_DETAILS}/:id`,
          name: 'network-switch-details',
          components: {
            // networkSwitchesOverviewTabs is used for the named router-view
            networkSwitchesOverviewTabs: NetworkSwitchOverviewDetails
          },
          meta: {
            breadCrumbMainPath: RoutingTypes.NETWORK_SWITCHES_INDEX,
            requiresAuth: true,
          }
        },
        {
          path: `${RoutingTypes.NETWORK_SWITCHES_NETWORK}/:id`,
          name: 'network-switch-network',
          components: {
            // networkSwitchesOverviewTabs is used for the named router-view
            networkSwitchesOverviewTabs: NetworkSwitchOverviewNetwork
          },
          meta: {
            breadCrumbMainPath: RoutingTypes.NETWORK_SWITCHES_INDEX,
            requiresAuth: true,
          }
        },
        {
          path: `${RoutingTypes.NETWORK_SWITCHES_LICENSES}/:id`,
          name: 'network-switch-licenses',
          components: {
            // networkSwitchesOverviewTabs is used for the named router-view
            networkSwitchesOverviewTabs: NetworkSwitchOverviewLicenses
          },
          meta: {
            breadCrumbMainPath: RoutingTypes.NETWORK_SWITCHES_INDEX,
            requiresAuth: true,
          }
        },
      ]
    },

    /**
     * Equipment / Misc
     */

    {
      path: RoutingTypes.MISC_EQUIPMENT_INDEX,
      name: 'miscEquipment',
      component: MiscEquipment,
      meta: {
        breadCrumbMainPath: RoutingTypes.MISC_EQUIPMENT_INDEX,
        requiresAuth: true,
      }
    },
    {
      path: `${RoutingTypes.MISC_EQUIPMENT_INDEX}/:id`,
      name: 'misc-equipment-overview',
      component: MiscEquipmentOverview,
      meta: {
        breadCrumbMainPath: RoutingTypes.MISC_EQUIPMENT_INDEX,
        requiresAuth: true,
      },
      children: [
        {
          path: `${RoutingTypes.MISC_EQUIPMENT_DETAILS}/:id`,
          name: 'misc-equipment-details',
          components: {
            // miscEquipmentOverviewTabs is used for the named router-view
            miscEquipmentOverviewTabs: MiscEquipmentOverviewDetails
          },
          meta: {
            breadCrumbMainPath: RoutingTypes.MISC_EQUIPMENT_INDEX,
            requiresAuth: true,
          }
        },
      ]
    },

    /**
     * Equipment / Racks
     */
    {
      path: RoutingTypes.RACKS_INDEX,
      name: 'racks',
      component: Racks,
      meta: {
        breadCrumbMainPath: RoutingTypes.RACKS_INDEX,
        requiresAuth: true,
      }
    },
    
    /**
     * Equipment / Racks / Layout
     */
    {
      path: `${RoutingTypes.RACKS_LAYOUT_INDEX}/:id`,
      name: 'racks-layout',
      component: RackLayout,
      meta: {
        breadCrumbMainPath: RoutingTypes.RACKS_INDEX,
        requiresAuth: true,
      }
    },

    /**
     * Equipment / PublicIpv4Numbers
     */
    {
      path: RoutingTypes.PUBLIC_IP_V4_NUMBERS_INDEX,
      name: 'public-ipv4-numbers',
      component: PublicIpv4Numbers,
      meta: {
        breadCrumbMainPath: RoutingTypes.PUBLIC_IP_V4_NUMBERS_INDEX,
        requiresAuth: true,
      }
    },
    
    /**
     * Equipment / IpPools
     */
    {
      path: RoutingTypes.IP_POOLS_INDEX,
      name: 'ip-pools',
      component: IpPools,
      meta: {
        breadCrumbMainPath: RoutingTypes.IP_POOLS_INDEX,
        requiresAuth: true,
      }
    },
    
    /**
     * Equipment / Service Levels
     */
    {
      path: RoutingTypes.SERVICE_LEVELS_INDEX,
      name: 'service-levels',
      component: ServiceLevels,
      meta: {
        breadCrumbMainPath: RoutingTypes.SERVICE_LEVELS_INDEX,
        requiresAuth: true,
      }
    },

    /**
     * Equipment / NetworkConfigs
     */
    {
      path: RoutingTypes.NETWORK_CONFIGS_INDEX,
      name: 'network-configs',
      component: NetworkConfigs,
      meta: {
        breadCrumbMainPath: RoutingTypes.NETWORK_CONFIGS_INDEX,
        requiresAuth: true,
      }
    },
    {
      path: `${RoutingTypes.NETWORK_CONFIGS_INDEX}/:id/:type`,
      name: 'network-configs-overview',
      component: NetworkConfigsOverview,
      meta: {
        breadCrumbMainPath: RoutingTypes.NETWORK_CONFIGS_INDEX,
        requiresAuth: true,
      },
      children: [
        {
          path: `${RoutingTypes.NETWORK_CONFIGS_DETAILS}/:id/:type`,
          name: 'network-configs-overview-simple-details',
          components: {
            // networkConfigOverviewTabs is used for the named router-view
            networkConfigOverviewTabs: NetworkConfigsOverviewDetails
          },
          meta: {
            breadCrumbMainPath: RoutingTypes.NETWORK_CONFIGS_INDEX,
            requiresAuth: true,
          }
        },
        {
          path: `${RoutingTypes.NETWORK_CONFIGS_SUBNETS}/:id/:type`,
          name: 'network-configs-subnets',
          components: {
            // networkConfigOverviewTabs is used for the named router-view
            networkConfigOverviewTabs: NetworkConfigsOverviewSubnets
          },
          meta: {
            breadCrumbMainPath: RoutingTypes.NETWORK_CONFIGS_INDEX,
            requiresAuth: true,
          }
        },
      ]
    },

    /**
     * Equipment / Checklists Templates
     */

    {
      path: RoutingTypes.CHECKLISTS_TEMPLATES_INDEX,
      name: 'checklist-templates',
      component: ChecklistTemplates,
      meta: {
        breadCrumbMainPath: RoutingTypes.CHECKLISTS_TEMPLATES_INDEX,
        requiresAuth: true,
      }
    },
    {
      path: `${RoutingTypes.CHECKLISTS_TEMPLATES_INDEX}/:id`,
      name: 'checklist-template-overview',
      component: ChecklistTemplatesOverview,
      meta: {
        breadCrumbMainPath: RoutingTypes.CHECKLISTS_TEMPLATES_INDEX,
        requiresAuth: true,
      },
      children: [
        {
          path: `${RoutingTypes.CHECKLISTS_TEMPLATES_DETAILS}/:id`,
          name: 'checklist-template-overview-details',
          components: {
            // dnsTemplateOverviewTabs is used for the named router-view
            checklistTemplateOverviewTabs: ChecklistTemplatesOverviewDetails
          },
          meta: {
            breadCrumbMainPath: RoutingTypes.CHECKLISTS_TEMPLATES_INDEX,
            requiresAuth: true,
          }
        },
        {
          path: `${RoutingTypes.CHECKLISTS_TEMPLATES_ITEMS}/:id`,
          name: 'checklist-template-items',
          components: {
            // checklistTemplateOverviewTabs is used for the named router-view
            checklistTemplateOverviewTabs: ChecklistTemplatesOverviewItems
          },
          meta: {
            breadCrumbMainPath: RoutingTypes.CHECKLISTS_TEMPLATES_INDEX,
            requiresAuth: true,
          }
        },
      ]
    },

    /**
     * SLA Tabs
     */
    {
      path: `${RoutingTypes.SLA_INDEX}`,
      name: 'sla-overview',
      component: SlaOverview,
      meta: {
        breadCrumbMainPath: RoutingTypes.SLA_INDEX,
        requiresAuth: true,
      },
      children: [
        {
          path: RoutingTypes.SLA_CONTACT_INDEX,
          name: 'sla-contacts',
          components: {
            // slaOverviewTabs is used for the named router-view
            slaOverviewTabs: SlaContact
          },
          meta: {
            breadCrumbMainPath: RoutingTypes.SLA_INDEX,
            requiresAuth: true,
          }
        },
        {
          path: RoutingTypes.SLA_MACHINES_INDEX,
          name: 'sla-machines',
          components: {
            // slaOverviewTabs is used for the named router-view
            slaOverviewTabs: SlaMachines
          },
          meta: {
            breadCrumbMainPath: RoutingTypes.SLA_INDEX,
            requiresAuth: true,
          }
        },
      ]
    },

    /**
     * Os Versions
     */
    {
      path: RoutingTypes.OS_VERSIONS_INDEX,
      name: 'os-versions',
      component: OsVersions,
      meta: {
        breadCrumbMainPath: RoutingTypes.OS_VERSIONS_INDEX,
        requiresAuth: true,
      }
    },

    /**
     * Equipment Names
     */
    {
      path: RoutingTypes.EQUIPMENT_NAMES_INDEX,
      name: 'equipment-names',
      component: EquipmentNames,
      meta: {
        breadCrumbMainPath: RoutingTypes.EQUIPMENT_NAMES_INDEX,
        requiresAuth: true,
      }
    },

    /**
     * Finance Tabs
     */
    {
      path: `${RoutingTypes.FINANCE_INDEX}`,
      name: 'finance-overview',
      component: FinanceOverview,
      meta: {
        breadCrumbMainPath: RoutingTypes.FINANCE_INDEX,
        requiresAuth: true,
      },
      children: [
        {
          path: RoutingTypes.INVOICES_OPEN_INDEX,
          name: 'finance-invoices-open',
          components: {
            // financeOverviewTabs is used for the named router-view
            financeOverviewTabs: InvoicesOpen
          },
          meta: {
            breadCrumbMainPath: RoutingTypes.FINANCE_INDEX,
            requiresAuth: true,
          }
      
        },
        {
          path: RoutingTypes.INVOICES_HISTORY_INDEX,
          name: 'finance-invoices',
          components: {
            // financeOverviewTabs is used for the named router-view
            financeOverviewTabs: Invoices
          },
          meta: {
            breadCrumbMainPath: RoutingTypes.FINANCE_INDEX,
            requiresAuth: true,
          }
        },
        {
          path: RoutingTypes.SUBSCRIPTIONS_INDEX,
          name: 'finance-subscriptions',
          components: {
            // financeOverviewTabs is used for the named router-view
            financeOverviewTabs: Subscriptions
          },
          meta: {
            breadCrumbMainPath: RoutingTypes.FINANCE_INDEX,
            requiresAuth: true,
          }
    
        },
      ]
    },

    /**
     * Usermanagement / Customers
     */
    {
      path: RoutingTypes.USER_MANAGEMENT_CUSTOMERS_INDEX,
      name: 'customers',
      component: Customers,
      meta: {
        breadCrumbMainPath: RoutingTypes.USER_MANAGEMENT_CUSTOMERS_INDEX,
        requiresAuth: true,
      }
    },

    /**
     * Usermanagement / Users Tabs
     */
    {
      path: `${RoutingTypes.USER_MANAGEMENT_USERS_INDEX}`,
      name: 'users-overview',
      component: UsersOverview,
      meta: {
        breadCrumbMainPath: RoutingTypes.USER_MANAGEMENT_USERS_INDEX,
        requiresAuth: true,
      },
      children: [
        {
          path: RoutingTypes.USER_MANAGEMENT_USERS_USERS,
          name: 'users-users',
          components: {
            // usersOverviewTabs is used for the named router-view
            usersOverviewTabs: Users
          },
          meta: {
            breadCrumbMainPath: RoutingTypes.USER_MANAGEMENT_USERS_INDEX,
            requiresAuth: true,
          }
        },
        {
          path: RoutingTypes.USER_MANAGEMENT_USERS_APIKEYS,
          name: 'users-apikeys',
          components: {
            // usersOverviewTabs is used for the named router-view
            usersOverviewTabs: ApiKeys
          },
          meta: {
            breadCrumbMainPath: RoutingTypes.USER_MANAGEMENT_USERS_INDEX,
            requiresAuth: true,
          }
        },
      ]
    },


    /**
     * Usermanagement / AccountSettings
     */
    {
      path: RoutingTypes.USER_MANAGEMENT_ACCOUNT_SETTINGS_INDEX,
      name: 'accountSettings',
      component: AccountSettings,
      meta: {
        breadCrumbMainPath: RoutingTypes.USER_MANAGEMENT_ACCOUNT_SETTINGS_INDEX,
        requiresAuth: true,
      }
    },

    /**
     * Usermanagement / EnableTwoFactorAuthentication
     */
    {
      path: RoutingTypes.USER_MANAGEMENT_ENABLE_TWO_FACTOR_AUTHENTICATION_INDEX,
      name: 'enableTwoFactorAuthentication',
      component: EnableTwoFactorAuthentication,
      meta: {
        breadCrumbMainPath: RoutingTypes.USER_MANAGEMENT_ACCOUNT_SETTINGS_INDEX,
        requiresAuth: true,
      }
    },

    /**
     * Usermanagement / Roles
     */
    {
      path: RoutingTypes.USER_MANAGEMENT_ROLES_INDEX,
      name: 'roles',
      component: Roles,
      meta: {
        breadCrumbMainPath: RoutingTypes.USER_MANAGEMENT_ROLES_INDEX,
        requiresAuth: true,
      }
    },

    /**
     * Usermanagement / Error Log
     */
    {
      path: RoutingTypes.USER_MANAGEMENT_ERROR_LOG_ENTRIES_INDEX,
      name: 'logged-errors',
      component: LoggedErrors,
      meta: {
        breadCrumbMainPath: RoutingTypes.USER_MANAGEMENT_ERROR_LOG_ENTRIES_INDEX,
        requiresAuth: true,
      }
    },

    /**
     * Usermanagement / Error Log / Details
     */
    {
      path: `${RoutingTypes.USER_MANAGEMENT_ERROR_LOG_ENTRIES_DETAILS}/:id`,
      name: 'logged-errors-details',
      component: LoggedErrorsDetails,
      meta: {
        breadCrumbMainPath: RoutingTypes.USER_MANAGEMENT_ERROR_LOG_ENTRIES_INDEX,
        requiresAuth: true,
      }
    },

    /**
     * Usermanagement / Action Log
     */
    {
      path: RoutingTypes.USER_MANAGEMENT_ORM_LOG_ENTRIES_INDEX,
      name: 'logged-audit-entries',
      component: LoggedAuditEntries,
      meta: {
        breadCrumbMainPath: RoutingTypes.USER_MANAGEMENT_ORM_LOG_ENTRIES_INDEX,
        requiresAuth: true,
      }
    },

    /**
     * Usermanagement / Action Log / Details
     */
    {
      path: `${RoutingTypes.USER_MANAGEMENT_ORM_LOG_ENTRIES_DETAILS}/:id`,
      name: 'logged-audit-entries-details',
      component: LoggedAuditEntriesDetails,
      meta: {
        breadCrumbMainPath: RoutingTypes.USER_MANAGEMENT_ORM_LOG_ENTRIES_INDEX,
        requiresAuth: true,
      }
    },

    /**
     * Usermanagement / Rabbit Transactions
     */
    {
      path: RoutingTypes.USER_MANAGEMENT_RABBIT_TRANSACTIONS_INDEX,
      name: 'rabbit-transactions',
      component: RabbitTransactions,
      meta: {
        breadCrumbMainPath: RoutingTypes.USER_MANAGEMENT_RABBIT_TRANSACTIONS_INDEX,
        requiresAuth: true,
      }
    },

    /**
     * Usermanagement / Rabbit Transactions / Details
     */
    {
      path: `${RoutingTypes.USER_MANAGEMENT_RABBIT_TRANSACTIONS_DETAILS}/:id`,
      name: 'rabbit-transactions-details',
      component: RabbitTransactionsDetails,
      meta: {
        breadCrumbMainPath: RoutingTypes.USER_MANAGEMENT_RABBIT_TRANSACTIONS_INDEX,
        requiresAuth: true,
      }
    },

    /**
     * Administration / Changed Equipment Resources
     */
    {
      path: RoutingTypes.ADMINISTRATION_CHANGED_EQUIPMENT_RESOURCES_INDEX,
      name: 'changed-equipment-resources',
      component: ChangedEquipmentResources,
      meta: {
        breadCrumbMainPath: RoutingTypes.ADMINISTRATION_CHANGED_EQUIPMENT_RESOURCES_INDEX,
        requiresAuth: true,
      }
    },

    /**
     * Administration / Licenses
     */
    {
      path: RoutingTypes.ADMINISTRATION_LICENSES_INDEX,
      name: 'licenses',
      component: Licenses,
      meta: {
        breadCrumbMainPath: RoutingTypes.ADMINISTRATION_LICENSES_INDEX,
        requiresAuth: true,
      }
    },

    /**
     * Administration / LicenseCategories
     */
    {
      path: RoutingTypes.ADMINISTRATION_LICENSE_CATEGORIES_INDEX,
      name: 'license-categories',
      component: LicenseCategories,
      meta: {
        breadCrumbMainPath: RoutingTypes.ADMINISTRATION_LICENSE_CATEGORIES_INDEX,
        requiresAuth: true,
      }
    },

    /**
     * Administration / SplaLicenses
     */
    {
      path: RoutingTypes.ADMINISTRATION_SPLA_LICENSES_INDEX,
      name: 'spla-licenses',
      component: SplaLicenses,
      meta: {
        breadCrumbMainPath: RoutingTypes.ADMINISTRATION_SPLA_LICENSES_INDEX,
        requiresAuth: true,
      }
    },

    /**
     * Administration / Licenses Per Machine
     */
    {
      path: RoutingTypes.ADMINISTRATION_LICENSES_PER_MACHINE_INDEX,
      name: 'licenses-per-machine',
      component: LicensesPerMachine,
      meta: {
        breadCrumbMainPath: RoutingTypes.ADMINISTRATION_LICENSES_PER_MACHINE_INDEX,
        requiresAuth: true,
      }
    },

    /**
     * Administration / Non Registered Domains
     */
    {
      path: RoutingTypes.ADMINISTRATION_NON_REGISTERED_DOMAINS_INDEX,
      name: 'non-registered-domains',
      component: NonRegisteredDomains,
      meta: {
        breadCrumbMainPath: RoutingTypes.ADMINISTRATION_NON_REGISTERED_DOMAINS_INDEX,
        requiresAuth: true,
      }
    },

    /**
     * Administration / Non Portal Registered Domains
     */
    {
      path: RoutingTypes.ADMINISTRATION_NON_PORTAL_REGISTERED_DOMAINS_INDEX,
      name: 'non-portal-registered-domains',
      component: NonPortalRegisteredDomains,
      meta: {
        breadCrumbMainPath: RoutingTypes.ADMINISTRATION_NON_PORTAL_REGISTERED_DOMAINS_INDEX,
        requiresAuth: true,
      }
    },

    /**
     * Administration / Non Portal Accounting Domains
     */
    {
      path: RoutingTypes.ADMINISTRATION_NON_PORTAL_ACCOUNTING_DOMAINS_INDEX,
      name: 'non-portal-accounting-domains',
      component: NonPortalAccountingDomains,
      meta: {
        breadCrumbMainPath: RoutingTypes.ADMINISTRATION_NON_PORTAL_ACCOUNTING_DOMAINS_INDEX,
        requiresAuth: true,
      }
    },

    /**
     * Administration / Non Accounting Portal Domains
     */
    {
      path: RoutingTypes.ADMINISTRATION_NON_ACCOUNTING_PORTAL_DOMAINS_INDEX,
      name: 'non-accounting-portal-domains',
      component: NonAccountingPortalDomains,
      meta: {
        breadCrumbMainPath: RoutingTypes.ADMINISTRATION_NON_ACCOUNTING_PORTAL_DOMAINS_INDEX,
        requiresAuth: true,
      }
    },

    /**
     * Administration / Mailbox Report
     */
    {
      path: RoutingTypes.ADMINISTRATION_MAILBOX_REPORT_INDEX,
      name: 'mailbox-report',
      component: MailboxReport,
      meta: {
        breadCrumbMainPath: RoutingTypes.ADMINISTRATION_MAILBOX_REPORT_INDEX,
        requiresAuth: true,
      }
    },

    /**
     * Administration / Imported Domains
     */
    {
      path: RoutingTypes.ADMINISTRATION_IMPORTED_DOMAINS_INDEX,
      name: 'imported-domains',
      component: ImportedDomains,
      meta: {
        breadCrumbMainPath: RoutingTypes.ADMINISTRATION_IMPORTED_DOMAINS_INDEX,
        requiresAuth: true,
      }
    },

    /**
     * Reports / Uptime
     */
    {
      path: RoutingTypes.REPORTS_UPTIME_INDEX,
      name: 'reports-uptime',
      component: Uptime,
      meta: {
        breadCrumbMainPath: RoutingTypes.REPORTS_UPTIME_INDEX,
        requiresAuth: true,
      }
    },
    /**
     * Reports / UptimeDetails
     */
    {
      path: `${RoutingTypes.REPORTS_UPTIME_DETAILS}/:id`,
      name: 'reports-uptime-details',
      component: UptimeDetails,
      meta: {
        breadCrumbMainPath: RoutingTypes.REPORTS_UPTIME_INDEX,
        requiresAuth: true,
      }
    },

    /**
     * Documents / Document Categories
     */
    {
      path: `${RoutingTypes.DOCUMENTS_CATEGORIES_INDEX}`,
      name: 'document-categories',
      component: DocumentCategories,
      meta: {
        breadCrumbMainPath: RoutingTypes.DOCUMENTS_CATEGORIES_INDEX,
        requiresAuth: true,
      }
    },

    /**
     * Documents / Manage documents
     */
    {
      path: `${RoutingTypes.DOCUMENTS_MANAGE}`,
      name: 'document-manage',
      component: DocumentManage,
      meta: {
        breadCrumbMainPath: RoutingTypes.DOCUMENTS_MANAGE,
        requiresAuth: true,
      }
    },

    /**
     * SANDBOX / Rackspace
     */
    {
      path: `${RoutingTypes.SANDBOX_TEST_COMPONENT}`,
      name: 'sandbox-rackspace',
      component: SandboxTestComponent,
      meta: {
        breadCrumbMainPath: RoutingTypes.SANDBOX_TEST_COMPONENT,
        requiresAuth: true,
      }
    },
  ]
})
