import validationRules from '@/mixins/ValidationRules.js';
import datesMixin from '@/mixins/Dates.js'
import AppError from '@/lib/customExceptions/AppError.js'

export default {
  mixins: [ validationRules, datesMixin ],

  props: {
    apiErrorMessages: AppError
  },

  data: function () {
    return {
      show: false,
      formData: null,
      postData: null,
      remoteErrors: null
    }
  },

  methods: {
    async open(payload) {
      this.show = true
      await this.$refs.form.resetValidation()
      this.formData = payload
        ? JSON.parse(JSON.stringify(payload)) 
        : JSON.parse(JSON.stringify(this.defaultFormData))
      this.clearRemoteValidation()
      // Focus on first element of the form if it is a "new" form
      if (!payload && this._.get(this.$refs, 'form.$children')) {
        for (let i = 0; i < this.$refs.form.$children.length; i++) {
          if (typeof this.$refs.form.$children[i].focus === 'function') {
            this.$nextTick(() => this.$refs.form.$children[i].focus())
            break;
          }

        }
      }

      if (typeof this.postOpen === 'function') {
        return await this.postOpen(payload)
      }
      
    },

    close() {
      this.show = false
    },

    async validate() {
      if (typeof this.preValidate === 'function') {
        await this.preValidate()
      }
      if (this.$refs.form.validate()) {
        this.$emit('save-clicked', this.postData ? this.postData : this.formData)
      }
    }

  },

  created() {
    this.formData = JSON.parse(JSON.stringify(this.defaultFormData))

    // remoteErrors velden MOETEN hier geinitaliseerd worden zodat ze
    // worden getriggered by een change tbv ":error-messages" van de velden.
    // remoteErrors velden zijn gelijk aan de formData velden
    this.remoteErrors = JSON.parse(JSON.stringify(this.defaultFormData))
  }

}