<template>
    <v-form>
      <v-container>
        <v-layout wrap>
          <v-flex xs12>
            <v-card
            >
              <v-toolbar
                dense
                flat
                float
                v-bind:class="{ impersonated : isImpersonated }"
              >
                <v-toolbar-title 
                  v-bind:class="{ impersonated : isImpersonated, 'grey--text' : !isImpersonated }"
                  class="body-2"
                >
                  {{ isImpersonated ? '[Impersonated]' : '' }} {{ $t('BASE.GLOBAL.WELCOME') }}
                </v-toolbar-title>
              </v-toolbar>
              <v-card
                class="pa-2"
                flat
              >
                <v-list two-line>
                  <v-list-tile>
                    <v-list-tile-avatar>
                      <v-icon 
                        large
                        class="grey lighten-1 white--text"
                      >
                        mdi-account
                      </v-icon>
                    </v-list-tile-avatar>
                    <v-list-tile-content>
                      <v-list-tile-title>{{ userName }}</v-list-tile-title>
                      <v-list-tile-sub-title>{{ customerName }}</v-list-tile-sub-title>
                    </v-list-tile-content>
                  </v-list-tile>
                </v-list>
                <v-layout wrap>
                  <v-flex xs12>
                    <v-btn
                      color="warning"
                      @click="$emit('logout-button-clicked')"
                      small
                    >
                      {{ $t('BUTTON.LOGOUT') }}
                    </v-btn>
                    <v-btn
                      color="warning"
                      @click="$emit('change-password-button-clicked')"
                      small
                    >
                      {{ $t('BUTTON.CHANGE_PASSWORD') }}
                    </v-btn>
                  </v-flex>
                </v-layout>
                <v-layout 
                  wrap
                  v-if="isImpersonated"
                >
                  <v-flex xs12>
                    <v-btn
                      color="error"
                      @click="$emit('return-to-user-button-clicked')"
                      small
                    >
                      {{ $t('BUTTON.RETURN_TO_USER') }}
                    </v-btn>
                  </v-flex>
                </v-layout>
              </v-card>
            </v-card>
          </v-flex>
        </v-layout>
      </v-container>
    </v-form>
</template>

<script>
  export default {
    props: {
      isImpersonated: {
        type: Boolean,
        default: false
      },
      userName: null,
      customerName: null
    },
  }
</script>

<style scoped>
.impersonated {
  background: red;
  color: black;
}
</style>
