import { Type, IfExists } from 'hello-type' // Check datatypes from API stream

export const idAsNumber = new Type({
  id: Number,
})

export const idAsString = new Type({
  id: String,
})

export const autoDnsAccountList = new Type({
  id: String,
  name: String,
  customer_name: IfExists(String),
  dnsTemplate_name: IfExists(String)
})

export const licenseAssignment = new Type({
  id: Number,
  startDate: String,
  endDate: String,
  memo: String,
  equipment: {
    id: String,
    name: String
  },
  license: {
    id: Number,
    name: String,
    active: Boolean
  }  
})

export const autoDnsAccount = new Type({
  id: String,
  name: String,
  dateCreated: String,
  dateLastModified: String,
  isArchive: Boolean,
  customer: {
    id: String,
    name: String
  },
  dnsTemplate: {
    id: String,
    name: String
  }
})

export const autoDnsActionList = new Type({
  id: Number,
  name: String , 
  changeStatus: IfExists(Boolean),
  code: IfExists(String),
})

export const autoDnsAction = new Type({
  id: Number,
  name: String,
  onSuccessStatus: {
    id: Number,
    name: String,
  },
  sendEmailOnError: Boolean,
  code: String,
  setDeletionDate: Boolean,
  onErrorStatus: {
    id: Number,
    name: String,
  },
  setCreationDate: Boolean,
  changeStatus: Boolean,
})

export const autoDnsHistoryList = new Type({
  id: Number,
  name: String,
  type: String,
  code: String,
  action: String,
  status: String,
  ownerUser: String,
  dateCreated: String,
})