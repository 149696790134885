import { Enum, Type } from 'hello-type' // Check datatypes from API stream

export const auditEntryList = new Type({
  id: String,
  action: String,
  entityName: String,
  logDate: String,
  message: String,
  userName: String,
})

export const licenseList = new Type({
  id: 0,
  name: String,
  code: String,
  supplier: String,
  datePurchased: String,
  active: Boolean,
  licenseCategory_id: Number,
  licenseCategory_name: String,
  maxLicenseCount: Number
})

export const loggedErrorList = new Type({
  id: String,
  message: String,
  logDate: String,
  category: String,
  severity: Enum('FATAL', 'ERROR', 'WARN', 'INFO', 'DEBUG')
})

export const rabbitTransactionList = new Type({
  id: String,
  routingKey: String,
  action: String,
  objectType: String,
  objectKeyValue: String,
  startTime: String,
  endTime: String,
  status_name: String
})
