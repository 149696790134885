import { Enum, Type, IfExists } from 'hello-type' // Check datatypes from API stream

export const idAsString = new Type({
  id: String
})

export const idArray = new Type({
  idRange: Array()
})

export const allConfigsList = new Type({
  id: String,
  name: String,
  tenant_name: String,
  vDom_name: String,
  vrf_name: String,
  type: Enum('simple', 'advanced'),
  meta: {
    canEdit: Boolean,
    canDelete: Boolean
  }
})

export const advancedConfig = new Type({
  id: String,
  name: String,
  vDom: {
    id: String,
    name: String
  },
  ipv6Nibble: String,
  prefix: Number,
  tenant: {
    id: String,
    name: String
  },
  vrf: {
    id: String,
    name: String
  },
  shortName: String,
  rangeUplinkIp: String,
  vLanUplink: Number,
  type: 'advanced',
  ospfNumber: Number,
  edgeNumber: Number,
  rangeIpv4: String,
  subnetIpv4: String  
})

export const simpleConfig = new Type({
  id: String,
  name: String,
  shortName: String,
  vrf: {
    id: String,
    name: String
  },
  tenant: {
    id: String,
    name: String
  },
  vDom: {
    id: String,
    name: String
  },
  type: 'simple',
})

export const subnetList = new Type(
  {
    id: String,
    name: IfExists(String),
    meta: IfExists({
      canDelete: Boolean,
      canEdit: Boolean
    }),  
    network: IfExists(String),
    gateway: IfExists(Enum(String, null)),
    isAdvanced: IfExists(Boolean),
    type: IfExists(Enum('private', 'public', 'ipv6')),
    networkConfig_id: IfExists(String),
    networkConfig_name: IfExists(String),
    fullName: String
  }
)

export const subnet = new Type({
  id: String,
  name: String,
  fullName: String,
  gateway: Enum(null, String),
  type: Enum('private', 'public', 'ipv6'),
  network: String,
  isAdvanced: Boolean,
  networkConfig: {
    id: String,
    name: String
  }
})

export const privateInterface = new Type({
  id: String,
  ipAsString: String,
  ipAsNumber: Number,
  subnet: {
    id: String,
    name: String,
    fullName: String
  },
  equipment: {
    id: String,
    name: String
  },
  interface: String  
})

export const publicInterface = new Type({
  id: String,
  ipAsString: String,
  ipAsNumber: Number,
  ipPool: {
    id: String,
    name: String
  },
  equipment: {
    id: String,
    name: String
  },
  subnet: {
    id: String,
    name: String,
    fullName: String
  },
  interface: String,
  reserved: Boolean
})

export const equipmentInterfaceList = new Type({
  meta: {
    canDelete: Boolean,
    canEdit: Boolean
  },
  id: String,
  interface: String,
  ipAsString: String,
  gateway: String,
  network: String,
  type: Enum('public', 'private', 'nat', 'nat64', 'ipv6')
})

export const ipPoolList = new Type({
  meta: IfExists({
    canEdit: Boolean,
    canDelete: Boolean
  }),
  id: String,
  name: String,
  dateCreated: IfExists(String),
  dateLastModified: IfExists(String),
  isArchive: IfExists(Boolean),
  customer_id: IfExists(String),
  customer_name: IfExists(String),
})

export const ipPool = new Type({
  id: String,
  name: String,
  dateCreated: String,
  dateLastModified: String,
  isArchive: Boolean,
  customer: {
    id: String,
    name: String
  }
})

export const ipv6Interface = new Type({
  id: String,
  ipAsString: String,
  subnet: {
    id: String,
    name: String,
    fullName: String
  },
  equipment: {
    id: String,
    name: String
  },
  interface: String
})

export const natInterface = new Type({
  id: String,
  ipAsString: String,
  ipAsNumber: Number,
  ipPool: {
    id: String,
    name: String
  },
  equipment: {
    id: String,
    name: String
  },
  subnet: Enum(null, {
    id: String,
    name: String,
    fullName: String
  }),
  interface: String,
  reserved: Boolean
})

export const ipv6NumberList = new Type({
  id: String,
  ipAsString: String,
  interface: String,
  equipment_id: String,
  equipment_name: String,
  subnet_id: String,
  subnet_name: String
})

export const privateIpv4NumberList = new Type({
  id: String,
  ipAsString: String,
  ipAsNumber: Number,
  interface: String,
  equipment_id: String,
  equipment_name: String,
  subnet_id: String,
  subnet_name: String  
})

export const privateIpv4NumberAvailableList = new Type({
  id: String,
  ipAsString: String
})

export const publicIpv4NumberList = new Type({
  id: String,
  ipAsString: String,
  ipAsNumber: Number,
  interface: Enum(null, String),
  reserved: Boolean,
  equipment_id: Enum(null, String),
  equipment_name: Enum(null, String),
  equipmentType_id: Enum(null, Number),
  subnet_id: Enum(null, String),
  subnet_name: Enum(null, String),
  ipPool_id: Enum(null, String),
  ipPool_name: Enum(null, String)
})

export const publicIpv4Number = new Type({
  id: String,
  ipAsString: String,
  ipAsNumber: Number,
  ipPool: Enum(null, {
    id: String,
    name: String
  }),
  equipment: Enum(null, {
    id: String,
    name: String
  }),
  subnet: Enum(null, {
    id: String,
    name: String,
    fullName: String
  }),
  interface: Enum(null, String),
  reserved: Boolean
})

export const publicIpv4NumberAvailableList = new Type({
  id: String,
  ipAsString: String
})

export const tenantList = new Type({
  id: String,
  name: String  
})

export const vdomList = new Type({
  id: String,
  name: String  
})

export const vrfList = new Type({
  id: String,
  name: String  
})



