import { Type, Enum } from 'hello-type' // Check datatypes from API stream

export const idAsNumber = new Type({
  id: Number
})

export const idAsString = new Type({
  id: String
})

/** 
 * Definition of Mailbox type for the expected API properties which will be checked on API call
 * @constant
*/
export const mailBoxList = new Type({
  id: Number,
  domain_name: String,
  fullName: String
})

/** 
 * Definition of Mailbox type for the expected API properties which will be checked on API call
 * @constant
*/
export const mailBox = new Type({
  active: Number,
  dateCreated: String,
  dateLastModified: String,
  domainName: String,
  fullName: String,
  id: Number,
  shortName: String,
})

/** 
 * Definition of Alias type for the expected API properties which will be checked on API call
 * @constant
*/
export const mailAliasList = new Type({
  address: String,
  domain_name: String,
  goTo: String,
  id: Number,
})

/** 
 * Definition of Alias type for the expected API properties which will be checked on API call
 * @constant
*/
export const mailAlias = new Type({
  active: Number,
  address: String,
  alias: String,
  dateCreated: String,
  dateLastModified: String,
  domainName: String,
  goTo: String,
  id: Number,
  type: Enum('R', 'C'),
})

/** 
 * Definition of Mailsettings for the expected API properties which will be checked on API call
 * @constant
*/
export const mailSettings = new Type({
  active: Number,
  aliasCount: Number,
  boxCount: Number,
  dateLastModified: String,
  dateCreated: String,
  description: String,
  id: Number,
  maxMailBoxes: Number,
  name: String,
})

/** 
 * Definition of Catchall settings for the expected API properties which will be checked on API call
 * @constant
*/
export const mailCatchAll = new Type({
  active: Number,
  address: String,
  alias: String,
  dateLastModified: String,
  dateCreated: String,
  domainName: String,
  goTo: String,
  id: Number,
  type: Enum('R', 'C'),
})

export const mailBoxReportList = new Type ({
  currentMailboxCount: Number,
  customer: String,
  domain: String,
  id: Number,
  maxMailboxCount: Number,
  saldo: Number
})