import i18n from '@/lib/i18n';
import { RepositoryFactory } from '@/api/RepositoryFactory'
const MenuRepository = RepositoryFactory.get('menu')

export default {
  namespaced: true,

  state: function () {
    return {
      mainMenu: [],
      managementMenu: [],
      userMenu: [],
      searchItemList: [],
      breadCrumbs: [],
      breadCrumbPath: undefined,
      currentBreadCrumb: '',
    }
  },

  getters: {
    getBreadCrumbs: state => {
      return state.breadCrumbs
    },

    getCurrentBreadCrumb: state => {
      return (state.currentBreadCrumb ? state.currentBreadCrumb.breadCrumb : [])
    },

    getMenu: (state, getters, rootState, rootGetters) => {
      function filterNodes(nodes) {
        for (let i=0; i < nodes.length; i++) {
          if (nodes[i].nodes) {
            nodes[i].nodes = filterNodes(nodes[i].nodes)
          }
        }
        // First filter all menu-items where this user does not have access to
        let tmpResult = nodes.filter(element => !element.apiEndpoint ||
          rootGetters['security/isAuthorized'](
            { 
              method: element.apiEndpoint.method, 
              endpoint: element.apiEndpoint.endpoint
            }
          )
        )
        // Return Menu where items also present in Main menu are filtered
        tmpResult = tmpResult.filter(element => !state.mainMenu.find(item => item.routeTo == element.routeTo))

        let returnNodes = []
        for (let i=0; i < tmpResult.length; i++) {
          if (!tmpResult[i].nodes || tmpResult[i].nodes.length > 0) {
            returnNodes.push(tmpResult[i])
          }
        }
        return returnNodes
      }

      let menu = []
      if (state.managementMenu) {
        for (let i = 0; i < state.managementMenu.length; i++) {
          let obj = JSON.parse(JSON.stringify(state.managementMenu[i]))
          obj.nodes = filterNodes(obj.nodes)
          if (obj.nodes.length > 0) {
            menu.push(obj)
          }
        }
      }
      return menu
    },

    getUserMenu: (state) => {
      return state.userMenu;
    },

    getMainMenu: (state, getters, rootState, rootGetters) => {
      let mainMenu = state.mainMenu.filter(
        item => !item.apiEndpoint ||
          rootGetters['security/isAuthorized'](
            { 
              method: item.apiEndpoint.method, 
              endpoint: item.apiEndpoint.endpoint 
            }
          )
      )

      mainMenu.forEach(element => {
        element.breadCrumbInfo = {
          url: element.routeTo,
          breadCrumb: [
            {
              text: i18n.t('MENU.MAIN.'+element.label),
              disabled: false,
              to: {
                path: element.routeTo
              },
              exact: true
            }
          ]
        }
      })

      return mainMenu
    },

    getSearchItemList: state => {
      return state.searchItemList
    }
  },

  mutations: {
    setBreadCrumb (state, payload) {
      state.breadCrumbs.push(payload)
    },

    setBreadCrumbPath (state, payload) {
      state.breadCrumbPath = payload
      let mainMenuBreadCrumb = state.mainMenu.find(element => element.routeTo === state.breadCrumbPath)
      state.currentBreadCrumb = mainMenuBreadCrumb ? mainMenuBreadCrumb.breadCrumbInfo : state.breadCrumbs.find(element => element.routeTo === state.breadCrumbPath)
    },

    setManagementMenu (state, payload) {
      state.managementMenu = payload
    },

    setUserMenu (state, payload) {
      state.userMenu = payload
    },

    setMainMenu (state, payload) {
      state.mainMenu = payload
    },

    setSearchItemList (state, payload) {
      state.searchItemList = payload
    }
  },

  actions: {
    setBreadCrumbPath (context, payload) {
      context.commit('setBreadCrumbPath', payload)
    },

    // Set items
    setManagementMenu (context, payload) {
      var fillBreadCrumbs = function (data, breadCrumb = []) {
        for (var i = 0; i < data.length; i++) {
          if (typeof data[i].label !== 'undefined') {
            breadCrumb.push({
              text: data[i].label,
              disabled: data[i].routeTo ? false : true,
              to: { path: `${data[i].routeTo}` },
              exact: true,
            })
            if (data[i].routeTo) {
              context.commit('setBreadCrumb', { routeTo: data[i].routeTo, breadCrumb: breadCrumb.slice() })
            }
          }
          if (typeof data[i].nodes !== 'undefined') {
            data[i].nodes = fillBreadCrumbs(data[i].nodes.slice(), breadCrumb.slice())
          }
          breadCrumb.pop()
        }
        return data
      }
      payload.breadCrumb = {
        text: payload.label,
        disabled: false,
        to: { path: `${payload.routeTo}` },
        exact: true,
      }
      context.commit('setManagementMenu', fillBreadCrumbs(payload.nodes, [payload.breadCrumb]))
    },

    // Set items
    setUserMenu (context, payload) {
      var fillBreadCrumbs = function (data, breadCrumb = []) {
        for (var i = 0; i < data.length; i++) {
          if (typeof data[i].label !== 'undefined') {
            breadCrumb.push({
              text: data[i].label,
              disabled: data[i].routeTo ? false : true,
              to: { path: `${data[i].routeTo}` },
              exact: true,
            })
            if (data[i].routeTo) {
              context.commit('setBreadCrumb', { routeTo: data[i].routeTo, breadCrumb: breadCrumb.slice() })
            }
          }
          if (typeof data[i].nodes !== 'undefined') {
            data[i].nodes = fillBreadCrumbs(data[i].nodes.slice(), breadCrumb.slice())
          }
          breadCrumb.pop()
        }
        return data
      }
      payload.breadCrumb = {
        text: payload.label,
        disabled: false,
        to: { path: `${payload.routeTo}` },
        exact: true,
      }
      context.commit('setUserMenu', fillBreadCrumbs(payload.nodes, [payload.breadCrumb]))
    },

    setMainMenu (context, payload) {
      context.commit('setMainMenu', payload)
    },

    setSearchItemList (context, payload) {
      context.commit('setSearchItemList', payload)
    },

    // Load menu items via API
    async loadMenu (context) {
      try {
        const managementMenu = await MenuRepository.getManagementMenu()
        context.commit('setBreadCrumb', { url: '/Main/Dashboard', breadCrumb: [{ 
          disabled: false, 
          text: 'Home',
          to: { path: '/Main/Dashboard' }, 
          exact: true,
        }] })
        context.dispatch('setManagementMenu', managementMenu)

        const mainMenu = await MenuRepository.getMainMenu()
        context.dispatch('setMainMenu', mainMenu)

        const userMenu = await MenuRepository.getUserMenu()
        context.dispatch('setUserMenu', userMenu)
      }
      catch(e) {
        context.dispatch(`applicationLog/log`, `Failed loading menu items: ${e}`, {root:true})
      }
    },

    // Load Data via API
    async loadSearchItemList (context) {
      try {
        const data = await MenuRepository.getSearchItemList()
        context.dispatch('setSearchItemList', data)
      }
      catch(e) {
        context.dispatch(`applicationLog/log`, `Failed loading searchItemList: ${e}`, {root:true})
      }
    },

  }
}
