import moment from 'moment'
import 'moment/locale/nl'

export default {
  methods: {
    formatDate(aDate, aFormat) {

      moment.locale(localStorage.getItem('language'))
      return moment(aDate).isValid() && aDate !== undefined ? moment(aDate).format(aFormat) : ''
    },

    parseDate(aDate, inputFormat, outputFormat) {
      moment.locale(localStorage.getItem('language'))
      return aDate ? moment(aDate, inputFormat).format(outputFormat) : null
    },

  }
}
