<template>
  <div>
    <v-toolbar
      app
      v-bind:class="stateIsImpersonated ? 'impersonated' : 'headerBar'"
      flat
    >
      <v-toolbar-title class="pr-5 pt-2">
          <img src="@/assets/logo_kaal.png" width="91px" height="44px">
      </v-toolbar-title>

      <base-search
        class="pt-2"
        :search-item-list="stateGetSearchItemList"
        @selected-search-item="routeToSelectedSearchItem($event)"
      >
      </base-search>

      <v-toolbar-title class="pr-5 white--text font-weight-bold"
        v-if="stateIsImpersonated"
      >
          IMPERSONATED
      </v-toolbar-title>
      <v-toolbar-items>
        <v-menu offset-y>
          <template v-slot:activator="{ on }">
            <v-btn class="text-none"
              left flat
              v-on="on"
            >
              <v-icon large class="pr-1">mdi-account-circle</v-icon>
              <v-layout column>
                <v-flex>
                  <div class="text-xs-left font-weight-bold">{{ stateGetUserName }}</div>
                </v-flex>
                <v-flex>
                  <div class="text-xs-left caption">{{ stateGetCustomerName }}</div>
                </v-flex>
              </v-layout>
              <v-icon class="pr-1">mdi-menu-down</v-icon>
            </v-btn>
          </template>
          <v-list class="cursor-pointer" 
            v-if="stateIsImpersonated"
          >
            <v-list-tile>
              <v-list-tile-title
                @click="returnToUser()"
              >
                {{ $t('BUTTON.RETURN_TO_USER') }}
              </v-list-tile-title>
            </v-list-tile>
          </v-list>
          <v-list class="cursor-pointer">
            <v-list-tile v-for="(item,index) in stateGetUserMenuItems" :key="index">
              <v-list-tile-title
                @click="routeTo(item.routeTo)"
              >
                {{ item.label }}
              </v-list-tile-title>
            </v-list-tile>
          </v-list>
        </v-menu>
      </v-toolbar-items>
    </v-toolbar>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import * as RoutingTypes from '@/router/routing-types.js'
import * as Constants from '@/lib/constants.js'

//const moduleName = 'TheHeader'

export default {
  data () {
    return {
      apiErrorMessages: null
    }
  },

  // Computed properties
  computed: {
    ...mapGetters({
      'stateGetCustomerName': `security/getCustomerName`,
      'stateIsImpersonated': `security/isImpersonated`,
      'stateGetSearchItemList': 'menu/getSearchItemList',
      'stateGetUserName': `security/getUserName`,
      'stateGetUserMenuItems': `menu/getUserMenu`,
    }),
  },

  // Methods
  methods: {
    ...mapActions({
      'stateClearCache': `clearCache`,
      'stateSecurityClearCache': `security/clearCache`,
      'stateReturnToUser': `security/returnToUser`,
      'stateLoadAllowedEndpointList': 'security/loadAllowedEndpointList',
      'stateLoadUserInfo': 'security/loadUserInfo',
      'stateLoadSearchItemList': 'menu/loadSearchItemList',
      'stateLoadMenuItems': `menu/loadMenu`,
    }),

    async routeTo (endPoint) {
      this.$router.push(endPoint)
        .catch(e => { this.$Helpers.handleRoutingErrors(e) })
    },


    async returnToUser () {
      await this.stateReturnToUser()
      await this.stateClearCache()
      await this.stateSecurityClearCache()
      this.stateLoadAllowedEndpointList()
      this.stateLoadMenuItems()
      this.$router.push(`${RoutingTypes.HOME_PAGE}`)
        .catch(e => { this.$Helpers.handleRoutingErrors(e) })
    },

    routeToSelectedSearchItem(selectedSearchItem) {
      switch (this._.get(selectedSearchItem, 'type')) {
        case Constants.CATEGORY_VIRTUAL_MACHINE:
          this.$router.push(`${RoutingTypes.VIRTUAL_MACHINES_DETAILS}/${this._.get(selectedSearchItem, 'resourceId')}`)
            .catch(e => { this.$Helpers.handleRoutingErrors(e) })
          break;
        case Constants.CATEGORY_SERVER:
          this.$router.push(`${RoutingTypes.SERVERS_DETAILS}/${this._.get(selectedSearchItem, 'resourceId')}`)
            .catch(e => { this.$Helpers.handleRoutingErrors(e) })
          break;
        case Constants.CATEGORY_NETWORK_SWITCH:
          this.$router.push(`${RoutingTypes.NETWORK_SWITCHES_DETAILS}/${this._.get(selectedSearchItem, 'resourceId')}`)
            .catch(e => { this.$Helpers.handleRoutingErrors(e) })
          break;
        case Constants.CATEGORY_DOMAIN:
          this.$router.push(`${RoutingTypes.DOMAINS_DETAILS}/${this._.get(selectedSearchItem, 'name')}`)
            .catch(e => { this.$Helpers.handleRoutingErrors(e) })
          break;
        case Constants.CATEGORY_NETWORK_CONFIG_SIMPLE:
          this.$router.push(`${RoutingTypes.NETWORK_CONFIGS_SUBNETS}/${this._.get(selectedSearchItem, 'resourceId')}/simple`)
            .catch(e => { this.$Helpers.handleRoutingErrors(e) })
          break;
        case Constants.CATEGORY_NETWORK_CONFIG_ADVANCED:
          this.$router.push(`${RoutingTypes.NETWORK_CONFIGS_SUBNETS}/${this._.get(selectedSearchItem, 'resourceId')}/advanced`)
            .catch(e => { this.$Helpers.handleRoutingErrors(e) })
          break;
        default:
            this.$Logging.error(`routeToSelectedSearchItem(): invalid routing type ${this._.get(selectedSearchItem, 'type')}`)
      }
    }

  },

  async mounted () {
    await this.stateLoadUserInfo()
    await this.stateLoadSearchItemList()
  }

}
</script>

<style scoped>
.impersonated {
  background: red;
  color: black;
}
</style>
