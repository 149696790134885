import Repository from '../Repository'
import Logger from '@/lib/Logger.js'
import Helpers from '@/lib/Helpers.js'
import * as DataTypes from './DnsRepositoryDataTypes'
import * as ApiTypes from '@/api/api-types'

const moduleName = 'DnsRepository'

/**
 * A module for handling API calls for domains
 * @module DnsRepository
 */
export default {

  /**
   * Get DNS records of a specified domain
   *
   * @param {String} domainName
   * @returns {Array} Array of array of name/value pairs for record properties
   */
  async getDnsRecordList (domainName) {
    Logger.info(`${moduleName}.getDnsRecordList(domainName): called with domainName ${domainName}`)
    return await Repository.get(
      Helpers.substituteApiVars(ApiTypes.GET_DNS_DOMAIN_NAME_RECORDS, { domainName: domainName }),
      DataTypes.dnsRecordList,
      `${moduleName}.getDnsRecordList()`
    )
  },

  /**
   * Update a DNS record
   * 
   * @alias module: DnsRepository
   * @param {Array} payload Array of name/value pairs for domain properties
   */
  async put (domainName, payload, validationField = null) {
    let urlParams = validationField ? `?validateOnly=true&validateFields=${validationField}` : ''
    return await Repository.put(
      Helpers.substituteApiVars(`${ApiTypes.PUT_DNS_DOMAIN_NAME_RECORDS_ID}${urlParams}`, { domainName: domainName, id: payload.id }),
      payload,
      null,
      `${moduleName}.put()`
    )
  },

  /**
   * Post a new DNS record
   * 
   * @alias module: DnsRepository
   * @param {Array} payload Array of name/value pairs for domain properties
   */
  async post (domainName, payload, validationField = null) {
    let urlParams = validationField ? `?validateOnly=true&validateFields=${validationField}` : ''
    return await Repository.post(
      Helpers.substituteApiVars(`${ApiTypes.POST_DNS_DOMAIN_NAME_RECORDS}${urlParams}`, { domainName: domainName }),
      payload,
      DataTypes.idAsNumber,
      `${moduleName}.post()`
    )
  },

  /**
   * Delete a DNS record
   * 
   * @alias module: DnsRepository
   * @param {Number} dnsRecordId Id of the DNS record
   */
  async delete (domainName, dnsRecordId) {
    return await Repository.delete(
      Helpers.substituteApiVars(ApiTypes.DELETE_DNS_DOMAIN_NAME_RECORDS_ID, { domainName: domainName, id: dnsRecordId }),
      `${moduleName}.delete()`
    )
  },

  /**
   * Get a specific DNS record
   *
   * @alias module: DnsRepository
   * @param {String} domainName
   * @param {Number} recordId
   * @returns {Array} Array of name/value pairs for record properties
   */
  async getRecord (domainName, recordId) {
    Logger.info(`${moduleName}.getRecord(domainName, recordId): called with domainName ${domainName} and recordId ${recordId}`)
    return await Repository.get(
      Helpers.substituteApiVars(ApiTypes.GET_DNS_DOMAIN_NAME_RECORDS_ID, { domainName: domainName, id: recordId }),
      DataTypes.dnsRecord,
      `${moduleName}.getRecord()`
    )
  },

  /**
   * Get list of possible TTL values
   *
   * @alias module: DnsRepository
   * @returns {Array} Array name/value pairs for TTL properties
   */
  async getTtl () {
    Logger.info(`${moduleName}.getTtl(): called`)
    return await Repository.get(
      ApiTypes.GET_DNS_TTL_VALUES,
      DataTypes.dnsTtlList,
      `${moduleName}.getTtl()`
    )
  },

  /**
   * Get list of possible records types (A, CNAME, MX etc)
   *
   * @alias module: DnsRepository
   * @returns {Array} Array name/value pairs for record type properties
   */
  async getTypes () {
    Logger.info(`${moduleName}.getTypes(): called`)
    return await Repository.get(
      ApiTypes.GET_DNS_RECORDS_TYPES,
      DataTypes.dnsTypeList,
      `${moduleName}.getTypes()`
    )
  },
}
