var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    { staticClass: "pa-4" },
    [
      _c(
        "v-layout",
        {
          attrs: {
            row: "",
            wrap: "",
            "justify-start": "",
            "align-baseline": ""
          }
        },
        [
          _c(
            "v-flex",
            { staticClass: "text-xs-left", attrs: { xs12: "", lg4: "" } },
            [
              _vm.enableAddButton && _vm.addButtonList
                ? [
                    _c(
                      "v-menu",
                      {
                        staticStyle: { display: "inline" },
                        attrs: { bottom: "", "offset-y": "" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "activator",
                              fn: function(ref) {
                                var on = ref.on
                                return [
                                  _c(
                                    "v-btn",
                                    _vm._g(
                                      {
                                        staticClass: "white--text",
                                        attrs: { color: "newButton" }
                                      },
                                      on
                                    ),
                                    [
                                      _c("v-icon", [
                                        _vm._v("add_circle_outline")
                                      ]),
                                      _vm._v(
                                        " " + _vm._s(_vm.$t("BUTTON.NEW")) + " "
                                      )
                                    ],
                                    1
                                  )
                                ]
                              }
                            }
                          ],
                          null,
                          false,
                          3199833827
                        )
                      },
                      [
                        _c(
                          "v-list",
                          _vm._l(_vm.addButtonList, function(item, i) {
                            return _c(
                              "v-list-tile",
                              {
                                key: i,
                                on: {
                                  click: function($event) {
                                    return _vm.$emit("add-clicked", item.id)
                                  }
                                }
                              },
                              [
                                _c("v-list-tile-title", [
                                  _vm._v(_vm._s(item.name))
                                ])
                              ],
                              1
                            )
                          }),
                          1
                        )
                      ],
                      1
                    )
                  ]
                : _vm.enableAddButton
                ? [
                    _c(
                      "v-btn",
                      {
                        staticClass: "white--text",
                        attrs: { color: "newButton" },
                        on: {
                          click: function($event) {
                            $event.stopPropagation()
                            return _vm.$emit("add-clicked")
                          }
                        }
                      },
                      [
                        _c("v-icon", [_vm._v("add_circle_outline")]),
                        _vm._v(" " + _vm._s(_vm.$t("BUTTON.NEW")) + " ")
                      ],
                      1
                    )
                  ]
                : _vm._e(),
              _vm._t("extra-buttons"),
              _vm.exportFilenamePrefix
                ? _c(
                    "v-btn",
                    {
                      staticClass: "white--text",
                      attrs: {
                        color: "exportButton",
                        disabled:
                          !_vm.$refs.refName ||
                          !_vm.$refs.refName.filteredItems ||
                          !_vm.$refs.refName.filteredItems.length > 0
                      },
                      on: {
                        click: function($event) {
                          return _vm.exportData()
                        }
                      }
                    },
                    [
                      _c("v-icon", [_vm._v("mdi-file-excel")]),
                      _vm._v(" " + _vm._s(_vm.$t("BUTTON.TO_EXCEL")) + " ")
                    ],
                    1
                  )
                : _vm._e()
            ],
            2
          ),
          _vm.enableSearch
            ? _c(
                "v-flex",
                { staticClass: "px-3", attrs: { xs12: "", lg4: "" } },
                [
                  _c("v-text-field", {
                    attrs: {
                      "append-icon": "search",
                      label: _vm.$t("BASE.SEARCH"),
                      "single-line": "",
                      "hide-details": "",
                      flat: ""
                    },
                    nativeOn: {
                      keyup: function($event) {
                        return _vm.debounceInput($event)
                      }
                    },
                    model: {
                      value: _vm.searchInput,
                      callback: function($$v) {
                        _vm.searchInput = $$v
                      },
                      expression: "searchInput"
                    }
                  })
                ],
                1
              )
            : _vm._e(),
          _c(
            "v-flex",
            { staticClass: "px-3", attrs: { xs12: "", lg2: "" } },
            [
              _vm._t("extra-right"),
              _vm.multiSearchField && _vm.companyList.length > 1
                ? _c("v-autocomplete", {
                    attrs: {
                      items: _vm.companyList,
                      "item-text": "customer",
                      "item-value": "customer",
                      label: _vm.multiSearchLabel,
                      "hide-no-data": "",
                      chips: "",
                      "deletable-chips": "",
                      "small-chips": "",
                      multiple: "",
                      dense: "",
                      flat: "",
                      filter: _vm.autocompleteSearch
                    },
                    model: {
                      value: _vm.multiSearchItems,
                      callback: function($$v) {
                        _vm.multiSearchItems = $$v
                      },
                      expression: "multiSearchItems"
                    }
                  })
                : _vm._e()
            ],
            2
          ),
          _c(
            "v-flex",
            { staticClass: "text-xs-right px-3", attrs: { xs12: "", lg2: "" } },
            [
              _vm.archiveSwitchField
                ? _c("v-switch", {
                    attrs: { label: _vm.archiveSwitchLabel },
                    model: {
                      value: _vm.archiveSwitch,
                      callback: function($$v) {
                        _vm.archiveSwitch = $$v
                      },
                      expression: "archiveSwitch"
                    }
                  })
                : _vm._e()
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-layout",
        {
          attrs: {
            row: "",
            wrap: "",
            "justify-space-between": "",
            "align-baseline": ""
          }
        },
        [_c("v-flex", { staticClass: "py-3", attrs: { xs12: "" } })],
        1
      ),
      _c(
        "v-layout",
        {
          attrs: {
            row: "",
            wrap: "",
            "justify-space-between": "",
            "align-baseline": ""
          }
        },
        [
          _c(
            "v-flex",
            { attrs: { xs12: "" } },
            [
              _c(
                "v-data-table",
                {
                  ref: "refName",
                  attrs: {
                    headers: _vm.headers,
                    items: _vm.filteredMultiSearchItems,
                    pagination: _vm.paginationSync,
                    search: _vm.search,
                    "custom-filter": _vm.dataTableSearch,
                    "rows-per-page-items": [10, 25, 100, 250],
                    "must-sort": ""
                  },
                  on: {
                    "update:pagination": [
                      function($event) {
                        _vm.paginationSync = $event
                      },
                      function($event) {
                        return _vm.updatePagination($event)
                      }
                    ]
                  },
                  scopedSlots: _vm._u(
                    [
                      _vm._l(Object.keys(_vm.$scopedSlots), function(slot) {
                        return {
                          key: slot,
                          fn: function(scope) {
                            return [_vm._t(slot, null, null, scope)]
                          }
                        }
                      })
                    ],
                    null,
                    true
                  )
                },
                [
                  _vm._l(Object.keys(_vm.$slots), function(slot) {
                    return _vm._t(slot, null, { slot: slot })
                  })
                ],
                2
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }