import Repository from '../Repository'
import Logger from '@/lib/Logger.js'
import Helpers from '@/lib/Helpers.js'
import * as DataTypes from './AutoDnsRepositoryDataTypes'
import * as ApiTypes from '@/api/api-types'

const moduleName = 'AutoDnsRepository'

/**
 * A module for handling API calls for AutoDns
 * @module AutoDnsRepository
 */
export default {

  /**
   * List AutoDns accounts
   *
   * @alias module: AutoDnsRepository
   * @returns {Array} Array of objects
   */
  async getAutoDnsAccountList () {
    Logger.info(`${moduleName}.getAutoDnsAccountList(): called`)
    return await Repository.get(
      ApiTypes.GET_AUTO_DNS_ACCOUNTS,
      DataTypes.autoDnsAccountList,
      `${moduleName}.getAutoDnsAccountList()`
    )
  },

  /**
   * Create AutoDns account
   * 
   * @alias module: AutoDnsRepository
   * @param {Object} payload
   * @returns {Object}
   */
  async postAutoDnsAccount (payload) {
    return await Repository.post(
      ApiTypes.POST_AUTO_DNS_ACCOUNTS,
      payload,
      DataTypes.idAsString,
      `${moduleName}.postAutoDnsAccount()`
    )
  },

  /**
   * Show AutoDns Account
   *
   * @alias module: AutoDnsRepository
   * @param {String} autoDnsAccountId
   * @returns {Object}
   */
  async getAutoDnsAccount (autoDnsAccountId) {
    Logger.info(`${moduleName}.getAutoDnsAccount(autoDnsAccountId): called with autoDnsAccountId ${autoDnsAccountId}`)
    return await Repository.get(
      Helpers.substituteApiVars(ApiTypes.GET_AUTO_DNS_ACCOUNTS_ID, { id: autoDnsAccountId }),
      DataTypes.autoDnsAccount,
      `${moduleName}.getAutoDnsAccount()`
    )
  },

  /**
   * Update AutoDns Account
   * 
   * @alias module: AutoDnsRepository
   * @param {Object} payload
   * @returns {Object}
   */
  async putAutoDnsAccount (payload) {
    return await Repository.put(
      Helpers.substituteApiVars(ApiTypes.PUT_AUTO_DNS_ACCOUNTS_ID, { id: payload.id }),
      payload,
      null,
      `${moduleName}.putAutoDnsAccount()`
    )
  },

  /**
   * Delete AutoDns account
   * 
   * @alias module: AutoDnsRepository
   * @param {String} autoDnsAccountId
   */
  async deleteAutoDnsAccount (autoDnsAccountId) {
    return await Repository.delete(
      Helpers.substituteApiVars(ApiTypes.DELETE_AUTO_DNS_ACCOUNTS_ID, { id: autoDnsAccountId }),
      `${moduleName}.deleteAutoDnsAccount()`
    )
  },

  /**
   * List AutoDns actions
   *
   * @alias module: AutoDnsRepository
   * @returns {Array} Array of objects
   */
  async getAutoDnsActionList () {
    Logger.info(`${moduleName}.getAutoDnsActionList(): called`)
    return await Repository.get(
      ApiTypes.GET_AUTO_DNS_ACTIONS,
      DataTypes.autoDnsActionList,
      `${moduleName}.getAutoDnsActionList()`
    )
  },

  /**
   * Create AutoDns action
   * 
   * @alias module: AutoDnsRepository
   * @param {Object} payload
   * @returns {Object}
   */
  async postAutoDnsAction (payload) {
    return await Repository.post(
      ApiTypes.POST_AUTO_DNS_ACTIONS,
      payload,
      DataTypes.idAsNumber,
      `${moduleName}.postAutoDnsAction()`
    )
  },

  /**
   * Show AutoDns Action
   *
   * @alias module: AutoDnsRepository
   * @param {String} autoDnsActionId
   * @returns {Object}
   */
  async getAutoDnsAction (autoDnsActionId) {
    Logger.info(`${moduleName}.getAutoDnsAction(autoDnsActionId): called with autoDnsActionId ${autoDnsActionId}`)
    return await Repository.get(
      Helpers.substituteApiVars(ApiTypes.GET_AUTO_DNS_ACTIONS_ID, { id: autoDnsActionId }),
      DataTypes.autoDnsAction,
      `${moduleName}.getAutoDnsAction()`
    )
  },

  /**
   * Update AutoDns Action
   * 
   * @alias module: AutoDnsRepository
   * @param {Object} payload
   * @returns {Object}
   */
  async putAutoDnsAction (payload) {
    return await Repository.put(
      Helpers.substituteApiVars(ApiTypes.PUT_AUTO_DNS_ACTIONS_ID, { id: payload.id }),
      payload,
      null,
      `${moduleName}.putAutoDnsAction()`
    )
  },

  /**
   * Delete AutoDns action
   * 
   * @alias module: AutoDnsRepository
   * @param {String} autoDnsActionId
   */
  async deleteAutoDnsAction (autoDnsActionId) {
    return await Repository.delete(
      Helpers.substituteApiVars(ApiTypes.DELETE_AUTO_DNS_ACTIONS_ID, { id: autoDnsActionId }),
      `${moduleName}.deleteAutoDnsAction()`
    )
  },

  /**
   * List AutoDns history
   *
   * @alias module: AutoDnsRepository
   * @param {Number}  year
   * @param {Number}  month
   * @returns {Array} Array of objects
   */
  async getAutoDnsHistoryList (year, month) {
    Logger.info(`${moduleName}.getAutoDnsHistoryList(year, month): called where year=${year} and month=${month}`)
    return await Repository.get(
      `${ApiTypes.GET_AUTO_DNS_HISTORY_ITEMS}?year=${year}&month=${month}`,
      DataTypes.autoDnsHistoryList,
      `${moduleName}.getAutoDnsHistoryList()`
    )
  },
}