var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-layout",
    [
      _c(
        "v-flex",
        { attrs: { xs12: "" } },
        [
          _c(
            "v-form",
            { ref: "searchForm" },
            [
              _c("v-autocomplete", {
                attrs: {
                  flat: "",
                  dense: "",
                  "solo-inverted": "",
                  "prepend-icon": "mdi-magnify",
                  "search-input": _vm.search,
                  items: _vm.searchItemList,
                  label: _vm.$t("BASE.GLOBAL.SEARCH"),
                  "item-text": "name",
                  "item-value": "id",
                  filter: _vm.autocompleteSearch
                },
                on: {
                  "update:searchInput": function($event) {
                    _vm.search = $event
                  },
                  "update:search-input": function($event) {
                    _vm.search = $event
                  },
                  blur: function($event) {
                    return _vm.resetForm()
                  }
                },
                scopedSlots: _vm._u([
                  {
                    key: "selection",
                    fn: function(data) {
                      return [_vm._v(" " + _vm._s(data.item.name) + " ")]
                    }
                  },
                  {
                    key: "item",
                    fn: function(data) {
                      return [
                        [
                          data.item
                            ? _c("v-icon", [
                                _vm._v(
                                  " " + _vm._s(_vm.icons[data.item.type]) + " "
                                )
                              ])
                            : _vm._e(),
                          _vm._v(" " + _vm._s(data.item.name) + " ")
                        ]
                      ]
                    }
                  }
                ]),
                model: {
                  value: _vm.selectedSearchItem,
                  callback: function($$v) {
                    _vm.selectedSearchItem = $$v
                  },
                  expression: "selectedSearchItem"
                }
              })
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }