import Repository from '../Repository'
import Logger from '@/lib/Logger.js'
import Helpers from '@/lib/Helpers.js'
import * as DataTypes from './NetworkRepositoryDataTypes'
import * as ApiTypes from '@/api/api-types'
import { store } from '@/store/store'

const moduleName = 'NetworkRepository'

/**
 * A module for handling API calls for network
 * @module NetworkRepository
 */
export default {

  /**
   * Get list of Network Configs
   * @alias module: NetworkRepository
   * @returns {Array} Array of Objects
   */
  async getAllConfigsList () {
    return await Repository.get(
      ApiTypes.GET_NETWORK_ALL_CONFIGS,
      DataTypes.allConfigsList,
      `${moduleName}.getAllConfigsList()`
    )
  },

  /**
   * Post a new Advanced Network Config
   * 
   * @alias module: NetworkRepository
   * @param {Object} payload
   * @param {String} validationField
   */
  async postAdvancedConfig (payload, validationField = null) {
    let urlParams = validationField ? `?validateOnly=true&validateFields=${validationField}` : ''
    return await Repository.post(
      `${ApiTypes.POST_NETWORK_ADVANCED_CONFIGS}${urlParams}`,
      payload,
      DataTypes.idAsString,
      `${moduleName}.postAdvancedConfig()`
    )
  },

  /**
   * Get an Advanced Config
   * @alias module: NetworkRepository
   * @returns {Object} Object
   */
  async getAdvancedConfig (id) {
    return await Repository.get(
      Helpers.substituteApiVars(ApiTypes.GET_NETWORK_ADVANCED_CONFIGS_ID, { id : id }),
      DataTypes.advancedConfig,
      `${moduleName}.getAdvancedConfig()`
    )
  },

  /**
   * Update an Advanced Config
   * 
   * @alias module: NetworkRepository
   * @param {Object} payload
   * @param {String} validationField
   */
  async putAdvancedConfig (payload, validationField = null) {
    let urlParams = validationField ? `?validateOnly=true&validateFields=${validationField}` : ''
    return await Repository.put(
      Helpers.substituteApiVars(`${ApiTypes.PUT_NETWORK_ADVANCED_CONFIGS_ID}${urlParams}`, { id: payload.id }),
      payload,
      null,
      `${moduleName}.putAdvancedConfig()`
    )
  },

  /**
   * Delete an Advanced Config
   * 
   * @alias module: NetworkRepository
   * @param {String} id
   */
  async deleteAdvancedConfig (id) {
    return await Repository.delete(
      Helpers.substituteApiVars(ApiTypes.DELETE_NETWORK_ADVANCED_CONFIGS_ID, { id: id }),
      `${moduleName}.deleteAdvancedConfig()`
    )
  },

  /**
   * Post a new Simple Network Config
   * 
   * @alias module: NetworkRepository
   * @param {String} validationField
   * @param {Object} payload
   */
  async postSimpleConfig (payload, validationField = null) {
    let urlParams = validationField ? `?validateOnly=true&validateFields=${validationField}` : ''
    return await Repository.post(
      `${ApiTypes.POST_NETWORK_SIMPLE_CONFIGS}${urlParams}`,
      payload,
      DataTypes.idAsString,
      `${moduleName}.postSimpleConfig()`
    )
  },

  /**
   * Get a Simple Config
   * @alias module: NetworkRepository
   * @returns {Object} Object
   */
  async getSimpleConfig (id) {
    return await Repository.get(
      Helpers.substituteApiVars(ApiTypes.GET_NETWORK_SIMPLE_CONFIGS_ID, { id : id }),
      DataTypes.simpleConfig,
      `${moduleName}.getSimpleConfig()`
    )
  },

  /**
   * Update a Simple Config
   * 
   * @alias module: NetworkRepository
   * @param {String} validationField
   * @param {Object} payload
   */
  async putSimpleConfig (payload, validationField = null) {
    let urlParams = validationField ? `?validateOnly=true&validateFields=${validationField}` : ''
    return await Repository.put(
      Helpers.substituteApiVars(`${ApiTypes.PUT_NETWORK_SIMPLE_CONFIGS_ID}${urlParams}`, { id: payload.id }),
      payload,
      null,
      `${moduleName}.putSimpleConfig()`
    )
  },

  /**
   * Delete a Simple Config
   * 
   * @alias module: NetworkRepository
   * @param {String} id
   */
  async deleteSimpleConfig (id) {
    return await Repository.delete(
      Helpers.substituteApiVars(ApiTypes.DELETE_NETWORK_SIMPLE_CONFIGS_ID, { id: id }),
      `${moduleName}.deleteSimpleConfig()`
    )
  },

  /** 
   * Get list of Subnets
   * @alias module: NetworkRepository
   * @param {String} networkConfigId
   * @returns {Array} Array of Objects
   */
  async getSubnetList (networkConfigId) {
    return await Repository.get(
      `${ApiTypes.GET_NETWORK_SUBNETS}?networkConfigId=${networkConfigId}`,
      DataTypes.subnetList,
      `${moduleName}.getSubnetList()`
    )
  },

  /**
   * Post a new Subnet
   * 
   * @alias module: NetworkRepository
   * @param {Object} payload
   * @param {String} validationField
   * @returns {String} id
   */
  async postSubnet (networkConfigId, payload, validationField = null) {
    Logger.info(`${moduleName}.postSubnet(networkConfigId, payload, validationField): called with validationField ${validationField}`)
    let urlParams = validationField ? `?validateOnly=true&validateFields=${validationField}` : ''
    let result = await Repository.post(
      Helpers.substituteApiVars(`${ApiTypes.POST_NETWORK_CONFIG_ID_SUBNETS}${urlParams}`, { networkConfigId: networkConfigId }),
      payload,
      DataTypes.idAsString,
      `${moduleName}.postSubnet()`
    )
    store.dispatch('network/loadSubnetList', { force: true, networkConfigId: networkConfigId })
    return result
  },

  /**
   * Get a Subnet
   * @alias module: NetworkRepository
   * @returns {Object} Object
   */
  async getSubnet (networkConfigId, id) {
    return await Repository.get(
      Helpers.substituteApiVars(ApiTypes.GET_NETWORK_CONFIG_ID_SUBNETS_ID, { networkConfigId: networkConfigId, id : id }),
      DataTypes.subnet,
      `${moduleName}.getSubnet()`
    )
  },

  /**
   * Update a Subnet
   * 
   * @alias module: NetworkRepository
   * @param {String} networkConfigId
   * @param {Object} payload
   * @param {String} validationField
   * @returns null
   */
  async putSubnet (networkConfigId, payload, validationField = null) {
    Logger.info(`${moduleName}.putSubnet(networkConfigId, payload, validationField): called with validationField ${validationField}`)
    let urlParams = validationField ? `?validateOnly=true&validateFields=${validationField}` : ''
    let result = await Repository.put(
      Helpers.substituteApiVars(`${ApiTypes.PUT_NETWORK_CONFIG_ID_SUBNETS_ID}${urlParams}`, { networkConfigId: networkConfigId, id: payload.id }),
      payload,
      null,
      `${moduleName}.putSubnet()`
    )
    store.dispatch('network/loadSubnetList', { force: true, networkConfigId: networkConfigId })
    return result
  },

  /**
   * Delete a Subnet
   * 
   * @alias module: NetworkRepository
   * @param {String} id
   * @param {String} networkConfigId
   */
  async deleteSubnet (networkConfigId, id) {
    let result = await Repository.delete(
      Helpers.substituteApiVars(ApiTypes.DELETE_NETWORK_CONFIG_ID_SUBNETS_ID, { networkConfigId: networkConfigId, id: id }),
      `${moduleName}.deleteSubnet()`
    )
    store.dispatch('network/loadSubnetList', { force: true, networkConfigId: networkConfigId })
    return result
  },

  /** 
   * Get list of Subnets for equipment
   * @alias module: NetworkRepository
   * @param {String} equipmentConfigId
   * @param {String} type
   * @returns {Array} Array of Objects
   */
  async getEquipmentSubnetList (equipmentId, type = null) {
    let typeParam = type ? `&type=${type}` : ''
    return await Repository.get(
      `${ApiTypes.GET_NETWORK_SUBNETS}?equipmentId=${equipmentId}${typeParam}`,
      DataTypes.subnetList,
      `${moduleName}.getEquipmentSubnetList()`
    )
  },

  /**
   * Assign a subnet to equipment
   * 
   * @alias module: NetworkRepository
   * @param {String} equipmentId
   * @param {Object} payload
   * @returns {String} id
   */
  async postEquipmentSubnet (equipmentId, payload) {
    return await Repository.post(
      Helpers.substituteApiVars(ApiTypes.POST_NETWORK_EQUIPMENT_ID_SUBNETS, { equipmentId: equipmentId }),
      payload,
      DataTypes.idAsString,
      `${moduleName}.postEquipmentSubnet()`
    )
  },

  /**
   * Delete a subnet from an Equipment
   * 
   * @alias module: NetworkRepository
   * @param {String} id
   * @param {String} equipmentId
   */
  async deleteEquipmentSubnet (equipmentId, id) {
    return await Repository.delete(
      Helpers.substituteApiVars(ApiTypes.DELETE_NETWORK_EQUIPMENT_ID_SUBNETS_ID, { equipmentId: equipmentId, id: id }),
      `${moduleName}.deleteEquipmentSubnet()`
    )
  },

  /** 
   * Get list of All Subnets
   * @alias module: NetworkRepository
   * @returns {Array} Array of Objects
   */
  async getAllSubnetList () {
    return await Repository.get(
      `${ApiTypes.GET_NETWORK_SUBNETS}`,
      DataTypes.subnetList,
      `${moduleName}.getAllSubnetList()`
    )
  },

  /**
   * Post a new Private Interface
   * 
   * @alias module: NetworkRepository
   * @param {Object} payload
   * @returns {String} id
   */
  async postPrivateInterface (equipmentId, payload) {
    return await Repository.post(
      Helpers.substituteApiVars(ApiTypes.POST_NETWORK_EQUIPMENT_ID_PRIVATE_INTERFACES, { equipmentId: equipmentId }),
      payload,
      DataTypes.idAsString,
      `${moduleName}.postPrivateInterface()`
    )
  },

  /**
   * Get a Private Interface
   * @alias module: NetworkRepository
   * @returns {Object} Object
   */
  async getPrivateInterface (equipmentId, id) {
    return await Repository.get(
      Helpers.substituteApiVars(ApiTypes.GET_NETWORK_EQUIPMENT_ID_PRIVATE_INTERFACES_ID, { equipmentId: equipmentId, id : id }),
      DataTypes.privateInterface,
      `${moduleName}.getPrivateInterface()`
    )
  },

  /**
   * Update a Private Interface
   * 
   * @alias module: NetworkRepository
   * @param {Object} payload
   * @returns null
   */
  async putPrivateInterface (equipmentId, payload) {
    return await Repository.put(
      Helpers.substituteApiVars(ApiTypes.PUT_NETWORK_EQUIPMENT_ID_PRIVATE_INTERFACES_ID, { equipmentId: equipmentId, id: payload.id }),
      payload,
      null,
      `${moduleName}.putPrivateInterface()`
    )
  },

  /**
   * Delete a Private Interface
   * 
   * @alias module: NetworkRepository
   * @param {String} id
   * @param {String} equipmentId
   */
  async deletePrivateInterface (equipmentId, id) {
    return await Repository.delete(
      Helpers.substituteApiVars(ApiTypes.DELETE_NETWORK_EQUIPMENT_ID_PRIVATE_INTERFACES_ID, { equipmentId: equipmentId, id: id }),
      `${moduleName}.deletePrivateInterface()`
    )
  },

  /**
   * Post a new Public Interface
   * 
   * @alias module: NetworkRepository
   * @param {Object} payload
   * @returns {String} id
   */
  async postPublicInterface (equipmentId, payload) {
    return await Repository.post(
      Helpers.substituteApiVars(ApiTypes.POST_NETWORK_EQUIPMENT_ID_PUBLIC_INTERFACES, { equipmentId: equipmentId }),
      payload,
      DataTypes.idAsString,
      `${moduleName}.postPublicInterface()`
    )
  },

  /**
   * Get a Public Interface
   * @alias module: NetworkRepository
   * @returns {Object} Object
   */
  async getPublicInterface (equipmentId, id) {
    return await Repository.get(
      Helpers.substituteApiVars(ApiTypes.GET_NETWORK_EQUIPMENT_ID_PUBLIC_INTERFACES_ID, { equipmentId: equipmentId, id : id }),
      DataTypes.publicInterface,
      `${moduleName}.getPublicInterface()`
    )
  },

  /**
   * Update a Public Interface
   * 
   * @alias module: NetworkRepository
   * @param {Object} payload
   * @returns null
   */
  async putPublicInterface (equipmentId, payload) {
    return await Repository.put(
      Helpers.substituteApiVars(ApiTypes.PUT_NETWORK_EQUIPMENT_ID_PUBLIC_INTERFACES_ID, { equipmentId: equipmentId, id: payload.id }),
      payload,
      null,
      `${moduleName}.putPublicInterface()`
    )
  },

  /**
   * Delete a Public Interface
   * 
   * @alias module: NetworkRepository
   * @param {String} id
   * @param {String} equipmentId
   */
  async deletePublicInterface (equipmentId, id) {
    return await Repository.delete(
      Helpers.substituteApiVars(ApiTypes.DELETE_NETWORK_EQUIPMENT_ID_PUBLIC_INTERFACES_ID, { equipmentId: equipmentId, id: id }),
      `${moduleName}.deletePublicInterface()`
    )
  },

  /**
   * Get list of All Interfaces for equipment
   * @alias module: NetworkRepository
   * @param {String} equipmentId
   * @returns {Array} Array of Objects
   */
  async getEquipmentInterfaceList (equipmentId) {
    return await Repository.get(
      Helpers.substituteApiVars(ApiTypes.GET_NETWORK_EQUIPMENT_ID_ALL_INTERFACES, { equipmentId: equipmentId }),
      DataTypes.equipmentInterfaceList,
      `${moduleName}.getEquipmentInterfaceList()`
    )
  },


  /**
   * Get list of IP pools
   * @alias module: NetworkRepository
   * @returns {Array} Array of Objects
   */
  async getIpPoolList (customerId = null) {
    let urlParams = customerId ? `?customerId=${customerId}` : ''
    return await Repository.get(
      `${ApiTypes.GET_NETWORK_IP_POOLS}${urlParams}`,
      DataTypes.ipPoolList,
      `${moduleName}.getIpPoolList()`
    )
  },

  /**
   * Post a new IP pool
   * 
   * @alias module: NetworkRepository
   * @param {Object} payload
   */
  async postIpPool (payload) {
    let result = await Repository.post(
      ApiTypes.POST_NETWORK_IP_POOLS,
      payload,
      DataTypes.idAsString,
      `${moduleName}.postIpPool()`
    )
    store.dispatch('network/loadIpPoolList', { force: true })
    return result
  },

  /**
   * Get an IP pool
   * @alias module: NetworkRepository
   * @returns {Object} Object
   */
  async getIpPool (id) {
    return await Repository.get(
      Helpers.substituteApiVars(ApiTypes.GET_NETWORK_IP_POOLS_ID, { id : id }),
      DataTypes.ipPool,
      `${moduleName}.getIpPool()`
    )
  },

  /**
   * Update an IP pool
   * 
   * @alias module: NetworkRepository
   * @param {Object} payload
   */
  async putIpPool (payload) {
    let result = await Repository.put(
      Helpers.substituteApiVars(ApiTypes.PUT_NETWORK_IP_POOLS_ID, { id: payload.id }),
      payload,
      null,
      `${moduleName}.putIpPool()`
    )
    store.dispatch('network/loadIpPoolList', { force: true })
    return result
  },

  /**
   * Delete an IP Pool
   * 
   * @alias module: NetworkRepository
   * @param {String} id
   */
  async deleteIpPool (id) {
    let result = await Repository.delete(
      Helpers.substituteApiVars(ApiTypes.DELETE_NETWORK_IP_POOLS_ID, { id: id }),
      `${moduleName}.deleteIpPool()`
    )
    store.dispatch('network/loadIpPoolList')
    return result
  },
  
  /**
   * Post a new IPv6 Interface
   * 
   * @alias module: NetworkRepository
   * @param {String} equipmentId
   * @param {Object} payload
   */
  async postIpv6Interface (equipmentId, payload) {
    return await Repository.post(
      Helpers.substituteApiVars(ApiTypes.POST_NETWORK_EQUIPMENT_ID_IPV6_INTERFACES, { equipmentId: equipmentId }),
      payload,
      DataTypes.idAsString,
      `${moduleName}.postIpv6Interface()`
    )
  },

  /**
   * Get an IPv6 Interface
   * @alias module: NetworkRepository
   * @param {String} equipmentId
   * @param {String} id
   * @returns {Object} Object
   */
  async getIpv6Interface (equipmentId, id) {
    return await Repository.get(
      Helpers.substituteApiVars(ApiTypes.GET_NETWORK_EQUIPMENT_ID_IPV6_INTERFACES_ID, { equipmentId: equipmentId, id : id }),
      DataTypes.ipv6Interface,
      `${moduleName}.getIpv6Interface()`
    )
  },

  /**
   * Update an IPv6 Interface
   * 
   * @alias module: NetworkRepository
   * @param {String} equipmentId
   * @param {Object} payload
   */
  async putIpv6Interface (equipmentId, payload) {
    return await Repository.put(
      Helpers.substituteApiVars(ApiTypes.PUT_NETWORK_EQUIPMENT_ID_IPV6_INTERFACES_ID, { equipmentId: equipmentId, id: payload.id }),
      payload,
      null,
      `${moduleName}.putIpv6Interface()`
    )
  },

  /**
   * Delete an IPv6 Interface
   * 
   * @alias module: NetworkRepository
   * @param {String} equipmentId
   * @param {String} id
   */
  async deleteIpv6Interface (equipmentId, id) {
    return await Repository.delete(
      Helpers.substituteApiVars(ApiTypes.DELETE_NETWORK_EQUIPMENT_ID_IPV6_INTERFACES_ID, { equipmentId: equipmentId, id: id }),
      `${moduleName}.deleteIpv6Interface()`
    )
  },
  
  /**
   * Get list of IPv6 Numbers
   * @alias module: NetworkRepository
   * @returns {Array} Array of Objects
   */
  async getIpv6NumberList () {
    return await Repository.get(
      ApiTypes.GET_NETWORK_IPV6,
      DataTypes.ipv6NumberList,
      `${moduleName}.getIpv6NumberList()`
    )
  },

  
  /**
   * Post a new NAT Interface
   * 
   * @alias module: NetworkRepository
   * @param {String} equipmentId
   * @param {Object} payload
   */
  async postNatInterface (equipmentId, payload) {
    return await Repository.post(
      Helpers.substituteApiVars(ApiTypes.POST_NETWORK_EQUIPMENT_ID_NAT_INTERFACES, { equipmentId: equipmentId }),
      payload,
      DataTypes.idAsString,
      `${moduleName}.postNatInterface()`
    )
  },

  /**
   * Get an NAT Interface
   * @alias module: NetworkRepository
   * @param {String} equipmentId
   * @param {String} id
   * @returns {Object} Object
   */
  async getNatInterface (equipmentId, id) {
    return await Repository.get(
      Helpers.substituteApiVars(ApiTypes.GET_NETWORK_EQUIPMENT_ID_NAT_INTERFACES_ID, { equipmentId: equipmentId, id : id }),
      DataTypes.natInterface,
      `${moduleName}.getNatInterface()`
    )
  },

  /**
   * Update an NAT Interface
   * 
   * @alias module: NetworkRepository
   * @param {String} equipmentId
   * @param {Object} payload
   */
  async putNatInterface (equipmentId, payload) {
    return await Repository.put(
      Helpers.substituteApiVars(ApiTypes.PUT_NETWORK_EQUIPMENT_ID_NAT_INTERFACES_ID, { equipmentId: equipmentId, id: payload.id }),
      payload,
      null,
      `${moduleName}.putNatInterface()`
    )
  },

  /**
   * Delete an NAT Interface
   * 
   * @alias module: NetworkRepository
   * @param {String} equipmentId
   * @param {String} id
   */
  async deleteNatInterface (equipmentId, id) {
    return await Repository.delete(
      Helpers.substituteApiVars(ApiTypes.DELETE_NETWORK_EQUIPMENT_ID_NAT_INTERFACES_ID, { equipmentId: equipmentId, id: id }),
      `${moduleName}.deleteNatInterface()`
    )
  },

  /**
   * Get list of private IPv4 Numbers
   * @alias module: NetworkRepository
   * @returns {Array} Array of Objects
   */
  async getPrivateIpv4NumberList () {
    return await Repository.get(
      ApiTypes.GET_NETWORK_PRIVATE_IPV4,
      DataTypes.privateIpv4NumberList,
      `${moduleName}.getPrivateIpv4NumberList()`
    )
  },

  /**
   * Get list of available private IPv4 Numbers
   * @alias module: NetworkRepository
   * @param {String} subnetId
   * @returns {Array} Array of Objects
   */
  async getPrivateIpv4NumberAvailableList (subnetId) {
    return await Repository.get(
      `${ApiTypes.GET_NETWORK_PRIVATE_IPV4_AVAILABLE}?subnetId=${subnetId}`,
      DataTypes.privateIpv4NumberAvailableList,
      `${moduleName}.getPrivateIpv4NumberAvailableList()`
    )
  },
    
  /**
   * Get list of public IPv4 Numbers
   * @alias module: NetworkRepository
   * @returns {Array} Array of Objects
   */
  async getPublicIpv4NumberList () {
    return await Repository.get(
      ApiTypes.GET_NETWORK_PUBLIC_IPV4,
      DataTypes.publicIpv4NumberList,
      `${moduleName}.getPublicIpv4NumberList()`
    )
  },

  /**
   * Post a new Public IPv4 Number
   * 
   * @alias module: NetworkRepository
   * @param {Object} payload
   */
  async postPublicIpv4Number (payload) {
    return await Repository.post(
      ApiTypes.POST_NETWORK_PUBLIC_IPV4,
      payload,
      DataTypes.idArray,
      `${moduleName}.postPublicIpv4Number()`
    )
  },

  /**
   * Get a Public IPv4 Number
   * @alias module: NetworkRepository
   * @returns {Object} Object
   */
  async getPublicIpv4Number (id) {
    return await Repository.get(
      Helpers.substituteApiVars(ApiTypes.GET_NETWORK_PUBLIC_IPV4_ID, { id : id }),
      DataTypes.publicIpv4Number,
      `${moduleName}.getPublicIpv4Number()`
    )
  },

  /**
   * Update a Public IPv4 Number
   * 
   * @alias module: NetworkRepository
   * @param {Object} payload
   */
  async putPublicIpv4Number (payload) {
    return await Repository.put(
      Helpers.substituteApiVars(ApiTypes.PUT_NETWORK_PUBLIC_IPV4_ID, { id: payload.id }),
      payload,
      null,
      `${moduleName}.putPublicIpv4Number()`
    )
  },

  /**
   * Delete a Public IPv4 Number
   * 
   * @alias module: NetworkRepository
   * @param {String} id
   */
  async deletePublicIpv4Number (id) {
    return await Repository.delete(
      Helpers.substituteApiVars(ApiTypes.DELETE_NETWORK_PUBLIC_IPV4_ID, { id: id }),
      `${moduleName}.deletePublicIpv4Number()`
    )
  },
  
  /**
   * Get list of available public IPv4 Numbers
   * @alias module: NetworkRepository
   * @param {String} subnetId
   * @param {String} ipPoolId
   * @returns {Array} Array of Objects
   */
  async getPublicIpv4NumberAvailableList (ipPoolId = null) {
    let params = ipPoolId ? `?ipPoolId=${ipPoolId}` : ''

    return await Repository.get(
      `${ApiTypes.GET_NETWORK_PUBLIC_IPV4_AVAILABLE}${params}`,
      DataTypes.publicIpv4NumberAvailableList,
      `${moduleName}.getPublicIpv4NumberAvailableList()`
    )
  },


  /**
   * Get list of Tenants
   * @alias module: NetworkRepository
   * @returns {Array} Array of Objects
   */
  async getTenantList () {
    return await Repository.get(
      ApiTypes.GET_NETWORK_TENANTS,
      DataTypes.tenantList,
      `${moduleName}.getTenantList()`
    )
  },

  /**
   * Get list of Vdoms
   * @alias module: NetworkRepository
   * @returns {Array} Array of Objects
   */
  async getVdomList () {
    return await Repository.get(
      ApiTypes.GET_NETWORK_VDOMS,
      DataTypes.vdomList,
      `${moduleName}.getVdomList()`
    )
  },

  /**
   * Get list of Vrfs
   * @alias module: NetworkRepository
   * @returns {Array} Array of Objects
   */
  async getVrfList () {
    return await Repository.get(
      ApiTypes.GET_NETWORK_VRFS,
      DataTypes.vrfList,
      `${moduleName}.getVrfList()`
    )
  },

}