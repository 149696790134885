import { Enum, Type, IfExists } from 'hello-type' // Check datatypes from API stream

export const idAsString = new Type({
  id: String
})

export const idAsNumber = new Type({
  id: Number
})

/** 
* Definition of DNS records for the expected API properties which will be checked on API call
* @constant
*/
export const dnsRecordList = new Type({
  content: String,
  fullName: String,
  id: Number,
  prio: IfExists(Enum(null, Number)),
  ttl: Number,
  type: Enum('A', 'AAAA', 'CAA', 'CNAME', 'DS', 'MX', 'NS', 'PTR', 'SOA', 'SRV', 'TLSA', 'TXT'),
  meta: {
    canDelete: Boolean,
    canEdit: Boolean
  }
})

export const dnsRecord = new Type({
  id: Number,
  shortName: String,
  fullName: String,
  type: Enum('A', 'AAAA', 'CAA', 'CNAME', 'DS', 'MX', 'NS', 'PTR', 'SOA', 'SRV', 'TLSA', 'TXT'),
  content: String,
  ttl: Number,
  prio: IfExists(Enum(null, Number)),
  service: IfExists(Enum(null, String)),
  proto: IfExists(Enum(null, String)),
  weight: IfExists(Enum(null, Number)),
  port: IfExists(Enum(null, Number)),
  target: IfExists(Enum(null, String)),
})

/** 
* Definition of TTL values for the expected API properties which will be checked on API call
* @constant
*/
export const dnsTtlList = new Type({
  id: Number,
  name: String
})

/** 
* Definition of dns record type values for the expected API properties which will be checked on API call
* @constant
*/
export const dnsTypeList = new Type({
  id: String,
  name: String
})
