import { Enum, Type, IfExists } from 'hello-type' // Check datatypes from API stream

export const idAsString = new Type({
  id: String
})

/** 
 * Definition of Customers type for the expected API properties which will be checked on API call
 * @constant
*/
export const customerList = new Type({
    id: String,
    name: String,
    isArchive: IfExists(Boolean),
    status_id: IfExists(Number),
    status_name: IfExists(String),
})

export const customer = new Type({
  id: String,
  name: String,
  snelstartRelatieId: Number,
  status: {
    id: Number,
    name: String
  }
})

export const relationList = new Type({
  id: Number,
  name: String
})

export const relation = new Type({
  id: Number,
  phoneNumber: String,
  relationCode: Number,
  contactCity: String,
  cellPhoneNumber: String,
  contactAddress: String,
  vatNumber: String,
  name: String,
  contactPerson: String,
  contactPostalCode: String,
  email: String
})

export const invoiceList = new Type({
    id: Number,
    invoiceNumber: String,
    invoiceDate: String,
    invoiceAmount: Number,
    dueDate: String,
    paid: Number,
    balance: Number,
    invoiceDatePaid: Enum(String, null),
})

export const invoice = new Type(String)

export const order = new Type({
  amountExVat: Number,
  orderDate: String,
  intervalCount: Number,
  interval: String,
  id: String,
  subscription: String,
  orderItems: Array(
    {
      count: Number,
      price: Number,
      articleCode: String,
      validUntilDate: String,
      discount: Number,
      id: Number,
      description: String,
      amount: Number
    }
  )
})

export const customerRelation = new Type({
  relationCode: Number,
  phoneNumber: String,
  memo: String,
  contactCity: String,
  cellPhoneNumber: String,
  contactAddress: String,
  vatNumber: String,
  id: Number,
  name: String,
  contactPerson: String,
  contactPostalCode: String,
  email: String
})

export const subscriptionList = new Type ({
  id: Number,
  category: String,
  orderId: Number,
  articleCode: String,
  description: String,
  number: Number,
  validUntilDate: String,
  price: Number,
  discount: Number,
  amount: Number  
})

export const statusList = new Type ({
  id: Number,
  name: String
})