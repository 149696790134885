// Define constants for API end points
// This allows the code to take advantage of tooling like linters,
// and putting all constants in a single file allows your collaborators
// to get an at-a-glance view of what SPI calls are possible in the entire application

// Menu
export const GET_MENU = "/menu-items";
export const GET_SEARCH_ITEMS = "/search-items";

// Auth
export const POST_AUTH_APITOKENS = "/auth/apitokens";
export const POST_AUTH_API_IMPERSONATED_TOKEN_ID =
  "/auth/api-impersonated-token/:id";
export const POST_AUTH_API_PARENT_USER_TOKEN = "/auth/api-parent-user-token";
export const GET_AUTH_ENDPOINTS = "/auth/endpoints";
export const GET_AUTH_ROLES = "/auth/roles";
export const POST_AUTH_ROLES = "/auth/roles";
export const PUT_AUTH_ROLES_ID = "/auth/roles/:id";
export const GET_AUTH_ROLES_ID = "/auth/roles/:id";
export const DELETE_AUTH_ROLES_ID = "/auth/roles/:id";
export const GET_AUTH_ROLES_ID_SCOPES = "/auth/roles/:id/scopes";
export const PUT_AUTH_ROLES_ID_SCOPES = "/auth/roles/:id/scopes";

// Auth-ApiKeys
export const GET_AUTH_APIKEYS = "/auth/apikeys";
export const POST_AUTH_APIKEYS = "/auth/apikeys";
export const PUT_AUTH_APIKEYS_ID = "/auth/apikeys/:id";
export const GET_AUTH_APIKEYS_ID = "/auth/apikeys/:id";
export const DELETE_AUTH_APIKEYS_ID = "/auth/apikeys/:id";
export const GET_AUTH_APIKEYS_ALL = "/auth/apikeys/all";

export const GET_AUTH_SCOPES = "/auth/scopes";

// Customers
export const GET_CUSTOMERS = "/customers"; // List customers
export const POST_CUSTOMERS = "/customers";
export const GET_CUSTOMERS_ID = "/customers/:id"; // Read customer
export const PUT_CUSTOMERS_ID = "/customers/:id";
export const DELETE_CUSTOMERS_ID = "/customers/:id";
export const GET_CUSTOMERS_RELATIONS = "/customers/relations"; // List customer relations
export const GET_CUSTOMERS_ID_RELATIONS = "/customers/:customerId/relations"; // Read customer relation
export const GET_CUSTOMERS_ID_INVOICES = "/customers/:customerId/invoices"; // List invoices for customer
export const GET_CUSTOMERS_ID_INVOICES_ID =
  "/customers/:customerId/invoices/:id"; // Download invoice for customer
export const GET_CUSTOMERS_ID_ORDERS_ID = "/customers/:customerId/orders/:id"; // Show order
export const GET_CUSTOMERS_ID_SUBSCRIPTIONS =
  "/customers/:customerId/subscriptions"; // List subscriptions for customer
export const GET_CUSTOMERS_STATUSES = "/customers/statuses"; // List customer statuses

// Users
export const GET_USERS = "/users";
export const POST_USERS = "/users";
export const GET_USERS_ID = "/users/:id";
export const PUT_USERS_ID = "/users/:id";
export const DELETE_USERS_ID = "/users/:id";
export const GET_USERS_CONTEXT_ROLES = "/users/context-roles";
export const POST_USERS_PASSWORD_RESET = "/users/password-reset";
export const POST_USERS_PASSWORD_RESET_REQUEST =
  "/users/password-reset-request";
export const PUT_USERS_OWN_PASSWORD = "/users/own-password";
export const PUT_USERS_ID_PASSWORD = "/users/:id/password";
export const POST_USERS_2FA_KEY = "/users/2fa-key";
export const POST_USERS_2FA = "/users/2fa";
export const GET_USERS_2FA_RECOVERY_CODES = "/users/2fa-recovery-codes";
export const PUT_USERS_2FA_RECOVERY_CODES = "/users/2fa-recovery-codes";
export const DELETE_USERS_2FA = "/users/2fa";
export const DELETE_USERS_ID_2FA = "/users/:id/2fa";

// Domains
export const GET_DOMAINS = "/domains"; // List Domains
export const POST_DOMAINS = "/domains"; // Create Domain
export const GET_DOMAINS_ID = "/domains/:domainName"; // Read Domain
export const PUT_DOMAINS_ID = "/domains/:domainName"; // Update Domain
export const DELETE_DOMAINS_ID = "/domains/:domainName"; // Delete Domain

export const GET_DOMAINS_DNS_SEC_STATUSES = "/domains/dns-sec-statuses"; // List dns-sec statuses
export const GET_DOMAINS_MAIL_TYPES = "/domains/mail-types"; // List mailtypes
export const GET_DOMAINS_STATUSES = "/domains/statuses"; // List domain statuses
export const GET_DOMAINS_REGISTRARS = "/domains/registrars"; // List domain registrars
export const PUT_DOMAINS_ID_DNS_SEC_STATUS =
  "/domains/:domainName/dns-sec-status"; // Set the DNSsec status
export const POST_DOMAINS_DOMAIN_NAME_DNSSEC = "/domains/:domainName/dns-sec";
export const POST_DNS_DOMAIN_NAME = "/dns/:domainName";
export const DELETE_DNS_DOMAIN_NAME = "/dns/:domainName";
export const GET_CUSTOMERS_ID_DOMAINS = "/customers/:customerId/domains";

// Domain redirects
export const GET_DOMAINS_ID_REDIRECT = "/domains/:domainName/redirect";
export const POST_DOMAINS_ID_REDIRECT = "/domains/:domainName/redirect";
export const PUT_DOMAINS_ID_REDIRECT = "/domains/:domainName/redirect";
export const DELETE_DOMAINS_ID_REDIRECT = "/domains/:domainName/redirect";

// Reports
export const GET_IMPORTED_DOMAINS = "/reports/imported-domains";
export const POST_IMPORTED_DOMAINS = "/reports/imported-domains";
export const GET_IMPORTED_DOMAINS_ID = "/reports/imported-domains/:id";
export const PUT_IMPORTED_DOMAINS_ID = "/reports/imported-domains/:id";
export const DELETE_IMPORTED_DOMAINS_ID = "/reports/imported-domains/:id";
export const GET_NON_ACCOUNTING_PORTAL_DOMAINS =
  "/reports/non-accounting-portal-domains";
export const GET_NON_PORTAL_ACCOUNTING_DOMAINS =
  "/reports/non-portal-accounting-domains";
export const GET_NON_PORTAL_REGISTERED_DOMAINS =
  "/reports/non-portal-registered-domains";
export const GET_NON_REGISTERED_PORTAL_DOMAINS =
  "/reports/non-registered-portal-domains";
export const GET_MAILBOX_REPORT = "/reports/mailbox-report";
export const GET_LICENSE_REPORTS_SPLA = "/reports/license-reports-spla";
export const GET_LICENSE_REPORTS_PER_MACHINE =
  "/reports/license-reports-per-machine";

// Auto DNS
export const GET_AUTO_DNS_ACCOUNTS = "/autodns/accounts";
export const POST_AUTO_DNS_ACCOUNTS = "/autodns/accounts";
export const GET_AUTO_DNS_ACCOUNTS_ID = "/autodns/accounts/:id";
export const PUT_AUTO_DNS_ACCOUNTS_ID = "/autodns/accounts/:id";
export const DELETE_AUTO_DNS_ACCOUNTS_ID = "/autodns/accounts/:id";
export const GET_AUTO_DNS_ACTIONS = "/autodns/actions";
export const POST_AUTO_DNS_ACTIONS = "/autodns/actions";
export const GET_AUTO_DNS_ACTIONS_ID = "/autodns/actions/:id";
export const PUT_AUTO_DNS_ACTIONS_ID = "/autodns/actions/:id";
export const DELETE_AUTO_DNS_ACTIONS_ID = "/autodns/actions/:id";
export const GET_AUTO_DNS_HISTORY_ITEMS = "/autodns/history-items";

// DNS operations
export const GET_DNS_DOMAIN_NAME_RECORDS = "/dns/:domainName/records"; // List dns records for domain
export const POST_DNS_DOMAIN_NAME_RECORDS = "/dns/:domainName/records"; // Create dns records for domain
export const GET_DNS_DOMAIN_NAME_RECORDS_ID = "/dns/:domainName/records/:id"; // Read dns records for domain
export const PUT_DNS_DOMAIN_NAME_RECORDS_ID = "/dns/:domainName/records/:id"; // Update dns records for domain
export const DELETE_DNS_DOMAIN_NAME_RECORDS_ID = "/dns/:domainName/records/:id"; // Delete dns records for domain
export const GET_DNS_RECORDS_TYPES = "/dns/records-types"; // List dns record types
export const GET_DNS_TTL_VALUES = "/dns/ttl-values"; // List ttl values

// Shared mail Alias operations
export const GET_DOMAINS_ID_MAIL_ALIASES = "/domains/:domainName/mail/aliases"; // List shared mail aliases
export const POST_DOMAINS_ID_MAIL_ALIASES = "/domains/:domainName/mail/aliases"; // Create shared mail alias
export const GET_DOMAINS_ID_MAIL_ALIASES_ID =
  "/domains/:domainName/mail/aliases/:id"; // Show Mail Alias
export const PUT_DOMAINS_ID_MAIL_ALIASES_ID =
  "/domains/:domainName/mail/aliases/:id"; // Update shared mail alias
export const DELETE_DOMAINS_ID_MAIL_ALIASES_ID =
  "/domains/:domainName/mail/aliases/:id"; // Delete shared mail alias

// Shared mail Mailbox operations
export const GET_DOMAINS_ID_MAIL_BOXES = "/domains/:domainName/mail/boxes"; // List shared mail boxes
export const POST_DOMAINS_ID_MAIL_BOXES = "/domains/:domainName/mail/boxes"; // Create shared mailbox
export const GET_DOMAINS_ID_MAIL_BOXES_ID =
  "/domains/:domainName/mail/boxes/:id"; // Read shared mailbox
export const PUT_DOMAINS_ID_MAIL_BOXES_ID =
  "/domains/:domainName/mail/boxes/:id"; // Update shared mailbox
export const DELETE_DOMAINS_ID_MAIL_BOXES_ID =
  "/domains/:domainName/mail/boxes/:id"; // Delete shared mailbox

// Shared mail Catchall operations
export const GET_DOMAINS_ID_MAIL_CATCHALL =
  "/domains/:domainName/mail/catchall"; // Show shared mail catchall
export const POST_DOMAINS_ID_MAIL_CATCHALL =
  "/domains/:domainName/mail/catchall"; // Create shared mail catchall
export const PUT_DOMAINS_ID_MAIL_CATCHALL =
  "/domains/:domainName/mail/catchall"; // Update shared mail catchall
export const DELETE_DOMAINS_ID_MAIL_CATCHALL =
  "/domains/:domainName/mail/catchall"; // Delete shared mail catchall

// Shared mail Settings
export const GET_DOMAINS_ID_MAIL_SETTINGS =
  "/domains/:domainName/mail/settings"; // Show mail settings
export const PUT_DOMAINS_ID_MAIL_SETTINGS =
  "/domains/:domainName/mail/settings"; // Update mail settings

// Workflow for enabling / disabling mail
export const POST_DOMAINS_ID_PREPARED_SHARED_MAIL =
  "/domains/:domainName/prepared-shared-mail"; // Prepare shared mail
export const POST_DOMAINS_ID_SHARED_MAIL = "/domains/:domainName/shared-mail"; // Enable shared-mail
export const DELETE_DOMAINS_ID_SHARED_MAIL = "/domains/:domainName/shared-mail"; // Disable shared-mail
export const POST_DOMAINS_ID_EXCHANGE = "/domains/:domainName/exchange";
export const DELETE_DOMAINS_ID_EXCHANGE = "/domains/:domainName/exchange";

// Anti-Spam
export const GET_DOMAINS_ID_ANTI_SPAM_SETTINGS =
  "/domains/:domainName/anti-spam/domain"; // list spam settings
export const POST_DOMAINS_ID_ANTI_SPAM_DOMAIN =
  "/domains/:domainName/anti-spam/domain"; // Enable anti spam
export const PUT_DOMAINS_ID_ANTI_SPAM_DOMAIN =
  "/domains/:domainName/anti-spam/domain"; // update antispam destination
export const DELETE_DOMAINS_ID_ANTI_SPAM_DOMAIN =
  "/domains/:domainName/anti-spam/domain"; // Delete antispam
export const POST_DOMAINS_ID_ANTI_SPAM_ALIAS =
  "/domains/:domainName/anti-spam/alias"; // Enable antispam alias
export const DELETE_DOMAINS_ID_ANTI_SPAM_ALIAS =
  "/domains/:domainName/anti-spam/alias"; // Delete antispam alias
export const GET_DOMAINS_ID_ANTI_SPAM_ALIAS_TARGETS =
  "/domains/:domainName/anti-spam/alias-targets";

// Equipment
export const GET_EQUIPMENT_BACKUP_METHODS = "/equipment/backup-methods";
export const GET_EQUIPMENT_ID_AVAILABLE_NETWORK_PORTS =
  "/equipment/:equipmentId/available-network-ports";
export const GET_EQUIPMENT_ID_NETWORK_PORTS =
  "/equipment/:equipmentId/network-ports";
export const POST_EQUIPMENT_ID_NETWORK_PORTS =
  "/equipment/:equipmentId/network-ports";
export const GET_EQUIPMENT_ID_NETWORK_PORTS_ID =
  "/equipment/:equipmentId/network-ports/:id";
export const PUT_EQUIPMENT_ID_NETWORK_PORTS_ID =
  "/equipment/:equipmentId/network-ports/:id";
export const DELETE_EQUIPMENT_ID_NETWORK_PORTS_ID =
  "/equipment/:equipmentId/network-ports/:id";
export const GET_EQUIPMENT_NAMES = "/equipment/names";
export const POST_EQUIPMENT_NAMES = "/equipment/names";
export const GET_EQUIPMENT_NAMES_ID = "/equipment/names/:id";
export const PUT_EQUIPMENT_NAMES_ID = "/equipment/names/:id";
export const DELETE_EQUIPMENT_NAMES_ID = "/equipment/names/:id";
export const GET_EQUIPMENT_NAME_POOLS = "/equipment/name-pools";
export const POST_EQUIPMENT_NAME_POOLS_ID_RANDOM_NAME =
  "/equipment/name-pools/:equipmentNamePoolId/random-name";
export const GET_EQUIPMENT_INSTALLERS = "/equipment/installers";
export const GET_EQUIPMENT_OS_VERSIONS = "/equipment/os-versions";
export const POST_EQUIPMENT_OS_VERSIONS = "/equipment/os-versions";
export const GET_EQUIPMENT_OS_VERSIONS_ID = "/equipment/os-versions/:id";
export const PUT_EQUIPMENT_OS_VERSIONS_ID = "/equipment/os-versions/:id";
export const DELETE_EQUIPMENT_OS_VERSIONS_ID = "/equipment/os-versions/:id";
export const GET_EQUIPMENT_RACKS = "/equipment/racks";
export const POST_EQUIPMENT_RACKS = "/equipment/racks";
export const GET_EQUIPMENT_RACKS_ID = "/equipment/racks/:id";
export const PUT_EQUIPMENT_RACKS_ID = "/equipment/racks/:id";
export const DELETE_EQUIPMENT_RACKS_ID = "/equipment/racks/:id";
export const GET_EQUIPMENT_RACKS_ID_LAYOUT = "/equipment/racks/:id/layout";
export const GET_EQUIPMENT_SERVERS = "/equipment/servers";
export const POST_EQUIPMENT_SERVERS = "/equipment/servers";
export const GET_EQUIPMENT_SERVERS_ID = "/equipment/Servers/:id";
export const PUT_EQUIPMENT_SERVERS_ID = "/equipment/Servers/:id";
export const DELETE_EQUIPMENT_SERVERS_ID = "/equipment/Servers/:id";
export const GET_EQUIPMENT_NEXT_SERVER_ALIAS = "/equipment/next-server-alias";
export const GET_EQUIPMENT_NEXT_VM_ALIAS = "/equipment/next-vm-alias";
export const GET_EQUIPMENT_SERVICE_LEVELS = "/equipment/service-levels";
export const POST_EQUIPMENT_SERVICE_LEVELS = "/equipment/service-levels";
export const GET_EQUIPMENT_SERVICE_LEVELS_ID = "/equipment/service-levels/:id";
export const PUT_EQUIPMENT_SERVICE_LEVELS_ID = "/equipment/service-levels/:id";
export const DELETE_EQUIPMENT_SERVICE_LEVELS_ID =
  "/equipment/service-levels/:id";
export const GET_EQUIPMENT_STATUSES = "/equipment/statuses";
export const GET_EQUIPMENT_SWITCHES = "/equipment/switches";
export const POST_EQUIPMENT_SWITCHES = "/equipment/switches";
export const GET_EQUIPMENT_SWITCHES_ID = "/equipment/switches/:id";
export const PUT_EQUIPMENT_SWITCHES_ID = "/equipment/switches/:id";
export const DELETE_EQUIPMENT_SWITCHES_ID = "/equipment/switches/:id";
export const GET_EQUIPMENT_VIRTUAL_MACHINES = "/equipment/virtual-machines";
export const POST_EQUIPMENT_VIRTUAL_MACHINES = "/equipment/virtual-machines";
export const GET_EQUIPMENT_VIRTUAL_MACHINES_ID =
  "/equipment/virtual-machines/:id";
export const PUT_EQUIPMENT_VIRTUAL_MACHINES_ID =
  "/equipment/virtual-machines/:id";
export const DELETE_EQUIPMENT_VIRTUAL_MACHINES_ID =
  "/equipment/virtual-machines/:id";
export const GET_EQUIPMENT_PHYSICAL_NETWORKED_EQUIPMENT =
  "/equipment/physical-networked-equipment";
export const GET_EQUIPMENT_MISC = "/equipment/misc";
export const POST_EQUIPMENT_MISC = "/equipment/misc";
export const GET_EQUIPMENT_MISC_ID = "/equipment/misc/:id";
export const PUT_EQUIPMENT_MISC_ID = "/equipment/misc/:id";
export const DELETE_EQUIPMENT_MISC_ID = "/equipment/misc/:id";
export const GET_EQUIPMENT_RESOURCES = "/equipment/resources";
export const GET_EQUIPMENT_RESOURCES_ID = "/equipment/resources/:id";

// Network
export const GET_NETWORK_ALL_CONFIGS = "/network/all-configs";
export const POST_NETWORK_ADVANCED_CONFIGS = "/network/advanced-configs";
export const PUT_NETWORK_ADVANCED_CONFIGS_ID = "/network/advanced-configs/:id";
export const GET_NETWORK_ADVANCED_CONFIGS_ID = "/network/advanced-configs/:id";
export const DELETE_NETWORK_ADVANCED_CONFIGS_ID =
  "/network/advanced-configs/:id";

export const POST_NETWORK_SIMPLE_CONFIGS = "/network/simple-configs";
export const PUT_NETWORK_SIMPLE_CONFIGS_ID = "/network/simple-configs/:id";
export const GET_NETWORK_SIMPLE_CONFIGS_ID = "/network/simple-configs/:id";
export const DELETE_NETWORK_SIMPLE_CONFIGS_ID = "/network/simple-configs/:id";

export const GET_NETWORK_CONFIG_ID_SUBNETS =
  "/network/configs/:networkConfigId/subnets";
export const POST_NETWORK_CONFIG_ID_SUBNETS =
  "/network/configs/:networkConfigId/subnets";
export const PUT_NETWORK_CONFIG_ID_SUBNETS_ID =
  "/network/configs/:networkConfigId/subnets/:id";
export const GET_NETWORK_CONFIG_ID_SUBNETS_ID =
  "/network/configs/:networkConfigId/subnets/:id";
export const DELETE_NETWORK_CONFIG_ID_SUBNETS_ID =
  "/network/configs/:networkConfigId/subnets/:id";

export const GET_NETWORK_EQUIPMENT_ID_SUBNETS =
  "/network/equipment/:equipmentId/subnets";
export const POST_NETWORK_EQUIPMENT_ID_SUBNETS =
  "/network/equipment/:equipmentId/subnets";
export const DELETE_NETWORK_EQUIPMENT_ID_SUBNETS_ID =
  "/network/equipment/:equipmentId/subnets/:id";

export const GET_NETWORK_SUBNETS = "/network/subnets";

export const POST_NETWORK_EQUIPMENT_ID_PRIVATE_INTERFACES =
  "/network/equipment/:equipmentId/private-interfaces";
export const PUT_NETWORK_EQUIPMENT_ID_PRIVATE_INTERFACES_ID =
  "/network/equipment/:equipmentId/private-interfaces/:id";
export const GET_NETWORK_EQUIPMENT_ID_PRIVATE_INTERFACES_ID =
  "/network/equipment/:equipmentId/private-interfaces/:id";
export const DELETE_NETWORK_EQUIPMENT_ID_PRIVATE_INTERFACES_ID =
  "/network/equipment/:equipmentId/private-interfaces/:id";

export const POST_NETWORK_EQUIPMENT_ID_PUBLIC_INTERFACES =
  "/network/equipment/:equipmentId/public-interfaces";
export const PUT_NETWORK_EQUIPMENT_ID_PUBLIC_INTERFACES_ID =
  "/network/equipment/:equipmentId/public-interfaces/:id";
export const GET_NETWORK_EQUIPMENT_ID_PUBLIC_INTERFACES_ID =
  "/network/equipment/:equipmentId/public-interfaces/:id";
export const DELETE_NETWORK_EQUIPMENT_ID_PUBLIC_INTERFACES_ID =
  "/network/equipment/:equipmentId/public-interfaces/:id";

export const GET_NETWORK_EQUIPMENT_ID_ALL_INTERFACES =
  "/network/equipment/:equipmentId/all-interfaces";

export const GET_NETWORK_IP_POOLS = "/network/ip-pools";
export const POST_NETWORK_IP_POOLS = "/network/ip-pools";
export const PUT_NETWORK_IP_POOLS_ID = "/network/ip-pools/:id";
export const GET_NETWORK_IP_POOLS_ID = "/network/ip-pools/:id";
export const DELETE_NETWORK_IP_POOLS_ID = "/network/ip-pools/:id";

export const POST_NETWORK_EQUIPMENT_ID_IPV6_INTERFACES =
  "/network/equipment/:equipmentId/ipv6-interfaces";
export const PUT_NETWORK_EQUIPMENT_ID_IPV6_INTERFACES_ID =
  "/network/equipment/:equipmentId/ipv6-interfaces/:id";
export const GET_NETWORK_EQUIPMENT_ID_IPV6_INTERFACES_ID =
  "/network/equipment/:equipmentId/ipv6-interfaces/:id";
export const DELETE_NETWORK_EQUIPMENT_ID_IPV6_INTERFACES_ID =
  "/network/equipment/:equipmentId/ipv6-interfaces/:id";

export const GET_NETWORK_IPV6 = "/network/ipv6";

export const POST_NETWORK_EQUIPMENT_ID_NAT_INTERFACES =
  "/network/equipment/:equipmentId/nat-interfaces";
export const PUT_NETWORK_EQUIPMENT_ID_NAT_INTERFACES_ID =
  "/network/equipment/:equipmentId/nat-interfaces/:id";
export const GET_NETWORK_EQUIPMENT_ID_NAT_INTERFACES_ID =
  "/network/equipment/:equipmentId/nat-interfaces/:id";
export const DELETE_NETWORK_EQUIPMENT_ID_NAT_INTERFACES_ID =
  "/network/equipment/:equipmentId/nat-interfaces/:id";

export const GET_NETWORK_PRIVATE_IPV4 = "/network/private-ipv4";
export const GET_NETWORK_PRIVATE_IPV4_AVAILABLE =
  "/network/private-ipv4-available";

export const GET_NETWORK_PUBLIC_IPV4 = "/network/public-ipv4";
export const POST_NETWORK_PUBLIC_IPV4 = "/network/public-ipv4";
export const PUT_NETWORK_PUBLIC_IPV4_ID = "/network/public-ipv4/:id";
export const DELETE_NETWORK_PUBLIC_IPV4_ID = "/network/public-ipv4/:id";
export const GET_NETWORK_PUBLIC_IPV4_ID = "/network/public-ipv4/:id";
export const GET_NETWORK_PUBLIC_IPV4_AVAILABLE =
  "/network/public-ipv4-available";

export const GET_NETWORK_TENANTS = "/network/tenants";
export const GET_NETWORK_VDOMS = "/network/vdoms";
export const GET_NETWORK_VRFS = "/network/vrfs";

// DNS templates
export const GET_DNS_TEMPLATES = "/dns-templates";
export const POST_DNS_TEMPLATES = "/dns-templates";
export const GET_DNS_TEMPLATES_ID = "/dns-templates/:id";
export const PUT_DNS_TEMPLATES_ID = "/dns-templates/:id";
export const DELETE_DNS_TEMPLATES_ID = "/dns-templates/:id";
export const POST_DNS_TEMPLATES_ID_DUPLICATE = "/dns-templates/:id/duplicate";
export const GET_DNS_TEMPLATES_ID_RECORDS =
  "/dns-templates/:dnsTemplateId/records";
export const POST_DNS_TEMPLATES_ID_RECORDS =
  "/dns-templates/:dnsTemplateId/records";
export const GET_DNS_TEMPLATES_ID_RECORDS_ID =
  "/dns-templates/:dnsTemplateId/records/:id";
export const PUT_DNS_TEMPLATES_ID_RECORDS_ID =
  "/dns-templates/:dnsTemplateId/records/:id";
export const DELETE_DNS_TEMPLATES_ID_RECORDS_ID =
  "/dns-templates/:dnsTemplateId/records/:id";

// Licenses
export const GET_EQUIPMENT_ID_LICENSE_ASSIGNMENTS =
  "/equipment/:equipmentId/license-assignments";
export const POST_EQUIPMENT_ID_LICENSE_ASSIGNMENTS =
  "/equipment/:equipmentId/license-assignments";
export const GET_EQUIPMENT_ID_LICENSE_ASSIGNMENTS_ID =
  "/equipment/:equipmentId/license-assignments/:id";
export const PUT_EQUIPMENT_ID_LICENSE_ASSIGNMENTS_ID =
  "/equipment/:equipmentId/license-assignments/:id";
export const DELETE_EQUIPMENT_ID_LICENSE_ASSIGNMENTS_ID =
  "/equipment/:equipmentId/license-assignments/:id";
export const GET_LICENSES = "/equipment/licenses";
export const POST_LICENSES = "/equipment/licenses";
export const GET_LICENSES_ID = "/equipment/licenses/:id";
export const PUT_LICENSES_ID = "/equipment/licenses/:id";
export const DELETE_LICENSES_ID = "/equipment/licenses/:id";
export const GET_LICENSE_CATEGORIES_ID_LICENSES =
  "/equipment/license-categories/:licenseCategoryId/licenses";
export const GET_LICENSE_CATEGORIES = "/equipment/license-categories";
export const POST_LICENSE_CATEGORIES = "/equipment/license-categories";
export const GET_LICENSE_CATEGORIES_ID = "/equipment/license-categories/:id";
export const PUT_LICENSE_CATEGORIES_ID = "/equipment/license-categories/:id";
export const DELETE_LICENSE_CATEGORIES = "/equipment/license-categories/:id";

// Logging
export const GET_LOGGED_AUDIT_ENTRIES = "/logs/audit-entries";
export const GET_LOGGED_AUDIT_ENTRIES_ID = "/logs/audit-entries/:id";
export const GET_LOGGED_ERRORS = "/logs/errors";
export const GET_LOGGED_ERRORS_ID = "/logs/errors/:id";
export const GET_RABBIT_TRANSACTIONS = "/logs/rabbit-transactions";
export const GET_RABBIT_TRANSACTIONS_ID = "/logs/rabbit-transactions/:id";

// SLA
export const GET_SLA_CONTACTS = "/sla/contacts";
export const POST_SLA_CONTACTS = "/sla/contacts";
export const GET_SLA_CONTACTS_ID = "/sla/contacts/:id";
export const PUT_SLA_CONTACTS_ID = "/sla/contacts/:id";
export const DELETE_SLA_CONTACTS_ID = "/sla/contacts/:id";
export const GET_SLA_SERVICE_LEVELS = "/sla/service-levels";

// Reports
export const GET_SLA_UPTIME_REPORTS_CUSTOMERS_ID =
  "/sla/uptime-reports/customers/:customerId";
export const GET_SLA_UPTIME_REPORTS_EQUIPMENT_ID =
  "/sla/uptime-reports/equipment/:equipmentId";

// Checklists
export const GET_CHECKLISTS_TEMPLATES = "/checklists/templates";
export const POST_CHECKLISTS_TEMPLATES = "/checklists/templates";
export const GET_CHECKLISTS_TEMPLATES_ID = "/checklists/templates/:id";
export const PUT_CHECKLISTS_TEMPLATES_ID = "/checklists/templates/:id";
export const DELETE_CHECKLISTS_TEMPLATES_ID = "/checklists/templates/:id";

export const GET_CHECKLISTS_ID_TEMPLATE_ITEMS =
  "/checklists/:checklistTemplateId/template-items";
export const POST_CHECKLISTS_ID_TEMPLATE_ITEMS =
  "/checklists/:checklistTemplateId/template-items";
export const GET_CHECKLISTS_ID_TEMPLATE_ITEMS_ID =
  "/checklists/:checklistTemplateId/template-items/:id";
export const PUT_CHECKLISTS_ID_TEMPLATE_ITEMS_ID =
  "/checklists/:checklistTemplateId/template-items/:id";
export const DELETE_CHECKLISTS_ID_TEMPLATE_ITEMS_ID =
  "/checklists/:checklistTemplateId/template-items/:id";

export const GET_CHECKLISTS = "/checklists";
export const GET_CHECKLISTS_MY_OPEN_ITEMS = "/checklists/my-open-items";
export const GET_CHECKLISTS_ALL_OPEN_ITEMS = "/checklists/all-open-items";
export const GET_CHECKLISTS_USERS = "/checklists/users";
export const POST_CHECKLISTS = "/checklists";
export const GET_CHECKLISTS_ID = "/checklists/:id";
export const PUT_CHECKLISTS_ID = "/checklists/:id";
export const DELETE_CHECKLISTS_ID = "/checklists/:id";

export const GET_CHECKLISTS_ID_ITEMS = "/checklists/:checklistId/items";
export const POST_CHECKLISTS_ID_ITEMS = "/checklists/:checklistId/items";
export const GET_CHECKLISTS_ID_ITEMS_ID = "/checklists/:checklistId/items/:id";
export const PUT_CHECKLISTS_ID_ITEMS_ID = "/checklists/:checklistId/items/:id";
export const DELETE_CHECKLISTS_ID_ITEMS_ID =
  "/checklists/:checklistId/items/:id";
export const PUT_CHECKLISTS_ID_CHECK_ITEMS_ID =
  "/checklists/:checklistId/check-items/:id";
export const PUT_CHECKLISTS_ID_BATCH_ITEMS_ID =
  "/checklists/:checklistId/batch-items";

// Documents
export const GET_CUSTOMERS_ID_DOCUMENTS = "/customers/:customerId/documents";
export const GET_CUSTOMERS_ID_DOCUMENTS_ID =
  "/customers/:customerId/documents/:id";
export const POST_CUSTOMERS_ID_DOCUMENTS = "/customers/:customerId/documents";
export const PUT_CUSTOMERS_ID_DOCUMENTS_ID =
  "/customers/:customerId/documents/:id";
export const DELETE_CUSTOMERS_ID_DOCUMENTS_ID =
  "/customers/:customerId/documents/:id";

export const POST_CUSTOMERS_DOCUMENTS_PUBLIC = "/customers/documents";
export const PUT_CUSTOMERS_DOCUMENTS_ID_PUBLIC = "/customers/documents/:id";
export const GET_CUSTOMERS_DOCUMENTS_ID_PUBLIC = "/customers/documents/:id";
export const DELETE_CUSTOMERS_DOCUMENTS_ID_PUBLIC = "/customers/documents/:id";

export const POST_CUSTOMERS_DOCUMENTS_LINKS_PUBLIC =
  "/customers/documents/links";
export const PUT_CUSTOMERS_DOCUMENTS_LINKS_ID_PUBLIC =
  "/customers/documents/links/:id";
export const GET_CUSTOMERS_DOCUMENTS_LINKS_ID_PUBLIC =
  "/customers/documents/links/:id";
export const DELETE_CUSTOMERS_DOCUMENTS_LINKS_ID_PUBLIC =
  "/customers/documents/links/:id";

export const GET_DOCUMENTS_CATEGORIES = "/documents/categories";
export const GET_DOCUMENTS_CATEGORIES_ID = "/documents/categories/:id";
export const DELETE_DOCUMENTS_CATEGORIES_ID = "/documents/categories/:id";
export const POST_DOCUMENTS_CATEGORIES = "/documents/categories";
export const PUT_DOCUMENTS_CATEGORIES_ID = "/documents/categories/:id";

export const GET_DOCUMENTS_TYPES = "/documents/types";
