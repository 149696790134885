import { Enum, Type } from 'hello-type' // Check datatypes from API stream

export const idAsString = new Type({
  id: String
})

/** 
* Definition of checklists for the expected API properties which will be checked on API call
* @constant
*/
export const templateList = new Type({
  id: String,
  name: String
})

export const template = new Type({
  id: String,
  name: String  
})

export const templateItemList = new Type({
  id: String,
  name: String,
  order: Number,
  type: Enum(0, 1)
})

export const templateItem = new Type({
  oldOrder: Number,
  checklistTemplate: {
    id: String,
    name: String
  },
  order: Number,
  id: String,
  name: String,
  type: Enum(0, 1)
})

export const checklistList = new Type({
  itemsOverdue: Number,
  itemsToday: Number,
  itemsOpen: Number,
  firstOverdueDate: Enum(null, String),
  id: String,
  name: String,
  dateCreated: String,
  equipment_id: String,
  equipment_name: String,
  status: String
})

export const checklist = new Type({
  itemsToday: Number,
  status: String,
  dateCreated: String,
  equipment: 
  {
      id: String,
      name: String
  },
  id: String,
  name: String,
  itemsOverdue: Number,
  itemsOpen: Number,
  firstOverdueDate: String,
})

export const checklistOpenItemsList = new Type({
  dateCreated: String,
  id: String,
  name: String,
  assignedTo: String,
  itemsOverdue: Number,
  itemsToday: Number,
  itemsOpen: Number,
  firstOverdueDate: Enum(null, String),
  status: Enum('overdue', 'todoToday', 'open', 'closed'),
  equipment: {
    id: String,
    name: String,
    type: Enum(1,4)
  }, 
  items: Array({
    id: String,
    name: String,
    dateDue: String,
    assignedTo: String,
    order: Number,
  }) 
})

export const checklistUserList = new Type({
  id: String,
  name: String
})

export const checklistItemList = new Type({
  id: String,
  name: String,
  order: Number,
  type: Enum(0,1),
  dateCreated: String,
  dateDue: Enum(null, String),
  dateFinished: Enum(null, String),
  assignedTo: Enum(null, String),
  completedBy: Enum(null, String),
  statusCode: Enum(0, 1)
})

export const checklistItem = new Type({
  oldOrder: Number,
  dateFinished: Enum(null, String),
  assignedTo: String,
  checklist: {
    id: String,
    name: String
  },
  order: Number,
  dateCreated: String,
  dateDue: String,
  id: String,
  name: String,
  completedBy: Enum(null, String),
  type: Enum(0, 1),
  checked: Boolean
})

export const batchChecklistItems = new Type({
  idArray: Array(String)
})