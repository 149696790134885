export default {
  namespaced: true,

  state: function () {
    return {
      // Since axios request are async, multiple requests
      // can run at the same time.
      // We increment the counter on each axios request and
      // decrement is when a request is finished.
      // The Loading Overlay will be shown if counter > 0
      counter: 0
    }
  },

  getters: {
    getShowLoading: state => {
      return state.counter > 0
    }
  },

  mutations: {
    incrementCounter (state) {
      state.counter++
    },

    decrementCounter (state) {
      state.counter--
    }
  },

  actions: {
    incrementCounter (context) {
      context.commit('incrementCounter')
    },

    decrementCounter (context) {
      context.commit('decrementCounter')
    }
  }
}
