var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("v-text-field", {
        attrs: {
          label: "Select...",
          value: _vm.computedFileName,
          "prepend-icon": "attach_file"
        },
        on: { click: _vm.onPickFile }
      }),
      _c("input", {
        ref: "fileInput",
        staticStyle: { display: "none" },
        attrs: { type: "file", accept: "*/*" },
        on: { change: _vm.onFilePicked }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }