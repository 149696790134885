import { render, staticRenderFns } from "./BaseWelcome.vue?vue&type=template&id=574abfa3&scoped=true&"
import script from "./BaseWelcome.vue?vue&type=script&lang=js&"
export * from "./BaseWelcome.vue?vue&type=script&lang=js&"
import style0 from "./BaseWelcome.vue?vue&type=style&index=0&id=574abfa3&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "574abfa3",
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VForm } from 'vuetify/lib/components/VForm';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VList } from 'vuetify/lib/components/VList';
import { VListTile } from 'vuetify/lib/components/VList';
import { VListTileAvatar } from 'vuetify/lib/components/VList';
import { VListTileContent } from 'vuetify/lib/components/VList';
import { VListTileSubTitle } from 'vuetify/lib/components/VList';
import { VListTileTitle } from 'vuetify/lib/components/VList';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';
installComponents(component, {VBtn,VCard,VContainer,VFlex,VForm,VIcon,VLayout,VList,VListTile,VListTileAvatar,VListTileContent,VListTileSubTitle,VListTileTitle,VToolbar,VToolbarTitle})


/* hot reload */
if (module.hot) {
  var api = require("/var/www/sites/mysite4u/frontend.mysite4u.nl/sources/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('574abfa3')) {
      api.createRecord('574abfa3', component.options)
    } else {
      api.reload('574abfa3', component.options)
    }
    module.hot.accept("./BaseWelcome.vue?vue&type=template&id=574abfa3&scoped=true&", function () {
      api.rerender('574abfa3', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/Base/BaseWelcome.vue"
export default component.exports