import { Enum, Type, IfExists } from 'hello-type' // Check datatypes from API stream

export const idAsString = new Type({
  id: String
})

/** 
 * Definition of Anti Spam Settings type for the expected API properties which will be checked on API call
 * @constant
*/
export const antiSpamSettings = new Type({
  primaryDomain: IfExists(String),
  aliases: IfExists(Array(
    IfExists(String)
  )),
  destinations: IfExists(Array(
    String
  )),
  antiSpamType: Enum('none', 'domain', 'alias'),
  outgoingUserCount: IfExists(Number)
})

export const antiSpamAliasTargets = new Type({
  id: String,
  name: String
})