// Repository.js

import axios from 'axios'
import { store } from '@/store/store'
import Logger from '@/lib/Logger.js'
import Helpers from '@/lib/Helpers.js'
import * as Constants from '@/lib/constants.js'

import AppError from '@/lib/customExceptions/AppError.js'


// Users opzoeken in lastpass: test_
const baseDomain = process.env.VUE_APP_API_DOMAIN
const baseURL = `${baseDomain}${process.env.VUE_APP_API_BASE_URL}`

//const moduleName = 'Repository'

    // "CREATED" 				    : 201,  // Axios Response
		// "ACCEPTED" 				  : 202,  // Axios Response
		// "SUCCESS" 				    : 200,  // Axios Response
		// "NO_CONTENT" 		  	: 204,  // Axios Response
		// "RESET" 			      	: 205,  // Axios Response, nog niet gebruikt      
		// "PARTIAL_CONTENT" 		: 206,  // Axios Response, nog niet gebruikt
		// "BAD_REQUEST" 		  	: 400,  // Axios Reject, validation
		// "NOT_AUTHORIZED" 		: 403,  // Axios Reject
		// "NOT_AUTHENTICATED" 	: 401,  // Axios Reject
		// "NOT_FOUND" 		    	: 404,  // Axios Reject
		// "NOT_ALLOWED" 		  	: 405,  // Axios Reject
		// "NOT_ACCEPTABLE" 		: 406,  // Axios Reject, nog niet gebruikt
		// "TOO_MANY_REQUESTS" 	: 429,  // Axios Reject, nog niet gebruikt
		// "EXPECTATION_FAILED" : 417,  // Axios Reject, nog niet gebruikt
		// "INTERNAL_ERROR" 		: 500,  // Axios Reject
    // "NOT_IMPLEMENTED" 		: 501   // Axios Reject, nog niet gebruikt
    
class Api {

  constructor() {

    axios.defaults.withCredentials = true
    this.api = axios.create({
      baseURL,
    })

    // Add a request interceptor
    this.api.interceptors.request.use(function (config) {
      // Increment Loading-Overlay counter
      // delay for 500 ms to hide the overlay for short requests
      setTimeout(
          function () { 
            store.dispatch(`loadingOverlay/incrementCounter`) 
          } 
      ,500)
      
      config.headers['Accept-Language'] =  localStorage.getItem('language') ? localStorage.getItem('language') : Constants.DEFAULT_LANGUAGE

      return config;
    }, function (error) {
      // Do something with request error
      store.dispatch(`loadingOverlay/decrementCounter`)
      return Promise.reject(error);
    });

    // Add a response interceptor
    this.api.interceptors.response.use(function (response) {
      store.dispatch(`loadingOverlay/decrementCounter`)
      store.dispatch(`checkVersionChange`, response)
      if (response.headers['content-type'].search('application/json') >= 0) {
        // Content-type application/json
        return response.data
      }
      else if (response.headers['content-type'].search('application/problem+json') >= 0) {
        // In case of an 200 or 201, there should never be an application/problem+json
        return Promise.reject(Error(`Content-type ${response.headers['content-type']} is invalid with status code ${response.status}`))
      }
      else {
        // Every other content-type, just return the response
        // Ie PDF's etc.
        return response
      }
    }, function (error) {
        store.dispatch(`loadingOverlay/decrementCounter`)
        if (!(error.response.headers['content-type'].search(/application\/problem\+json/) >= 0)) {
          // In every error the content-type should be application/problem+json
          return Promise.reject(Error(`Content-type ${error.response.headers['content-type']} is invalid, this should be application/problem+json`))
        }

      return Promise.reject(
          new AppError(JSON.parse(JSON.stringify(error.response.data)))
        );
    });
  }

  /**
   *
   *
   * @param {String} endpoint
   * @param {Object} [dataType=null]
   * @param {String} [callingModule=null]
   * @returns {Object}
   * @memberof Api
   */
  async get(endpoint, dataType = null, callingModule = null) {
    Logger.info(`Repository.get() called for ${callingModule}`)
    var response = 
      await this.api.get(endpoint, {
        headers: {
          'Authorization': `Bearer ${store.getters['security/getToken']}`
        }
      })

    if (dataType) {
      Helpers.assertApiData(callingModule, dataType, response)
    }
    return response.data
  }

  async getBlob (endpoint, dataType = null, callingModule = null) {
    Logger.info(`Repository.getBlob() called for ${callingModule}`)
    var response = 
      await this.api.get(endpoint, {
        responseType: 'blob',
        headers: {
          'Authorization': `Bearer ${store.getters['security/getToken']}`,
          'Accept': 'application/octet-stream',
        }
      })


    if (dataType) {
      Helpers.assertApiData(callingModule, dataType, response)
    }
    return response
  }

  /**
   * Post, Create a new record through the api
   *
   * @param {String} endpoint
   * @param {Object} payload
   * @param {Object} [dataType=null]
   * @param {String} [callingModule=null]
   * @returns {Object}
   * @memberof Api
   */
  async post (endpoint, payload, dataType = null, callingModule = null) {
    Logger.info(`Repository.post() called for ${callingModule}`)
    // Omit all fields with null-values in payload
    var field
    var postObject = {}
    for (field in payload) {
      if (payload[field] !== null) {
        postObject[field] = payload[field]
      }
    }

    var response = await this.api.post(
      endpoint, postObject, {
        headers: {
          'Authorization': `Bearer ${store.getters['security/getToken']}`
        },
        'Content-Type': 'application/json',
    })
    if (dataType) {
      Helpers.assertApiData(callingModule, dataType, response)
    }
    return response
  }

  /**
   * PostFormData, Create a new record through the api
   *
   * @param {String} endpoint
   * @param {Object} payload
   * @param {Object} [dataType=null]
   * @param {String} [callingModule=null]
   * @returns {Object}
   * @memberof Api
   */
  async postFormData (endpoint, payload, dataType = null, callingModule = null) {
    Logger.info(`Repository.postFormData() called for ${callingModule}`)
    // Omit all fields with null-values in payload
    var field
    var formData = new FormData()
    for (field in payload) {
      if (payload[field] !== null) {
        formData.append(field, payload[field])
      }
    }

    var response = await this.api.post(
      endpoint, formData, {
        headers: {
          'Authorization': `Bearer ${store.getters['security/getToken']}`
        },
        'Content-Type': 'multipart/form-data',
    })

    if (dataType) {
      Helpers.assertApiData(callingModule, dataType, response)
    }
    return response
  }

  /**
   * Put, Update data through the api
   *
   * @param {String} endpoint
   * @param {Object} payload
   * @param {Object} [dataType=null]
   * @param {String} [callingModule=null]
   * @returns
   * @memberof Api
   */
  async put (endpoint, payload, dataType = null, callingModule = null) {
    Logger.info(`Repository.put() called for ${callingModule}`)
    var response = await this.api.put(
      endpoint, payload, {
        headers: {
          'Authorization': `Bearer ${store.getters['security/getToken']}`
        },
        'Content-Type': 'application/json',
    })
    if (dataType) {
      Helpers.assertApiData(callingModule, dataType, response)
    }
    return response.data
  }

  /**
   * Deleting an item through the api
   *
   * @param {String} endpoint
   * @param {String} [callingModule=null]
   * @returns
   * @memberof Api
   */
  async delete (endpoint, callingModule = null) {
    Logger.info(`Repository.delete() called for ${callingModule}`)
    var response = await this.api.delete(
      endpoint, {
        headers: {
          'Authorization': `Bearer ${store.getters['security/getToken']}`
        }
      }
    )
    return response
  }
}

export let api = new Api();

// Export api
export default api