<template>
  <v-layout row justify-center>
    <v-dialog 
      v-model="show"
      persistent 
      :max-width="maxWidth"
    >
      <v-card>
          <v-card-title class="headline">
            {{ title }}
          </v-card-title>

          <!-- Alert box for API Error Messages //-->
          <base-api-error-messages-dialog
            :apiErrorMessages="apiErrorMessages"
          >
          </base-api-error-messages-dialog>            

          <v-card-text>
            <slot>
            </slot>
            <small v-if="!hideRequired">*{{ $t('VALIDATION.INDICATES_REQUIRED')}}</small>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn v-if="!hideCancelButton" color="blue darken-1" flat @click.native="$emit('cancel-clicked')">{{ $t('BUTTON.CANCEL') }}</v-btn>
            <v-btn v-if="!hideOkButton" :disabled="saveButtonClicked" color="green darken-1" flat @click.native="saveClicked()">{{ $t('BUTTON.OK') }}</v-btn>
            <v-btn v-if="showCloseButton" color="green darken-1" flat @click.native="$emit('close-clicked')">{{ $t('BUTTON.CLOSE') }}</v-btn>
          </v-card-actions>
      </v-card>
    </v-dialog>
  </v-layout>
</template>

<script>
import * as Constants from '@/lib/constants.js'
import AppError from '@/lib/customExceptions/AppError.js'

export default {
  props: {
    hideRequired: {
      type: Boolean,
      default: false
    },
    maxWidth: {
      type: String,
      default: '500px'
    },
    title: {
      type: String,
      default: null
    },
    apiErrorMessages: AppError,
    show: {
      type: Boolean,
      default: false
    },
    hideOkButton: {
      type: Boolean,
      default: false
    },
    hideCancelButton: {
      type: Boolean,
      default: false
    },
    showCloseButton: {
      type: Boolean,
      default: false
    }
  },

  data: function () {
    return {
      saveButtonClicked: false,
      isMounted: false
    }
  },

  watch: {
    show (val) {
      if (val) {
        this.saveButtonClicked = false
      }
    },
  },

  methods: {
    saveClicked() {
      if (!this.saveButtonClicked) {
        this.saveButtonClicked = true
        setTimeout(() => {
          this.saveButtonClicked = false
        }, Constants.SAVE_BUTTON_DEBOUNCE);

        this.$emit('save-clicked')
      }
    }
  },
}
</script>
