var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-layout",
    { attrs: { row: "", "justify-center": "" } },
    [
      _c(
        "v-dialog",
        {
          attrs: { persistent: "", "max-width": _vm.maxWidth },
          model: {
            value: _vm.show,
            callback: function($$v) {
              _vm.show = $$v
            },
            expression: "show"
          }
        },
        [
          _c(
            "v-card",
            [
              _c("v-card-title", { staticClass: "headline" }, [
                _vm._v(" " + _vm._s(_vm.title) + " ")
              ]),
              _c("base-api-error-messages-dialog", {
                attrs: { apiErrorMessages: _vm.apiErrorMessages }
              }),
              _c(
                "v-card-text",
                [
                  _vm._t("default"),
                  !_vm.hideRequired
                    ? _c("small", [
                        _vm._v(
                          "*" + _vm._s(_vm.$t("VALIDATION.INDICATES_REQUIRED"))
                        )
                      ])
                    : _vm._e()
                ],
                2
              ),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  !_vm.hideCancelButton
                    ? _c(
                        "v-btn",
                        {
                          attrs: { color: "blue darken-1", flat: "" },
                          nativeOn: {
                            click: function($event) {
                              return _vm.$emit("cancel-clicked")
                            }
                          }
                        },
                        [_vm._v(_vm._s(_vm.$t("BUTTON.CANCEL")))]
                      )
                    : _vm._e(),
                  !_vm.hideOkButton
                    ? _c(
                        "v-btn",
                        {
                          attrs: {
                            disabled: _vm.saveButtonClicked,
                            color: "green darken-1",
                            flat: ""
                          },
                          nativeOn: {
                            click: function($event) {
                              return _vm.saveClicked()
                            }
                          }
                        },
                        [_vm._v(_vm._s(_vm.$t("BUTTON.OK")))]
                      )
                    : _vm._e(),
                  _vm.showCloseButton
                    ? _c(
                        "v-btn",
                        {
                          attrs: { color: "green darken-1", flat: "" },
                          nativeOn: {
                            click: function($event) {
                              return _vm.$emit("close-clicked")
                            }
                          }
                        },
                        [_vm._v(_vm._s(_vm.$t("BUTTON.CLOSE")))]
                      )
                    : _vm._e()
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }