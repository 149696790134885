<template>
  <!-- Alert box for API Error Messages //-->
  <v-container 
    v-if="apiErrorMessages" 
    class="pb-0"
  >
    <v-alert
      :value="true"
      type="error"
    >
      {{ apiErrorMessages.title }}
      <div v-html="validationErrors"></div>
    </v-alert>            
  </v-container>
</template>

<script>
import AppError from '@/lib/customExceptions/AppError.js'

export default {

  props: {
    apiErrorMessages: AppError
  },

  data: function () {
    return {
    }
  },

  computed: {
    validationErrors() {
      let resultHtml = ''

      if (this.apiErrorMessages.validationErrors) {
        resultHtml += '<ul>'
        for (var key in this.apiErrorMessages.validationErrors) {
          resultHtml += `<li>${key}:</li>`
          resultHtml += '<ul>'
          this.apiErrorMessages.validationErrors[key].forEach(element => resultHtml += `<li>${element.message}</li>`)
          resultHtml += '</ul>'
        }
        resultHtml += '</ul>'
      }
      return resultHtml
    }
  }
}
</script>
