<template>
  <v-layout v-if="stateGetShowDialog" row justify-center>
    <v-dialog :value="true" persistent max-width="400">
      <v-card>
        <v-card-title class="headline">{{ $t('BASE.USER_MESSAGE_TITLE') }}</v-card-title>
        <v-card-text>
          {{ stateGetUserMessage }}
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" flat @click.native="close()">{{ $t('BUTTON.CLOSE') }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-layout>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
  data: function () {
    return {
    }
  },

  computed: {
    ...mapGetters({
      'stateGetUserMessage': `userMessages/getUserMessage`,
      'stateGetShowDialog': `userMessages/getShowDialog`,
    }),
  },

  methods: {
    ...mapActions({
      'stateResetUserMessage': `userMessages/resetUserMessage`
    }),

    close() {
      this.stateResetUserMessage()
    },

    cancel() {
      this.resolve(false)
      this.close()
    }
  }
}
</script>
