import { RepositoryFactory } from '@/api/RepositoryFactory'
const LicensesRepository = RepositoryFactory.get('licenses')

export default {
  namespaced: true,

  state: function () {
    return {
      categoryList: undefined,
    }
  },

  getters: {
    getCategoryList: state => {
      return state.categoryList
    },
  },

  mutations: {
    setCategoryList (state, payload) {
      state.categoryList = payload
    },

    clearCache (state) {
      state.categoryList = undefined
    }
  },

  actions: {
    setCategoryList (context, payload) {
      context.commit('setCategoryList', payload)
    },

    clearCache ({ commit }) {
      commit('clearCache')
    },

    // Load Data via API
    async loadCategoryList (context, payload = {}) {
      if (context.getters['getCategoryList'] && !payload.force) {
        // The items are already stored
        return
      }
      try {
        const data = await LicensesRepository.getCategoryList()
        context.dispatch('setCategoryList', data)
      }
      catch(e) {
        context.dispatch(`applicationLog/log`, `Failed loading categoryList: ${e}`, {root:true})
      }
    },

  }
}
