var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("v-text-field", {
    attrs: {
      value: _vm.value,
      "append-icon": _vm.showPassword ? "visibility" : "visibility_off",
      rules: [
        _vm.validationRules.required,
        _vm.validationRules.passwordStrength
      ],
      type: _vm.showPassword ? "text" : "password",
      label: _vm.label,
      hint: _vm.$t("BASE.GLOBAL.PASSWORD_HINT", {
        field: _vm.minPasswordLength
      }),
      counter: "",
      loading: "",
      "validate-on-blur": ""
    },
    on: {
      input: function($event) {
        return _vm.$emit("input", $event)
      },
      "click:append": function($event) {
        _vm.showPassword = !_vm.showPassword
      }
    },
    scopedSlots: _vm._u([
      {
        key: "progress",
        fn: function() {
          return [
            _c("v-progress-linear", {
              attrs: {
                value: _vm.passwordStrength,
                color: _vm.passwordStrengthColor,
                height: "7"
              }
            })
          ]
        },
        proxy: true
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }