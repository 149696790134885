import Repository from '../Repository'
import Logger from '@/lib/Logger.js'
import Helpers from '@/lib/Helpers.js'
import * as DataTypes from './ChecklistsRepositoryDataTypes'
import * as ApiTypes from '@/api/api-types'

const moduleName = 'ChecklistsRepository'

/**
 * A module for handling API calls for checklists
 * @module ChecklistsRepository
 */
export default {

  /**
   * Get List of Checklist Templates
   *
   * @returns {Array} Array of objects
   */
  async getTemplateList () {
    Logger.info(`${moduleName}.getTemplates()`)
    return await Repository.get(
      ApiTypes.GET_CHECKLISTS_TEMPLATES,
      DataTypes.templateList,
      `${moduleName}.getTemplates()`
    )
  },

  /**
   * Post a new checklist Template
   * 
   * @alias module: ChecklistsRepository
   * @param {Array} payload Array of name/value pairs for domain properties
   * @param {String} validationField Fieldname to validate
   * @returns {Object} checkListTemplate object
   */
  async postTemplate (payload, validationField = null) {
    Logger.info(`${moduleName}.postTemplate(validationField): called with validationField ${validationField}`)
    let urlParams = validationField ? `?validateOnly=true&validateFields=${validationField}` : ''
    return await Repository.post(
      `${ApiTypes.POST_CHECKLISTS_TEMPLATES}${urlParams}`,
      payload,
      DataTypes.idAsString,
      `${moduleName}.postTemplate()`
    )
  },

  /**
   * Get a specific checklist template
   *
   * @alias module: ChecklistsRepository
   * @param {String} templateId
   * @returns {Object} checklistTemplate object
   */
  async getTemplate (templateId) {
    Logger.info(`${moduleName}.getTemplate(templateId): called with templateId ${templateId}`)
    return await Repository.get(
      Helpers.substituteApiVars(ApiTypes.GET_CHECKLISTS_TEMPLATES_ID, { id: templateId }),
      DataTypes.template,
      `${moduleName}.getTemplate()`
    )
  },
 
  /**
   * Update checklist template
   * 
   * @alias module: ChecklistsRepository
   * @param {String} validationField Fieldname to validate
   * @param {Object} payload checklistTemplate object
   */
  async putTemplate (payload, validationField = null) {
    Logger.info(`${moduleName}.updateTemplate(validationField): called with validationField ${validationField}`)
    let urlParams = validationField ? `?validateOnly=true&validateFields=${validationField}` : ''
    return await Repository.put(
      Helpers.substituteApiVars(`${ApiTypes.PUT_CHECKLISTS_TEMPLATES_ID}${urlParams}`, { id: payload.id }),
      payload,
      null,
      `${moduleName}.putTemplate()`
    )
  },

  /**
   * Delete a checklist template
   * 
   * @alias module: ChecklistsRepository
   * @param {String} templateId
   */
  async deleteTemplate (templateId) {
    Logger.info(`${moduleName}.deleteTemplate(templateId): called with templateId ${templateId}`)
    return await Repository.delete(
      Helpers.substituteApiVars(ApiTypes.DELETE_CHECKLISTS_TEMPLATES_ID, { id: templateId }),
      `${moduleName}.deleteTemplate()`
    )
  },



  /**
   * Get List of Checklist-items from a specified Template
   *
   * @alias module: ChecklistsRepository
   * @param {String} templateId
   * @returns {Array} Array of objects
   */
  async getTemplateItems (templateId) {
    Logger.info(`${moduleName}.getTemplateItems(templateId): called with templateId ${templateId}`)
    return await Repository.get(
      Helpers.substituteApiVars(ApiTypes.GET_CHECKLISTS_ID_TEMPLATE_ITEMS, { checklistTemplateId: templateId }),
      DataTypes.templateItemList,
      `${moduleName}.getTemplateItems()`
    )
  },

  /**
   * Post a new template item
   * 
   * @alias module: ChecklistsRepository
   * @param {String} templateId TemplateId
   * @param {Array} payload Object
   * @param {String} validationField Fieldname to validate
   * @returns {Object} checkListTemplateItem object
   */
  async postTemplateItem (templateId, payload, validationField = null) {
    Logger.info(`${moduleName}.postTemplateItem(validationField): called with validationField ${validationField}`)
    let urlParams = validationField ? `?validateOnly=true&validateFields=${validationField}` : ''
    return await Repository.post(
      Helpers.substituteApiVars(`${ApiTypes.POST_CHECKLISTS_ID_TEMPLATE_ITEMS}${urlParams}`, {checklistTemplateId: templateId }),
      payload,
      DataTypes.idAsString,
      `${moduleName}.postTemplateItem()`
    )
  },

  /**
   * Get a specific checklist template item
   *
   * @alias module: ChecklistsRepository
   * @param {String} templateId
   * @param {String} templateItemId
   * @returns {Object} checklistTemplate object
   */
  async getTemplateItem (templateId, templateItemId) {
    Logger.info(`${moduleName}.getTemplateItem(templateId, templateItemId): called with templateId ${templateId} and templateItemId ${templateItemId}`)
    return await Repository.get(
      Helpers.substituteApiVars(ApiTypes.GET_CHECKLISTS_ID_TEMPLATE_ITEMS_ID, { checklistTemplateId: templateId, id: templateItemId }),
      DataTypes.templateItem,
      `${moduleName}.getTemplateItem()`
    )
  },
 
  /**
   * Update checklist template item
   * 
   * @alias module: ChecklistsRepository
   * @param {String} templateId
   * @param {String} validationField Fieldname to validate
   * @param {Object} payload checklistTemplateItem object
   */
  async putTemplateItem (templateId, payload, validationField = null) {
    Logger.info(`${moduleName}.updateTemplateItem(templateId, validationField): called with templateId ${templateId} and validationField ${validationField}`)
    let urlParams = validationField ? `?validateOnly=true&validateFields=${validationField}` : ''
    return await Repository.put(
      Helpers.substituteApiVars(`${ApiTypes.PUT_CHECKLISTS_ID_TEMPLATE_ITEMS_ID}${urlParams}`, { checklistTemplateId: templateId, id: payload.id }),
      payload,
      null,
      `${moduleName}.putTemplateItem()`
    )
  },

  /**
   * Delete a checklist template item
   * 
   * @alias module: ChecklistsRepository
   * @param {String} templateId
   * @param {String} templateItemId
   */
  async deleteTemplateItem (templateId, templateItemId) {
    Logger.info(`${moduleName}.deleteTemplateItem(templateId, templateItemId): called with templateId ${templateId} and templateItemId ${templateItemId}`)
    return await Repository.delete(
      Helpers.substituteApiVars(ApiTypes.DELETE_CHECKLISTS_ID_TEMPLATE_ITEMS_ID, { checklistTemplateId: templateId, id: templateItemId }),
      `${moduleName}.deleteTemplateItem()`
    )
  },


  /**
   * Get List of Checklists
   *
   * @returns {Array} Array of objects
   */
  async getChecklists (equipmentId = null) {
    Logger.info(`${moduleName}.getChecklists(equipmentId): called with equipmentId ${equipmentId}`)
    let urlParams = equipmentId ? `?equipmentId=${equipmentId}` : ''
    return await Repository.get(
      `${ApiTypes.GET_CHECKLISTS}${urlParams}`,
      DataTypes.checklistList,
      `${moduleName}.getChecklists()`
    )
  },

  /**
   * Get List of Checklists with Open Items for current user
   *
   * @returns {Array} Array of objects
   */
  async getChecklistsMyOpenItems () {
    Logger.info(`${moduleName}.getChecklistsMyOpenItems()`)
    return await Repository.get(
      ApiTypes.GET_CHECKLISTS_MY_OPEN_ITEMS,
      DataTypes.checklistOpenItemsList,
      `${moduleName}.getChecklistsMyOpenItems()`
    )
  },

  /**
   * Get List of Checklists with Open Items for all users
   *
   * @returns {Array} Array of objects
   */
  async getChecklistsAllOpenItems () {
    Logger.info(`${moduleName}.getChecklistsAllOpenItems()`)
    return await Repository.get(
      ApiTypes.GET_CHECKLISTS_ALL_OPEN_ITEMS,
      DataTypes.checklistOpenItemsList,
      `${moduleName}.getChecklistsAllOpenItems()`
    )
  },

  /**
   * Get List of available users for Checklists
   *
   * @returns {Array} Array of objects
   */
  async getChecklistUsers () {
    Logger.info(`${moduleName}.getChecklistUsers()`)
    return await Repository.get(
      ApiTypes.GET_CHECKLISTS_USERS,
      DataTypes.checklistUserList,
      `${moduleName}.getChecklistUsers()`
    )
  },

  /**
   * Post a new checklist
   * 
   * @alias module: ChecklistsRepository
   * @param {Array} payload Array of name/value pairs for domain properties
   * @param {String} validationField Fieldname to validate
   * @returns {Object} checkList object
   */
  async postChecklist (payload, validationField = null) {
    Logger.info(`${moduleName}.postChecklist(validationField): called with validationField ${validationField}`)
    let urlParams = validationField ? `?validateOnly=true&validateFields=${validationField}` : ''
    return await Repository.post(
      `${ApiTypes.POST_CHECKLISTS}${urlParams}`,
      payload,
      DataTypes.idAsString,
      `${moduleName}.postChecklist()`
    )
  },

  /**
   * Get a specific checklist
   *
   * @alias module: ChecklistsRepository
   * @param {String} checklistId
   * @returns {Object} checklist object
   */
  async getChecklist (checklistId) {
    Logger.info(`${moduleName}.getChecklist(checklistId): called with checklistId ${checklistId}`)
    return await Repository.get(
      Helpers.substituteApiVars(ApiTypes.GET_CHECKLISTS_ID, { id: checklistId }),
      DataTypes.checklist,
      `${moduleName}.getChecklist()`
    )
  },

  /**
   * Update checklist
   * 
   * @alias module: ChecklistsRepository
   * @param {String} validationField Fieldname to validate
   * @param {Object} payload checklist object
   * @returns {Object} checklist object
   */
  async putChecklist (payload, validationField = null) {
    Logger.info(`${moduleName}.updateChecklist(validationField): called with validationField ${validationField}`)
    let urlParams = validationField ? `?validateOnly=true&validateFields=${validationField}` : ''
    return await Repository.put(
      Helpers.substituteApiVars(`${ApiTypes.PUT_CHECKLISTS_ID}${urlParams}`, { id: payload.id }),
      payload,
      null,
      `${moduleName}.putChecklist()`
    )
  },

  /**
   * Delete a checklist
   * 
   * @alias module: ChecklistsRepository
   * @param {String} checklistId
   */
  async deleteChecklist (checklistId) {
    Logger.info(`${moduleName}.deleteChecklist(checklistId): called with checklistId ${checklistId}`)
    return await Repository.delete(
      Helpers.substituteApiVars(ApiTypes.DELETE_CHECKLISTS_ID, { id: checklistId }),
      `${moduleName}.deleteChecklist()`
    )
  },



  /**
   * Get List of Checklist-items from a specified checklist
   *
   * @alias module: ChecklistsRepository
   * @param {String} checklistId
   * @returns {Array} Array of objects
   */
  async getChecklistItems (checklistId) {
    Logger.info(`${moduleName}.getChecklistItems(checklistId): called with checklistId ${checklistId}`)
    return await Repository.get(
      Helpers.substituteApiVars(ApiTypes.GET_CHECKLISTS_ID_ITEMS, { checklistId: checklistId }),
      DataTypes.checklistItemList,
      `${moduleName}.getChecklistItems()`
    )
  },

  /**
   * Post a new checklist item
   * 
   * @alias module: ChecklistsRepository
   * @param {String} checklistId ChecklistId
   * @param {Array} payload Object
   * @param {String} validationField Fieldname to validate
   * @returns {Object} checkListItem object
   */
  async postChecklistItem (checklistId, payload, validationField = null) {
    Logger.info(`${moduleName}.postChecklistItem(validationField): called with validationField ${validationField}`)
    let urlParams = validationField ? `?validateOnly=true&validateFields=${validationField}` : ''
    return await Repository.post(
      Helpers.substituteApiVars(`${ApiTypes.POST_CHECKLISTS_ID_ITEMS}${urlParams}`, {checklistId: checklistId }),
      payload,
      DataTypes.idAsString,
      `${moduleName}.postChecklistItem()`
    )
  },

  /**
   * Get a specific checklist item
   *
   * @alias module: ChecklistsRepository
   * @param {String} checklistId
   * @param {String} itemId
   * @returns {Object} checklistItem object
   */
  async getChecklistItem (checklistId, itemId) {
    Logger.info(`${moduleName}.getChecklistItem(checklistId, itemId): called with checklistId ${checklistId} and itemId ${itemId}`)
    return await Repository.get(
      Helpers.substituteApiVars(ApiTypes.GET_CHECKLISTS_ID_ITEMS_ID, { checklistId: checklistId, id: itemId }),
      DataTypes.checklistItem,
      `${moduleName}.getChecklistItem()`
    )
  },
 
  /**
   * Update checklist  item
   * 
   * @alias module: ChecklistsRepository
   * @param {String} checklistId
   * @param {String} validationField Fieldname to validate
   * @param {Object} payload checklistItem object
   */
  async putChecklistItem (checklistId, payload, validationField = null) {
    Logger.info(`${moduleName}.updateChecklistItem(checklistId, validationField): called with checklistId ${checklistId} and validationField ${validationField}`)
    let urlParams = validationField ? `?validateOnly=true&validateFields=${validationField}` : ''
    return await Repository.put(
      Helpers.substituteApiVars(`${ApiTypes.PUT_CHECKLISTS_ID_ITEMS_ID}${urlParams}`, { checklistId: checklistId, id: payload.id }),
      payload,
      null,
      `${moduleName}.putChecklistItem()`
    )
  },

  /**
   * Delete a checklist item
   * 
   * @alias module: ChecklistsRepository
   * @param {String} checklistId
   * @param {String} itemId
   */
  async deleteChecklistItem (checklistId, itemId) {
    Logger.info(`${moduleName}.deleteChecklistItem(checklistId, itemId): called with checklistId ${checklistId} and itemId ${itemId}`)
    return await Repository.delete(
      Helpers.substituteApiVars(ApiTypes.DELETE_CHECKLISTS_ID_ITEMS_ID, { checklistId: checklistId, id: itemId }),
      `${moduleName}.deleteChecklistItem()`
    )
  },

  /**
   * Check a checklist item
   * 
   * @alias module: ChecklistsRepository
   * @param {String} checklistId
   * @param {String} itemId
   * @param {Object} payload checklistItem object
   */
  async checkChecklistItem (checklistId, itemId, payload) {
    Logger.info(`${moduleName}.checkChecklistItem(checklistId, itemId): called with checklistId ${checklistId} and itemId ${itemId}`)
    return await Repository.put(
      Helpers.substituteApiVars(`${ApiTypes.PUT_CHECKLISTS_ID_CHECK_ITEMS_ID}`, { checklistId: checklistId, id: itemId }),
      payload,
      null,
      `${moduleName}.checkChecklistItem()`
    )
  },

  /**
   * Batch update checklist items
   * 
   * @alias module: ChecklistsRepository
   * @param {String} checklistId
   * @param {Object} payload batchChecklistItem object
   */
  async batchUpdateChecklistItems (checklistId, payload) {
    Logger.info(`${moduleName}.batchUpdateChecklistItems(checklistId): called with checklistId ${checklistId}`)
    return await Repository.put(
      Helpers.substituteApiVars(`${ApiTypes.PUT_CHECKLISTS_ID_BATCH_ITEMS_ID}`, { checklistId: checklistId }),
      payload,
      DataTypes.batchChecklistItems,
      `${moduleName}.batchUpdateChecklistItems()`
    )
  },
}
