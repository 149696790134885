import { Enum, Type } from 'hello-type' // Check datatypes from API stream

export const idAsString = new Type({
  id: String
})

/** 
 * Definition of Customers type for the expected API properties which will be checked on API call
 * @constant
*/
export const userList = new Type({
  id: String,
  name: String,
  isArchive: Boolean,
  email: String,
  dateLastLogin: Enum(String, null),
  rolesAsString: String,
  customer_id: String,
  customer_name: String,
  userContext_id: Enum('10', '20', '30', '40'),
  userContext_name: String
})

export const user = new Type({
  id: String,
  name: String,
  dateCreated: String,
  dateLastModified: String,
  isArchive: Boolean,
  rolesAsString: String,
  dateLastLogin: String,
  insertion: Enum(String, null),
  lastName: String,
  userContext: {
    id: Enum('10', '20', '30', '40'),
    name: String
  },
  firstName: String,
  email: String,
  roles: [
    {
      id: String,
      name: String,
      userContext_id: Enum('10', '20', '30', '40'),
    }
  ],
  customer: {
    id: String,
    name: String
  },
})

export const userContextRoleList = new Type({
  id: Enum('10', '20', '30', '40'),
  name: String,
  roles: [
    {
      id: String,
      name: String
    }
  ]  
})

export const passwordResetRequest = new Type({
  status: String
})

export const user2FAKey = new Type({
  multifactorAuthKey: String,
  QRCodestring: String
})

export const user2FARecoveryCodeList = new Type({
  recoveryCodes: Array(String)
})
