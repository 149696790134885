import { RepositoryFactory } from '@/api/RepositoryFactory'
const NetworkRepository = RepositoryFactory.get('network')

export default {
  namespaced: true,

  state: function () {
    return {
      ipPoolList: undefined,
      subnetList: undefined,
      vlanList: undefined,
      physicalNetworkedEquipmentList: undefined,
      tenantList: undefined,
      vdomList: undefined,
      vrfList: undefined,
    }
  },

  getters: {
    /**
     * Get list of IP pools
     * @returns {array}
     */ 
    getIpPoolList: state => {
      return state.ipPoolList || []
    },

    /**
     * Get list of sub nets
     * @returns {array}
     */ 
    getSubnetList: state => {
      return state.subnetList || []
    },

    /**
     * Get list of vlans
     * @returns {array}
     */ 
    getVlanList: state => {
      return state.vlanList || []
    },

    /**
     * Get list of tenants
     * @returns {array}
     */ 
    getTenantList: state => {
      return state.tenantList || []
    },

    /**
     * Get list of Vdoms
     * @returns {array}
     */ 
    getVdomList: state => {
      return state.vdomList || []
    },

    /**
     * Get list of vrfs
     * @returns {array}
     */ 
    getVrfList: state => {
      return state.vrfList || []
    },
  },

  mutations: {
    /**
     * Mutation: Set list of IP pools
     * @param {Object} state
     * @param {Array} payload
     */ 
    setIpPoolList (state, payload) {
      state.ipPoolList = payload
    },

    /**
     * Mutation: Set list of sub nets
     * @param {Object} state
     * @param {Array} payload
     */ 
    setSubnetList (state, payload) {
      state.subnetList = payload
    },

    /**
     * Mutation: Set list of vlans
     * @param {Object} state
     * @param {Array} payload
     */ 
    setVlanList (state, payload) {
      state.vlanList = payload
    },

    /**
     * Mutation: Set list of tenants
     * @param {Object} state
     * @param {Array} payload
     */ 
    setTenantList (state, payload) {
      state.tenantList = payload
    },

    /**
     * Mutation: Set list of Vdoms
     * @param {Object} state
     * @param {Array} payload
     */ 
    setVdomList (state, payload) {
      state.vdomList = payload
    },

    /**
     * Mutation: Set list of Vrfs
     * @param {Object} state
     * @param {Array} payload
     */ 
    setVrfList (state, payload) {
      state.vrfList = payload
    },

    clearCache (state) {
      state.ipPoolList = undefined
      state.subnetList = undefined
      state.vlanList = undefined
      state.tenantList = undefined
      state.vdomList = undefined
      state.vrfList = undefined
    }

  },

  actions: {
    clearCache ({ commit }) {
      commit('clearCache')
    },

    /**
     * Action: Set list of IP pools
     * @param {Object} context
     * @param {Array} payload
     */ 
    setIpPoolList (context, payload) {
      context.commit(`setIpPoolList`, payload)
    },

    /**
     * Action: Set list of sub nets
     * @param {Object} context
     * @param {Array} payload
     */ 
    setSubnetList (context, payload) {
      context.commit(`setSubnetList`, payload)
    },

    /**
     * Action: Set list of vlans
     * @param {Object} context
     * @param {Array} payload
     */ 
    setVlanList (context, payload) {
      context.commit(`setVlanList`, payload)
    },

    /**
     * Action: Load via API and store them, but only if items have not been stored yet.
     */ 
    async loadIpPoolList (context, payload = {}) {
      if (context.rootState.network.ipPoolList !== undefined && !payload.force) {
        // The items are already stored
        return
      }
      try {
        const data = await NetworkRepository.getIpPoolList()
        context.dispatch(`setIpPoolList`, data)
      }
      catch(e) {
        context.dispatch(`applicationLog/log`, `Failed loading items: ${e}`, {root:true})
      }
    },

    /**
     * Action: Set list of tenants
     * @param {Object} context
     * @param {Array} payload
     */ 
    setTenantList (context, payload) {
      context.commit(`setTenantList`, payload)
    },

    /**
     * Action: Set list of vdoms
     * @param {Object} context
     * @param {Array} payload
     */ 
    setVdomList (context, payload) {
      context.commit(`setVdomList`, payload)
    },

    /**
     * Action: Set list of Vrfs
     * @param {Object} context
     * @param {Array} payload
     */ 
    setVrfList (context, payload) {
      context.commit(`setVrfList`, payload)
    },
    

    /**
     * Action: Load subnet list
     */ 
    async loadSubnetList (context, payload = {}) {
      if (context.rootState.network.subnetList !== undefined && !payload.force) {
        // The items are already stored
        return
      }
      try {
        const data = await NetworkRepository.getSubnetList(payload.networkConfigId)
        context.dispatch(`setSubnetList`, data)
      }
      catch(e) {
        context.dispatch(`applicationLog/log`, `Failed loading items: ${e}`, {root:true})
      }
    },

    /**
     * Action: Load data vlan list
     */ 
    async loadVlanList (context, payload = {}) {
      if (context.rootState.network.vlanList !== undefined && !payload.force) {
        // The items are already stored
        return
      }
      try {
        const data = await NetworkRepository.getVlanList()
        context.dispatch(`setVlanList`, data)
      }
      catch(e) {
        context.dispatch(`applicationLog/log`, `Failed loading items: ${e}`, {root:true})
      }
    },    

    /**
     * Action: Load tenant list
     */ 
    async loadTenantList (context, payload = {}) {
      if (context.rootState.network.tenantList !== undefined && !payload.force) {
        // The items are already stored
        return
      }
      try {
        const data = await NetworkRepository.getTenantList()
        context.dispatch(`setTenantList`, data)
      }
      catch(e) {
        context.dispatch(`applicationLog/log`, `Failed loading items: ${e}`, {root:true})
      }
    },
    
    /**
     * Action: Load Vdom list
     */ 
    async loadVdomList (context, payload = {}) {
      if (context.rootState.network.vdomList !== undefined && !payload.force) {
        // The items are already stored
        return
      }
      try {
        const data = await NetworkRepository.getVdomList()
        context.dispatch(`setVdomList`, data)
      }
      catch(e) {
        context.dispatch(`applicationLog/log`, `Failed loading items: ${e}`, {root:true})
      }
    },
    
    /**
     * Action: Load Vrf list
     */ 
    async loadVrfList (context, payload = {}) {
      if (context.rootState.network.vrfList !== undefined && !payload.force) {
        // The items are already stored
        return
      }
      try {
        const data = await NetworkRepository.getVrfList()
        context.dispatch(`setVrfList`, data)
      }
      catch(e) {
        context.dispatch(`applicationLog/log`, `Failed loading items: ${e}`, {root:true})
      }
    },

  }
}
