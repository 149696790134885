import { Enum, Type, IfExists } from "hello-type"; // Check datatypes from API stream

export const idAsString = new Type({
  id: String,
});

export const backupMethodList = new Type({
  id: String,
  name: String,
});

export const networkInterfaceList = new Type({
  id: String,
  name: String,
  ipNumber_name: String,
  ipNumber_ipPool_name: String,
});

export const networkInterface = new Type({
  id: String,
  name: String,
  equipment: {
    id: String,
    name: String,
  },
  ipNumber: {
    id: String,
    name: String,
    ipPool: {
      id: String,
      name: String,
    },
  },
});

export const availableNetworkPortList = new Type({
  id: String,
  name: String,
});

export const networkPortList = new Type({
  id: String,
  name: String,
  connectedPort_name: Enum(String, null),
  connectedPort_equipment_name: Enum(String, null),
});

export const networkPort = new Type({
  id: String,
  name: String,
  equipment: {
    id: String,
    name: String,
  },
  connectedPort: Enum(null, {
    id: String,
    name: String,
    equipment: {
      id: String,
      name: String,
    },
  }),
});

export const namePoolList = new Type({
  id: String,
  name: String,
});

export const equipmentNameList = new Type({
  id: String,
  name: String,
  equipmentNamePool_id: String,
  equipmentNamePool_name: String,
});

export const equipmentName = new Type({
  id: String,
  name: String,
  equipmentNamePool: {
    id: String,
    name: String,
  },
});

export const namePoolRandomName = new Type({
  name: String,
});

export const installerList = new Type({
  id: String,
  name: String,
});

export const osVersionList = new Type({
  id: String,
  name: String,
  active: Boolean,
  meta: {
    canEdit: Boolean,
    canDelete: Boolean,
  },
});

export const osVersion = new Type({
  id: String,
  name: String,
  active: Boolean,
});

export const rackList = new Type({
  id: Enum(String, null),
  name: String,
  height: IfExists(Number),
  memo: IfExists(Enum(null, String)),
  customer_id: IfExists(String),
  customer_name: IfExists(String),
  meta: IfExists({
    canEdit: Boolean,
    canDelete: Boolean,
  }),
});

export const rackLayout = new Type({
  id: String,
  name: String,
  height: Number,
  equipment: Enum(
    Array(),
    Array({
      id: String,
      name: String,
      height: Number,
      rackPosition: Number,
      type: Enum("S", "P", "N", "V", "O"),
    })
  ),
});

export const rack = new Type({
  id: String,
  name: String,
  dateCreated: String,
  dateLastModified: String,
  isArchive: Boolean,
  height: Number,
  memo: Enum(String, null),
  customer: {
    id: String,
    name: String,
  },
});

export const serverList = new Type({
  id: String,
  name: String,
  isArchive: IfExists(Boolean),
  alias: IfExists(Enum(String, null)),
  memo: IfExists(String),
  equipmentType_id: IfExists(Number),
  equipmentType_name: IfExists(String),
  serviceLevel_id: IfExists(String),
  serviceLevel_name: IfExists(String),
  backupMethod_id: IfExists(String),
  backupMethod_name: IfExists(String),
  status_id: IfExists(Number),
  status_name: IfExists(String),
  osVersion_id: IfExists(String),
  osVersion_name: IfExists(String),
  customer_id: IfExists(String),
  customer_name: IfExists(String),
});

export const server = new Type({
  id: String,
  name: String,
  dateCreated: String,
  dateLastModified: String,
  isArchive: Boolean,
  customer: {
    id: String,
    name: String,
  },
  backupMethod: {
    id: String,
    name: String,
  },
  memo: Enum(String, null),
  alias: Enum(String, null),
  status: {
    id: Number,
    name: String,
  },
  serviceLevel: {
    id: String,
    name: String,
  },
  osVersion: {
    id: String,
    name: String,
  },
  customerAlias: String,
  equipmentType: {
    id: Number,
    name: String,
  },
  height: Number,
  rackPosition: Number,
  hardwareDetails: Enum(null, String),
  serialNumber: Enum(null, String),
  support: Enum(null, String),
  rack: Enum(null, {
    id: String,
    name: String,
  }),
});

export const nextServerAlias = new Type({
  name: String,
});

export const nextVmAlias = new Type({
  name: String,
});

export const serviceLevelList = new Type({
  id: String,
  name: String,
  description: String,
  meta: {
    canEdit: Boolean,
    canDelete: Boolean,
  },
});

export const serviceLevel = new Type({
  id: String,
  name: String,
  description: String,
});

export const statusList = new Type({
  id: Number,
  name: String,
});

export const networkSwitchList = new Type({
  id: String,
  name: String,
  isArchive: IfExists(Boolean),
  alias: IfExists(Enum(String, null)),
  memo: IfExists(Enum(String, null)),
  equipmentType_id: IfExists(Number),
  equipmentType_name: IfExists(String),
  serviceLevel_id: IfExists(String),
  serviceLevel_name: IfExists(String),
  backupMethod_id: IfExists(String),
  backupMethod_name: IfExists(String),
  status_id: IfExists(Number),
  status_name: IfExists(String),
  osVersion_id: IfExists(String),
  osVersion_name: IfExists(String),
  customer_id: IfExists(String),
  customer_name: IfExists(String),
});

// switch is a reserved word, so use myswitch instead
export const networkSwitch = new Type({
  id: String,
  name: String,
  dateCreated: String,
  dateLastModified: String,
  isArchive: Boolean,
  customer: {
    id: String,
    name: String,
  },
  backupMethod: {
    id: String,
    name: String,
  },
  memo: Enum(String, null),
  alias: Enum(String, null),
  status: {
    id: Number,
    name: String,
  },
  serviceLevel: {
    id: String,
    name: String,
  },
  osVersion: {
    id: String,
    name: String,
  },
  customerAlias: String,
  equipmentType: {
    id: Number,
    name: String,
  },
  height: Number,
  rackPosition: Number,
  hardwareDetails: Enum(null, String),
  serialNumber: Enum(null, String),
  support: Enum(null, String),
  rack: Enum(null, {
    id: String,
    name: String,
  }),
});

export const virtualMachineList = new Type({
  id: String,
  name: String,
  isArchive: IfExists(Boolean),
  alias: IfExists(Enum(String, null)),
  memo: IfExists(Enum(String, null)),
  equipmentType_id: IfExists(Number),
  equipmentType_name: IfExists(String),
  serviceLevel_id: IfExists(String),
  serviceLevel_name: IfExists(String),
  backupMethod_id: IfExists(String),
  backupMethod_name: IfExists(String),
  status_id: IfExists(Number),
  status_name: IfExists(String),
  osVersion_id: IfExists(String),
  osVersion_name: IfExists(String),
  customer_id: IfExists(String),
  customer_name: IfExists(String),
});

export const virtualMachine = new Type({
  id: String,
  name: String,
  dateCreated: String,
  dateLastModified: String,
  isArchive: Boolean,
  customer: {
    id: String,
    name: String,
  },
  backupMethod: {
    id: String,
    name: String,
  },
  memo: Enum(String, null),
  alias: Enum(String, null),
  status: {
    id: Number,
    name: String,
  },
  serviceLevel: {
    id: String,
    name: String,
  },
  osVersion: {
    id: String,
    name: String,
  },
  customerAlias: String,
  equipmentType: {
    id: Number,
    name: String,
  },
});

export const physicalNetworkedEquipmentList = new Type({
  id: String,
  name: String,
  dateCreated: IfExists(String),
  dateLastModified: IfExists(String),
  isArchive: IfExists(Boolean),
  alias: IfExists(Enum(String, null)),
  memo: IfExists(Enum(String, null)),
  equipmentType_id: IfExists(Number),
  equipmentType_name: IfExists(String),
  serviceLevel_id: IfExists(String),
  serviceLevel_name: IfExists(String),
  backupMethod_id: IfExists(String),
  backupMethod_name: IfExists(String),
  status_id: IfExists(Number),
  status_name: IfExists(String),
  osVersion_id: IfExists(String),
  osVersion_name: IfExists(String),
  customer_id: IfExists(String),
  customer_name: IfExists(String),
});

export const miscEquipmentItemList = new Type({
  id: String,
  name: String,
  isArchive: IfExists(Boolean),
  alias: IfExists(Enum(String, null)),
  memo: IfExists(Enum(String, null)),
  equipmentType_id: IfExists(Number),
  equipmentType_name: IfExists(String),
  status_id: IfExists(Number),
  status_name: IfExists(String),
  customer_id: IfExists(String),
  customer_name: IfExists(String),
});

export const miscEquipmentItem = new Type({
  id: String,
  name: String,
  isArchive: Boolean,
  customer: {
    id: String,
    name: String,
  },
  memo: Enum(String, null),
  alias: Enum(String, null),
  status: {
    id: Number,
    name: String,
  },
  equipmentType: {
    id: Number,
    name: String,
  },
  height: Number,
  rackPosition: Number,
  rack: Enum(
    {
      id: String,
      name: String,
    },
    null
  ),
});

export const changedEquipmentResourcesList = new Type({
  id: String,
  name: String,
  dateChanged: String,
  folderPath: String,
  changeStatus: Enum("none", "new", "changed"),
  newValues: {
    memoryShares: Number,
    cpuShares: Number,
    cpuCores: Number,
    status: String,
    dateCreated: String,
    memory: Number,
    provisionedStorage: Number,
    diskArrayAsString: String,
  },
  oldValues: IfExists({
    memoryShares: Number,
    cpuShares: Number,
    cpuCores: Number,
    status: String,
    dateCreated: String,
    memory: Number,
    provisionedStorage: Number,
    diskArrayAsString: String,
  }),
});

export const equipmentResourcesVM = new Type({
  name: String,
  cpuCores: Number,
  cpuShares: Number,
  memory: Number,
  memoryShares: Number,
  diskArrayAsString: String,
  provisionedStorage: Number,
  folderPath: String,
  status: String,
});
