import * as Constants from '@/lib/constants'
var zxcvbn = require('zxcvbn')

export default {
  data () {
    var trans = this.$i18n

    return {
      remoteErrors: {},
      validationRules: {
        required: v => !!v || v === 0 || trans.t('VALIDATION.IS_REQUIRED'),
        integer: v => !v || /^[0-9]*$/.test(v) || trans.t('VALIDATION.IS_INVALID'),
        domainName: v => /^([a-z0-9]+(-[a-z0-9]+)*\.)+[a-z]{2,}$/i.test(v) || trans.t('VALIDATION.IS_INVALID'),
        dnsSrvServiceProtocol: v => /^_.+/.test(v) || trans.t('VALIDATION.IS_INVALID'),
        eMail: value => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          return pattern.test(value) || trans.t('VALIDATION.IS_INVALID')
        },
        eMailPrefix: value => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))$/
          return pattern.test(value) || trans.t('VALIDATION.IS_INVALID')
        },
        // eslint-disable-next-line
        hostName: v => !v || /^([a-zA-Z0-9_\-]{0,62}\.?)+[a-zA-Z0-9]{1}[a-zA-Z0-9\-]{0,62}$/.test(v) || trans.t('VALIDATION.IS_INVALID'),
        passwordStrength: v => (!!v && zxcvbn(v).score >= Constants.PASSWORD_STRENGTH_MIN) || trans.t('VALIDATION.PASSWORD_STRENGTH'),
        url: v => /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)/i.test(v) || trans.t('VALIDATION.IS_INVALID'),
      }

    }
  },

  methods: {
    remoteValidation(fieldName) {
      this.$emit('remote-validation', { fieldName: fieldName, postData: this.formData })
    },

    clearRemoteValidation(fieldName = null) {
      if (!fieldName) {
        // Clear all fields
        for (var property in this.remoteErrors) {

          this.remoteErrors[property] = ''
        }
      }
      this.remoteErrors[fieldName] = ''
    },

    setRemoteErrors(error) {
      for (var key in error.validationErrors) {
        this.remoteErrors[key] = ''
        error.validationErrors[key].forEach(validationError => {
            this.remoteErrors[key] += this.remoteErrors[key] == '' ? '' : ', '
            this.remoteErrors[key] += validationError.message
          }
        )
      }
    }
  }
}
