import { RepositoryFactory } from '@/api/RepositoryFactory'
const UsersRepository = RepositoryFactory.get('users')

export default {
  namespaced: true,

  state: function () {
    return {
      userContextRoleList: undefined,
    }
  },

  getters: {
    getUserContextRoleList: state => {
      return state.userContextRoleList
    },
  },

  mutations: {
    setUserContextRoleList (state, payload) {
      state.userContextRoleList = payload
    },
  
    clearCache (state) {
      state.userContextRoleList = undefined
    }
},

  actions: {
    clearCache ({ commit }) {
      commit('clearCache')
    },

    setUserContextRoleList (context, payload) {
      context.commit('setUserContextRoleList', payload)
    },

    async updateOwnPassword (context, payload) {
      let response = await UsersRepository.putUserOwnPassword(payload) 
      return response
    },

    // Load Data via API
    async loadUserContextRoleList (context, payload = {}) {
      if (context.getters['getUserContextRoleList'] && !payload.force) {
        // The items are already stored
        return
      }
      try {
        const data = await UsersRepository.getUserContextRoleList()
        context.dispatch('setUserContextRoleList', data)
      }
      catch(e) {
        context.dispatch(`applicationLog/log`, `Failed loading userContextRoleList: ${e}`, {root:true})
      }
    },

  }
}
