<template>
  <v-container bg fill-height grid-list-md>
    <v-dialog 
      v-model="show"
      persistent 
      max-width="500px"
    >
      <v-layout row justify-center align-center>
        <v-flex xs12>
          <v-card>
            <v-form
              v-on:submit.prevent
              ref="form"
            >
              <v-card-title>
                <span class="headline"><div v-html="dialogTitle"></div></span>
              </v-card-title>

              <!-- Alert box for API Error Messages //-->
              <base-api-error-messages-dialog
                :apiErrorMessages="apiErrorMessages"
              >
              </base-api-error-messages-dialog>            

              <v-card-text>
                <template 
                  v-if="!useRecoveryCode"
                >
                  <v-container grid-list-md>
                    <v-layout wrap>
                      <v-flex xs2 class="pa-2">
                        <v-icon large>mdi-cellphone-key</v-icon>
                      </v-flex>
                      <v-flex xs8>
                        {{ $t('BASE.GLOBAL.USERTOKEN_MESSAGE') }}
                      </v-flex>
                    </v-layout>
                    <v-layout wrap>
                      <v-flex xs8 class="pa-2">
                        <v-text-field
                          ref="userToken"
                          mask="### ###"
                          v-on:keyup.enter="submit()"
                          v-model="formData.userToken"
                          type="text"
                          :label="$t('BASE.GLOBAL.USERTOKEN')"
                          :rules="[validationRules.required]"
                          validate-on-blur
                        >
                        </v-text-field>
                      </v-flex>
                    </v-layout>
                    <v-layout wrap>
                      <v-flex xs1 class="pa-2">
                        <v-checkbox class="ma-0 pa-0"
                          v-model="formData.saveTrustedDevice"
                        >
                        </v-checkbox>
                      </v-flex>
                      <v-flex xs11 class="pa-2">
                        {{ $t('BASE.GLOBAL.SAVE_TRUSTED_DEVICE') }}
                      </v-flex>
                    </v-layout>
                    <v-layout wrap>
                      <v-flex xs8>
                        <span><a href="#" @click="useRecoveryCode=true" style="font-size: 12px; color:#aaa">{{ $t('BUTTON.USE_RECOVERY_CODE') }}</a></span>
                      </v-flex>
                    </v-layout>
                  </v-container>
                </template>
                <template 
                  v-else
                >
                  <v-container grid-list-md>
                    <v-layout wrap>
                      <v-flex xs2 class="pa-2">
                        <v-icon large>mdi-restore</v-icon>
                      </v-flex>
                      <v-flex xs8>
                        {{ $t('BASE.GLOBAL.RECOVERY_CODE_MESSAGE') }}
                      </v-flex>
                    </v-layout>
                    <v-layout wrap>
                      <v-flex xs8 class="pa-2">
                        <v-text-field
                          mask="## ### ###"
                          v-on:keyup.enter="submit()"
                          v-model="formData.userToken"
                          type="text"
                          :label="$t('BASE.GLOBAL.RECOVERY_CODE')"
                          :rules="[validationRules.required]"
                          validate-on-blur
                        >
                        </v-text-field>
                      </v-flex>
                    </v-layout>
                    <v-layout wrap>
                      <v-flex xs8>
                        <span><a href="#" @click="useRecoveryCode=false" style="font-size: 12px; color:#aaa">{{ $t('BUTTON.USE_AUTHENTICATOR_APP') }}</a></span>
                      </v-flex>
                    </v-layout>
                  </v-container>
                </template>

              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" flat @click.native="cancel()">{{ $t('BUTTON.CANCEL') }}</v-btn>
                <v-btn color="green darken-1" flat @click="submit()">{{ $t('BUTTON.SUBMIT') }}</v-btn>
              </v-card-actions>
            </v-form>
          </v-card>
        </v-flex>
      </v-layout>
    </v-dialog>
  </v-container>
</template>

<script>
import dialogFunctions from '@/mixins/DialogFunctions.js'
import AppError from '@/lib/customExceptions/AppError.js'

export default {
  mixins: [ dialogFunctions ],

  props: {
    apiErrorMessages: AppError,
  },

  data: function () {
    return {
      defaultFormData: {
        userToken: '',
        saveTrustedDevice: false
      },
      useRecoveryCode: false,
    }
  },

  // Computed
  computed: {
    dialogTitle: function () {
      return this.$t('DIALOG.2FA.TITLE') 
    },
  },

  methods: {
    close() {
      this.show = false
    },

    postOpen() {
      return new Promise((resolve, reject) => {
        this.resolve = resolve
        this.reject = reject
      })
    },

    submit() {
      this.validate()
      this.resolve(this.formData)
    },

    cancel() {
      this.resolve(false)
      this.close()
    }

  }
}
</script>

<style scoped>
.hidden {
  display: none
}
</style>