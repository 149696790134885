import { Enum, Type } from 'hello-type' // Check datatypes from API stream

export const idAsString = new Type({
  id: String
})

export const idAsNumber = new Type({
  id: Number
})

export const licenseAssignmentList = new Type({
  id: Number,
  licenseCategory_id: Number,
  licenseCategory_name: String,
  license_id: Number,
  license_name: String,
  license_code: String,
  startDate: String,
  endDate: Enum(null, String),
  memo: Enum(null, String)
})

export const licenseAssignment = new Type({
  id: Number,
  memo: String,
  startDate: String,
  endDate: Enum(String, null),
  license: {
    id: Number,
    name: String,
  },
  equipment: {
    id: String,
    name: String
  },
})

export const licenseList = new Type({
  id: Number,
  name: String,
  active: Boolean,
  code: String,
  memo: Enum(String, null),
  supplier: Enum(String, null),
  datePurchased: String,
  maxLicenseCount: Number,
  licenseCategory_id: Number,
  licenseCategory_name: String,
})

export const license = new Type({
  id: Number,
  name: String,
  active: Boolean,
  datePurchased: String,
  memo: Enum(String, null),
  code: String,
  licenseCategory: {
    id: Number,
    name: String
  },
  maxLicenseCount: Number,
  supplier: String,
})

export const categoryLicenseList = new Type({
  id: Number,
  name: String  
})

export const licenseCategoryList = new Type({
  id: Number,
  name: String,
  active: Boolean  
})

export const licenseCategory = new Type({
  id: Number,
  name: String,
  active: Boolean  
})

export const licenseReportsSplaList = new Type({
  id: Number,
  name: String,
  code: String,
  memo: String,
  category: String,
  maxLicenseCount: Number,
  count: Number,
})

export const licenseReportsPerMachineList = new Type({
  id: Number,
  category: String,
  name: String,
  code: String,
  equipment_name: String,
  equipment_alias: String,
  customer_name: String,
  startDate: String,
  endDate: Enum(null, String),
})