import { RepositoryFactory } from '@/api/RepositoryFactory'
const DomainsRepository = RepositoryFactory.get('domains')
const DnsTemplatesRepository = RepositoryFactory.get('dnsTemplates')

export default {
  namespaced: true,

  state: function () {
    return {
      dnsSecStatuses: undefined,
      domainStatuses: undefined,
      dnsTemplates: undefined,
      items: undefined,
      mailTypes: undefined,
      registrars: undefined
    }
  },

  getters: {
    /**
     * Get DNSsec statuses
     * @returns {array} Array of name/value pairs for id and name
     */ 
    getDnsSecStatusList: state => {
      return state.dnsSecStatuses || []
    },

    /**
     * Get DNS templates
     * @returns {array} Array of name/value pairs for id and name
     */ 
    getDnsTemplateList: state => {
      return state.dnsTemplates || []
    },

    /**
     * Get Domain statuses
     * @returns {array} Array of name/value pairs for id and name
     */ 
    getDomainStatusList: state => {
      return state.domainStatuses || []
    },

    /**
     * Get Mail types
     * @returns {array} Array of name/value pairs for id and name
     */ 
    getMailTypeList: state => {
      return state.mailTypes || []
    },

    /**
     * Get Registrars
     * @returns {array} Array of name/value pairs for id and name
     */ 
    getRegistrarList: state => {
      return state.registrars || []
    },
  },

  mutations: {
    /**
     * Mutation: Set DNSsec statuses
     * @param {array} Array of name/value pairs for id and name
     */ 
    setDnsSecStatusList (state, payload) {
      state.dnsSecStatuses = payload
    },

    /**
     * Mutation: Set DNS templates
     * @param {array} Array of name/value pairs for id and name
     */ 
    setDnsTemplateList (state, payload) {
      state.dnsTemplates = payload
    },

    /**
     * Mutation: Set Domain statuses
     * @param {array} Array of name/value pairs for id and name
     */ 
    setDomainStatusList (state, payload) {
      state.domainStatuses = payload
    },

    /**
     * Mutation: Set Mail types
     * @param {array} Array of name/value pairs for id and name
     */ 
    setMailTypeList (state, payload) {
      state.mailTypes = payload
    },

    /**
     * Mutation: Set Registrars
     * @param {array} Array of name/value pairs for id and name
     */ 
    setRegistrarList (state, payload) {
      state.registrars = payload
    },

    clearCache (state) {
      state.dnsSecStatuses = undefined
      state.dnsTemplates = undefined
      state.domainStatuses = undefined
      state.mailTypes = undefined
      state.registrars = undefined
    }

  },

  actions: {
    clearCache ({ commit }) {
      commit('clearCache')
    },

    /**
     * Action: Set DNSsec statuses
     * @param {array} Array of array of name/value pairs for the domain properties
     */ 
    setDnsSecStatusList (context, payload) {
      context.commit('setDnsSecStatusList', payload)
    },

    /**
     * Action: Set DNS templates
     * @param {array} Array of array of name/value pairs for the domain properties
     */ 
    setDnsTemplateList (context, payload) {
      context.commit('setDnsTemplateList', payload)
    },

    /**
     * Action: Load DNSsec statuses via API and store them, but only if items have not been stored yet.
     */ 
    async loadDnsSecStatusList (context, payload = {}) {
      if (context.rootState.domains.dnsSecStatuses !== undefined && !payload.force) {
        // The items are already stored
        return
      }
      try {
        const data = await DomainsRepository.getDnsSecStatuses()
        context.dispatch('setDnsSecStatusList', data)
      }
      catch(e) {
        context.dispatch(`applicationLog/log`, `Failed loading items: ${e}`, {root:true})
      }
    },

    /**
     * Action: Load DNS templates via API and store them, but only if items have not been stored yet.
     */ 
    async loadDnsTemplateList (context, payload = {}) {
      if (context.rootState.domains.dnsTemplates !== undefined && !payload.force) {
        // The items are already stored
        return
      }
      try {
        const data = await DnsTemplatesRepository.getDnsTemplateList()
        context.dispatch('setDnsTemplateList', data)
      }
      catch(e) {
        context.dispatch(`applicationLog/log`, `Failed loading items: ${e}`, {root:true})
      }
    },

    /**
     * Action: Set Domain statuses
     * @param {array} Array of array of name/value pairs for the domain properties
     */ 
    setDomainStatusList (context, payload) {
      context.commit('setDomainStatusList', payload)
    },

    /**
     * Action: Load Domain statuses via API and store them, but only if items have not been stored yet.
     */ 
    async loadDomainStatusList (context, payload = {}) {
      if (context.rootState.domains.domainStatuses !== undefined && !payload.force) {
        // The items are already stored
        return
      }
      try {
        const data = await DomainsRepository.getDomainStatuses()
        context.dispatch('setDomainStatusList', data)
      }
      catch(e) {
        context.dispatch(`applicationLog/log`, `Failed loading items: ${e}`, {root:true})
      }
    },

    /**
     * Action: Set Mail types
     * @param {array} Array of array of name/value pairs for the domain properties
     */ 
    setMailTypeList (context, payload) {
      context.commit('setMailTypeList', payload)
    },

    /**
     * Action: Load Domain statuses via API and store them, but only if items have not been stored yet.
     */ 
    async loadMailTypeList (context, payload = {}) {
      if (context.rootState.domains.mailTypes !== undefined && !payload.force) {
        // The items are already stored
        return
      }
      try {
        const data = await DomainsRepository.getMailTypes()
        context.dispatch('setMailTypeList', data)
      }
      catch(e) {
        context.dispatch(`applicationLog/log`, `Failed loading items: ${e}`, {root:true})
      }
    },

    /**
     * Action: Set Registrars
     * @param {array} Array of array of name/value pairs for the domain properties
     */ 
    setRegistrarList (context, payload) {
      context.commit('setRegistrarList', payload)
    },

    /**
     * Action: Load Registrars via API and store them, but only if items have not been stored yet.
     */ 
    async loadRegistrarList (context, payload = {}) {
      if (context.rootState.domains.registrars !== undefined && !payload.force) {
        // The items are already stored
        return
      }
      try {
        const data = await DomainsRepository.getRegistrars()
        context.dispatch('setRegistrarList', data)
      }
      catch(e) {
        context.dispatch(`applicationLog/log`, `Failed loading items: ${e}`, {root:true})
      }
    }
  }
}
