
export default {
  namespaced: true,

  state: function () {
    return {
      items: [] // An array of messages since multiple errors/warnings can occur
    }
  },

  getters: {
    getMessageList: state => {
      return state.items
    },

    getShowDialog: state => {
      return state.items.length ? true : false
    }
  },

  mutations: {
    log (state, payload) {
      state.items.push(payload)
    },

    reset (state) {
      state.items = []
    }
  },

  actions: {
    log (context, payload) {
      context.commit('log', payload)
    },

    resetMessageList (context) {
      context.commit('reset')
    }
  }
}
