import { RepositoryFactory } from '@/api/RepositoryFactory'
const CustomersRepository = RepositoryFactory.get('customers')

export default {
  namespaced: true,

  state: function () {
    return {
      customerList: undefined,
      relationList: undefined,
      statusList: undefined,
    }
  },

  getters: {
    getCustomerList: state => {
      return state.customerList
    },

    getRelationList: state => {
      return state.relationList
    },

    getStatusList: state => {
      return state.statusList
    },
  },

  mutations: {
    setCustomerList (state, payload) {
      state.customerList = payload
    },

    setRelationList (state, payload) {
      state.relationList = payload
    },

    setStatusList (state, payload) {
      state.statusList = payload
    },

    clearCache (state) {
      state.customerList = undefined
      state.relationList = undefined
      state.statusList = undefined
    }
  },

  actions: {
    setCustomerList (context, payload) {
      context.commit('setCustomerList', payload)
    },

    setRelationList (context, payload) {
      context.commit('setRelationList', payload)
    },

    setStatusList (context, payload) {
      context.commit('setStatusList', payload)
    },

    clearCache ({ commit }) {
      commit('clearCache')
    },


    // Load Data via API
    async loadCustomerList (context, payload = {}) {
      if (context.getters['getCustomerList'] && !payload.force) {
        // The items are already stored
        return
      }
      try {
        const data = await CustomersRepository.getCustomerList()
        context.dispatch('setCustomerList', data)
      }
      catch(e) {
        context.dispatch(`applicationLog/log`, `Failed loading customerList: ${e}`, {root:true})
      }
    },

    async loadRelationList (context, payload = {}) {
      if (context.getters['getRelationList'] && !payload.force) {
        // The items are already stored
        return
      }
      try {
        const data = await CustomersRepository.getRelationList()
        context.dispatch('setRelationList', data)
      }
      catch(e) {
        context.dispatch(`applicationLog/log`, `Failed loading relationList: ${e}`, {root:true})
      }
    },

    async loadStatusList (context, payload = {}) {
      if (context.getters['getStatusList'] && !payload.force) {
        // The items are already stored
        return
      }
      try {
        const data = await CustomersRepository.getStatusList()
        context.dispatch('setStatusList', data)
      }
      catch(e) {
        context.dispatch(`applicationLog/log`, `Failed loading statusList: ${e}`, {root:true})
      }
    },

  }
}
