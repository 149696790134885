<template>
  <div>
    <v-text-field 
      label="Select..."
      @click='onPickFile'
      :value="computedFileName"
      prepend-icon="attach_file"
    ></v-text-field>
    <!-- Hidden -->
    <input
      type="file"
      style="display: none"
      ref="fileInput"
      accept="*/*"
      @change="onFilePicked">
  </div>
</template>

<script>
export default {

  props: ['value'],

  data: function() {
    return {
      fileName: '',
      url: '',
      fileObject: null,
      cardResult: '',
      name: '',
      size: '',
      type: '',
      lastModifiedDate: '',
      loading: false
    }
  },

  computed: {
    computedFileName() {
      return this.value && this.value.name ? this.value.name : ''
    }
  },

  methods: {
    onPickFile () {
      this.$refs.fileInput.click()
    },

    onFilePicked (event) {
      const files = event.target.files
      if (files[0] !== undefined) {
        this.fileName = files[0].name
        this.$emit('input', files[0])
        this.$refs.fileInput.value = ''
      }
    },
  },

}
</script>