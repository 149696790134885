var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.stateGetShowDialog
    ? _c(
        "v-layout",
        { attrs: { row: "", "justify-center": "" } },
        [
          _c(
            "v-dialog",
            { attrs: { value: true, persistent: "", "max-width": "400" } },
            [
              _c(
                "v-card",
                [
                  _c("v-card-title", { staticClass: "headline" }, [
                    _vm._v(_vm._s(_vm.$t("BASE.USER_MESSAGE_TITLE")))
                  ]),
                  _c("v-card-text", [
                    _vm._v(" " + _vm._s(_vm.stateGetUserMessage) + " ")
                  ]),
                  _c(
                    "v-card-actions",
                    [
                      _c("v-spacer"),
                      _c(
                        "v-btn",
                        {
                          attrs: { color: "blue darken-1", flat: "" },
                          nativeOn: {
                            click: function($event) {
                              return _vm.close()
                            }
                          }
                        },
                        [_vm._v(_vm._s(_vm.$t("BUTTON.CLOSE")))]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }