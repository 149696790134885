import Repository from '../Repository'
//import Logger from '@/lib/Logger.js'
import * as ApiTypes from '../../api/api-types';
import Helpers from '@/lib/Helpers'
import * as DataTypes from './SlaRepositoryDataTypes'


const moduleName = 'SlaRepository'

export default {
  /**
   * Get contact list
   * 
   * @alias module: SlaRepository
   * @returns {Array} Array of objects 
   */
  async getContactList (customerId = null) {
    let urlParams = customerId ? `?customerId=${customerId}` : ''
    return await Repository.get(
      `${ApiTypes.GET_SLA_CONTACTS}${urlParams}`,
      DataTypes.contactList,
      `${moduleName}.getContactList()`
    )
  },

  /**
   * Post a new contact
   * 
   * @alias module: SlaRepository
   * @param {Object} payload
   * @returns {Object} 
   */
  async postContact (payload) {
    return await Repository.post(
      ApiTypes.POST_SLA_CONTACTS,
      payload,
      DataTypes.idAsString,
      `${moduleName}.postContact()`
    )
  },

  /**
   * Get a contact
   * @alias module: SlaRepository
   * @param {String} id
   * @returns {Object} Object
   */
  async getContact (id) {
    return await Repository.get(
      Helpers.substituteApiVars(ApiTypes.GET_SLA_CONTACTS_ID, { id: id }),
      DataTypes.contact,
      `${moduleName}.getContact()`
    )
  },

  /**
   * Update a contact
   * 
   * @alias module: SlaRepository
   * @param {Object} payload
   */
  async putContact (payload) {
    return await Repository.put(
      Helpers.substituteApiVars(ApiTypes.PUT_SLA_CONTACTS_ID, { id: payload.id }),
      payload,
      null,
      `${moduleName}.putContact()`
    )
  },

  /**
   * Delete a contact
   * 
   * @alias module: SlaRepository
   * @param {String} id
   */
  async deleteContact (id) {
    return await Repository.delete(
      Helpers.substituteApiVars(ApiTypes.DELETE_SLA_CONTACTS_ID, { id: id }),
      `${moduleName}.deleteContact()`
    )
  },

  /**
   * Get Sla list
   * 
   * @alias module: SlaRepository
   * @returns {Array} Array of objects 
   */
  async getSlaList () {
    return await Repository.get(
      ApiTypes.GET_SLA_SERVICE_LEVELS,
      DataTypes.slaList,
      `${moduleName}.getSlaList()`
    )
  },

  /**
   * Get uptime report list
   * 
   * @alias module: SlaRepository
   * @param {String} customerId
   * @returns {Array} Array of objects 
   */
  async getUptimeReportList (customerId) {
    return await Repository.get(
      Helpers.substituteApiVars(ApiTypes.GET_SLA_UPTIME_REPORTS_CUSTOMERS_ID, { customerId: customerId }),
      DataTypes.uptimeReportList,
      `${moduleName}.getUptimeReportList()`
    )
  },

  /**
   * Get a Uptime Report Equipment
   * @alias module: SlaRepository
   * @param {String} equipmentId
   * @returns {Object} Object
   */
  async getUptimeReportEquipment (equipmentId) {
    return await Repository.get(
      Helpers.substituteApiVars(ApiTypes.GET_SLA_UPTIME_REPORTS_EQUIPMENT_ID, { equipmentId: equipmentId }),
      DataTypes.uptimeReportEquipment,
      `${moduleName}.getUptimeReportEquipment()`
    )
  },


}
