import AuthRepository from './modules/AuthRepository';
import CustomersRepository from './modules/CustomersRepository';
import UsersRepository from './modules/UsersRepository';
import DomainsRepository from './modules/DomainsRepository'
import DnsRepository from './modules/DnsRepository'
import AutoDnsRepository from './modules/AutoDnsRepository'
import DnsTemplatesRepository from './modules/DnsTemplatesRepository'
import MenuRepository from './modules/MenuRepository'
import SharedMailRepository from './modules/SharedMailRepository'
import AntiSpamRepository from './modules/AntiSpamRepository'
import EquipmentRepository from './modules/EquipmentRepository'
import LicensesRepository from './modules/LicensesRepository'
import NetworkRepository from './modules/NetworkRepository';
import LoggingRepository from './modules/LoggingRepository';
import SlaRepository from './modules/SlaRepository';
import ChecklistsRepository from './modules/ChecklistsRepository';
import DocumentsRepository from './modules/DocumentsRepository';

const repositories = {
  auth: AuthRepository,
  customers: CustomersRepository,
  users: UsersRepository,
  domains: DomainsRepository,
  dns: DnsRepository,
  autoDns: AutoDnsRepository,
  dnsTemplates: DnsTemplatesRepository,
  equipment: EquipmentRepository,
  licenses: LicensesRepository,
  network: NetworkRepository,
  menu: MenuRepository,
  sharedMail: SharedMailRepository,
  antiSpam: AntiSpamRepository,
  logging: LoggingRepository,
  sla: SlaRepository,
  checklists: ChecklistsRepository,
  documents: DocumentsRepository
  // Other repositories
}

export const RepositoryFactory = {
  get: name => repositories[name]
}
