<template>
    <v-layout>
      <v-flex xs12>
        <v-form
          ref="searchForm"
        >
          <v-autocomplete
            flat
            dense
            solo-inverted
            prepend-icon="mdi-magnify"
            v-model="selectedSearchItem"
            :search-input.sync="search"
            :items="searchItemList"
            :label="$t('BASE.GLOBAL.SEARCH')"
            item-text="name"
            item-value="id"
            :filter="autocompleteSearch"
            @blur="resetForm()"
          >
            <template v-slot:selection="data">
              {{ data.item.name }}
            </template>
            <template v-slot:item="data">
              <template>
                  <v-icon v-if="data.item">
                    {{ icons[data.item.type] }}
                  </v-icon>
                  {{ data.item.name }}
              </template>
            </template>
          </v-autocomplete>
        </v-form>
      </v-flex>
    </v-layout>
</template>

<script>
  export default {
    props: {
      searchItemList: Array
    },

    data () {
      return {
        selectedSearchItem: null,
        search: '',
        icons: {
          'VM': 'mdi-virtual-reality',
          'SRV': 'mdi-server',
          'NWS': 'mdi-switch',
          'DOM': 'mdi-web',
          'ACFG': 'mdi-wan',
          'SCFG': 'mdi-wan',
        },

        sortBy: name,
      }
    },

    computed: {
      computedRegExp () {
        return RegExp(this.search, 'i')
      }
    },

    watch: {
      selectedSearchItem (val) {
        if (val) {
          let result = this.searchItemList.find(function(value) { return value.id === val })
          this.$emit('selected-search-item', result)
        }
      },
    },

    methods: {
      remove (item) {
        const index = this.friends.indexOf(item.name)
        if (index >= 0) this.friends.splice(index, 1)
      },

      resetForm () {
        this.$refs.searchForm.reset()
        this.selectedSearchItem = null
        this.search = ''
      },

      // autocompleteSearch()
      // used by: v-auto-complete (Filter company or sub-user)
      // Special search filter for v-auto-complete to support
      // searches by regular expressions
      autocompleteSearch(item, queryText, itemText) {
        try {
          // If queryText contains only alphanumeric characters
          // Then just do a normal search
          // If RegExp is valid than do a RegExp search
          return this.computedRegExp.test(itemText)
        }
        catch(e) {
          // If RegExp is invalid than do a normal search
          return itemText.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) > -1
        }
      },
    }
  }
</script>

