var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-content",
    [
      _c("base-confirm-dialog", { ref: "confirmAppUpdate" }),
      _c(
        "v-navigation-drawer",
        {
          attrs: {
            dark: "",
            app: "",
            "mobile-break-point": "0",
            "mini-variant": !_vm.stateGetMenuExpanded
          }
        },
        [
          _c(
            "v-layout",
            {
              attrs: {
                "align-left": "",
                "justify-space-between": "",
                column: "",
                "fill-height": ""
              }
            },
            [
              _c(
                "v-list",
                { attrs: { dark: "" } },
                [
                  _vm.stateGetApiVersionChanged
                    ? _c(
                        "v-list-tile",
                        {
                          on: {
                            click: function($event) {
                              return _vm.appUpdate()
                            }
                          }
                        },
                        [
                          _c(
                            "v-list-tile-action",
                            [
                              _c(
                                "v-icon",
                                {
                                  staticClass: "pr-1",
                                  attrs: { color: "warning" }
                                },
                                [_vm._v("mdi-alert")]
                              )
                            ],
                            1
                          ),
                          _c(
                            "v-list-tile-content",
                            [
                              _c(
                                "v-btn",
                                {
                                  staticClass: "ma-0",
                                  attrs: { color: "warning" }
                                },
                                [_vm._v(_vm._s(_vm.$t("BUTTON.RELOAD_APP")))]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "v-list-tile",
                    {
                      attrs: {
                        "active-class":
                          "white--text font-weight-bold selectedMenuItem"
                      },
                      on: { click: _vm.stateSwitchMenuExpanded }
                    },
                    [
                      _c(
                        "v-list-tile-action",
                        [
                          _c("v-icon", { staticClass: "pr-1" }, [
                            _vm._v(
                              _vm._s(
                                _vm.stateGetMenuExpanded
                                  ? "mdi-arrow-left-bold"
                                  : "mdi-arrow-right-bold"
                              )
                            )
                          ])
                        ],
                        1
                      ),
                      _c("v-list-tile-content", [_vm._v(" Collapse ")])
                    ],
                    1
                  ),
                  _vm._l(_vm.stateGetMainMenuItems, function(menuItem, index) {
                    return _c(
                      "v-list-tile",
                      {
                        key: index,
                        attrs: {
                          router: "",
                          to: menuItem.routeTo,
                          "active-class":
                            "white--text font-weight-bold selectedMenuItem"
                        }
                      },
                      [
                        _c(
                          "v-list-tile-action",
                          [
                            _c("v-icon", { staticClass: "pr-1" }, [
                              _vm._v(_vm._s(menuItem.icon))
                            ])
                          ],
                          1
                        ),
                        _c("v-list-tile-content", [
                          _vm._v(
                            " " +
                              _vm._s(_vm.$t("MENU.MAIN." + menuItem.label)) +
                              " "
                          )
                        ])
                      ],
                      1
                    )
                  })
                ],
                2
              ),
              !_vm.managementMenuEmpty()
                ? _c(
                    "v-list",
                    { attrs: { dark: "" } },
                    [
                      _c(
                        "v-list-group",
                        {
                          attrs: {
                            "active-class": "white--text font-weight-bold",
                            "no-action": "",
                            "prepend-icon": "settings"
                          },
                          on: {
                            click: function($event) {
                              return _vm.collapseAll()
                            }
                          }
                        },
                        [
                          _c(
                            "v-list-tile",
                            {
                              attrs: {
                                slot: "activator",
                                "active-class": "white--text font-weight-bold"
                              },
                              slot: "activator"
                            },
                            [
                              _c("v-list-tile-content", [
                                _vm._v(
                                  " " + _vm._s(_vm.$t("MENU.MANAGEMENT")) + " "
                                )
                              ])
                            ],
                            1
                          ),
                          _vm._l(_vm.stateGetMenuItems, function(
                            menuItem,
                            index
                          ) {
                            return _c(
                              "v-list-group",
                              {
                                key: menuItem.label,
                                attrs: {
                                  "sub-group": "",
                                  "active-class":
                                    "white--text font-weight-bold",
                                  "no-action": ""
                                },
                                on: {
                                  click: function($event) {
                                    return _vm.collapseOthers(index)
                                  }
                                },
                                model: {
                                  value: _vm.listGroupValue[index],
                                  callback: function($$v) {
                                    _vm.$set(_vm.listGroupValue, index, $$v)
                                  },
                                  expression: "listGroupValue[index]"
                                }
                              },
                              [
                                _c(
                                  "v-list-tile",
                                  {
                                    attrs: {
                                      slot: "activator",
                                      "active-class":
                                        "white--text font-weight-bold selectedMenuItem"
                                    },
                                    slot: "activator"
                                  },
                                  [
                                    _c("v-list-tile-content", [
                                      _vm._v(" " + _vm._s(menuItem.label) + " ")
                                    ])
                                  ],
                                  1
                                ),
                                _c("base-menu-dividers", {
                                  attrs: {
                                    label: menuItem.label,
                                    url: menuItem.routeTo,
                                    nodes: menuItem.nodes,
                                    firstTime: true,
                                    sideNav: true
                                  }
                                })
                              ],
                              1
                            )
                          })
                        ],
                        2
                      )
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }