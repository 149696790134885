var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    { staticClass: "pa-4" },
    [
      _c(
        "v-layout",
        {
          attrs: {
            row: "",
            wrap: "",
            "justify-start": "",
            "align-baseline": ""
          }
        },
        [
          _c(
            "v-flex",
            {
              staticClass: "text-xs-left",
              attrs: { xs12: "", sm7: "", md4: "" }
            },
            [
              _vm.enableAddButton
                ? _c(
                    "v-btn",
                    {
                      staticClass: "white--text",
                      attrs: { color: "newButton" },
                      on: {
                        click: function($event) {
                          $event.stopPropagation()
                          return _vm.$emit("add-clicked")
                        }
                      }
                    },
                    [
                      _c("v-icon", [_vm._v("add_circle_outline")]),
                      _vm._v(" " + _vm._s(_vm.$t("BUTTON.NEW")) + " ")
                    ],
                    1
                  )
                : _vm._e(),
              _vm.exportFilenamePrefix
                ? _c(
                    "v-btn",
                    {
                      staticClass: "white--text",
                      attrs: { color: "exportButton" },
                      on: {
                        click: function($event) {
                          return _vm.exportData()
                        }
                      }
                    },
                    [
                      _c("v-icon", [_vm._v("mdi-file-excel")]),
                      _vm._v(" " + _vm._s(_vm.$t("BUTTON.TO_EXCEL")) + " ")
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          ),
          _c(
            "v-flex",
            { staticClass: "px-3", attrs: { xs12: "", sm3: "", md3: "" } },
            [
              _c("v-text-field", {
                attrs: {
                  "append-icon": "search",
                  label: _vm.$t("BASE.SEARCH"),
                  "single-line": "",
                  "hide-details": "",
                  flat: ""
                },
                nativeOn: {
                  keyup: function($event) {
                    return _vm.debounceInput($event)
                  }
                },
                model: {
                  value: _vm.searchInput,
                  callback: function($$v) {
                    _vm.searchInput = $$v
                  },
                  expression: "searchInput"
                }
              })
            ],
            1
          ),
          _c(
            "v-flex",
            { staticClass: "px-3", attrs: { xs12: "", sm3: "", md3: "" } },
            [
              _vm.dropdownList
                ? _c("v-autocomplete", {
                    attrs: {
                      items: _vm.dropdownList,
                      "item-text": "name",
                      "item-value": "id",
                      label: _vm.dropdownLabel,
                      "hide-no-data": "",
                      dense: "",
                      flat: "",
                      filter: _vm.autocompleteSearch
                    },
                    model: {
                      value: _vm.computedDropdownSelectedItem,
                      callback: function($$v) {
                        _vm.computedDropdownSelectedItem = $$v
                      },
                      expression: "computedDropdownSelectedItem"
                    }
                  })
                : _vm._e()
            ],
            1
          ),
          _c(
            "v-flex",
            { staticClass: "text-xs-right", attrs: { xs6: "", md2: "" } },
            [
              _vm.archiveSwitchField
                ? _c("v-switch", {
                    attrs: { label: _vm.archiveSwitchLabel },
                    model: {
                      value: _vm.switchShowAll,
                      callback: function($$v) {
                        _vm.switchShowAll = $$v
                      },
                      expression: "switchShowAll"
                    }
                  })
                : _vm._e()
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-layout",
        {
          attrs: {
            row: "",
            wrap: "",
            "justify-space-between": "",
            "align-baseline": ""
          }
        },
        [_c("v-flex", { staticClass: "py-3", attrs: { xs12: "" } })],
        1
      ),
      _c(
        "v-layout",
        {
          attrs: {
            row: "",
            wrap: "",
            "justify-space-between": "",
            "align-baseline": ""
          }
        },
        [
          _c(
            "v-flex",
            { attrs: { xs12: "" } },
            [
              _c(
                "v-data-table",
                {
                  ref: "refName",
                  attrs: {
                    headers: _vm.headers,
                    items: _vm.items,
                    pagination: _vm.paginationSync,
                    search: _vm.search,
                    "custom-filter": _vm.dataTableSearch,
                    "rows-per-page-items": [10, 25, 100, 250],
                    "must-sort": ""
                  },
                  on: {
                    "update:pagination": function($event) {
                      _vm.paginationSync = $event
                    }
                  },
                  scopedSlots: _vm._u(
                    [
                      _vm._l(Object.keys(_vm.$scopedSlots), function(slot) {
                        return {
                          key: slot,
                          fn: function(scope) {
                            return [_vm._t(slot, null, null, scope)]
                          }
                        }
                      })
                    ],
                    null,
                    true
                  )
                },
                [
                  _vm._l(Object.keys(_vm.$slots), function(slot) {
                    return _vm._t(slot, null, { slot: slot })
                  })
                ],
                2
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }