var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    [
      _c(
        "v-layout",
        { attrs: { row: "", wrap: "" } },
        [
          _vm._l(_vm.items, function(item) {
            return _vm._t("default", [
              _c(
                "v-flex",
                { staticClass: "Grid-cell-name", attrs: { xs5: "" } },
                [
                  _c(
                    "div",
                    { staticClass: "text-xs-left font-weight-bold pa-2" },
                    [_vm._v(_vm._s(item.name))]
                  )
                ]
              ),
              _c(
                "v-flex",
                { staticClass: "Grid-cell-value", attrs: { xs7: "" } },
                [
                  item.renderHtml
                    ? _c("div", {
                        staticClass: "text-xs-left pa-2",
                        domProps: { innerHTML: _vm._s(item.value) }
                      })
                    : _c("div", { staticClass: "text-xs-left pa-2" }, [
                        _vm._v(" " + _vm._s(item.value) + " ")
                      ])
                ]
              )
            ])
          })
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }