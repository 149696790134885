var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-toolbar",
        {
          class: _vm.stateIsImpersonated ? "impersonated" : "headerBar",
          attrs: { app: "", flat: "" }
        },
        [
          _c("v-toolbar-title", { staticClass: "pr-5 pt-2" }, [
            _c("img", {
              attrs: {
                src: require("@/assets/logo_kaal.png"),
                width: "91px",
                height: "44px"
              }
            })
          ]),
          _c("base-search", {
            staticClass: "pt-2",
            attrs: { "search-item-list": _vm.stateGetSearchItemList },
            on: {
              "selected-search-item": function($event) {
                return _vm.routeToSelectedSearchItem($event)
              }
            }
          }),
          _vm.stateIsImpersonated
            ? _c(
                "v-toolbar-title",
                { staticClass: "pr-5 white--text font-weight-bold" },
                [_vm._v(" IMPERSONATED ")]
              )
            : _vm._e(),
          _c(
            "v-toolbar-items",
            [
              _c(
                "v-menu",
                {
                  attrs: { "offset-y": "" },
                  scopedSlots: _vm._u([
                    {
                      key: "activator",
                      fn: function(ref) {
                        var on = ref.on
                        return [
                          _c(
                            "v-btn",
                            _vm._g(
                              {
                                staticClass: "text-none",
                                attrs: { left: "", flat: "" }
                              },
                              on
                            ),
                            [
                              _c(
                                "v-icon",
                                { staticClass: "pr-1", attrs: { large: "" } },
                                [_vm._v("mdi-account-circle")]
                              ),
                              _c(
                                "v-layout",
                                { attrs: { column: "" } },
                                [
                                  _c("v-flex", [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "text-xs-left font-weight-bold"
                                      },
                                      [_vm._v(_vm._s(_vm.stateGetUserName))]
                                    )
                                  ]),
                                  _c("v-flex", [
                                    _c(
                                      "div",
                                      { staticClass: "text-xs-left caption" },
                                      [_vm._v(_vm._s(_vm.stateGetCustomerName))]
                                    )
                                  ])
                                ],
                                1
                              ),
                              _c("v-icon", { staticClass: "pr-1" }, [
                                _vm._v("mdi-menu-down")
                              ])
                            ],
                            1
                          )
                        ]
                      }
                    }
                  ])
                },
                [
                  _vm.stateIsImpersonated
                    ? _c(
                        "v-list",
                        { staticClass: "cursor-pointer" },
                        [
                          _c(
                            "v-list-tile",
                            [
                              _c(
                                "v-list-tile-title",
                                {
                                  on: {
                                    click: function($event) {
                                      return _vm.returnToUser()
                                    }
                                  }
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.$t("BUTTON.RETURN_TO_USER")) +
                                      " "
                                  )
                                ]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "v-list",
                    { staticClass: "cursor-pointer" },
                    _vm._l(_vm.stateGetUserMenuItems, function(item, index) {
                      return _c(
                        "v-list-tile",
                        { key: index },
                        [
                          _c(
                            "v-list-tile-title",
                            {
                              on: {
                                click: function($event) {
                                  return _vm.routeTo(item.routeTo)
                                }
                              }
                            },
                            [_vm._v(" " + _vm._s(item.label) + " ")]
                          )
                        ],
                        1
                      )
                    }),
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }