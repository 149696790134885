<template>
<v-btn 
  color="editButton" 
  :class="classExtra +' ma2 white--text'"
  :to="to"
>
<v-icon class="pr-2">mdi-pencil</v-icon>{{ $t('BUTTON.EDIT') }}
</v-btn>
</template>

<script>
export default {
  props: {
    classExtra: {
      type: String
    },
    to: {
      type: String
    }
  },
  data () {
    return {
    }
  }
}
</script>