var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-btn",
    {
      class: _vm.classExtra + " ma2 white--text",
      attrs: { color: "editButton", to: _vm.to }
    },
    [
      _c("v-icon", { staticClass: "pr-2" }, [_vm._v("mdi-pencil")]),
      _vm._v(_vm._s(_vm.$t("BUTTON.EDIT")) + " ")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }