import Repository from '../Repository'
import Logger from '@/lib/Logger.js'
import Helpers from '@/lib/Helpers.js'
import * as DataTypes from './DocumentsRepositoryDataTypes'
import * as ApiTypes from '@/api/api-types'
import { store } from '@/store/store'

const moduleName = 'DocumentsRepository'

/**
 * A module for handling API calls for documents
 * @module DocumentsRepository
 */
export default {

  /**
   * Get List of Documents
   *
   * @returns {Array} Array of objects
   */
  async getDocumentList (customerId) {
    Logger.info(`${moduleName}.getDocumentList() with customerId ${customerId}`)
    return await Repository.get(
      Helpers.substituteApiVars(`${ApiTypes.GET_CUSTOMERS_ID_DOCUMENTS}`, { customerId: customerId }),
      DataTypes.documentList,
      `${moduleName}.getDocumentList()`
    )
  },

  /**
   * Post a new Document
   * 
   * @alias module: DocumentsRepository
   * @param {String | null} customerId
   * @param {Array} payload Array of name/value pairs for document properties
   * @returns {String} Document ID
   */
  async postDocument (customerId, payload) {
    Logger.info(`${moduleName}.postDocument() called with customerId: ${customerId}`)
    let endPoint = !customerId
      ? ApiTypes.POST_CUSTOMERS_DOCUMENTS_PUBLIC 
      : Helpers.substituteApiVars(`${ApiTypes.POST_CUSTOMERS_ID_DOCUMENTS}`, { customerId: customerId })
    return await Repository.postFormData(
      endPoint,
      payload,
      DataTypes.idAsString,
      `${moduleName}.postDocument()`
    )
  },

  /**
   * Get a specific document
   *
   * @alias module: DocumentsRepository
   * @param {String} id
   * @returns {Object} Document properties, without actual content
   */
  async getDocument (customerId, documentId) {
    Logger.info(`${moduleName}.getDocument(documentId): called with documentId ${documentId} and customerId: ${customerId}`)
    let endPoint = !customerId
      ? Helpers.substituteApiVars(`${ApiTypes.GET_CUSTOMERS_DOCUMENTS_ID_PUBLIC}`, { id: documentId }) 
      : Helpers.substituteApiVars(`${ApiTypes.GET_CUSTOMERS_ID_DOCUMENTS_ID}`, { customerId: customerId, id: documentId })
    return await Repository.get(
      endPoint,
      DataTypes.document,
      `${moduleName}.getDocument()`
    )
  },

  /**
   * Download a specific document
   *
   * @alias module: DocumentsRepository
   * @param {String} id
   * @returns {Object} Document properties, without actual content
   */
  async downloadDocument (customerId, documentId) {
    Logger.info(`${moduleName}.downloadDocument(documentId): called with documentId ${documentId} and customerId: ${customerId}`)
    let endPoint = !customerId
      ? Helpers.substituteApiVars(`${ApiTypes.GET_CUSTOMERS_DOCUMENTS_ID_PUBLIC}`, { id: documentId }) 
      : Helpers.substituteApiVars(`${ApiTypes.GET_CUSTOMERS_ID_DOCUMENTS_ID}`, { customerId: customerId, id: documentId })
    return await Repository.getBlob(
      endPoint,
      null,
      `${moduleName}.downloadDocument()`
    )
  },

  /**
   * Update document
   * 
   * @alias module: DocumentsRepository
   * @param {String | null} customerId
   * @param {Object} payload document object
   */
  async putDocument (customerId, payload) {
    Logger.info(`${moduleName}.putDocument(): called with customerId: ${customerId}`)
    let endPoint = !customerId
      ? Helpers.substituteApiVars(`${ApiTypes.PUT_CUSTOMERS_DOCUMENTS_ID_PUBLIC}`, { id: payload.id }) 
      : Helpers.substituteApiVars(`${ApiTypes.PUT_CUSTOMERS_ID_DOCUMENTS_ID}`, { id: payload.id, customerId: customerId })
    return await Repository.put(
      endPoint,
      payload,
      null,
      `${moduleName}.putDocument()`
    )
  },

  /**
   * Delete a document
   * 
   * @alias module: DocumentsRepository
   * @param {String | null} customerId
   * @param {String} id
   */
  async deleteDocument (customerId, id) {
    Logger.info(`${moduleName}.deleteDocument(id): called with customerId ${customerId} and id ${id}`)
    let endPoint = !customerId
      ? Helpers.substituteApiVars(`${ApiTypes.DELETE_CUSTOMERS_DOCUMENTS_ID_PUBLIC}`, { id: id }) 
      : Helpers.substituteApiVars(`${ApiTypes.DELETE_CUSTOMERS_ID_DOCUMENTS_ID}`, { customerId: customerId, id: id })
    return await Repository.delete(
      endPoint,
      `${moduleName}.deleteDocument()`
    )
  },

  /**
   * Post a new Link
   * 
   * @alias module: DocumentsRepository
   * @param {Array} payload Array of name/value pairs for link properties
   * @returns {String} Link ID
   */
  async postLink (payload) {
    Logger.info(`${moduleName}.postLink() called`)
    return await Repository.post(
      ApiTypes.POST_CUSTOMERS_DOCUMENTS_LINKS_PUBLIC,
      payload,
      DataTypes.idAsString,
      `${moduleName}.postLink()`
    )
  },

  /**
   * Get a specific link
   *
   * @alias module: DocumentsRepository
   * @param {String} id
   * @returns {Object} Link properties
   */
  async getLink (id) {
    Logger.info(`${moduleName}.getLink(id): called with id ${id}`)
    return await Repository.get(
      Helpers.substituteApiVars(`${ApiTypes.GET_CUSTOMERS_DOCUMENTS_LINKS_ID_PUBLIC}`, { id: id }),
      DataTypes.link,
      `${moduleName}.getLink()`
    )
  },

  /**
   * Update link
   * 
   * @alias module: DocumentsRepository
   * @param {Object} payload link object
   */
  async putLink (payload) {
    Logger.info(`${moduleName}.putLink(): called`)
    return await Repository.put(
      Helpers.substituteApiVars(`${ApiTypes.PUT_CUSTOMERS_DOCUMENTS_LINKS_ID_PUBLIC}`, { id: payload.id }),
      payload,
      null,
      `${moduleName}.putLink()`
    )
  },

  /**
   * Delete a link
   * 
   * @alias module: DocumentsRepository
   * @param {String} id
   */
  async deleteLink (id) {
    Logger.info(`${moduleName}.deleteLink(id): called with id ${id}`)
    return await Repository.delete(
      Helpers.substituteApiVars(`${ApiTypes.DELETE_CUSTOMERS_DOCUMENTS_LINKS_ID_PUBLIC}`, { id: id }),
      `${moduleName}.deleteLink()`
    )
  },

  /**
   * Get List of Categories
   *
   * @returns {Array} Array of objects
   */
  async getCategoryList () {
    Logger.info(`${moduleName}.getCategoryList()`)
    return await Repository.get(
      ApiTypes.GET_DOCUMENTS_CATEGORIES,
      DataTypes.categoryList,
      `${moduleName}.getCategoryList()`
    )
  },

  /**
   * Post a new Category
   * 
   * @alias module: DocumentsRepository
   * @param {Array} payload Array of name/value pairs for category properties
   * @returns {String} Category ID
   */
  async postCategory (payload) {
    Logger.info(`${moduleName}.postCategory() called`)
    let result = await Repository.post(
      `${ApiTypes.POST_DOCUMENTS_CATEGORIES}`,
      payload,
      DataTypes.idAsString,
      `${moduleName}.postCategories()`
    )
    store.dispatch('documents/loadCategoryList', { force: true })
    return result
  },

  /**
   * Get a specific category
   *
   * @alias module: DocumentsRepository
   * @param {String} id
   * @returns {Object} Category properties
   */
  async getCategory (id) {
    Logger.info(`${moduleName}.getCategory(id): called with id ${id}`)
    return await Repository.get(
      Helpers.substituteApiVars(ApiTypes.GET_DOCUMENTS_CATEGORIES_ID, { id: id }),
      DataTypes.category,
      `${moduleName}.getCategory()`
    )
  },

  /**
   * Update category
   * 
   * @alias module: DocumentsRepository
   * @param {Object} payload category object
   */
  async putCategory (payload) {
    Logger.info(`${moduleName}.putCategory(): called`)
    let result = await Repository.put(
      Helpers.substituteApiVars(`${ApiTypes.PUT_DOCUMENTS_CATEGORIES_ID}`, { id: payload.id }),
      payload,
      null,
      `${moduleName}.putCategory()`
    )
    store.dispatch('documents/loadCategoryList', { force: true })
    return result
  },

  /**
   * Delete a category
   * 
   * @alias module: DocumentsRepository
   * @param {String} id
   */
  async deleteCategory (id) {
    Logger.info(`${moduleName}.deleteCategory(id): called with id ${id}`)
    let result = await Repository.delete(
      Helpers.substituteApiVars(ApiTypes.DELETE_DOCUMENTS_CATEGORIES_ID, { id: id }),
      `${moduleName}.deleteCategory()`
    )
    store.dispatch('documents/loadCategoryList', { force: true })
    return result
  },

  /**
   * Get List of Types
   *
   * @returns {Array} Array of objects
   */
  async getTypeList () {
    Logger.info(`${moduleName}.getTypeList()`)
    return await Repository.get(
      ApiTypes.GET_DOCUMENTS_TYPES,
      DataTypes.typeList,
      `${moduleName}.getTypeList()`
    )
  },

}
