import Repository from "../Repository";
//import Logger from '@/lib/Logger.js'
import Helpers from "@/lib/Helpers.js";
import * as DataTypes from "./EquipmentRepositoryDataTypes";
import * as ApiTypes from "@/api/api-types";
import { store } from "@/store/store";
import moment from "moment";
import "moment/locale/nl";
const moduleName = "EquipmentRepository";

/**
 * A module for handling API calls for equipment
 * @module EquipmentRepository
 */
export default {
  /**
   * Get list of backup methods
   * @alias module: EquipmentRepository
   * @returns {Array} Array of Objects
   */
  async getBackupMethodList() {
    return await Repository.get(
      ApiTypes.GET_EQUIPMENT_BACKUP_METHODS,
      DataTypes.backupMethodList,
      `${moduleName}.getBackupMethodList()`
    );
  },

  /**
   * Get list of available network ports for an equipment
   * @alias module: EquipmentRepository
   * @param {String} equipmentId
   * @returns {Array} Array of Objects
   */
  async getAvailableNetworkPortList(equipmentId) {
    return await Repository.get(
      Helpers.substituteApiVars(
        ApiTypes.GET_EQUIPMENT_ID_AVAILABLE_NETWORK_PORTS,
        { equipmentId: equipmentId }
      ),
      DataTypes.availableNetworkPortList,
      `${moduleName}.getAvailableNetworkPortList()`
    );
  },

  /**
   * Get list of network ports for an equipment
   * @alias module: EquipmentRepository
   * @param {String} equipmentId
   * @returns {Array} Array of Objects
   */
  async getNetworkPortList(equipmentId) {
    return await Repository.get(
      Helpers.substituteApiVars(ApiTypes.GET_EQUIPMENT_ID_NETWORK_PORTS, {
        equipmentId: equipmentId,
      }),
      DataTypes.networkPortList,
      `${moduleName}.getNetworkPortList()`
    );
  },

  /**
   * Post a new network Port for an equipment
   *
   * @alias module: EquipmentRepository
   * @param {String} equipmentId
   * @param {Object} payload
   * @returns {Object}
   */
  async postNetworkPort(equipmentId, payload) {
    return await Repository.post(
      Helpers.substituteApiVars(ApiTypes.POST_EQUIPMENT_ID_NETWORK_PORTS, {
        equipmentId: equipmentId,
      }),
      payload,
      DataTypes.idAsString,
      `${moduleName}.postNetworkPort()`
    );
  },

  /**
   * Get a network port of an equipment
   * @alias module: EquipmentRepository
   * @param {String} equipmentId
   * @param {String} id
   * @returns {Object} Object
   */
  async getNetworkPort(equipmentId, id) {
    return await Repository.get(
      Helpers.substituteApiVars(ApiTypes.GET_EQUIPMENT_ID_NETWORK_PORTS_ID, {
        equipmentId: equipmentId,
        id: id,
      }),
      DataTypes.networkPort,
      `${moduleName}.getNetworkPort()`
    );
  },

  /**
   * Update a network port for an equipment
   *
   * @alias module: EquipmentRepository
   * @param {String} equipmentId
   * @param {String} id
   * @param {Object} payload
   */
  async putNetworkPort(equipmentId, payload) {
    return await Repository.put(
      Helpers.substituteApiVars(ApiTypes.PUT_EQUIPMENT_ID_NETWORK_PORTS_ID, {
        equipmentId: equipmentId,
        id: payload.id,
      }),
      payload,
      null,
      `${moduleName}.putNetworkPort()`
    );
  },

  /**
   * Delete a network port for an equipment
   *
   * @alias module: EquipmentRepository
   * @param {String} equipmentId
   * @param {String} id
   */
  async deleteNetworkPort(equipmentId, id) {
    return await Repository.delete(
      Helpers.substituteApiVars(ApiTypes.DELETE_EQUIPMENT_ID_NETWORK_PORTS_ID, {
        equipmentId: equipmentId,
        id: id,
      }),
      `${moduleName}.deleteNetworkPort()`
    );
  },

  /**
   * Get name pool list
   * @alias module: EquipmentRepository
   * @returns {Array} Array of Objects
   */
  async getEquipmentNamePoolList() {
    return await Repository.get(
      ApiTypes.GET_EQUIPMENT_NAME_POOLS,
      DataTypes.namePoolList,
      `${moduleName}.getEquipmentNamePoolList()`
    );
  },

  /**
   * Get a random name for a pool
   *
   * @alias module: EquipmentRepository
   * @param {String} equipmentNamePoolId
   * @param {Object} payload
   * @returns {Object}
   */
  async postNamePoolRandomName(equipmentNamePoolId) {
    return await Repository.post(
      Helpers.substituteApiVars(
        ApiTypes.POST_EQUIPMENT_NAME_POOLS_ID_RANDOM_NAME,
        { equipmentNamePoolId: equipmentNamePoolId }
      ),
      {},
      DataTypes.namePoolRandomName,
      `${moduleName}.postNamePoolRandomName()`
    );
  },

  /**
   * Get list of equipment names
   * @alias module: EquipmentRepository
   * @returns {Array} Array of Objects
   */
  async getEquipmentNameList() {
    return await Repository.get(
      ApiTypes.GET_EQUIPMENT_NAMES,
      DataTypes.equipmentNameList,
      `${moduleName}.getEquipmentNameList()`
    );
  },

  /**
   * Post a new equipment name
   *
   * @alias module: EquipmentRepository
   * @param {Object} payload
   * @returns {Object}
   */
  async postEquipmentName(payload) {
    return await Repository.post(
      ApiTypes.POST_EQUIPMENT_NAMES,
      payload,
      DataTypes.idAsString,
      `${moduleName}.postEquipmentName()`
    );
  },

  /**
   * Get an equipment name
   * @alias module: EquipmentRepository
   * @param {String} id
   * @returns {Object} Object
   */
  async getEquipmentName(id) {
    return await Repository.get(
      Helpers.substituteApiVars(ApiTypes.GET_EQUIPMENT_NAMES_ID, { id: id }),
      DataTypes.equipmentName,
      `${moduleName}.getEquipmentName()`
    );
  },

  /**
   * Update an equipment name
   *
   * @alias module: EquipmentRepository
   * @param {Object} payload
   */
  async putEquipmentName(payload) {
    return await Repository.put(
      Helpers.substituteApiVars(ApiTypes.PUT_EQUIPMENT_NAMES_ID, {
        id: payload.id,
      }),
      payload,
      null,
      `${moduleName}.putEquipmentName()`
    );
  },

  /**
   * Delete an equipment name
   *
   * @alias module: EquipmentRepository
   * @param {String} id
   */
  async deleteEquipmentName(id) {
    return await Repository.delete(
      Helpers.substituteApiVars(ApiTypes.DELETE_EQUIPMENT_NAMES_ID, { id: id }),
      `${moduleName}.deleteEquipmentName()`
    );
  },

  /**
   * Get list of installers
   * @alias module: EquipmentRepository
   * @returns {Array} Array of Objects
   */
  async getInstallerList() {
    return await Repository.get(
      ApiTypes.GET_EQUIPMENT_INSTALLERS,
      DataTypes.installerList,
      `${moduleName}.getInstallerList()`
    );
  },

  /**
   * Get list of OS versions
   * @alias module: EquipmentRepository
   * @returns {Array} Array of Objects
   */
  async getOsVersionList() {
    return await Repository.get(
      `${ApiTypes.GET_EQUIPMENT_OS_VERSIONS}?listType=all`,
      DataTypes.osVersionList,
      `${moduleName}.getOsVersionList()`
    );
  },

  /**
   * Post a new OS version
   *
   * @alias module: EquipmentRepository
   * @param {Object} payload
   */
  async postOsVersion(payload) {
    let result = await Repository.post(
      ApiTypes.POST_EQUIPMENT_OS_VERSIONS,
      payload,
      DataTypes.idAsString,
      `${moduleName}.postOsVersion()`
    );
    store.dispatch("equipment/loadOsVersionList", { force: true });
    return result;
  },

  /**
   * Get a OS version
   * @alias module: EquipmentRepository
   * @returns {Object} Object
   */
  async getOsVersion(id) {
    return await Repository.get(
      Helpers.substituteApiVars(ApiTypes.GET_EQUIPMENT_OS_VERSIONS_ID, {
        id: id,
      }),
      DataTypes.osVersion,
      `${moduleName}.getOsVersion()`
    );
  },

  /**
   * Update a OS version
   *
   * @alias module: EquipmentRepository
   * @param {Object} payload
   */
  async putOsVersion(payload) {
    let result = await Repository.put(
      Helpers.substituteApiVars(ApiTypes.PUT_EQUIPMENT_OS_VERSIONS_ID, {
        id: payload.id,
      }),
      payload,
      null,
      `${moduleName}.putOsVersion()`
    );
    store.dispatch("equipment/loadOsVersionList", { force: true });
    return result;
  },

  /**
   * Delete a OS version
   *
   * @alias module: EquipmentRepository
   * @param {String} id
   */
  async deleteOsVersion(id) {
    let result = await Repository.delete(
      Helpers.substituteApiVars(ApiTypes.DELETE_EQUIPMENT_OS_VERSIONS_ID, {
        id: id,
      }),
      `${moduleName}.deleteOsVersion()`
    );
    store.dispatch("equipment/loadOsVersionList", { force: true });
    return result;
  },

  /**
   * Get list of racks
   * @alias module: EquipmentRepository
   * @returns {Array} Array of Objects
   */
  async getRackList(listFormat = "default") {
    return await Repository.get(
      `${ApiTypes.GET_EQUIPMENT_RACKS}?listFormat=${listFormat}`,
      DataTypes.rackList,
      `${moduleName}.getRackList()`
    );
  },

  /**
   * Post a new rack
   *
   * @alias module: EquipmentRepository
   * @param {Object} payload
   */
  async postRack(payload) {
    let result = await Repository.post(
      ApiTypes.POST_EQUIPMENT_RACKS,
      payload,
      DataTypes.idAsString,
      `${moduleName}.postRack()`
    );
    store.dispatch("equipment/loadRackList", { force: true });
    return result;
  },

  /**
   * Get a rack
   * @alias module: EquipmentRepository
   * @returns {Object} Object
   */
  async getRack(id) {
    return await Repository.get(
      Helpers.substituteApiVars(ApiTypes.GET_EQUIPMENT_RACKS_ID, { id: id }),
      DataTypes.rack,
      `${moduleName}.getRack()`
    );
  },

  /**
   * Update a rack
   *
   * @alias module: EquipmentRepository
   * @param {Object} payload
   */
  async putRack(payload) {
    let result = await Repository.put(
      Helpers.substituteApiVars(ApiTypes.PUT_EQUIPMENT_RACKS_ID, {
        id: payload.id,
      }),
      payload,
      null,
      `${moduleName}.putRack()`
    );
    store.dispatch("equipment/loadRackList", { force: true });
    return result;
  },

  /**
   * Delete a rack
   *
   * @alias module: EquipmentRepository
   * @param {String} id
   */
  async deleteRack(id) {
    let result = await Repository.delete(
      Helpers.substituteApiVars(ApiTypes.DELETE_EQUIPMENT_RACKS_ID, { id: id }),
      `${moduleName}.deleteRack()`
    );
    store.dispatch("equipment/loadRackList", { force: true });
    return result;
  },

  /**
   * Get Rack layout
   * @alias module: EquipmentRepository
   * @param {String} id
   * @returns {Array} Array of Objects
   */
  async getRackLayout(id) {
    return await Repository.get(
      Helpers.substituteApiVars(ApiTypes.GET_EQUIPMENT_RACKS_ID_LAYOUT, {
        id: id,
      }),
      DataTypes.rackLayout,
      `${moduleName}.getRackLayout()`
    );
  },

  /**
   * Get list of servers
   * @alias module: EquipmentRepository
   * @returns {Array} Array of Objects
   */
  async getServerList() {
    return await Repository.get(
      `${ApiTypes.GET_EQUIPMENT_SERVERS}?listType=all`,
      DataTypes.serverList,
      `${moduleName}.getServerList()`
    );
  },

  /**
   * Post a new server
   *
   * @alias module: EquipmentRepository
   * @param {Object} payload
   */
  async postServer(payload) {
    return await Repository.post(
      ApiTypes.POST_EQUIPMENT_SERVERS,
      payload,
      DataTypes.idAsString,
      `${moduleName}.postServer()`
    );
  },

  /**
   * Get a server
   * @alias module: EquipmentRepository
   * @returns {Object} Object
   */
  async getServer(id) {
    return await Repository.get(
      Helpers.substituteApiVars(ApiTypes.GET_EQUIPMENT_SERVERS_ID, { id: id }),
      DataTypes.server,
      `${moduleName}.getServer()`
    );
  },

  /**
   * Update a server
   *
   * @alias module: EquipmentRepository
   * @param {Object} payload
   */
  async putServer(payload) {
    return await Repository.put(
      Helpers.substituteApiVars(ApiTypes.PUT_EQUIPMENT_SERVERS_ID, {
        id: payload.id,
      }),
      payload,
      null,
      `${moduleName}.putServer()`
    );
  },

  /**
   * Delete a server
   *
   * @alias module: EquipmentRepository
   * @param {String} id
   */
  async deleteServer(id) {
    return await Repository.delete(
      Helpers.substituteApiVars(ApiTypes.DELETE_EQUIPMENT_SERVERS_ID, {
        id: id,
      }),
      `${moduleName}.deleteServer()`
    );
  },

  /**
   * Get next server alias
   * @alias module: EquipmentRepository
   * @returns {Object} Object
   */
  async getNextServerAlias() {
    return await Repository.get(
      ApiTypes.GET_EQUIPMENT_NEXT_SERVER_ALIAS,
      DataTypes.nextServerAlias,
      `${moduleName}.getNextServerAlias()`
    );
  },

  /**
   * Get next VM alias
   * @alias module: EquipmentRepository
   * @returns {Object} Object
   */
  async getNextVmAlias() {
    return await Repository.get(
      ApiTypes.GET_EQUIPMENT_NEXT_VM_ALIAS,
      DataTypes.nextVmAlias,
      `${moduleName}.getNextVmAlias()`
    );
  },

  /**
   * Get list of service levels
   * @alias module: EquipmentRepository
   * @returns {Array} Array of Objects
   */
  async getServiceLevelList(listFormat = "default") {
    return await Repository.get(
      `${ApiTypes.GET_EQUIPMENT_SERVICE_LEVELS}?listFormat=${listFormat}`,
      DataTypes.serviceLevelList,
      `${moduleName}.getServiceLevelList()`
    );
  },

  /**
   * Post a new service level
   *
   * @alias module: EquipmentRepository
   * @param {Object} payload
   */
  async postServiceLevel(payload) {
    let result = await Repository.post(
      ApiTypes.POST_EQUIPMENT_SERVICE_LEVELS,
      payload,
      DataTypes.idAsString,
      `${moduleName}.postServiceLevel()`
    );
    store.dispatch("equipment/loadServiceLevelList", { force: true });
    return result;
  },

  /**
   * Get a service level
   * @alias module: EquipmentRepository
   * @returns {Object} Object
   */
  async getServiceLevel(id) {
    return await Repository.get(
      Helpers.substituteApiVars(ApiTypes.GET_EQUIPMENT_SERVICE_LEVELS_ID, {
        id: id,
      }),
      DataTypes.serviceLevel,
      `${moduleName}.getServiceLevel()`
    );
  },

  /**
   * Update a service level
   *
   * @alias module: EquipmentRepository
   * @param {Object} payload
   */
  async putServiceLevel(payload) {
    let result = await Repository.put(
      Helpers.substituteApiVars(ApiTypes.PUT_EQUIPMENT_SERVICE_LEVELS_ID, {
        id: payload.id,
      }),
      payload,
      null,
      `${moduleName}.putServiceLevel()`
    );
    store.dispatch("equipment/loadServiceLevelList", { force: true });
    return result;
  },

  /**
   * Delete a service level
   *
   * @alias module: EquipmentRepository
   * @param {String} id
   */
  async deleteServiceLevel(id) {
    let result = await Repository.delete(
      Helpers.substituteApiVars(ApiTypes.DELETE_EQUIPMENT_SERVICE_LEVELS_ID, {
        id: id,
      }),
      `${moduleName}.deleteServiceLevel()`
    );
    store.dispatch("equipment/loadServiceLevelList", { force: true });
    return result;
  },

  /**
   * Get list of statuses
   * @alias module: EquipmentRepository
   * @returns {Array} Array of Objects
   */
  async getStatusList(listFormat = "default") {
    return await Repository.get(
      `${ApiTypes.GET_EQUIPMENT_STATUSES}?listFormat=${listFormat}`,
      DataTypes.statusList,
      `${moduleName}.getStatusList()`
    );
  },

  /**
   * Get list of switches
   * @alias module: EquipmentRepository
   * @returns {Array} Array of Objects
   */
  async getNetworkSwitchList(listFormat = "default") {
    return await Repository.get(
      `${ApiTypes.GET_EQUIPMENT_SWITCHES}?listType=all&listFormat=${listFormat}`,
      DataTypes.networkSwitchList,
      `${moduleName}.getNetworkSwitchList()`
    );
  },

  /**
   * Post a new switch
   *
   * @alias module: EquipmentRepository
   * @param {Object} payload
   */
  async postNetworkSwitch(payload) {
    let result = await Repository.post(
      ApiTypes.POST_EQUIPMENT_SWITCHES,
      payload,
      DataTypes.idAsString,
      `${moduleName}.postNetworkSwitch()`
    );
    store.dispatch("equipment/loadSwitchList", { force: true });
    return result;
  },

  /**
   * Get a switch
   * @alias module: EquipmentRepository
   * @returns {Object} Object
   */
  async getNetworkSwitch(id) {
    return await Repository.get(
      Helpers.substituteApiVars(ApiTypes.GET_EQUIPMENT_SWITCHES_ID, { id: id }),
      DataTypes.networkSwitch,
      `${moduleName}.getNetworkSwitch()`
    );
  },

  /**
   * Update a switch
   *
   * @alias module: EquipmentRepository
   * @param {Object} payload
   */
  async putNetworkSwitch(payload) {
    let result = await Repository.put(
      Helpers.substituteApiVars(ApiTypes.PUT_EQUIPMENT_SWITCHES_ID, {
        id: payload.id,
      }),
      payload,
      null,
      `${moduleName}.putNetworkSwitch()`
    );
    store.dispatch("equipment/loadSwitchList", { force: true });
    return result;
  },

  /**
   * Delete a switch
   *
   * @alias module: EquipmentRepository
   * @param {String} id
   */
  async deleteNetworkSwitch(id) {
    let result = await Repository.delete(
      Helpers.substituteApiVars(ApiTypes.DELETE_EQUIPMENT_SWITCHES_ID, {
        id: id,
      }),
      `${moduleName}.deleteNetworkSwitch()`
    );
    store.dispatch("equipment/loadSwitchList", { force: true });
    return result;
  },

  /**
   * Get list of virtual machines
   * @alias module: EquipmentRepository
   * @returns {Array} Array of Objects
   */
  async getVirtualMachineList() {
    return await Repository.get(
      `${ApiTypes.GET_EQUIPMENT_VIRTUAL_MACHINES}?ListType=all`,
      DataTypes.virtualMachineList,
      `${moduleName}.getVirtualMachineList()`
    );
  },

  /**
   * Post a new virtual machine
   *
   * @alias module: EquipmentRepository
   * @param {Object} payload
   */
  async postVirtualMachine(payload) {
    return await Repository.post(
      ApiTypes.POST_EQUIPMENT_VIRTUAL_MACHINES,
      payload,
      DataTypes.idAsString,
      `${moduleName}.postVirtualMachine()`
    );
  },

  /**
   * Get a virtual machine
   * @alias module: EquipmentRepository
   * @returns {Object} Object
   */
  async getVirtualMachine(id) {
    return await Repository.get(
      Helpers.substituteApiVars(ApiTypes.GET_EQUIPMENT_VIRTUAL_MACHINES_ID, {
        id: id,
      }),
      DataTypes.virtualMachine,
      `${moduleName}.getVirtualMachine()`
    );
  },

  /**
   * Update a virtual machine
   *
   * @alias module: EquipmentRepository
   * @param {Object} payload
   */
  async putVirtualMachine(payload) {
    return await Repository.put(
      Helpers.substituteApiVars(ApiTypes.PUT_EQUIPMENT_VIRTUAL_MACHINES_ID, {
        id: payload.id,
      }),
      payload,
      null,
      `${moduleName}.putVirtualMachine()`
    );
  },

  /**
   * Delete a virtual machine
   *
   * @alias module: EquipmentRepository
   * @param {String} id
   */
  async deleteVirtualMachine(id) {
    return await Repository.delete(
      Helpers.substituteApiVars(ApiTypes.DELETE_EQUIPMENT_VIRTUAL_MACHINES_ID, {
        id: id,
      }),
      `${moduleName}.deleteVirtualMachine()`
    );
  },

  /**
   * Get list of physical networked equipment
   * @alias module: EquipmentRepository
   * @returns {Array} Array of Objects
   */
  async getPhysicalNetworkedEquipmentList(listFormat = "default") {
    return await Repository.get(
      `${ApiTypes.GET_EQUIPMENT_PHYSICAL_NETWORKED_EQUIPMENT}?listFormat=${listFormat}`,
      DataTypes.physicalNetworkedEquipmentList,
      `${moduleName}.getPhysicalNetworkedEquipmentList()`
    );
  },

  /**
   * Get list of Misc equipment
   * @alias module: EquipmentRepository
   * @returns {Array} Array of Objects
   */
  async getMiscEquipmentItemList() {
    return await Repository.get(
      ApiTypes.GET_EQUIPMENT_MISC,
      DataTypes.miscEquipmentItemList,
      `${moduleName}.getMiscEquipmentItemList()`
    );
  },

  /**
   * Post a new misc equipment Item
   *
   * @alias module: EquipmentRepository
   * @param {Object} payload
   * @returns {Object}
   */
  async postMiscEquipmentItem(payload) {
    return await Repository.post(
      ApiTypes.POST_EQUIPMENT_MISC,
      payload,
      DataTypes.idAsString,
      `${moduleName}.postMiscEquipmentItem()`
    );
  },

  /**
   * Get a misc equipment item
   * @alias module: EquipmentRepository
   * @param {String} id
   * @returns {Object} Object
   */
  async getMiscEquipmentItem(id) {
    return await Repository.get(
      Helpers.substituteApiVars(ApiTypes.GET_EQUIPMENT_MISC_ID, { id: id }),
      DataTypes.miscEquipmentItem,
      `${moduleName}.getMiscEquipmentItem()`
    );
  },

  /**
   * Update a misc equipment item
   *
   * @alias module: EquipmentRepository
   * @param {Object} payload
   */
  async putMiscEquipmentItem(payload) {
    return await Repository.put(
      Helpers.substituteApiVars(ApiTypes.PUT_EQUIPMENT_MISC_ID, {
        id: payload.id,
      }),
      payload,
      null,
      `${moduleName}.putMiscEquipmentItem()`
    );
  },

  /**
   * Delete a misc equipment item
   *
   * @alias module: EquipmentRepository
   * @param {String} id
   */
  async deleteMiscEquipmentItem(id) {
    return await Repository.delete(
      Helpers.substituteApiVars(ApiTypes.DELETE_EQUIPMENT_MISC_ID, { id: id }),
      `${moduleName}.deleteMiscEquipmentItem()`
    );
  },

  /**
   * Get list of equipment resource changes
   * @alias module: EquipmentRepository
   * @returns {Array} Array of Objects
   */
  async getChangedEquipmentResourcesList(fromDate = null, toDate = null) {
    let today = new Date();
    let lastMonth = new Date();
    lastMonth.setDate(today.getDate() - 30);
    moment.locale(localStorage.getItem("language"));
    fromDate = fromDate ? fromDate : moment(lastMonth).format("YYYY-MM-DD");
    toDate = toDate ? toDate : moment(today).format("YYYY-MM-DD");

    return await Repository.get(
      `${ApiTypes.GET_EQUIPMENT_RESOURCES}?fromDate=${fromDate}&toDate=${toDate}`,
      DataTypes.changedEquipmentResourcesList,
      `${moduleName}.getChangedEquipmentResourcesList()`
    );
  },

  /**
   * Get equipment resources
   * @alias module: EquipmentRepository
   * @param {String} id
   * @returns {Object} Object
   */
  async getEquipmentResourcesVM(id) {
    return await Repository.get(
      Helpers.substituteApiVars(ApiTypes.GET_EQUIPMENT_RESOURCES_ID, {
        id: id,
      }),
      DataTypes.equipmentResourcesVM,
      `${moduleName}.getEquipmentResourcesVM()`
    );
  },
};
